import { Box, Grid, Typography } from "@mui/material";
import React from "react";

const BillingCount = ({element, count=0}) => {
  return (
    <>
      <Grid item>
        <Box
          sx={{
            width: "80px",
            height: "80px",
            borderRadius: "50%",
            border: "2px solid #ffffff",
            backgroundColor: "#002c6b",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            boxShadow:"0px 0px 10px #00000029"
          }}
        >
          <Typography variant="h6" sx={{color:"#ffffff", fontSize:"26px"}}>
            {count}
          </Typography>
          {/* <Typography variant="body1" marginTop={0} sx={{color:"#ffffff", fontSize:"18px"}}>
            of 5000
          </Typography> */}
        </Box>
        <Typography variant="subtitle1" align="center" fontSize={"18px"}>{element}</Typography>
      </Grid>
    </>
  );
};

export default BillingCount;
