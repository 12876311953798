import React, { useState, useEffect, useReducer } from "react";
import { useParams } from "react-router";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  TextField,
  Button,
  IconButton,
  Select,
  Radio,
  CircularProgress,
  TextareaAutosize,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useLocation, useNavigate } from "react-router";
import { ProviderGroupApi } from "../../../../../service/ProviderGroup";
import cardStyle from "../../../../../theme/card-layout";
import Input from "../../../Patients/components/Patient/Input";
import { CustomScrollbars } from "../../../../../ui-component/ScrollBar";
import { useNotification } from "../../../../../hooks/use-notification";
import { ProvidersApi } from "../../../../../service/ProviderGroup/Providers";
import CircularLoader from "../../../../../ui-component/CircularLoader";
import avatar from "../../../../../assets/adminAvatar.jpg";
import authReducer from '../../../../../contexts/auth/auth-reducer';
import AuthActions from '../../../../../contexts/auth/auth-actions';
import { loadState } from "../../../../../helpers/storage";
import { languageOptions } from "../../../../../ccm-constant";
import UserAuditLog from "./UserAuditLog";
const containerStyle = {
  position: "relative",
};

const imageContainerStyle = {
  position: "relative",
  // display: "flex !important",
  // alignItems: "center !important",
  // minHeight: "180px",
};

const cameraIconStyle = {
  position: "absolute",
  bottom: -15,
  left: "6rem",
  transform: "translateX(-50%)",
  backgroundColor: "white",
  borderRadius: "50%",
};

const field = [
  {
    label: "Provider Group",
    fieldName: "provider_group",
    fieldType: TextField,
    disable: true,
    placeholder: "Enter Provider Group",
  },
  {
    label: "Speciality",
    fieldName: "specialty",
    fieldType: Select,
    options: [],
    placeholder: "Select Speciality",
  },
  {
    label: "First Name",
    fieldName: "first_name",
    fieldType: TextField,
    placeholder: "Enter First Name",
  },
  {
    label: "Last Name",
    fieldName: "last_name",
    fieldType: TextField,
    placeholder: "Enter Last Name",
  },
  {
    label: "Role",
    fieldName: "role",
    fieldType: Select,
    placeholder: "Select Role",
    disable: true,
    options: [
      {
        name: "CARETEAM",
        label: "Care Team",
      },
      {
        name: "PHYSICIAN",
        label: "Physician",
      },
      {
        name: "NON_CLINICAL",
        label: "Non Clinical",
      },
    ],

  },
  {
    label: "Provider Type",
    fieldName: "provider_type",
    fieldType: Select,
    placeholder: "Select Provider Type",
    options: [],
  },
  {
    label: "Email ID",
    fieldName: "email",
    fieldType: TextField,
    disable: true,
    placeholder: "Enter Email ID",
  },
  {
    label: "Phone Number",
    fieldName: "phone_number",
    fieldType: "Phone",
    placeholder: "Enter Phone Number",
  },
  {
    label: "Gender",
    fieldName: "gender",
    fieldType: Radio,
    fieldValue: ["Male", "Female", "Other"],
  },
  {
    label: "Preferred Languages",
    fieldName: "language",
    fieldType: Select,
    placeholder: "Select Language",
    options: languageOptions,
  },

  {
    label: "NPI Number",
    fieldName: "npi",
    fieldType: TextField,
    placeholder: "Enter NPI Number",
  },
  {
    label: "License Number",
    fieldName: "license_number",
    fieldType: TextField,
    placeholder: "Enter License Number",
  },
  {
    label: "Year Of Experience",
    fieldName: "year_of_experience",
    fieldType: TextField,
    placeholder: "Enter Year Of Experience",
  },
  {
    label: "Address Line 1",
    fieldName: "address_line_1",
    fieldType: TextField,
    placeholder: "Enter Address Line 1",
  },
  {
    label: "Address Line 2",
    fieldName: "address_line_2",
    placeholder: "Enter Address Line 2",
    fieldType: TextField,
  },
  {
    label: "State",
    fieldName: "state",
    fieldType: TextField,
    placeholder: "Enter State",
  },
  {
    label: "City",
    fieldName: "city",
    fieldType: TextField,
    placeholder: "Enter City",
  },
  {
    label: "Country",
    fieldName: "country",
    fieldType: TextField,
    placeholder: "Enter Country",
  },
  {
    label: "Zip Code",
    fieldName: "zip_code",
    fieldType: TextField,
    placeholder: "Enter Zip Code",
  },
];

function ProviderProfileDetails({ saveClick, setSave, setEdit, setLoading, edit }) {
  const formRef = React.createRef();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  let id = JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid;
  const { notifySuccess, notifyError } = useNotification();
  const [editMode, setEditMode] = useState(edit);
  const [userData, setUserData] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [updateloading, setUpdateLoading] = useState(false);
  const [fields, setFields] = useState(field);
  const [pageN, setPageN] = useState(location?.state?.pn);
  const [imageLoading, setImageLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(true);
  const initState = loadState('user') ?? {
    tokens: JSON.parse(localStorage.getItem("user"))?.tokens,
    user: JSON.parse(localStorage.getItem("user"))?.user
  };
  const [selectedType, setSelectedType] = useState()
  console.log("selectedType", selectedType);
  const [state, dispatch] = useReducer(authReducer, initState);
  useEffect(() => {
    formRef?.current?.resetForm();
    formRef?.current?.setErrors({});
    setEditMode(edit)
  }, [edit])

  useEffect(() => {
    console.log("formRef 6666", formRef);
    formRef.current?.validateForm();
    if (saveClick && formRef.current) {
      formRef.current?.submitForm();
      if (!formRef.current?.errors) {
        handleSaveClick(formRef.current.values);
      } else {
        setSave(false);
      }
    }
  }, [saveClick]);

  const handleSaveClick = (values) => {
    const payload = {
      // provider_group: values?.provider_group,
      speciality: values?.specialty,
      first_name: values?.first_name,
      last_name: values?.last_name,
      provider_type: values?.provider_type,
      address: {
        address_line_1: values?.address_line_1,
        address_line_2: values?.address_line_2,
        state: values?.state,
        city: values?.city,
        country: values?.country,
        zip_code: values?.zip_code,
      },
      role: values?.role,
      phone_number: values?.phone_number,
      gender:
        values?.gender === "Male"
          ? "M"
          : values?.gender === "Female"
            ? "F"
            : "O",
      language: values?.language,
      npi: values?.npi,
      license_number: values?.license_number,
      year_of_experience: values?.year_of_experience,
      bio: values?.bio,
    };

    setUpdateLoading(true);
    setLoading(true);
    ProviderGroupApi.updateProvider(id, payload)
      .then((response) => {
        console.log("ProviderGroupApi response", response.data);
        if (id && values?.profile_pic !== userData?.profile_pic) {
          ProvidersApi.AddProviderImage(id, {
            profile_pic: values?.profile_pic,
          })
            .then((response) => {
              notifySuccess("Provider Updated Successfully!");
              setEdit(false);
              setSave(false);
              setLoading(false);
              navigate(`/settings`, {
                state: {
                  pn: pageN,
                },
              });
              formRef?.current?.resetForm();
              getCurrentData();
            })
            .catch((error) => {
              setUpdateLoading(false);
              setSave(false);
              setLoading(false);
              console.error("Error fetching data:", error);
              notifyError(error?.response?.data?.error);
            });
        } else {
          notifySuccess("Provider Updated Successfully!");
          setEdit(false);
          setSave(false);
          setLoading(false);
          navigate(`/settings`, {
            state: {
              pn: pageN,
            },
          });
          formRef?.current?.resetForm();
          getCurrentData()
        }
        setUserData(response.data);
      })
      .catch((error) => {
        setSave(false);
        setLoading(false);
        notifyError(error?.response?.data?.error);
      });
  };

  useEffect(() => {
    if(selectedType)
    getProviderTypesData();
  }, [selectedType])


  const getCurrentData = () => {
    setDataLoading(true);
    if (id)
      ProviderGroupApi.getProvider(id)
        .then((response) => {
          if (response?.data) {
            let user = { ...response }
            console.log("first user", user)
            dispatch({
              type: AuthActions.setUser,
              payload: {
                user,
                tokens: JSON.parse(localStorage.getItem("user"))?.tokens
              }
            });
          }
          console.log("ProviderGroupApi response", response.data);
          setUserData(response.data);
          setSelectedType(response?.data?.role)
          setSelectedImage(response?.data?.profile_pic || avatar);
          setDataLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
  }
  useEffect(() => {
    getCurrentData()
  }, []);

  const getProviderTypesData = () => {
    let MapSpecialityData = [];
    let MapData = [];
    let mapData = [];
    console.log("failed api")
    ProviderGroupApi.getProviderType(selectedType)
      .then((response) => {
        if (response?.data)
          response?.data?.forEach((element) => {
            MapData.push({
              // label: `${element?.initials} - ${element?.name}`,
              label: element?.initials,
              name: element?.initials,
            });
            console.log("ProviderGroupApi response", MapData);
          });
      })
      .catch((error) => {
        // notifyError(error?.response?.data?.error);
      });

    ProviderGroupApi.getProviderSpeciality()
      .then((response) => {
        if (response?.data)
          response?.data?.forEach((element) => {
            MapSpecialityData.push({
              name: element,
              label: element,
            });
          });
      })
      .catch((error) => {
        // notifyError(error?.response?.data?.error);
      });

    fields?.map((ele) => {
      if (ele?.fieldName === "specialty") {
        mapData.push({
          label: "Speciality",
          fieldName: "specialty",
          fieldType: Select,
          placeholder: "Select Speciality",
          options: MapSpecialityData,
          defaultData: userData?.speciality,
        });
      } else if (ele?.fieldName === "provider_type") {
        mapData.push({
          label: "Provider Type",
          fieldName: "provider_type",
          fieldType: Select,
          placeholder: "Select Provider Type",
          options: MapData,
          defaultData: userData?.provider_type_initials,
        });
      } else {
        mapData?.push(ele);
      }
    });
    setFields(mapData);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email ID address")
      .required("Email ID is required"),
    first_name: Yup.string()
      // .max(50, "First Name should not exceed 50 characters")
      .required("First Name is required"),
    last_name: Yup.string()
      // .max(50, "Last Name should not exceed 50 characters")
      .required("Last Name is required"),
    phone_number: Yup.string()
      .matches(/^[\d\s()+-]+$/, "Invalid Phone number")
      .min(10, "Phone number is too short")
      .max(20, "Phone number is too long")
      .required("Phone number is required"),
    address_line_1: Yup.string()
      // .max(50, "Address Line 1 should not exceed 50 characters")
      .required("Address Line 1 is required"),
    // bio: Yup.string().max(250, "Bio must not exceed 250 characters"),
    zip_code: Yup.string()
      .matches(
        /^[0-9]{5,6}$/,
        "Zip code must be 5 to 6 digits and contain only numbers"
      )
      .required("Zip Code is required"),
    specialty: Yup.array().min(1, "At least one speciality is required"), // Ensure at least one speciality is selected
    npi: Yup.string()
      .matches(
        /^[1-5][0-1][0-9]{8}$/,
        "NPI must be a 10-digit number with first number should between 1-5 and second should be 0 or 1"
      )
      .required("NPI is required"),
    language: Yup.string().required("Language is required"),
    state: Yup.string().required("State is required"),
    role: Yup.string().required("Role is required"),
    country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    provider_type: Yup.string().required("Provider Type is required"),
    year_of_experience: Yup.number()
      .required("Year Of Experience is required")
      .typeError("Year Of Experience must be a number")
      .positive("Year Of Experience must be a positive number")
      .min(0, "Year Of Experience must valid")
      .max(70, "Year Of Experience must be at most 70"),
  });

  const isSupportedFileType = (fileType) => {
    const supportedFormats = ["jpeg", "jpg", "png", "gif", "bmp"];
    return supportedFormats.some((format) => fileType.endsWith(format));
  };

  const scrollableFieldStyle = {
    height: "140px", // Set the desired height for your scrollable field
    overflow: "auto",
    width: "100%",
  };

  const isURL = (url) => {
    try {
      if (url.includes("https://")) {
        // new URL(url);
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  };

  return (
    <Grid container spacing={2} padding={3}>
      <Grid item xs={12}>
        <Card sx={{ boxShadow: 0, height: "70vh" }}>
          <div style={{ position: "relative", top: dataLoading ? "40%" : "10px", marginBottom: "1%" }}>
            {dataLoading ? (
              <Grid items>
                <CircularLoader />
              </Grid>
            ) : (
              <>
                {/* <CardContent sx={{ alignItems: "center" }}> */}
                <Formik
                  initialValues={{
                    provider_group: userData?.provider_group?.display_name,
                    specialty: userData?.speciality,
                    first_name: userData?.first_name,
                    last_name: userData?.last_name,
                    address_line_1: userData?.address?.address_line_1,
                    address_line_2: userData?.address?.address_line_2,
                    state: userData?.address?.state,
                    city: userData?.address?.city,
                    country: userData?.address?.country,
                    zip_code: userData?.address?.zip_code,
                    role: userData?.role,
                    phone_number: userData?.phone_number,
                    email: userData?.email,
                    gender:
                      userData?.gender === "M"
                        ? "Male"
                        : userData?.gender === "F"
                          ? "Female"
                          : "Other",
                    language: userData?.language,
                    npi: userData?.npi,
                    license_number: userData?.license_number,
                    profile_pic: userData?.profile_pic,
                    year_of_experience: userData?.year_of_experience,
                    provider_type: userData?.provider_type_initials,
                    bio: userData?.bio,
                  }}
                  innerRef={formRef}
                  onSubmit={(values) => handleSaveClick(values)}
                  validationSchema={validationSchema}
                  enableReinitialize
                >
                  {({ values, setFieldValue, handleSubmit }) => (
                    <Form>
                      <CustomScrollbars height={"65vh"}>
                        <Grid item xs={12}>
                          <Grid container xs={12} marginBottom={2}>
                            <Box
                              display="flex"
                              marginBottom={3}
                              width="97%"
                              margin={1}
                            >
                              {/* Image container */}
                              <Box
                                position="relative"
                                marginRight={{ xs: 0, sm: 2 }}
                                marginBottom={{ xs: 2, sm: 0 }}
                              >
                                {/* Backdrop overlay */}
                                {imageLoading && (
                                  <Box
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    width="100%"
                                    height="100%"
                                    backgroundColor="rgba(119, 118, 118, 0.4)"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    opacity={0.2}
                                    zIndex={2}
                                  >
                                    <CircularProgress
                                      size={50}
                                      sx={{
                                        color: "#ffffff",
                                        position: "absolute",
                                      }}
                                    />
                                  </Box>
                                )}

                                {/* User profile photo */}
                                <Avatar
                                  alt="User Profile Photo"
                                  src={
                                    (isURL(selectedImage)
                                      ? `${selectedImage}?${new Date().getTime()}`
                                      : selectedImage) || avatar
                                  }
                                  sx={{
                                    width: 200,
                                    height: 180,
                                    borderRadius: "0%",
                                    marginBottom: "0px",
                                    objectFit: "contain",
                                    padding: 1,
                                    backgroundColor: "#ffffff",
                                    boxShadow: "0px 0px 8px #00000029",
                                    visibility: imageLoading ? "hidden" : "visible",
                                  }}
                                  onError={(e) => {
                                    e.target.src = avatar;
                                    e.target.onerror = null;
                                    setImageLoading(false);
                                  }}
                                  onLoad={() => setImageLoading(false)}
                                />

                                {editMode && (
                                  <label
                                    htmlFor="image-upload"
                                    style={cameraIconStyle}
                                  >
                                    <input
                                      id="image-upload"
                                      type="file"
                                      onChange={(e) => {
                                        let file = null;
                                        file = e.target?.files[0];
                                        const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
                                        if (file) {
                                          if (!isSupportedFileType(file.type)) {
                                            notifyError(
                                              "Please select a valid image format, such as .jpeg, .jpg, .png, .gif or .bmp."
                                            );
                                          } else if (file.size > maxSize) {
                                            notifyError(
                                              "Image size should not exceed 5 MB."
                                            );
                                          } else {
                                            setFieldValue("profile_pic", file);
                                            const reader = new FileReader();
                                            reader.onload = (e) => {
                                              setSelectedImage(e.target.result);
                                            };
                                            reader.readAsDataURL(file);
                                          }
                                        }
                                      }}
                                      style={{ display: "none" }}
                                    />
                                    <IconButton
                                      color="primary"
                                      aria-label="upload picture"
                                      component="span"
                                    >
                                      <PhotoCameraIcon />
                                    </IconButton>
                                  </label>
                                )}
                              </Box>

                              {/* Bio container */}
                              <Box
                                display="flex"
                                flexDirection="column"
                                flexGrow={1}
                                width="97%"
                                margin={1}
                              >
                                <React.Fragment key={"bio"}>
                                  <Typography variant="subtitle1" marginRight={2}>
                                    Bio :
                                  </Typography>
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    // boxShadow={
                                    //   values?.bio
                                    //     ? "0px 0px 5px #00000020"
                                    //     : null
                                    // }
                                    width="97%"
                                  >
                                    {!editMode ? (
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          ...cardStyle,
                                          ...scrollableFieldStyle,
                                          "&:hover": {
                                            border: "0px",
                                          },
                                          // backgroundColor: "#ffffff",
                                          whiteSpace: "pre-line",
                                          textAlign: "justify",
                                          border: "0px",
                                          resize: "unset",
                                          margin: "2px",
                                          padding: "2px",
                                          // width:"100%" // Use margin instead of padding
                                        }}
                                      >
                                        {values?.bio}
                                      </Typography>
                                    ) : (
                                      <Box
                                        display="flex"
                                        flexDirection="column"
                                        flexGrow={1}

                                      // marginTop={1}
                                      >
                                        <TextareaAutosize
                                          className="css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input"
                                          placeholder="Enter bio"
                                          style={{
                                            ...scrollableFieldStyle,
                                            whiteSpace: "pre-line",
                                            resize: "unset",
                                            textAlign: "left",
                                            fontSize: "16px",
                                            fontFamily: theme?.typography?.fontFamily,
                                            // letterSpacing: "0px",
                                            // opacity: 1,
                                            // wordBreak: "break-word",
                                            // fontSize: "18px",
                                            // color: "#4C4C4CCC",
                                            boxShadow: "0px 0px 8px #00000029",
                                            border: "0px",
                                            padding: "10px",
                                            margin: 1,
                                            "&:hover": {
                                              border: "0px",
                                            },
                                            "&:focus": {
                                              outline: 0,
                                              border: "0px",
                                            },
                                          }}
                                          name="bio"
                                          defaultValue={values?.bio}
                                          onChange={(e) =>
                                            setFieldValue("bio", e.target.value)
                                          }
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                </React.Fragment>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid container xs={12} columnSpacing={5} rowSpacing={2}>
                            {fields?.slice(0, 11)?.map((field) => (
                              <Grid item xs={12} md={6}>
                                <React.Fragment key={field.key}>
                                  {!editMode && (
                                    <Grid container xs={12} spacing={2}>
                                      <Grid item xs={5}>
                                        <Typography variant="subtitle1">
                                          {field.label}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={7}>
                                        {
                                          <>
                                            <Typography
                                              variant="body1"
                                              component="div"
                                              sx={{ marginBottom: "5px" }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: 700,
                                                }}
                                              >
                                                :&nbsp;&nbsp;&nbsp;
                                              </span>
                                              {field?.fieldName === "speciality" ||
                                                field?.fieldName === "specialty"
                                                ? values[field?.fieldName]?.join(", ")
                                                : values[field?.fieldName]}
                                            </Typography>
                                          </>
                                        }
                                      </Grid>
                                    </Grid>
                                  )}
                                  {editMode && <Input data={field} />}
                                  <ErrorMessage
                                    name={field.fieldName}
                                    component="div"
                                    style={{
                                      color: "red",
                                      fontSize: "15px",
                                      marginBottom: 1,
                                      marginTop: "10px",
                                    }}
                                  />
                                </React.Fragment>
                              </Grid>
                            ))}
                            {!editMode ? (
                              <Grid item xs={12} md={6}>
                                <React.Fragment key={"address"}>
                                  <Grid container xs={12} spacing={2}>
                                    <Grid item xs={5}>
                                      <Typography variant="subtitle1">
                                        Address
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                      {
                                        <>
                                          <Typography
                                            variant="body1"
                                            component="div"
                                            sx={{ marginBottom: "5px" }}
                                          >
                                            <span style={{ fontWeight: 700 }}>
                                              :&nbsp;&nbsp;&nbsp;
                                            </span>
                                            {`${userData?.address?.address_line_1} ${userData?.address?.address_line_1
                                              ? ","
                                              : ""
                                              }
                                ${userData?.address?.address_line_2} ${userData?.address?.address_line_2
                                                ? ","
                                                : ""
                                              }
                                ${userData?.address?.city} ${userData?.address?.city ? "," : ""}
                                ${userData?.address?.state} ${userData?.address?.state ? "," : ""
                                              }
                                ${userData?.address?.country} ${userData?.address?.country ? "," : ""
                                              }
                                ${userData?.address?.zip_code}`}
                                          </Typography>
                                        </>
                                      }
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              </Grid>
                            ) : (
                              <>
                                {fields?.slice(11, 19)?.map((field) => (
                                  <Grid item xs={12} md={6}>
                                    <React.Fragment key={field.key}>
                                      {!editMode && (
                                        <Grid container xs={12} spacing={2}>
                                          <Grid item xs={5}>
                                            <Typography variant="subtitle1">
                                              {field.label}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={7}>
                                            {
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  component="div"
                                                  sx={{
                                                    marginBottom: "5px",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: 700,
                                                    }}
                                                  >
                                                    :&nbsp;&nbsp;&nbsp;
                                                  </span>
                                                  {values[field.fieldName]}
                                                </Typography>
                                              </>
                                            }
                                          </Grid>
                                        </Grid>
                                      )}
                                      {editMode && <Input data={field} />}
                                      <ErrorMessage
                                        name={field.fieldName}
                                        component="div"
                                        style={{
                                          color: "red",
                                          fontSize: "15px",
                                          marginBottom: 1,
                                          marginTop: "10px",
                                        }}
                                      />
                                    </React.Fragment>
                                  </Grid>
                                ))}
                              </>
                            )}
                          </Grid>
                        </Grid>
                        <Grid marginTop={"2vh"}>
                          <Typography variant="cardTitle"> Log List</Typography>
                          <UserAuditLog />
                        </Grid>
                      </CustomScrollbars>
                    </Form>
                  )}
                </Formik>
                {/* </CardContent> */}
              </>
            )}
          </div>
        </Card>
      </Grid>
    </Grid>
  );
}

export default ProviderProfileDetails;
