import React from 'react';
import { Grid, Typography, Box, styled, CircularProgress } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const StyledBox = styled(Box)({
  position: 'relative',
  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 3px 8px',
  borderRadius: 8,
  padding: 10,
  display: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height:"17.5vh",
  overflow: 'hidden', // Prevent content overflow
  background:"#01CFFF"
});

const DollarIcon = styled(AttachMoneyIcon)({
  position: 'absolute',
  top: '35%',
  left: 40,
  fontSize: '1200%',
  opacity: 0.2,
  transform: 'translate(-50%, -50%)', // Center the icon within its container
});

const TextContainer = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginLeft:'15%'
});

const Revenue = ({revenue, loading}) => {
  return (
    <>
      {loading ? <CircularProgress color="primary" sx={{ marginTop: "5%", marginLeft: "47%" }} /> :
        <StyledBox>
          <DollarIcon />
          
          <TextContainer item xs={12} textAlign="center">
            <Typography variant="h5" color="secondary" sx={{ fontWeight: 'bold', mt:1 }}>
              Minimum Expected Revenue
            </Typography>
            <Typography variant="h2" color="secondary" sx={{ fontWeight: 'bold', mt: 3 }}>
              {revenue?.toFixed(2)}
            </Typography>
          </TextContainer>
        </StyledBox>}
      </>
  );
};

export default Revenue;