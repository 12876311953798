import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  CardActions,
  Card,
  CardContent,
} from "@mui/material";
import { customDataTableStyles } from "../../../../ccm-constant";
import { IconArrowsSort } from "@tabler/icons-react";
import CustomPagination from "../../../../ui-component/Pagination";
import { SettingsApi } from "../../../../service/Settings/Index";
import CircularLoader from "../../../../ui-component/CircularLoader";
import { CustomScrollbars } from "../../../../ui-component/ScrollBar";
import { formatDate } from "../../../../ccm-constant";

const MyDataTable = ({ onStartDate, onEndDate, auditEvent }) => {
  console.log("auditEvent", auditEvent);
  console.log("onStartDate, onEndDate", onStartDate, onStartDate);
  const columns = [
    {
      name: "Date & Time",
      selector: (row) => `${formatDate(row?.timestamp, true)}`,
      sortable: true,
      width: "25%",
      // cell: (row) => (<>
      //     {formatDate(row.timestamp)}
      //     </>
      // ),
    },
    {
      name: "Event",
      selector: (row)=> row?.action,
      // sortable: true,
      width: "15%",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {toTitleCase(row.action)}
        </div>
      ),
    },
    {
      name: "Description",
      selector: (row) => `${row?.description?.description}`,
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.description?.description !== undefined
            ? row.description?.description
            : "-"}
        </div>
      ),
      // sortable: true,
      // width: "550px",
    },
    {
      name: "User IP Address",
      selector: (row)=> row?.remote_addr,
      // sortable: true,
      width: "18%",
    },
    {
      name: "User",
      selector: (row) => row?.description?.action_on,
      // sortable: true,
      width: "15%",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.description?.action_on !== undefined
            ? row?.description?.action_on
            : "-"}
        </div>
      ),
    },
  ];
  const [auditLog, setAuditLog] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  console.log("currentPage", setCurrentPage)


  function toTitleCase(str) {
    return str
      ?.toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  const handleSort = (column, sortDirection) => {
    console.log("handleSort", column, sortDirection);

    if (column.name === "Date & Time") {
      setSortedColumn("timestamp");
    } else if (column.name === "User") {
      setSortedColumn("actor");
    } else {
      setSortedColumn(column.selector);
    }
    setSortDirection(sortDirection);
  };
  const type = "audit_event"
  
  useEffect(() => {
    console.log(
      currentPage,
      sortDirection,
      sortedColumn,
      onEndDate,
      onStartDate,
      type
    );
    setLoading(true);
    SettingsApi.auditEvent(
      currentPage === 0 ? currentPage : (currentPage - 1) * 10,
      sortDirection,
      sortedColumn,
      onEndDate,
      onStartDate,
      auditEvent ? type : null
    )
      .then((response) => {
        console.log("auditLog", response);
        setAuditLog(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // if (currentPage || sortDirection || sortedColumn || auditEvent || onStartDate && onEndDate) {
    //   if (onStartDate !== null && onEndDate !== null && onStartDate !=="NaN-NaN-NaN" && onEndDate !== "NaN-NaN-NaN") {
    //     setCurrentPage(0);
    //     fetchAuditLog();
    //   } else {
    //     fetchAuditLog();
    //   }
    // }
    return () => {
      // setAuditLog([]);
    };
  }, [currentPage, sortDirection, sortedColumn, auditEvent]);

  useEffect(() => {
    console.log("onStartDate", onStartDate, onEndDate);
    if (onStartDate !== null && onEndDate !== null)
    {
      if (
      (onStartDate !== "NaN-NaN-NaN" && onEndDate !== "NaN-NaN-NaN") ||
      (onStartDate === "null" && onEndDate === "null")
      ) {
      setCurrentPage(0)
      fetchAuditLog();
      console.log("fetch4")
    }
    else if (onStartDate === "NaN-NaN-NaN" && onEndDate === "NaN-NaN-NaN") {
        setCurrentPage(0)
      fetchAuditLog();
      console.log("fetch5")
    }}
  }, [onStartDate, onEndDate]);

  const fetchAuditLog = () => {
    setLoading(true);
    SettingsApi.auditEvent(
      currentPage === 0 ? currentPage : (currentPage - 1) * 10,
      sortDirection,
      sortedColumn,
      onEndDate,
      onStartDate,
      auditEvent ? type : null
    )
      .then((response) => {
        console.log("auditLog", response);
        setAuditLog(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }

  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ height: "75vh", position: "relative" }}>
          <CardContent>
            <Grid
              container
              xs={12}
              padding={1}
              sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
            >
              <CustomScrollbars height="58vh">
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!loading ? (
                    <DataTable
                      columns={columns}
                      data={auditLog?.data}
                      sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                      sortServer
                      onSort={handleSort}
                      sortColumn={sortedColumn}
                      sortDirection={sortDirection}
                      highlightOnHover
                      pointerOnHover
                      customStyles={customDataTableStyles}
                    />
                  ) : (
                    <CircularProgress
                      color="primary"
                      sx={{ marginTop: "15%" }}
                    />
                  )}
                </Grid>
              </CustomScrollbars>
            </Grid>
          </CardContent>
          <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}>
            {auditLog?.count >= 1 && (
              <Grid
                item
                xs={12}
                marginTop={2}
              // display="flex"
              // alignItems="center"
              // justifyContent="space-between"
              >
                <CustomPagination
                  count={auditLog?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </Grid>
            )}
          </CardActions>
        </Card>
      </Grid>
    </>
  );
};

function AuditEvent({ onStartDate, onEndDate, auditEvent }) {
  return <MyDataTable onStartDate={onStartDate} onEndDate={onEndDate} auditEvent={auditEvent} />;
}

export default AuditEvent;
