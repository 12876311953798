import React from "react";
import { Box, Drawer } from "@mui/material";

const DrawerComponent = ({ open, handleClose, data, extraWidth, drawerSize }) => {
  // Function to prevent the backdrop click event
  const preventBackdropClick = (event) => {
    event.stopPropagation();
  };

  return (
    <Drawer
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      anchor="right"
      // Set the ModalProps to disable the backdrop click event
      ModalProps={{
        BackdropProps: {
          onClick: preventBackdropClick,
        },
      }}
    >
      <Box sx={{ width: drawerSize ? "auto" : "40vw", padding: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {data}
      </Box>
    </Drawer>
  );
};

export default DrawerComponent;
