import React from "react";
import {
  Grid,
  TextField,
  Autocomplete,
  Typography,
  Button,
} from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { PatientApi } from "../../../../../../../../../service/ProviderGroup/Patient";
import { useParams } from "react-router";
import { formatDate } from '../../../../../../../../../ccm-constant';

function ViewNotes({ handleClose, data }) {

  return (
    <Grid container spacing={2} padding={1}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <Typography variant="h6" marginBottom={1}>
          View Note
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          style={{
            height: "40px",
            fontWeight: 700,
            width: "100px",
            textTransform: "none",
            marginRight: 15,
          }}
          onClick={() => handleClose(false)}
        >
          Close
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1" marginBottom={1}>
          Time
        </Typography>
        <Typography variant="body1" marginBottom={1}>
          {formatDate(data?.created_at, true)}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1" marginBottom={1}>
          Reading
        </Typography>
        <Typography variant="body1" marginBottom={1}>
        {data?.diastolic_value ? `${data?.value}/${data?.diastolic_value} ${data?.unit}` : `${data?.value} ${data?.unit}`}
        </Typography>
      </Grid>

      <Grid item xs={12} marginBottom={1}>
        <Typography variant="subtitle1" marginBottom={1}>
          Note
        </Typography>
        <Typography variant="body1" marginBottom={1}>
          {data?.note}
        </Typography>
      </Grid>

    </Grid>
  );
}

export default ViewNotes;
