import React, { useState, useEffect } from "react";
import { Grid, Typography, Avatar, Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router";
import { ProviderGroupApi } from "../../../../../service/ProviderGroup";
import { CustomScrollbars } from "../../../../../ui-component/ScrollBar";
import CircularLoader from "../../../../../ui-component/CircularLoader";
import avatar from "../../../../../assets/adminAvatar.jpg";
import UserAuditLog from "./UserAuditLog";

const scrollableFieldStyle = {
  height: "140px", // Set the desired height for your scrollable field
  overflow: "auto",
  width: "100%",
};
const providerGroupDetails = [
  {
    label: "Email",
    fieldName: "email",
  },
  {
    label: "Provider Group Name",
    fieldName: "display_name",
  },
  {
    label: "Phone",
    fieldName: "phone_number",
  },
  {
    label: "Speciality",
    fieldName: "speciality",
  },
  {
    label: "Website",
    fieldName: "website",
  },
  {
    label: "Practice ID",
    fieldName: "practice_id",
  },
];

function GroupProfile() {
  const navigate = useNavigate();
  let id = JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid;
  const [userData, setUserData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    ProviderGroupApi.getProviderGroup(id)
      .then((response) => {
        console.log("ProviderGroupApi response", response.data);
        setUserData(response.data);
        setSelectedImage(response?.data?.profile_pic || avatar);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(false);
      });
  }, [id]);

  const isURL = (url) => {
    try {
      if (url.includes("https://")) {
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  };

  return (
    <Grid item style={{ height: "72vh" }}>
      <div style={{ position: "relative", top: loading ? "40%" : "10px" }}>
        {loading ? (
          <Grid items>
            <CircularLoader />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} padding={3} paddingTop={0}>
              <CustomScrollbars height={"68vh"}>
                <Grid item xs={12} padding={1}>
                  <Grid container xs={12}>
                    <Grid container xs={12} marginBottom={3}>
                      <Box
                        display="flex"
                        flexDirection={{ xs: "column", sm: "row" }}
                        marginBottom={2}
                        spacing={3}
                        width={"100%"}
                      >
                        {/* Image container */}
                        <Box position="relative" marginRight={2}>
                          {/* Backdrop overlay */}
                          {imageLoading && (
                            <Box
                              position="absolute"
                              top={0}
                              left={0}
                              width="100%"
                              height="100%"
                              backgroundColor="rgba(119, 118, 118, 0.7)"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              opacity={0.3}
                              zIndex={2}
                            >
                              <CircularProgress
                                size={50}
                                sx={{ color: "#ffffff", position: "absolute" }}
                              />
                            </Box>
                          )}

                          {/* User profile photo */}
                          <Avatar
                            alt="User Profile Photo"
                            src={
                              (isURL(selectedImage)
                                ? `${selectedImage}?${new Date().getTime()}`
                                : selectedImage) || avatar
                            }
                            sx={{
                              width: 200,
                              height: 200,
                              borderRadius: "0%",
                              marginBottom: "0px",
                              objectFit: "contain",
                              padding: 1,
                              backgroundColor: "#ffffff",
                              boxShadow: "0px 0px 8px #00000029",
                              visibility: imageLoading ? "hidden" : "visible",
                            }}
                            onError={(e) => {
                              e.target.src = avatar;
                              e.target.onerror = null;
                              setImageLoading(false);
                            }}
                            onLoad={() => setImageLoading(false)}
                          />
                        </Box>

                        {/* Bio container */}
                        <Box
                          display="flex"
                          flexDirection="column"
                          flexGrow={1}
                          width="100%"
                          marginTop={4}
                        >
                          <React.Fragment key={"bio"}>
                            <Typography variant="subtitle1" marginRight={2}>
                              Bio :
                            </Typography>
                            <Box
                              display="flex"
                              flexDirection="column"
                              boxShadow={
                                userData?.bio ? "0px 0px 5px #00000020" : null
                              }
                              width="100%"
                              marginTop={1}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  ...scrollableFieldStyle,
                                  "&:hover": {
                                    border: "0px",
                                  },
                                  resize: "unset",
                                  // backgroundColor: "#ffffff",
                                  whiteSpace: "pre-line",
                                  textAlign: "justify",
                                  border: "0px",
                                  margin: "2px",
                                  padding: "2px",
                                  width: "100%", // Use margin instead of padding
                                }}
                              >
                                {userData?.bio}
                              </Typography>
                            </Box>
                          </React.Fragment>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid container xs={12} spacing={1}>
                      <Grid item xs={12} md={6}>
                        <Grid item xs={12}>
                          {providerGroupDetails
                            ?.slice(0, 6)
                            .map((field, index) => (
                              <Grid container xs={12} spacing={2} key={index}>
                                <Grid item xs={4}>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    sx={{
                                      marginBottom: "5px",
                                      display: "flex",
                                    }}
                                  >
                                    {field.label}
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography
                                    variant="body1"
                                    component="div"
                                    sx={{
                                      marginBottom: "10px",
                                      display: "flex",
                                    }}
                                  >
                                    : &nbsp;&nbsp;&nbsp;
                                    {/* {userData[field.fieldName]} */}
                                    {field?.fieldName === "speciality" ||
                                      field?.fieldName === "specialty"
                                      ? userData[field?.fieldName]?.join(", ")
                                      : userData[field?.fieldName]}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={6} spacing={1}>
                        <Grid item xs={12}>
                          <Grid container xs={12} spacing={2}>
                            <Grid item xs={4}>
                              <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{
                                  marginBottom: "5px",
                                  display: "flex",
                                }}
                              >
                                Primary Address
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography
                                variant="body1"
                                component="div"
                                sx={{
                                  marginBottom: "10px",
                                  display: "flex",
                                }}
                              >
                                : &nbsp;&nbsp;&nbsp;
                                {`${userData?.address?.address_line_1} ${userData?.address?.address_line_2} ${userData?.address?.city} ${userData?.address?.state} ${userData?.address?.country} ${userData?.address?.zip_code}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container xs={12} spacing={2}>
                            <Grid item xs={4}>
                              <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{
                                  marginBottom: "5px",
                                  display: "flex",
                                }}
                              >
                                Billing Address
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography
                                variant="body1"
                                component="div"
                                sx={{
                                  marginBottom: "10px",
                                  display: "flex",
                                }}
                              >
                                : &nbsp;&nbsp;&nbsp;
                                {`${userData?.address?.billing_address?.address_line_1}  ${userData?.address?.billing_address?.address_line_2} ${userData?.address?.billing_address?.city} ${userData?.address?.billing_address?.state} ${userData?.address?.billing_address?.country} ${userData?.address?.billing_address?.zip_code}`}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  </Grid>
                  <Grid marginTop={"3vh"}>
                    <Typography variant="cardTitle"> Log List</Typography>
                    <UserAuditLog />
                  </Grid>
              </CustomScrollbars>
              </Grid>
          </>
        )}
      </div>
    </Grid>
  );
}

export default GroupProfile;
