import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useParams } from "react-router";
import {
  DaysFromDate,
  dateFromDays,
  formatDate,
} from "../../../../../../../../../../../ccm-constant";
import cardStyle from "../../../../../../../../../../../theme/card-layout";
import { DatePicker } from "../../../../../../../../../../../ui-component/DatePicker/DatePicker";
import FormDataTable from "../../../../../../features/FormDataTable";
import { PatientApi } from "../../../../../../../../../../../service/ProviderGroup/Patient";
import { useNotification } from "../../../../../../../../../../../hooks/use-notification";

function MedicationForm({ setOpenDrawer, setRefreshList, selectedData, setSelectedRow }) {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [onsetDate, setOnsetDate] = useState(selectedData?.start_date || null);
  const { notifySuccess, notifyError } = useNotification();
  const [stopDate, setStopDate] = useState(selectedData?.stop_date || null);
  const [forLifeTime, setForLifeTime] = useState(selectedData?.for_lifetime || false);
  const [loading, setLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false);


  useEffect(() => {
    if (isSaving) {
      setTimeout(() => {
        setIsSaving(false);
      }, 2000);
    }
  }, [isSaving])

  useEffect(() => {
    setOnsetDate(onsetDate);
  }, [onsetDate]);

  const RouteOptions = [
    { label: "Intramuscular", name: "Intramuscular" },
    { label: "Intravenous", name: "Intravenous" },
    { label: "Nasal", name: "Nasal" },
    { label: "Optic", name: "Optic" },
    { label: "Oral", name: "Oral" },
    { label: "Rectal", name: "Rectal" },
    { label: "Subcutaneous", name: "Subcutaneous" },
    { label: "Sublingual", name: "Sublingual" },
    { label: "Transdermal", name: "Transdermal" },
    { label: "Vaginal", name: "Vaginal" },
  ];

  const DosageOptions = [
    {
      label: "IU",
      name: "IU",
    },
    {
      label: "%",
      name: "%",
    },
    {
      label: "capsule",
      name: "capsule",
    },
    {
      label: "drops",
      name: "drops",
    },
    {
      label: "g",
      name: "g",
    },
    {
      label: "mEq",
      name: "mEq",
    },
    {
      label: "mg",
      name: "mg",
    },
    {
      label: "mg per kg",
      name: "mg per kg",
    },
    {
      label: "mL",
      name: "mL",
    },
    {
      label: "mL per hour",
      name: "mL per hour",
    },
    {
      label: "mcg",
      name: "mcg",
    },
    {
      label: "puffs",
      name: "puffs",
    },
    {
      label: "sprays",
      name: "sprays",
    },
    {
      label: "tablespoon",
      name: "tablespoon",
    },
    {
      label: "tablets",
      name: "tablets",
    },
    {
      label: "teaspoon",
      name: "teaspoon",
    },
    {
      label: "unit",
      name: "unit",
    },
  ];

  const SigWhenOptions = [
    { label: "After Meal", name: "After Meal" },
    { label: "Before Meal", name: "Before Meal" },
    { label: "Bedtime", name: "Bedtime" },
    { label: "In the Morning", name: "In the Morning" },
    { label: "PRN", name: "PRN" },
    { label: "With Meal", name: "With Meal" },
  ];

  const frequancyOptions = [
    { label: "3 Time Per Week", name: "3 Time Per Week" },
    { label: "3 Times A Day", name: "3 Times A Day" },
    { label: "Every 10 Hours", name: "Every 10 Hours" },
    { label: "Every 2 Hours", name: "Every 2 Hours" },
    { label: "Every 4 Hours", name: "Every 4 Hours" },
    { label: "Every 6 Hours", name: "Every 6 Hours" },
    { label: "Every 8 Hours", name: "Every 8 Hours" },
    { label: "Every Day", name: "Every Day" },
    { label: "Every Other Day", name: "Every Other Day" },
    { label: "Twice A Day", name: "Twice A Day" },
    { label: "Weekly", name: "Weekly" },
  ];

  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.srNo,
      width: "80px",
    },
    {
      name: "Medication Name",
      selector: (row) => row.medicationName,
      minWidth: "140px",
    },
    {
      name: "Note",
      selector: (row) => row.note,
      minWidth: "140px",
    },
    {
      name: "Start Date",
      selector: (row) => formatDate(row?.start_date),
      width: "120px",
    },
    {
      name: "Duration",
      selector: (row) =>
        row?.medicationFor &&
          !row?.for_lifetime &&
          row?.medicationStatus !== "historical"
          ? `${row?.medicationFor} days`
          : row?.for_lifetime && row?.medicationStatus !== "historical"
            ? "for lifetime"
            : row?.medicationStatus === "historical" &&
              row?.start_date &&
              row?.stop_date
              ? DaysFromDate(row?.start_date, row?.stop_date)
              : "NA",
      width: "120px",
    },
    // {
    //   name: "Note",
    //   selector: "note",
    //   // width: "200px",
    // },
    {
      name: "End Date",
      selector: (row) =>
        row?.stop_date
          ? formatDate(row?.stop_date)
          : row?.medicationFor &&
            !row?.for_lifetime &&
            row?.medicationStatus !== "historical"
            ? dateFromDays(row?.start_date, row?.medicationFor)
            : "NA",
      width: "120px",
    },
  ];

  const validationSchema = Yup.object().shape({
    medicationName: Yup.string().required("Medication Name is required"),
    sigQuantity: Yup.string()
      .required("Sig Quantity is required")
      .matches(/^[0-9]+$/, "Sig Quantity must be a number"),
    sigDosageUnit: Yup.string().required("Sig Dosage Unit is required"),
    sigWhen: Yup.string().required("Sig When is required"),
    byOralRoute: Yup.string().required("By Oral Route is required"),
    frequancy: Yup.string().required("Frequency is required"),
    start_date: Yup.date().required("Start date is required"),
  });

  const validateField = (values) => {
    const errors = {};
    console.log("validateField", values)
    if (!values.for_lifetime && values?.selectedStatus === "active") {
      if (!values?.medicationFor) {
        errors.medicationFor = "For days is required";
      } else if (!(Number.isInteger(Number(values?.medicationFor)) >= 0)) {
        errors.medicationFor = "For days must be an integer";
      }
    }

    // if (!values.start_date) {
    //   errors.start_date = "Stop date is required";
    // }

    if (values?.selectedStatus !== "active") {
      if (!values.stop_date) {
        errors.stop_date = "Stop date is required";
      }
    }

    return errors;
  };

  const SubmitMedicationsData = () => {
    if (isSaving) {
      // If already saving, return to prevent multiple calls
      return;
    }
    setIsSaving(true);
    const payload = [];
    if (data?.length === 0) {
      notifyError("Please add at least one medication.")
    }
    else {
      data.forEach((element) => {
        payload.push({
          name: element?.medicationName,
          quantity: element?.sigQuantity,
          dosage: element?.sigDosageUnit,
          when: element?.sigWhen,
          oral_route: element?.byOralRoute,
          for_days: !element?.for_lifetime ? element?.medicationFor : null,
          status: element?.medicationStatus,
          note: element?.note,
          start_date: element?.start_date,
          stop_date: !element?.for_lifetime ? element?.stop_date : null,
          for_lifetime: element?.for_lifetime,
          frequency: element?.frequancy,
        });
      });
      if (payload?.length) {
        setLoading(true)
        PatientApi.addMedications(id, payload)
          .then((response) => {
            setOpenDrawer(false);
            setRefreshList(true);
            setLoading(false)
            notifySuccess("Medications added successfully");
            console.log("response.data", response.data);
          })
          .catch((error) => {
            setLoading(false)
            console.error("Error addAllergies data:", error);
            notifyError(error?.response?.data?.error);
          })
          .finally(() => {
            setIsSaving(false); // Reset saving status after API call is completed
          });
      }
    }
  };

  const UpdateMedicationsData = (values) => {
    setLoading(true)
    const payload = {
      name: values?.medicationName,
      quantity: values?.sigQuantity,
      dosage: values?.sigDosageUnit,
      when: values?.sigWhen,
      oral_route: values?.byOralRoute,
      for_days: !values?.for_lifetime && values?.medicationFor !== "" && values?.selectedStatus !== "historical" ? values?.medicationFor : null,
      status: values?.selectedStatus,
      note: values?.note === "" ? null : values?.note,
      start_date: onsetDate === "" ? null : onsetDate,
      stop_date: !values?.for_lifetime && stopDate !== "" ? stopDate : null,
      for_lifetime: values?.selectedStatus === "historical" ? false : values?.for_lifetime,
      frequency: values?.frequancy,
    };
    PatientApi.updateMedications(selectedData?.uuid, payload)
      .then((response) => {
        setOpenDrawer(false);
        setRefreshList(true);
        setLoading(false)
        notifySuccess("Medication updated successfully");
        console.log("response.data", response.data);
      })
      .catch((error) => {
        setLoading(false)
        console.error("Error addAllergies data:", error);
        notifyError(error?.response?.data?.error);
      });
  };

  return (
    <>
      <Grid container marginTop={2}>
        <Formik
          initialValues={{
            medicationName: selectedData?.name || null,
            sigQuantity: selectedData?.quantity || null,
            sigDosageUnit: selectedData?.dosage || null,
            sigWhen: selectedData?.when || null,
            selectedStatus: selectedData?.status || "active",
            byOralRoute: selectedData?.oral_route || null,
            medicationFor: selectedData?.for_days || null,
            note: selectedData?.note || null,
            for_lifetime: selectedData?.for_lifetime || false,
            start_date: selectedData?.start_date || null,
            stop_date: selectedData?.stop_date || null,
            frequancy: selectedData?.frequency || null,
          }}
          validate={(values) => validateField(values)}
          validationSchema={validationSchema}
          onSubmit={(values, { setFieldValue, resetForm, setErrors }) => {
            if (!Number.isInteger(Number(values?.medicationFor))) {
              setErrors({ "medicationFor": "For days must be an integer" })
            }
            const newCondition = {
              srNo: data?.length + 1 || 1,
              medicationName: values?.medicationName,
              sigQuantity: values?.sigQuantity,
              sigDosageUnit: values?.sigDosageUnit,
              sigWhen: values?.sigWhen,
              byOralRoute: values?.byOralRoute,
              medicationFor: values?.medicationFor
                ? values?.medicationFor
                : null,
              medicationStatus: values?.selectedStatus,
              note: values?.note,
              start_date: values?.start_date,
              stop_date: values?.stop_date,
              for_lifetime: values?.for_lifetime,
              frequancy: values?.frequancy,
            }


            if (!selectedData) {
              setData([...(data || []), newCondition]);
              resetForm({
                medicationName: "",
                sigQuantity: "",
                sigDosageUnit: "",
                sigWhen: "",
                selectedStatus: "active",
                byOralRoute: "",
                medicationFor: "",
                note: "",
                start_date: null,
                stop_date: null,
                for_lifetime: false,
                frequancy: "",
              });
              setFieldValue("medicationName", "")
              setFieldValue("sigQuantity", "")
              setFieldValue("note", "")
              setFieldValue("medicationFor", "");
              setFieldValue("for_lifetime", false);
              setOnsetDate(null);
              setStopDate(null);
              setForLifeTime(false);
            }
            else {
              UpdateMedicationsData(values)
            }
            // Reset the form to its initial state

          }}
          enableReinitialize
        >
          {({ values, handleSubmit, setFieldTouched, setFieldValue, errors, setErrors }) => (
            <Form>
              {/* <Grid container> */}
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: 2,
                }}
              // spacing={2}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    width: "100px",
                    textTransform: "none",
                    marginRight: 15,
                  }}
                  onClick={() => { setOpenDrawer(false); setSelectedRow(null) }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    width: "100px",
                    textTransform: "none",
                  }}
                  onClick={() => {

                    // if(Object.keys(errors)?.length && selectedData){
                    //   setErrors(errors)

                    //   // Object.keys(errors).map((errKey) => 
                    //   // setErrors({[errKey]: `${errors[errKey]}`})
                    //   // )
                    //   // console.log("EEEEEEEEEEEEE",errors,  Object.keys(errors)[0], errors[Object.keys(errors)[0]] )
                    // // notifyError(Object.values(errors)[0].replace(/"/g, ''))
                    //   // console.log("medication errors", errors, values)
                    // }
                    // else{

                    !selectedData ? SubmitMedicationsData() : handleSubmit()
                  }
                    //   if(selectedData){
                    //     UpdateMedicationsData(values, errors)
                    //   }
                    // else{
                    //    SubmitMedicationsData()
                    // }
                  }
                // }
                >
                  Save

                  &nbsp;
                  {loading && (
                    <CircularProgress size={18} style={{ color: "white" }} />
                  )}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" marginBottom={2}>
                  {selectedData ? "Edit Medication" : "Add Medication"}
                </Typography>
                {/* <Typography variant="body1" marginBottom={2}>
                  Chronic Medication to be managed under CCM Programs
                </Typography> */}

                <Typography variant="subtitle1" marginBottom={1}>
                  Medication Name
                </Typography>
                <Grid item xs={12} marginBottom={2}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    style={cardStyle}
                    placeholder="Enter Medication Name"
                    sx={{ width: "100%" }}
                    name="medicationName"
                  />
                  <ErrorMessage
                    name="medicationName"
                    component="div"
                    style={{
                      color: "red",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />
                </Grid>

                <Typography variant="subtitle1" marginBottom={1}>
                  Sig
                </Typography>
                <Grid
                  container
                  // xs={12}
                  spacing={3}
                  marginBottom={2}
                  sx={{ display: "flex" }}
                >
                  <Grid item xs={12} md={4} lg={4}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      style={cardStyle}
                      placeholder="Sig Quantity"
                      sx={{ width: "100%" }}
                      name="sigQuantity"
                    />
                    <ErrorMessage
                      name="sigQuantity"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <Field variant="outlined" fullWidth name="sigDosageUnit">
                      {({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          id="combo-box-demo"
                          options={DosageOptions}
                          sx={cardStyle}
                          renderInput={(params) => (
                            <TextField {...params} {...field} placeholder="Dosage Unit" />
                          )}
                          onChange={(e, newValue) => {
                            setFieldValue("sigDosageUnit", newValue?.name || null);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="sigDosageUnit"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4}>
                    <Field variant="outlined" fullWidth name="sigWhen">
                      {({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          id="combo-box-demo"
                          options={SigWhenOptions}
                          sx={cardStyle}
                          renderInput={(params) => (
                            <TextField {...params}   {...field} placeholder="When" />
                          )}
                          onChange={(e, newValue) => {
                            setFieldValue("sigWhen", newValue?.name || null);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="sigWhen"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" marginBottom={1}>
                      By Oral Route
                    </Typography>
                    <Field variant="outlined" fullWidth name="byOralRoute">
                      {({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          id="combo-box-demo"
                          options={RouteOptions}
                          sx={cardStyle}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              placeholder="By Oral Route"
                            />
                          )}
                          onChange={(e, newValue) => {
                            setFieldValue("byOralRoute", newValue?.name || null);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="byOralRoute"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" marginBottom={1}>
                      Frequency
                    </Typography>
                    <Field variant="outlined" fullWidth name="frequancy">
                      {({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          id="combo-box-demo"
                          options={frequancyOptions}
                          sx={cardStyle}
                          renderInput={(params) => (
                            <TextField {...params}   {...field} placeholder="Frequency" />
                          )}
                          onChange={(e, newValue) => {
                            setFieldValue("frequancy", newValue?.name || null);
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="frequancy"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" marginBottom={1}>
                      Start Date
                    </Typography>
                    <DatePicker
                      setOnSetDate={setOnsetDate}
                      onSetDate={onsetDate}
                      fieldName="start_date"
                      lastDate={values?.selectedStatus === "historical" ? false : true}
                    />
                    <ErrorMessage
                      name="start_date"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>

                  {values?.selectedStatus === "historical" ?
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                      <Typography variant="subtitle1" marginBottom={1}>
                        Stop Date
                      </Typography>
                      <DatePicker
                        setOnSetDate={setStopDate}
                        onSetDate={stopDate}
                        fieldName="stop_date"
                        dateFrom={onsetDate}
                        // lastDate = {true}
                        disable={onsetDate ? false : true}
                      />
                      <ErrorMessage
                        name="stop_date"
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                    :
                    !forLifeTime && values?.selectedStatus === "active" ?
                      <Grid item xs={12} md={4} lg={4} xl={4}>
                        <Typography variant="subtitle1" marginBottom={1}>
                          For Days
                        </Typography>
                        <Field
                          as={TextField}
                          variant="outlined"
                          style={cardStyle}
                          placeholder="For Days"
                          sx={{ width: "100%" }}
                          name="medicationFor"
                          values={values?.medicationFor}
                          disabled={values?.for_lifetime}
                          onChange={(e) => {
                            const inputValue = parseInt(e.target.value, 10) || "";
                            setFieldValue("medicationFor", inputValue);
                          }}
                        // onClick = {(e)=>{setFieldValue("medicationFor",values?.medicationFor)}}
                        />
                        <ErrorMessage
                          name="medicationFor"
                          component="div"
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        />
                      </Grid> : null
                  }

                  {values?.selectedStatus !== "historical" && (
                    <Grid item xs={12} md={4} lg={4} xl={4}>
                      <Typography variant="subtitle1" marginBottom={4}>
                        {/* For LifeTime */}
                      </Typography>
                      <Field
                        name="for_lifetime"
                        variant="subtitle1"
                        type="checkbox"
                      >
                        {({ field }) => (<Grid display={"flex"} justifyContent={"left"}>
                          <FormControlLabel
                            variant="subtitle1"
                            control={
                              <Checkbox
                                {...field}
                                style={{ fontVariant: "bold", fontWeight: 600 }}
                                onChange={(e) => {
                                  // e.preventDefault()
                                  // e.stopPropagation()
                                  setForLifeTime(!forLifeTime);
                                  setFieldValue("for_lifetime", !forLifeTime);
                                  setFieldValue("medicationFor", "");
                                  setFieldTouched("medicationFor", false);
                                }}
                              />
                            }
                            // label="For LifeTime"
                            onClick={(e) => e.stopPropagation()}
                          />
                          <Typography paddingTop={1}>For LifeTime</Typography>
                        </Grid>
                        )}
                      </Field>
                      <ErrorMessage
                        name="for_lifetime"
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={4} lg={4} xl={4}>
                    <Grid
                      container
                      item
                      xs={12}
                      marginBottom={2}
                      lg={12}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Grid item xs={12} lg={12}>
                        <Typography variant="subtitle1" marginBottom={1}>
                          Status
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{ display: "flex", gap: "10px" }}
                      >
                        <Button
                          variant={
                            values?.selectedStatus === "active"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          onClick={() => {
                            if (values?.selectedStatus !== "active") {
                              setFieldValue("selectedStatus", "active");
                              setForLifeTime(false);
                              setFieldValue("medicationFor", null);
                              setFieldValue("for_lifetime", false);
                              setFieldTouched("stop_date", null);
                            }
                          }}
                          style={{ textTransform: "none", height: "30px" }}
                        >
                          Active
                        </Button>
                        <Button
                          variant={
                            values?.selectedStatus === "historical"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          style={{ textTransform: "none", height: "30px" }}
                          onClick={() => {
                            if (values?.selectedStatus !== "historical") {
                              setFieldValue("selectedStatus", "historical");
                              setForLifeTime(false);
                              setFieldValue("medicationFor", null);
                              setFieldValue("for_lifetime", false);
                              setFieldTouched("medicationFor", false);
                            }
                          }}
                        >
                          Historical
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} lg={12} marginTop={2}>
                <Typography variant="subtitle1" marginBottom={1}>
                  Note
                </Typography>

                <Field
                  as={TextField}
                  variant="outlined"
                  style={cardStyle}
                  minRows={3}
                  maxRows={3}
                  multiline={true}
                  name="note"
                  placeholder="Note"
                  sx={{ width: "100%" }}
                />
              </Grid>

              {!selectedData && <Grid container justifyContent="flex-end" marginTop={3}>
                <Grid item>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    style={{
                      alignItems: "center",
                      height: "40px",
                      fontWeight: 700,
                      width: "100px",
                      textTransform: "none",
                    }}
                    onClick={handleSubmit}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>}
              {/* </Grid> */}
            </Form>
          )}
        </Formik>
        <Grid item xs={12} lg={12} marginTop={1}>
          {data?.length ? (
            <FormDataTable data={data} columns={columns} />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
export default MedicationForm;
