import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, CircularProgress } from "@mui/material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { PatientApi } from '../../../../../../../../../../service/ProviderGroup/Patient';
import { useParams } from 'react-router-dom';
import { useNotification } from '../../../../../../../../../../hooks/use-notification';
import DragAndDropCSV from '../../../../../../../../../../ui-component/DragAndDropCSV';
import DeleteIcon from '@mui/icons-material/Delete';
import { CustomScrollbars } from '../../../../../../../../../../ui-component/ScrollBar';
import ConfirmBox from '../../../../../../../../../../ui-component/ConfirmBox';

const PatientDocumentForm = ({ setOpenDrawer, setRefreshList }) => {
  const { id } = useParams();
  const { notifySuccess, notifyError } = useNotification();
  const [multiSelect, setMultiSelect] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [openCnf, setOpenCnf] = useState(false);
  const [removeFile, setRemoveFile] = useState(null);
  console.log("cnf", removeFile)
  // const [providerGroupId, setProviderGroupId] = useState(JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group?.uuid);

  const handleUpload = () => {
    const uniqueFiles = [...new Map(selectedFiles.map(file => [file.name, file])).values()];

    if (uniqueFiles.length !== 0) {
      setLoading(true);
      const payload = uniqueFiles.reduce((result, file, index) => {
        result[`document${index}`] = file;
        return result;
      }, {});
    if (selectedFiles?.length !== 0) {
      setLoading(true);
      PatientApi.uploadPatientDocuments(id, payload)
        .then((response) => {
          setLoading(false);
          notifySuccess("Document is uploaded successfully");
          setOpenDrawer(false);
          setRefreshList(true);
        })
        .catch((error) => {
          console.error("Error fetching Care Team Providers data:", error);
          notifyError(error?.response?.data?.error);
          setLoading(false);
        });
    }
    else {
      notifyError("Please select document");
    }
  }
  };

  const handleRemoveFile = () => {
    console.log("IN REMOVE", removeFile)
    const updatedFiles = selectedFiles.filter((file) => file !== removeFile);
    setSelectedFiles(updatedFiles);
    setOpenCnf(false);
  }

  return (
    <>
      <Formik
        initialValues={{

        }}
        onSubmit={(data) => {
        }}
      >
        {({ handleSubmit, values }) => (
          <Form>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    width: "100px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setOpenDrawer(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    marginLeft: "20px",
                    width: "120px",
                    color: "#ffffff",
                    textTransform: "none",
                  }}
                  onClick={() => { handleUpload() }}
                >
                  Upload&nbsp;
                  {loading && (
                    <CircularProgress size={18} style={{ color: "white" }} />
                  )}
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  height: 350,
                  position: "relative",
                  marginBottom: "20px",
                  borderRadius: "20px",
                  opacity: 1,
                }}
                marginTop={4}
              >
                <DragAndDropCSV selectedFiles= {selectedFiles} setSelectedFiles={setSelectedFiles} multiSelect={multiSelect} />
              </Grid>
              <Typography
                variant="h6"
                sx={{
                  marginBottom: "20px",
                }}
              >
                Uploaded Documents
              </Typography>
              <CustomScrollbars height="42vh">
                {selectedFiles?.length > 0 ? selectedFiles?.map((file, index) => (
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "60px",
                      padding: "10px",
                      border: "1px ",
                      background: "#FFFFFF 0% 0 % no - repeat padding- box",
                      borderradius: "5px",
                      background: '#FFFFFF 0% 0% no-repeat padding-box',
                      boxShadow: "0px 0px 8px 0px #00000029",
                      opacity: 1,
                      backgroundColor: index === hoveredIndex ? "#4C4C4C26" : "transparent",
                    }}
                    marginBottom={2}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{ marginBottom: 1 }}
                    >
                      <Typography variant='subtitle1'>
                        {file?.name}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", justifyContent: "end" }}
                    >
                      <Button
                        variant="outlined"
                        style={{
                          height: "40px",
                          fontWeight: 700,
                          marginLeft: "20px",
                          width: "50px",
                          textTransform: "none",
                          border: 0,
                        }}
                        onClick={() => {
                          setOpenCnf(true);
                          setRemoveFile(file);
                        }}
                      >
                        <DeleteIcon />
                      </Button>
                    </Grid>
                  </Grid>
                )) :
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "60px",
                      padding: "10px"
                    }}
                    marginBottom={1}
                  >
                    <Typography
                      variant='body2'
                    >
                      There are no records to display
                    </Typography>
                  </Grid>
                }
              </CustomScrollbars>
            </Grid>
            <ConfirmBox
              handleClose={() => setOpenCnf(false)}
              open={openCnf}
              handleSubmit={() => handleRemoveFile(removeFile)}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default PatientDocumentForm