import config from "../../config";
import httpClient from "../base";

const BACKEND_BASE_URL = config.BACKEND_BASE_URL;

export const ProviderGroupApi = {

 addUser: (userType, payload) =>
 httpClient.post(`${BACKEND_BASE_URL}users/api/v1/provider_group`, payload),
 
 getUser: (query) =>
 httpClient.get(`${BACKEND_BASE_URL}users/api/v1/provider_group`,{...query}),

 updateUser: (id, payload) =>
 httpClient.put(`${BACKEND_BASE_URL}users/api/v1/provider_group/${id}`, payload),
 
 getProviderGroup: (id) =>
 httpClient.get(`${BACKEND_BASE_URL}users/api/v1/provider_group/${id}`),

 deleteUser: (id) =>
 httpClient.delete(`${BACKEND_BASE_URL}users/api/v1/provider_group/${id}`),

 AddProviderGroupImage: (uuid, payload) =>
 httpClient.put(`${BACKEND_BASE_URL}users/api/v1/provider_group/pic/${uuid}`, payload,{}, Headers={
   "Content-Type": "multipart/form-data",
 }),
 getProvider: (id) =>
 httpClient.get(`${BACKEND_BASE_URL}users/api/v1/provider/${id}`),
 updateProvider: (id, payload) =>
 httpClient.put(`${BACKEND_BASE_URL}users/api/v1/provider/${id}`, payload),
 getProviderType: (selectedType) =>
 httpClient.get(`${BACKEND_BASE_URL}users/api/v1/get_types?role=${selectedType}`),
 getProviderSpeciality: () =>
 httpClient.get(`${BACKEND_BASE_URL}users/api/v1/get_speciality`),
 
};