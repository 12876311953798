import config from "../../config";
import httpClient from "../base";

const BACKEND_BASE_URL = config.BACKEND_BASE_URL;

export const Billing = {
  getReportData: (PFId, Patient_id, payload, download) => 
    !download ? 
    
    httpClient.post(
      `${BACKEND_BASE_URL}settings/api/v1/billing_configure/provider_group/${PFId}/patient/${Patient_id}`,
      payload
    ) :   httpClient.postInBlob(
      `${BACKEND_BASE_URL}settings/api/v1/billing_configure/provider_group/${PFId}/patient/${Patient_id}`,
      payload, {"download": download}
    ),

  getCareConditionsConfigure: (providerGroup) =>
    httpClient.get(
      `${BACKEND_BASE_URL}settings/api/v1/billing_configure/provider_group/${providerGroup}`
    ),

  getBillingData: (id, selectedMonth, selectedYear) =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/patient_billing/${id}`, {
      month: selectedMonth,
      year: selectedYear,
    }),

  updateConfig: (id, payload) =>
    httpClient.put(
      `${BACKEND_BASE_URL}settings/api/v1/billing_configure/provider_group/${id}`,
      payload
    ),

  getConfig: (id) =>
    httpClient.get(
      `${BACKEND_BASE_URL}settings/api/v1/billing_configure/provider_group/${id}`
    ),


    reportBillingError: (id, payload, type) =>
    httpClient.put(`${BACKEND_BASE_URL}settings/api/v1/billing_configure/set_error/${id}?set=${type}`, payload),
};
