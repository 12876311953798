import React, { useState, useEffect, useRef } from 'react';
import { Button, Grid, Typography, CircularProgress, TextField, Box, Avatar, IconButton } from "@mui/material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { PatientApi } from '../../../../../../../../../../service/ProviderGroup/Patient';
import { useParams } from 'react-router-dom';
import { useNotification } from '../../../../../../../../../../hooks/use-notification';
import * as Yup from "yup";
import cardStyle from '../../../../../../../../../../theme/card-layout';
import avatar from "../../../../../../../../../../assets/adminAvatar.jpg";
import CircularLoader from '../../../../../../../../../../ui-component/CircularLoader';
import ReplayIcon from '@mui/icons-material/Replay';
// import FileViewer from 'react-file-viewer';
const ViewDocument = ({ setViewDocument, setRefreshList, viewDocumentData, previewDocumnet, setPreviewDocument, setDrawerSize }) => {
  const { id } = useParams();
  const { notifySuccess, notifyError } = useNotification();
  const [loading, setLoading] = useState(false);
  const [demoFile, setDemoFile] = useState(`${viewDocumentData?.s3_link}?${Date.now()}`);
  const [docLoading, setDocLoading] = useState(true);
  const [docDownloading, setDocDownloading] = useState(false);
  const iframe = useRef()

  const downloadFile = (url, file_name) => {
    setDocDownloading(true);
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name); // Change the file name and extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setDocDownloading(false);
        notifySuccess('File downloaded successfully')
      })
      .catch(error => {
        notifyError('There was an error to downloading the file');
        setDocDownloading(false);
      });
  };

  const reload_clicked= ()=>{
    const viewIframe = iframe.current
    if (viewIframe){
      viewIframe.src = `https://view.officeapps.live.com/op/embed.aspx?embedded=true&src=${viewDocumentData?.s3_link}?${Date.now()}`
    }
  }

  const field = [
    {
      label: "Note",
      fieldName: "note",
      fieldType: TextField,
      placeholder: "Note",
    },
  ];

  const validationSchema = Yup.object().shape({
    note: Yup.string().required("Note is required"),
  });

  return (
    <>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            note: viewDocumentData?.note !== "" ? viewDocumentData?.note : "",
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(data) => {
            setLoading(true);
            const payload = {
              note: data.note
            }
            PatientApi.updatePatientDocument(viewDocumentData?.uuid, payload)
              .then((response) => {
                setLoading(false);
                setViewDocument(false);
                notifySuccess(`${viewDocumentData?.note !== "" ? "Note updated successfully" : "Note added successfully"}`);
                setRefreshList(true);
              })
              .catch((error) => {
                console.error("Error fetching Document data:", error);
                notifyError(error?.response?.data?.error);
              });
          }}
        >
          {({ handleSubmit, values }) => (
            <Form>
              <Grid container>
                {!previewDocumnet ?
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        height: "40px",
                        fontWeight: 700,
                        width: "100px",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setViewDocument(false)
                        setPreviewDocument(false);
                        setDrawerSize(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        height: "40px",
                        fontWeight: 700,
                        marginLeft: "20px",
                        width: "120px",
                        color: "#ffffff",
                        textTransform: "none",
                      }}
                      onClick={handleSubmit}
                    >
                      {`${viewDocumentData?.note !== "" ? "Update" : "Add"}`}&nbsp;
                      {loading && (
                        <CircularProgress
                          size={18}
                          style={{ color: "white" }}
                        />
                      )}
                    </Button>
                  </Grid>
                  :
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        height: "40px",
                        fontWeight: 700,
                        width: "100px",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setViewDocument(false)
                        setPreviewDocument(false);
                        setDrawerSize(false);
                      }}
                    >
                      Back
                    </Button>
        
                    <Button
                      variant="contained"
                      style={{
                        height: "40px",
                        fontWeight: 700,
                        marginLeft: "20px",
                        width: "120px",
                        color: "#ffffff",
                        textTransform: "none",
                      }}
                      onClick={() => downloadFile(`${viewDocumentData?.s3_link}?${Date.now()}`, `${viewDocumentData.file_name}.${viewDocumentData.document_type}`)}

                    >
                      Download&nbsp;
                      {docDownloading && (
                        <CircularProgress
                          size={18}
                          style={{ color: "white" }}
                        />
                      )}
                    </Button>

                    <Button
                        variant="contained"
                        style={{
                          height: "40px",
                          fontWeight: 700,
                          marginLeft: "20px",
                          width: "120px",
                          color: "#ffffff",
                          textTransform: "none",
                        }}
                        onClick={reload_clicked}>
                        Reload
                        <IconButton 
                          aria-label="Reload"
                          color="secondary" 
                          onClick={reload_clicked}
                          sx={{
                            marginBottom: "1%",
                          }}>
                            <ReplayIcon />
                        </IconButton>
                    </Button>

                  </Grid>
                }
                <Grid container justifyContent="flex-center" sx={{ display: "flex", alignItems: "center" }} xs={12} marginTop={4}>
                  <Typography
                    variant="h6"
                    sx={{
                      marginBottom: "20px",
                    }}
                  >
                    {`${!previewDocumnet ? `${viewDocumentData?.note !== "" ? "Edit Notes" : "Add Notes"}` : "View"}`}
                  </Typography>
                  {!previewDocumnet ?
                    <Grid container>
                      {field?.map((inputData, index) => (
                        <Grid item xs={12} lg={12} key={inputData?.label || index} marginTop={1}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              marginBottom: "10px",
                            }}
                          >
                            {inputData?.label}
                          </Typography>
                          <Field
                            as={TextField}
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder={inputData?.placeholder}
                            name={inputData?.fieldName}
                            sx={cardStyle}
                            multiline
                            rows={4}
                          />
                          <ErrorMessage
                            name={inputData.fieldName}
                            component="div"
                            style={{
                              color: "red",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    :
                    <Grid container>
                      {
                        ["jpg", "jpeg", "png"].includes(viewDocumentData?.document_type) ?
                          <Grid item xs={12} paddingRight={1}>
                            <Avatar
                              alt="User Profile Photo"
                              src={
                                viewDocumentData?.s3_link ? `${viewDocumentData?.s3_link}?${Date.now()}` : avatar
                              }
                              sx={{
                                width: 'auto',
                                height: 500,
                                borderRadius: "0%",
                                marginBottom: "0px",
                                objectFit: "contain",
                                padding: 1,
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 8px #00000029",
                                // visibility: imageLoading
                                //   ? "hidden"
                                //   : "visible",
                              }}
                              onError={(e) => {
                                e.target.src = avatar;
                                e.target.onerror = null;
                              }}
                            />
                          </Grid> :
                          ["pdf"].includes(viewDocumentData?.document_type) ?
                            <Grid item xs={12}>
                              {/* {docLoading ?
                              <CircularLoader /> : */}
                              <embed src={`${viewDocumentData?.s3_link}?${Date.now()}#toolbar=0&navpanes=0`}
                                type="application/pdf"
                                width="100%"
                                height="600px"
                              // onLoad={() => { setDocLoading(false) }}
                              >
                              </embed>
                              {/* } */}
                            </Grid>
                            :
                            <Grid item xs={12}>
                              <iframe
                                ref={iframe} 
                                src={`https://view.officeapps.live.com/op/embed.aspx?src=${viewDocumentData?.s3_link}?${Date.now()}&embedded=true`}
                                width="100%"
                                height="600px"
                              ></iframe>

                              {/* <iframe src={`https://docs.google.com/viewer?hl=en&embedded=true&url=${viewDocumentData?.s3_link}?${Date.now()}`}
                                width="100%"
                                height="600px"
                              ></iframe> */}

                            </Grid>

                      }
                      <Grid item marginTop={3}>
                        <Typography variant="h6">
                          Notes
                        </Typography>
                        <Typography variant="body1">
                          {`${viewDocumentData?.note}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  )
}

export default ViewDocument