// questionsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  quaAns: [],
  goals: [],
  carePlanAssign: false,
  icd_code: "",
  conditonName: "",
  call_list_update: false,
  firstName: "",
  lastName:""
};

const quesAnsSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    addQuesAns: (state, action) => {
      state.quaAns = action.payload
    },
    addGoals: (state, action) => {
      state.goals = action.payload
    },
    removeQuesAns: (state, action) => {
      console.log("action",action)
      // Use the action.payload as the index to remove the question
      const indexToRemove = action.payload;

      // Remove the question with the matching index
      state.questions.splice(indexToRemove, 1);
    },
    isCarePlanAssign: (state, action) => { 
      state.carePlanAssign = action.payload
    },
    addCode: (state, action) => { 
      state.icd_code = action.payload
    },
    addCondition: (state, action) => { 
      state.conditonName = action.payload
    },
    updateCall: (state, action) => { 
      state.call_list_update = action.payload
    },
    searchEHRFirst: (state, action) => { 
      state.firstName = action.payload
    },
    searchEHRLast: (state, action) => { 
      state.lastName = action.payload
    },
    
  },
});

export const { addQuesAns, removeQuesAns, addGoals, isCarePlanAssign, addCode, addCondition, updateCall, searchEHRFirst, searchEHRLast} = quesAnsSlice.actions;

export default quesAnsSlice.reducer;
