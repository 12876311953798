import config from "../../config";
import httpClient from "../base"

const BACKEND_BASE_URL = config.BACKEND_BASE_URL;

export const CarePlanApi = {

getCareConditions: (condition, code) =>
httpClient.get(`${BACKEND_BASE_URL}care-plan/api/v1/list?condition=${condition}&icd_code=${code}`),
  
getCareQuestionsById: (id) =>
httpClient.get(`${BACKEND_BASE_URL}care-plan/api/v1/${id}/questions`),

assignCarePlan: (id, payload) =>
httpClient.post(`${BACKEND_BASE_URL}care-plan/api/v1/${id}`, payload),

getCareAllCarePlan: () =>
httpClient.get(`${BACKEND_BASE_URL}care-plan/api/v1/list`),

updateCarePlanNotes: (id, payload) =>
httpClient.put(`${BACKEND_BASE_URL}care-plan/api/v1/${id}/note`, payload),

getPatientCarePlan: (id, query) =>
httpClient.get(`${BACKEND_BASE_URL}care-plan/api/v1/${id}`,{ ...query }),

getHistory: (id) =>
httpClient.get(`${BACKEND_BASE_URL}care-plan/api/v1/${id}/note-history`),
    
}