import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  PatientForm: {

  },
  updateNoteHistory: false
};

const patientFormSlice = createSlice({
  name: 'PatientForm',
  initialState,
  reducers: {
    updatePatientFormState: (state, action) => {
        state.PatientForm.patientDetails = action.payload; 
      },
  },
});

export const { updatePatientFormState } = patientFormSlice.actions;
export default patientFormSlice.reducer;