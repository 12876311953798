import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  CardActions,
  Card,
  CardContent,
  Button,
  Modal,
} from "@mui/material";
import { customDataTableStyles } from "../../../../../ccm-constant";
import { IconArrowsSort } from "@tabler/icons-react";
import CustomPagination from "../../../../../ui-component/Pagination";
import { SettingsApi } from "../../../../../service/Settings/Index";
import { CustomScrollbars } from "../../../../../ui-component/ScrollBar";
import CircularLoader from "../../../../../ui-component/CircularLoader";
import { formatDate } from "../../../../../ccm-constant";
import AllAuditLog from "./AllAuditLog";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  height: 700,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const MyDataTable = ({ onStartDate, onEndDate, auditEvent }) => {
  console.log("auditEvent", auditEvent);
  console.log("onStartDate, onEndDate", onStartDate, onStartDate);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const columns = [

    {
      name: "Log In",
      selector: (row) => row?.action,
      // sortable: true,
      width: "20%",
      cell: (row) => <>{formatDate(row.log_in, true)}</>,
    },
    {
      name: "Log Out",
      selector: (row) => row?.action,
      // sortable: true,
      width: "20%",
      cell: (row) => <>{formatDate(row.log_out, true)}</>,
    },
    {
      name: "Description",
      selector: (row) => `${row?.description}`,
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.description !== undefined ? row.description : "-"}
        </div>
      ),
      // sortable: true,
      width: "20%",
    },
    {
      name: "User IP Address",
      selector: (row) => row?.ip_address,
      // sortable: true,
      width: "15%",
    },
    {
      name: "User",
      selector: (row) => row?.user_name,
      // sortable: true,
      width: "25%",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.user_name !== undefined ? row?.user_name : "-"}
        </div>
      ),
    },
  ];
  const [auditLog, setAuditLog] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  console.log("currentPage", setCurrentPage);

  function toTitleCase(str) {
    return str
      ?.toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }

  const handleSort = (column, sortDirection) => {
    console.log("handleSort", column, sortDirection);

    if (column.name === "Date & Time") {
      setSortedColumn("timestamp");
    } else if (column.name === "User") {
      setSortedColumn("actor");
    } else {
      setSortedColumn(column.selector);
    }
    setSortDirection(sortDirection);
  };
  const type = "audit_event";

  useEffect(() => {
    console.log(
      currentPage,
      sortDirection,
      sortedColumn,
      onEndDate,
      onStartDate,
      type
    );
    setLoading(true);
    const limit = "personal";
    SettingsApi.userAuditLog(
      currentPage === 0 ? currentPage : (currentPage - 1) * 10,
      sortDirection,
      sortedColumn,
      onEndDate,
      onStartDate,
      // auditEvent ? type : null,
      limit
    )
      .then((response) => {
        console.log("auditLog", response);
        setAuditLog(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // if (currentPage || sortDirection || sortedColumn || auditEvent || onStartDate && onEndDate) {
    //   if (onStartDate !== null && onEndDate !== null && onStartDate !=="NaN-NaN-NaN" && onEndDate !== "NaN-NaN-NaN") {
    //     setCurrentPage(0);
    //     fetchAuditLog();
    //   } else {
    //     fetchAuditLog();
    //   }
    // }
    return () => {
      // setAuditLog([]);
    };
  }, [currentPage, sortDirection, sortedColumn, auditEvent]);

  useEffect(() => {
    console.log("onStartDate", onStartDate, onEndDate);
    if (onStartDate !== null && onEndDate !== null) {
      if (
        (onStartDate !== "NaN-NaN-NaN" && onEndDate !== "NaN-NaN-NaN") ||
        (onStartDate === "null" && onEndDate === "null")
      ) {
        setCurrentPage(0);
        fetchAuditLog();
        console.log("fetch4");
      } else if (onStartDate === "NaN-NaN-NaN" && onEndDate === "NaN-NaN-NaN") {
        setCurrentPage(0);
        fetchAuditLog();
        console.log("fetch5");
      }
    }
  }, [onStartDate, onEndDate]);

  const fetchAuditLog = () => {
    const offset = 2;
    setLoading(true);
    SettingsApi.userAuditLog(
      currentPage === 0 ? currentPage : (currentPage - 1) * 10,
      sortDirection,
      sortedColumn,
      onEndDate,
      onStartDate,
      auditEvent ? type : null,
      offset
    )
      .then((response) => {
        console.log("auditLog", response);
        setAuditLog(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  const Table = () => {
    return(
    <Grid item xs={12}>
      
    {/* <Card sx={{ position: "relative" }}> */}
      <CardContent>
        <Grid
          container
          xs={12}
          padding={1}
          sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
        >
          <CustomScrollbars height="58vh">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* {!loading ? ( */}
                <DataTable
                  columns={columns}
                  data={auditLog?.data}
                  sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                  sortServer
                  onSort={handleSort}
                  sortColumn={sortedColumn}
                  sortDirection={sortDirection}
                  highlightOnHover
                  pointerOnHover
                  customStyles={customDataTableStyles}
                />
              {/* ) : (
                <CircularProgress
                  color="primary"
                  sx={{ marginTop: "15%" }}
                />
               )} */}
            </Grid>
          </CustomScrollbars>
        </Grid>
      </CardContent>
     {/* {open && <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}>
        {auditLog?.count >= 1 && (
          <Grid
            item
            xs={12}
            marginTop={2}
            // display="flex"
            // alignItems="center"
            // justifyContent="space-between"
          >
            <CustomPagination
              count={auditLog?.count}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Grid>
        )}
      </CardActions>} */}
    {/* </Card> */}
      </Grid>
    )
  }

  return (
    <>
      <Grid
        item
        xs={12}
        // padding={2}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          marginRight: 2,
        }}
      >
        { auditLog?.data?.length >=2 && <Button
          variant="text"
          sx={{
            textTransform: "none",
            textDecoration: "underline",
            paddingTop: "0px",
          }}
          // onClick={() => ViewAllLi<ConfirmBoxst()}
          onClick={handleOpen}
        >
          View All List
        </Button>}
      </Grid>
      <Table/>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
                Text in a modal
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography> */}
          <AllAuditLog />
        </Box>
      </Modal>
    </>
  );
};

function UserAuditLog({ onStartDate, onEndDate, auditEvent }) {
  return (
    <MyDataTable
      onStartDate={onStartDate}
      onEndDate={onEndDate}
      auditEvent={auditEvent}
    />
  );
}

export default UserAuditLog;
