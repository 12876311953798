import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Card,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import cardStyle from "../../../../../../theme/card-layout";
import { CustomScrollbars } from "../../../../../../ui-component/ScrollBar";
import { SettingsApi } from "../../../../../../service/Settings/Index";
import { useSettings } from "../../..";
import { useNotification } from "../../../../../../hooks/use-notification";
import { FileUploader } from "react-drag-drop-files";
import { IconUpload } from "@tabler/icons-react";
import { useTheme } from "@mui/material/styles";
import DragAndDrop from "../../../../../../ui-component/DragAndDrop"
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/bootstrap.css";
import CustomPhoneInput from "../../../../../../ui-component/CustomPhoneInput";

function AddProvider({ handleClose }) {
  const { setIsUserAdd } = useSettings();
  const { notifySuccess, notifyError } = useNotification();
  const [selectedImage, setSelectedImage] = useState(null);
  const [phone, setPhone] = useState("");

  const initialValues = {
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    address_line_1: "",
    address_line_2: "",
    country: "",
    state: "",
    zip_code: "",
    city:"",
    profile_pic: "",
  };
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const fileTypes = ["JPEG", "PNG", "GIF", "JPG", "SVG", "BMP"];
  const primaryColor = theme.palette.primary.main;

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      // .max(50, "First Name must not exceed 50 characters")
      .required("First Name is required"),
    last_name: Yup.string()
      // .max(50, "Last Name must not exceed 50 characters")
      .required("Last Name is required"),
    // phone_number: Yup.string()
    //   .matches(/^[0-9]{10}$/, "Invalid phone number")
    //   .required("Phone Number is required"),
    email: Yup.string().email("Invalid Email ID").required("Email ID is required"),
    address_line_1: Yup.string()
      // .max(50, "Address Line 1 must not exceed 50 characters")
      .required("Address Line 1 is required"),
    // address_line_2: Yup.string().max(
    //   50,
    //   "Address Line 2 must not exceed 50 characters"
    // ),
    state: Yup.string()
      // .max(50, "State must not exceed 50 characters")
      .required("State is required"),
    city: Yup.string()
      // .max(50, "State must not exceed 50 characters")
      .required("City is required"),
    country: Yup.string()
      // .max(50, "Country must not exceed 50 characters")
      .required("Country is required"),
    zip_code: Yup.string()
      .matches(
        /^[0-9]{5,6}$/,
        "Zip code must be 5 to 6 digits and contain only numbers"
      )
      .required("Zip code Address is required"),
    phone_number: Yup.string()
      .matches(/^[\d\s()+-]+$/, "Invalid Phone number")
      .min(10, "Phone number is too short")
      .max(20, "Phone number is too long")
      .required("Phone number is required"),
  });

  const handleFormSubmit = (values) => {
    let payload = {
      email: values?.email,
      details: {
        first_name: values?.first_name,
        last_name: values?.last_name,
        phone_number: values?.phone_number,
        // profile_pic: values?.profile_pic,
      },
      address: {
        address_line_1: values?.address_line_1,
        address_line_2: values?.address_line_2,
        country: values?.country,
        state: values?.state,
        city:values?.city,
        zip_code: values?.zip_code,
      },
    };
    setLoading(true);
    SettingsApi.addUser("admin", payload)
      .then((response) => {
        if (values?.profile_pic) {
          SettingsApi.AddProfileImage(response?.data?.uuid, {
            profile_pic: values?.profile_pic[0],
          })
            .then((response) => {
              handleClose();
              setLoading(false);
              setIsUserAdd((prev) => !prev);
              notifySuccess("Admin Added Successfully!");
            })
            .catch((error) => {
              notifyError(error?.response?.data?.error);
            });
        } else {
          handleClose();
          setLoading(false);
          setIsUserAdd((prev) => !prev);
          notifySuccess("Admin Added Successfully!");
        }
      })
      .catch((error) => {
        if (!error?.response?.data?.error?.address) {
          notifyError(error?.response?.data?.error);
        } else {
          notifyError("Something went wrong!");
        }

        setLoading(false);
        // for (const key in error?.response?.data?.error) {
        //   console.log("key", key);
        //   if (key === "address") {
        //     if (error?.response?.data?.error?.hasOwnProperty(key)) {
        //       for (const key in error?.response?.data?.error?.address) {
        //         if (
        //           error?.response?.data?.error?.address?.hasOwnProperty(key)
        //         ) {
        //           for (const key in error?.response?.data?.error?.address) {
        //             if (
        //               error?.response?.data?.error?.address?.hasOwnProperty(key)
        //             ) {
        //               const values = error?.response?.data?.error?.address?.[key];
        //               values?.forEach((value, index) => {
        //                 notifyError(`${key} - ${value}`);
        //               });
        //             }
        //           }
        //         }
        //       }
        //     }
        //   } else {
        //     if (error?.response?.data?.error?.hasOwnProperty(key)) {
        //       const values = error?.response?.data?.error[key];
        //       values.forEach((value, index) => {
        //         notifyError(`${key} - ${value}`);
        //       });
        //     }
        //   }
        // }
        // for (const key in error?.response?.data?.error) {
        //   if (error?.response?.data?.error?.hasOwnProperty(key)) {
        //     console.log(`Key: ${key}`);
        //     const values = error?.response?.data?.error[key];
        //     console.log(`Values:`);
        //     values.forEach((value, index) => {
        //       notifyError(`${key} - ${value}`);
        //       console.log(value);
        //     });
        //   }
        // }
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
    >
      {({ values, setFieldValue, handleSubmit }) => (
        <Form>
          <Grid container>
            {/* Header */}
            <Grid item xs={6} padding={2}>
              <Typography variant="h6" sx={{ marginBottom: "15px" }}>
                Add Admin User
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              padding={2}
              sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{
                  height: "40px",
                  fontWeight: 700,
                  textTransform: "none",
                  width: "163px",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{
                  height: "40px",
                  fontWeight: 700,
                  marginLeft: "20px",
                  textTransform: "none",
                  width: "163px",
                }}
                onClick={handleSubmit}
              >
                Save
                {loading && (
                  <CircularProgress size={18} style={{ color: "white" }} />
                )}
              </Button>
            </Grid>

            {/* Form Content */}
            <CustomScrollbars height={"85.5vh"}>
              <Grid container>
                <Grid items xs={12} lg={6}>
                  <Grid container spacing={2} padding={2}>
                    {/* First Name */}
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500" }}
                      >
                        First Name
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        style={cardStyle}
                        placeholder="First Name"
                        name="first_name"
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        style={{ color: "red", paddingTop: "5px" }}
                      />
                    </Grid>

                    {/* Address Line 1 */}
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500" }}
                      >
                        Address Line 1
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        style={cardStyle}
                        placeholder="Address Line 1"
                        name="address_line_1"
                      />
                      <ErrorMessage
                        name="address_line_1"
                        component="div"
                        style={{ color: "red", paddingTop: "5px" }}
                      />
                    </Grid>

                    {/* Email Id */}
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500" }}
                      >
                        Email ID
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        style={cardStyle}
                        placeholder="Enter Email"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        style={{ color: "red", paddingTop: "5px" }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500" }}
                      >
                        City
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        style={cardStyle}
                        placeholder="City"
                        name="city"
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        style={{ color: "red", paddingTop: "5px" }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500" }}
                      >
                        Country
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        style={cardStyle}
                        placeholder="Country"
                        name="country"
                      />
                      <ErrorMessage
                        name="country"
                        component="div"
                        style={{ color: "red", paddingTop: "5px" }}
                      />
                    </Grid>
                    {/* ZipCode */}
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500" }}
                      >
                        Zip Code
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        style={cardStyle}
                        placeholder="Enter Zip Code"
                        name="zip_code"
                      />
                      <ErrorMessage
                        name="zip_code"
                        component="div"
                        style={{ color: "red", paddingTop: "5px" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid items xs={12} lg={6}>
                  <Grid container spacing={2} padding={2}>
                    {/* Last Name */}
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500" }}
                      >
                        Last Name
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        style={cardStyle}
                        placeholder="Last Name"
                        name="last_name"
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        style={{ color: "red", paddingTop: "5px" }}
                      />
                    </Grid>

                    {/* Address Line 2 */}
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500" }}
                      >
                        Address Line 2
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        style={cardStyle}
                        placeholder="Address Line 2"
                        name="address_line_2"
                      />
                      <ErrorMessage
                        name="address_line_2"
                        component="div"
                        style={{ color: "red", paddingTop: "5px" }}
                      />
                    </Grid>

                    {/* Phone Number */}
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500" }}
                      >
                        Phone Number
                      </Typography>
                      <CustomPhoneInput
                      name="phone_number"
                      as={TextField}
                      variant="outlined"
                        country={"us"}
                        enableSearch={true}
                        value={values.phone_number}
                        placeholder="Enter Phone Number"
                        onChange={(phone) =>
                          setFieldValue("phone_number", phone)
                        }
                      />
                      {/* <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        style={cardStyle}
                        placeholder="Enter Phone Number"
                        name="phone_number"
                      /> */}
                      <ErrorMessage
                        name="phone_number"
                        component="div"
                        style={{ color: "red", paddingTop: "5px" }}
                      />
                    </Grid>

                    {/* State */}
                    <Grid item xs={12}>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "500" }}
                      >
                        State
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        fullWidth
                        style={cardStyle}
                        placeholder="State"
                        name="state"
                      />
                      <ErrorMessage
                        name="state"
                        component="div"
                        style={{ color: "red", paddingTop: "5px" }}
                      />
                    </Grid>

                    <Grid item xs={12} marginTop={1}>
                      <DragAndDrop isProvider={false}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CustomScrollbars>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default AddProvider;
