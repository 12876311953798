import { Tab, Tabs } from "@mui/material";
import React, { memo } from "react";
import { changeNavTab } from "../../../../store/slices/NavTabsSlices";
import { useDispatch } from "react-redux";

const NavTabs = memo(({ a11TabsProps, value, setValue }) => {
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    // console.log("first,a11TabsProps", event?.target?.textContent, event)
    // const userRole = JSON.parse(localStorage.getItem("user") || "{}")
    // console.log("first,a11TabsProps",a11TabsProps?.length)
    // let newValues = userRole?.user?.data?.role === "Non Clinical" ?  newValue : newValue
    setValue(event?.target?.textContent);
    dispatch(changeNavTab(event?.target?.textContent));
  };

  function a11Tabs(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <>
      <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" xs={6} variant="scrollable">
        {a11TabsProps?.map((tab, id) => (
          <Tab
            key={id} // Assign a unique key
            label={tab.label}
            {...a11Tabs(id)}
            style={{ textTransform: "none", fontSize: "17px" }}
            sx={{
              minWidth: 0,
              borderBottomColor: tab.label === value ? "#002c6b" : "transparent", // Change the border color when selected
              borderBottomWidth: 2, // Add a border width to make the border visible
              borderBottomStyle: "solid", // Use a solid border style
              color: tab.label === value ? "#002c6b" : null,
            }}
          />)
        )}
      </Tabs>
    </>
  );
});

export default NavTabs;
