// PDFService.jsx (Child Component)
import React from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';

function PDFService({ children, pdfExportRef }) {
  const pdfExportComponent = React.useRef(null);

  React.useImperativeHandle(pdfExportRef, () => ({
    exportPDFWithMethod: () => {
      if (pdfExportComponent.current) {
        pdfExportComponent.current.save();
      }
    },
  }));

  return (
    <div className="App">
      <PDFExport
        ref={pdfExportComponent}
        paperSize="A4"
        landscape
        margin = "1cm"
        fileName={`Report for ${new Date().getFullYear()}`}
        author="KendoReact Team"
      >
        <div id="show_math_loader" style={{ display: 'none' }}>
          Parsing Mathjax
        </div>
        <div id="print_page">{children}</div>
      </PDFExport>
    </div>
  );
}

export default PDFService;
