import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  CircularProgress,
} from "@mui/material";
import React, { createContext, useEffect, useState } from "react";
import cardStyle from "../../../../theme/card-layout";
import Breadcrumb from "../../../../ui-component/Breadcrumb";
import SearchIcon from "@mui/icons-material/Search";
import Enrollement from "./Enrollement";
import Device from "./Device";
import TimeLog from "./TimeLog";
import Insurance from "./Insurance";
import DrawerComponent from "../../../../ui-component/Drawer";
import AddInsurance from "./AddInsurance";
import BillingReport from "./BillingReport";
import Configure from "./Configure";
import { useNotification } from "../../../../hooks/use-notification";
import { Billing } from "../../../../service/Billing";
import { PatientApi } from "../../../../service/ProviderGroup/Patient";
import CheckPermission from "../../../../ui-component/ButtonWrapper";

export const patientContetxt = createContext()

const PatientDetails = () => {
  const { PatientId } = useParams();

  console.log("id=======", PatientId);
  const [value, setValue] = useState(0);
  const [addInsurance, setAddInsurance] = useState(false);
  const [loading, setLoading] = useState(false);
  const [monthlyBillingReport, setMonthlyBillingReport] = useState(false);
  const { notifySuccess, notifyError } = useNotification();
  const [fetchingData, setFetchingData] = useState(false);
  const [patientdata, setPatientdata] = useState();


  
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId =
  userType === "provider_group"
  ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
  : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
  ?.uuid;
  
  let providerRole = userType === "provider"  ? ["Physician", "Care Team"].includes(JSON.parse(localStorage.getItem("user"))?.user?.data?.role) : true


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getPatientData();
  }, [value, addInsurance]);

  const a11TabsProps = [
    {
      label: "Enrollment",
    },
    {
      label: "Device",
    },
    {
      label: "Time Log",
    },
    {
      label: "Insurance",
    },
  ];

  function a11Tabs(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const sendReportHandler = () => {
    const body = {
      html_report: "",
    };
    setLoading(true);
    Billing.getReportData(ProviderId, PatientId, body)
      .then((response) => {
        notifySuccess(
          "The report has been successfully sent to the registered email address!"
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notifyError(error?.response?.data?.error);
        console.error("Error fetching Time Log data:", error);
      });
  };

  const tabComponents = {
    0: <Enrollement/>,
    1: <Device />,
    2: <TimeLog />,
    3: <Insurance />,
  };

  const getPatientData = () => {
    setFetchingData(true);
    PatientApi.getPatientDataById(PatientId)
      .then((response) => {
        console.log("PatientApi response", response);
        setPatientdata(response);
        setFetchingData(false);
      })
      .catch((error) => {
        setFetchingData(false);
        setPatientdata(null)
        console.error("Error fetching patients data:", error);
      });
  };

  return (
    <patientContetxt.Provider   value={{
      patientData:patientdata,
      fetchingData:fetchingData
    }}>
    <Grid container spacing={3} padding={3}>
      <Grid item xs={12}>
        <Card
          sx={{
            ...cardStyle,
            minHeight: "88vh",
          }}
        >
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Breadcrumb value={"Billing"} tabLabels={""} title="Billing" />
              </Grid>
              <Grid item display={"flex"} justifyContent={"end"} xs={6}>
                {/* <TextField
                  variant="outlined"
                  placeholder="Type here to search"
                  size="small"
                  sx={{ ...cardStyle, width: "400px" }}
                  // value={searchText}
                  // onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                /> */}
                <Stack direction={"row"} spacing={3}>
                  {value === 0 ? (
                    <>
                      <Button
                        variant="outlined"
                        style={{
                          textTransform: "none",
                          width: "auto",
                        }}
                        onClick={() => setMonthlyBillingReport(true)}
                      >
                        View Report
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          textTransform: "none",
                          width: "auto",
                        }}
                        onClick={sendReportHandler}
                      >
                        Send Report
                        {loading && (
                          <CircularProgress
                            size={18}
                            style={{ color: "white" }}
                          />
                        )}
                      </Button>
                    </>
                  ) : value === 3 && providerRole ? (
                    <CheckPermission codeName={"change_patient"}>
                      <Button
                        variant="contained"
                        style={{
                          textTransform: "none",
                          width: "auto",
                        }}
                        onClick={() => setAddInsurance(true)}
                      >
                        Edit Insurance
                      </Button>
                    </CheckPermission>
                  ) : (
                    <></>
                  )}
                </Stack>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                xs={6}
                variant="scrollable"
              >
                {a11TabsProps?.map((tab, id) => {
                  return (
                    <Tab
                      label={tab.label}
                      {...a11Tabs(id)}
                      style={{ textTransform: "none", fontSize: "17px" }}
                      sx={{
                        minWidth: 0,
                      }}
                    />
                  );
                })}
              </Tabs>
            </Grid>

            <Grid item xs={12}>
              {tabComponents[value]}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <DrawerComponent
        open={addInsurance}
        handleClose={() => setAddInsurance(false)}
        data={<AddInsurance patientData={patientdata} handleClose={() => setAddInsurance(false)} />}
      />

      <DrawerComponent
        open={monthlyBillingReport}
        handleClose={() => setMonthlyBillingReport(false)}
        drawerSize={true}
        data={
          <Configure
            handleClose={() => setMonthlyBillingReport(false)}
            isViewReport={true}
            patientId={PatientId}
          />
        }
      />
    </Grid>
    </patientContetxt.Provider>
  );
};

export default PatientDetails;
