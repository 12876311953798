import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  Select,
  TextField,
  CircularProgress,
  Box,
} from "@mui/material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { ProvidersApi } from "../../../../../../../../../service/ProviderGroup/Providers";
import { PatientApi } from "../../../../../../../../../service/ProviderGroup/Patient";
import { useNotification } from "../../../../../../../../../hooks/use-notification";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";
import SelectDropdown from "../../../../../../../../../ui-component/SelectDropdown";

const TimeLogForm = ({ setOpenDrawer, setRefreshList }) => {
  const { id } = useParams();
  const [timer, setTimer] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [allProviders, setAllProviders] = useState([]);
  const { notifyError, notifySuccess } = useNotification();
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [minEndDate, setMinEndDate] = useState(null);
  const currentDate = new Date();
      const minDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);; // Current date
      const maxDate = currentDate; // max date (30+ days)
    
      const dateObj = new Date(minDate);
    
      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const day = String(dateObj.getDate()).padStart(2, "0");
    
      const formattedDate = `${year}-${month}-${day}`;
    
      const dateObj1 = new Date(maxDate);
    
      const year1 = dateObj1.getFullYear();
      const month1 = String(dateObj1.getMonth() + 1).padStart(2, "0");
      const day1 = String(dateObj1.getDate()).padStart(2, "0");
    
      const formattedDate1 = `${year1}-${month1}-${day1}`;

  const [startDate, setStartDate] = useState(new Date());
  console.log("START", startDate);
  const [endDate, setEndDate] = useState(null);

  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId =
    userType === "provider_group"
      ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
      : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
        ?.uuid;

  useEffect(() => {
    fetchAllProviders();
  }, []);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
        setTotalTime((prevTotalTime) => prevTotalTime + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);

  const fetchAllProviders = async () => {
    console.log("I am calling");
    const query = {
      order_by: "first_name"
    };
    PatientApi.getLoggedByList(id, query)
      .then((response) => {
        setAllProviders(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching Providers data:", error);
      });
  };

  const field = [
    {
      label: "Logged As",
      fieldName: "as",
      fieldType: Select,
      placeholder: "Select",
      options: [
        { label: "Discussion With Other Providers", name: "Discussion With Other Providers" },
        { label: "Initial Action Items", name: "Initial Action Items" },
        { label: "Monthly Clinical Review", name: "Monthly Clinical Review" },
        { label: "Telephone Call With Patient", name: "Telephone Call With Patient" },
      ],
    },
    {
      label: "Logged By",
      fieldName: "by",
      fieldType: Select,
      placeholder: "Select",
      options: [
        ...(allProviders?.map((provider) => ({
          label: `${provider?.first_name} ${provider?.last_name}`,
          name: `${provider?.uuid}`,
        })) || []),
      ],
    },
    {
      label: "Note",
      fieldName: "note",
      fieldType: TextField,
      placeholder: "Note",
    },
  ];

  const validationSchema = Yup.object().shape({
    as: Yup.object().required("Logged As is required"),
    by: Yup.object().required("Logged By is required"),
    note: Yup.string().required("Note is required"),
    startDate: Yup.date().required("Start Date Time is required"),
    endDate: Yup.date().required("End Date Time is required"),
  });

  const handleDateTimeChange = (date, isStart) => {
    if (date) {
      const separatedValues = separateDateAndTime(date);
      if (isStart) {
        console.log("Start Date:", separatedValues?.date);
        console.log("Start Time:", separatedValues?.time);
        setStartDate(separatedValues);
      } else {
        console.log("End Date:", separatedValues?.date);
        console.log("End Time:", separatedValues?.time);
        setEndDate(separatedValues);
      }
    }
  };

  const separateDateAndTime = (dateTime) => {
    const date = dayjs(dateTime)?.format("YYYY-MM-DD");
    const time = dayjs(dateTime)?.format("HH:mm:ss");
    return { date, time };
  };

  const validDate = (values) => {
    const errors = {};
    if (startDate && endDate) {
      const startDateTime = dayjs(`${startDate.date} ${startDate.time}`);
      const endDateTime = dayjs(`${endDate.date} ${endDate.time}`);
      console.log(
        "endDateTime.isAfter(startDateTime)",
        endDateTime.isAfter(startDateTime),
        endDateTime,
        startDateTime
      );

      if (startDateTime.isAfter(endDateTime)) {
        errors.endDate =
          "End date time should be later than the start date  time.";
      }

      if (startDateTime.isSame(endDateTime)) {
        errors.endDate =
          "Start date time should be earlier than the end date time.";
      }
    }
    return errors;
  };

  // const setMinEndDates = (startDate) => {
  //   setMinEndDate(startDate);
  // };

  const disableEndTime = (date) => {
    if (startDate) {
      const startDateTime = dayjs(startDate.date + " " + startDate.time);
      return date.isBefore(startDateTime);
    }
    return false;
  };

  return (
    <>
      <Formik
        initialValues={{
          as: "",
          by: "",
          startDate: "",
          note: "",
          endDate: "",
        }}
        validationSchema={validationSchema}
        validate={validDate}
        onSubmit={(data) => {
          console.log("Time Data", data);
          const payload = {
            patient: id,
            start_time_log: data?.startDate,
            end_time_log: data?.endDate,
            timelog_by: data?.by?.name,
            timelog_as: data?.as?.label,
            // start_time: startDate?.time,
            // end_time: endDate?.time,
            // start_date: startDate?.date,
            // end_date: endDate?.date,
            note: data?.note,
          };
          if (!showError) {
            setLoading(true);
            PatientApi.createTimeLog(payload)
              .then((response) => {
                notifySuccess(response?.message);
                setOpenDrawer(false);
                setRefreshList(true);
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                console.error(
                  "Error fetching assigning care team data:",
                  error
                );
                notifyError(error?.response?.data?.error);
              });
          } else {
          }
        }}
      >
        {({ handleSubmit, values, setFieldValue, touched, errors }) => (
          <Form>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    width: "100px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setOpenDrawer(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    marginLeft: "20px",
                    width: "120px",
                    color: "#ffffff",
                    textTransform: "none",
                  }}
                  onClick={()=> !loading ? handleSubmit() : null}
                >
                  Save &nbsp;
                  {loading && (
                    <CircularProgress size={18} style={{ color: "white" }} />
                  )}
                </Button>
              </Grid>
              {/* <Grid item xs={12}>
                <Grid container height="86vh"> */}
              <Grid
                item
                xs={12}
                sx={{
                  height: 400,
                  position: "relative",
                  marginBottom: "20px",
                  borderRadius: "20px",
                  opacity: 1,
                }}
              >
                <Grid container>
                  {field?.map((inputData, index) => (
                    <Grid
                      item
                      xs={12}
                      key={inputData?.label || index}
                      sx={{ marginTop: "10px" }}
                    >
                      {inputData?.fieldName === "note" ? (
                        <>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DateTimePicker"]}>
                              <Grid container>
                                <Grid md={12} sm={12} lg={6} item>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ marginBottom: "10px" }}
                                  >
                                    Start Date Time
                                  </Typography>
                                  <DateTimePicker
                                    onChange={(date) => {
                                      setFieldValue("startDate", date);
                                      handleDateTimeChange(date, true);
                                      setMinEndDate(date);
                                    }}
                                    ampm={false}
                                    minDate={dayjs(formattedDate)}
                                    maxDate={dayjs(formattedDate1)}
                                    name={"startDate"}
                                    disableFuture
                                    // format="YYYY-MM-DD HH:mm:ss"
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                    }}
                                    value={
                                      startDate &&
                                      new Date(
                                        `${startDate?.date}T${startDate?.time}`
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={"startDate"}
                                    component="div"
                                    style={{
                                      color: "red",
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                    }}
                                  />
                                </Grid>
                                <Grid md={12} sm={12} lg={6} item>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ marginBottom: "10px" }}
                                  >
                                    End Date Time
                                  </Typography>
                                  <DateTimePicker
                                    name={"endDate"}
                                    onChange={(date) => {
                                      setFieldValue("endDate", date);
                                      handleDateTimeChange(date, false);
                                    }}
                                    ampm={false}
                                    minDate={minEndDate}
                                    disableTime={(date) => disableEndTime(date)}
                                    disableFuture
                                    // format="YYYY-MM-DD HH:mm:ss"
                                    disabled={!startDate}
                                    viewRenderers={{
                                      hours: renderTimeViewClock,
                                      minutes: renderTimeViewClock,
                                      seconds: renderTimeViewClock,
                                    }}
                                    value={
                                      endDate &&
                                      new Date(
                                        `${endDate?.date}T${endDate?.time}`
                                      )
                                    }
                                  />
                                  <ErrorMessage
                                    name={"endDate"}
                                    component="div"
                                    style={{
                                      color: "red",
                                      marginTop: "10px",
                                      marginBottom: "10px",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </DemoContainer>
                          </LocalizationProvider>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                          >
                            {inputData?.label}
                          </Typography>
                          <Field
                            as={TextField}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            placeholder={inputData?.placeholder}
                            name={inputData?.fieldName}
                            sx={cardStyle}
                          />
                          <ErrorMessage
                            name={inputData.fieldName}
                            component="div"
                            style={{
                              color: "red",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          />
                        </>
                      ) : (
                        <>
                          <SelectDropdown data={inputData}></SelectDropdown>
                          <ErrorMessage
                            name={inputData.fieldName}
                            component="div"
                            style={{
                              color: "red",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }}
                          />
                        </>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TimeLogForm;