import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  TextField,
  Button,
  IconButton,
  Select,
  Radio,
  CircularProgress,
  TextareaAutosize,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useLocation, useNavigate } from "react-router";
import { ProviderGroupApi } from "../../../../../../../../../../service/ProviderGroup";
import cardStyle from "../../../../../../../../../../theme/card-layout";
import { useTheme } from "@mui/material/styles";
import Input from "../../../../../Input";
import { CustomScrollbars } from "../../../../../../../../../../ui-component/ScrollBar";
import { useNotification } from "../../../../../../../../../../hooks/use-notification";
import { ProvidersApi } from "../../../../../../../../../../service/ProviderGroup/Providers";
import CircularLoader from "../../../../../../../../../../ui-component/CircularLoader";
import avatar from "../../../../../../../../../../assets/adminAvatar.jpg";
import Breadcrumb from "../../../../../../../../../../ui-component/Breadcrumb";
import { PatientApi } from "../../../../../../../../../../service/ProviderGroup/Patient";
import CheckPermission from "../../../../../../../../../../ui-component/ButtonWrapper";

const containerStyle = {
  position: "relative",
};

const imageContainerStyle = {
  position: "relative",
  // display: "flex !important",
  // alignItems: "center !important",
  // minHeight: "180px",
};

const cameraIconStyle = {
  position: "absolute",
  bottom: -15,
  left: "7rem",
  transform: "translateX(-50%)",
  backgroundColor: "white",
  borderRadius: "50%",
};

const field = [
  {
    label: "Full Name",
    fieldName: "name",
    fieldType: TextField,
    placeholder: "Full Name",
  },
  {
    label: "Relationship",
    fieldName: "relation",
    fieldType: Select,
    placeholder: "Select Relationship",
    options: [
      {
        label: "Child",
        name: "Child",
      },
      {
        label: "Employer",
        name: "Employer",
      },
      {
        label: "Father",
        name: "Father",
      },
      {
        label: "Grandparent",
        name: "Grandparent",
      },
      {
        label: "Grandchild",
        name: "Grandchild",
      },
      {
        label: "Guardian",
        name: "Guardian",
      },
      {
        label: "Life Partner",
        name: "Life Partner",
      },
      {
        label: "Mother",
        name: "Mother",
      },
      {
        label: "Other",
        name: "Other",
      },
      {
        label: "Parent",
        name: "Parent",
      },
      {
        label: "Spouse",
        name: "Spouse",
      },
      {
        label: "Unknown",
        name: "Unknown",
      },
      {
        label: "Parent",
        name: "Parent",
      },
    ],
  },
  {
    label: "Email ID",
    fieldName: "email",
    fieldType: TextField,
    placeholder: "Email ID",
  },
  {
    label: "Phone Number",
    fieldName: "phone_number",
    // fieldType: TextField,
    placeholder: "Phone Number",
  },
  {
    label: "Address Line 1",
    fieldName: "address_line_1",
    fieldType: TextField,
    placeholder: "Address Line 1",
  },
  {
    label: "Address Line 2",
    fieldName: "address_line_2",
    fieldType: TextField,
    placeholder: "Address Line 2",
  },
  {
    label: "City",
    fieldName: "city",
    fieldType: TextField,
    placeholder: "City",
  },
  {
    label: "Country",
    fieldName: "country",
    fieldType: TextField,
    placeholder: "Country",
  },
  {
    label: "State",
    fieldName: "state",
    fieldType: TextField,
    placeholder: "State",
  },
  {
    label: "Zip Code",
    fieldName: "zip_code",
    fieldType: TextField,
    placeholder: "Zip Code",
  },
];

function EditFamilyMember() {
  const navigate = useNavigate();
  const location = useLocation()
  const { id, memberId } = useParams();
  // const provider_id = JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group?.uuid || JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
  // const id = JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
  const { notifySuccess, notifyError } = useNotification();
  const [editMode, setEditMode] = useState(false);
  const [memberData, setMemberData] = useState(null);
  console.log("DATA RESPONSE", memberData)
  const [selectedImage, setSelectedImage] = useState();
  const [loading, setLoading] = useState(false);
  const [updateloading, setUpdateLoading] = useState(false);
  const [fields, setFields] = useState(field);
  const [imageLoading, setImageLoading] = useState(true);
  const userRole = JSON.parse(localStorage.getItem("user") || "{}");

  const handleCancelClick = () => {
    setEditMode(false);
    setLoading(false);
  };

  const handleEditClick = () => {
    setEditMode(false);
    navigate(`/patients/profile/${id}`, {
      state: { tabIndex: "Family", enrolled_status: location?.state?.enrolled_status, patientId: id, care_team_member:location?.state?.care_team_member  },
    });
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const fetchMemberData = () => {
    setLoading(true);
    PatientApi.getFamilyMemberDataById(id, memberId)
      .then((response) => {
        setMemberData(response);
        setSelectedImage(response?.profile_pic || avatar);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Family Member Data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchMemberData();
    setImageLoading(true);
  }, [])


  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    phone_number: Yup.string()
      .matches(/^[\d\s()+-]+$/, "Invalid Phone number")
      .min(10, "Phone number is too short")
      .max(20, "Phone number is too long")
      .required("Phone number is required"),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    address_line_2: Yup.string().required("Address Line 2 is required"),
    zip_code: Yup.string()
      .matches(
        /^[0-9]{5,6}$/,
        "Zip code must be 5 to 6 digits and contain only numbers"
      )
      .required("Zip code is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    relation: Yup.string().required("Relationship is required"),
    email: Yup.string().required("Email ID is required"),
  });

  const isSupportedFileType = (fileType) => {
    const supportedFormats = ["jpeg", "jpg", "png", "gif", "bmp"];
    return supportedFormats.some((format) => fileType.endsWith(format));
  };

  const scrollableFieldStyle = {
    height: "140px", // Set the desired height for your scrollable field
    overflow: "auto",
    width: "100%",
  };

  const isURL = (url) => {
    try {
      if (url.includes("https://")) {
        // new URL(url);
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  };

  return (
    <Grid container spacing={2} padding={3}>
      <Grid item xs={12}>
        <Card sx={{ boxShadow: 3, height: "88vh" }}>
          <>
            <CardContent sx={{ alignItems: "center" }}>
              <Formik
                initialValues={{
                  name: memberData?.name || "",
                  phone_number: memberData?.phone_number || "",
                  address_line_1: memberData?.address?.address_line_1,
                  address_line_2: memberData?.address?.address_line_2,
                  zip_code: memberData?.address?.zip_code || "",
                  city: memberData?.address?.city || "",
                  state: memberData?.address?.state || "",
                  country: memberData?.address?.country || "",
                  relation: memberData?.relation || "",
                  email: memberData?.email || "",
                  profile_pic: "",
                }}
                onSubmit={(data) => {
                  setUpdateLoading(true);
                  console.log("Family Member Data", data);
                  const formData = new FormData();
                  let address = {
                    address_line_1: data?.address_line_1,
                    address_line_2: data?.address_line_2,
                    zip_code: data?.zip_code,
                    city: data?.city,
                    state: data?.state,
                    country: data?.country,
                  };
                  formData.append("address", JSON.stringify(address));
                  formData.append("name", data?.name);
                  formData.append("phone_number", data?.phone_number);
                  formData.append("relation", data?.relation);
                  formData.append("email", data?.email);
                  formData.append("profile_pic", data?.profile_pic);
                  PatientApi.updateFamilyMember(id, memberId, formData)
                    .then((response) => {
                      notifySuccess(response?.message);
                      setUpdateLoading(false);
                      setEditMode(false);
                      setLoading(false);
                      fetchMemberData();
                    })
                    .catch((error) => {
                      console.error("Error fetching Update Family Member Data:", error);
                      setLoading(false);
                      notifyError(error?.response?.data?.error)
                    });
                }}
                validationSchema={validationSchema}
                enableReinitialize
              >
                {({ values, setFieldValue, handleSubmit, resetForm }) => (
                  <Form>
                    <Grid
                      container
                      spacing={2}
                      padding={2}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <>
                        <Typography>
                          <Breadcrumb
                            tabLabels={""}
                            value={""}
                            title={"Family Member"}
                          />
                        </Typography>
                        {editMode ? (
                          <span>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                textTransform: "none",
                                width: "163px",
                              }}
                              onClick={() => {
                                handleCancelClick();
                                resetForm();
                              }}
                              sx={{ marginRight: "10px" }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              color="primary"
                              style={{
                                marginRight: "8px",
                                textTransform: "none",
                                width: "163px",
                              }}
                              type="submit"
                              onClick={handleSubmit}
                            >
                              Save &nbsp;
                              {updateloading && (
                                <CircularProgress
                                  size={18}
                                  style={{ color: "white" }}
                                />
                              )}
                            </Button>
                          </span>
                        ) : (
                          <span>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                textTransform: "none",
                                width: "200px",
                              }}
                              sx={{ marginRight: "10px" }}
                              onClick={handleEditClick}
                            >
                              Cancel
                            </Button>
                            <CheckPermission codeName={"add_familymembers"}>
                              <Button
                                variant="contained"
                                style={{
                                  textTransform: "none",
                                  width: "200px",
                                }}
                                onClick={(e) => {
                                  handleEdit();
                                  e.preventDefault();
                                }}
                              >
                                Edit Family Member
                              </Button>
                            </CheckPermission>
                          </span>
                        )}
                      </>
                    </Grid>
                    {loading ?
                      <Grid item xs={12} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <CircularLoader />
                      </Grid>
                      :
                      <CustomScrollbars height={"74vh"}>
                        <Grid item xs={12}>
                          <Grid container xs={12} marginBottom={2}>
                            <Box
                              display="flex"
                              marginBottom={3}
                              width="97%"
                              margin={1}
                            >
                              <Box
                                position="relative"
                                marginRight={{ xs: 0, sm: 2 }}
                                marginBottom={{ xs: 2, sm: 0 }}
                              >
                                {(imageLoading) && (
                                  <Box
                                    position="absolute"
                                    top={0}
                                    left={0}
                                    width="100%"
                                    height="100%"
                                    backgroundColor="rgba(119, 118, 118, 0.4)"
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    opacity={0.2}
                                    zIndex={2}
                                  >
                                    <CircularProgress
                                      size={50}
                                      sx={{
                                        color: "#ffffff",
                                        position: "absolute",
                                      }}
                                    />
                                  </Box>
                                )}
                                <Avatar
                                  alt="User Profile Photo"
                                  src={
                                    (isURL(selectedImage)
                                      ? `${selectedImage}?${new Date().getTime()}`
                                      : selectedImage) || avatar
                                  }
                                  sx={{
                                    width: 200,
                                    height: 180,
                                    borderRadius: "0%",
                                    marginBottom: "0px",
                                    objectFit: "contain",
                                    padding: 1,
                                    backgroundColor: "#ffffff",
                                    boxShadow: "0px 0px 8px #00000029",
                                    visibility: imageLoading
                                      ? "hidden"
                                      : "visible",
                                  }}
                                  onError={(e) => {
                                    e.target.src = avatar;
                                    e.target.onerror = null;
                                    setImageLoading(false);
                                  }}
                                  onLoad={() => setImageLoading(false)}
                                />
                                {editMode && (
                                  <label
                                    htmlFor="image-upload"
                                    style={cameraIconStyle}
                                  >
                                    <input
                                      id="image-upload"
                                      type="file"
                                      onChange={(e) => {
                                        let file = null;
                                        file = e.target?.files[0];
                                        const maxSize = 5 * 1024 * 1024;
                                        if (file) {
                                          if (
                                            !isSupportedFileType(file.type)
                                          ) {
                                            notifyError(
                                              "Please select a valid image format, such as .jpeg, .jpg, .png, .gif or .bmp."
                                            );
                                          } else if (file.size > maxSize) {
                                            notifyError(
                                              "Image size should not exceed 5 MB."
                                            );
                                          } else {
                                            setFieldValue(
                                              "profile_pic",
                                              file
                                            );
                                            const reader = new FileReader();
                                            reader.onload = (e) => {
                                              setSelectedImage(
                                                e.target.result
                                              );
                                            };
                                            reader.readAsDataURL(file);
                                          }
                                        }
                                      }}
                                      style={{ display: "none" }}
                                    />
                                    <IconButton
                                      color="primary"
                                      aria-label="upload picture"
                                      component="span"
                                    >
                                      <PhotoCameraIcon />
                                    </IconButton>
                                  </label>
                                )}
                              </Box>
                            </Box>
                          </Grid>

                          <Grid
                            container
                            xs={12}
                            columnSpacing={5}
                            rowSpacing={2}
                            paddingLeft={1}
                          >
                            {fields?.slice(0, editMode ? 11 : 4)?.map((field, index) => (
                              <Grid item xs={12} md={6}>
                                <React.Fragment key={field.key}>
                                  {!editMode ? (
                                    <Grid container xs={12} spacing={2}>
                                      <Grid item xs={5}>
                                        <Typography variant="subtitle1">
                                          {field.label}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={7}>
                                        {
                                          <>
                                            <Typography
                                              variant="body1"
                                              component="div"
                                              sx={{ marginBottom: "5px" }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: 700,
                                                }}
                                              >
                                                :&nbsp;&nbsp;&nbsp;
                                              </span>
                                              {field?.fieldName ===
                                                "speciality" ||
                                                field?.fieldName === "specialty"
                                                ? values[
                                                  field?.fieldName
                                                ]?.join(", ")
                                                : values[field?.fieldName]}
                                            </Typography>
                                          </>
                                        }
                                      </Grid>
                                    </Grid>
                                  ) : <Input data={field} />
                                  }
                                  <ErrorMessage
                                    name={field.fieldName}
                                    component="div"
                                    style={{
                                      color: "red",
                                      fontSize: "15px",
                                      marginBottom: 1,
                                      marginTop: "10px",
                                    }}
                                  />
                                </React.Fragment>
                              </Grid>
                            ))}
                            {!editMode && (
                              <Grid item xs={12} md={6}>
                                <React.Fragment key={"address"}>
                                  <Grid container xs={12} spacing={2}>
                                    <Grid item xs={5}>
                                      <Typography variant="subtitle1">
                                        Address
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={7}>
                                      {
                                        <>
                                          <Typography
                                            variant="body1"
                                            component="div"
                                            sx={{ marginBottom: "5px" }}
                                          >
                                            <span style={{ fontWeight: 700 }}>
                                              :&nbsp;&nbsp;&nbsp;
                                            </span>
                                            {`${memberData?.address ? memberData?.address?.address_line_1 : ""}${memberData?.address?.address_line_1 ? "," : ""
                                              } ${memberData?.address?.address_line_2 || ""}${memberData?.address?.address_line_2 ? "," : ""
                                              } ${memberData?.address?.state || ""}${memberData?.address?.state ? "," : ""} ${memberData?.address?.country || ""
                                              }${memberData?.address?.country ? "," : ""} ${memberData?.address?.zip_code || ""
                                              }`}
                                          </Typography>
                                        </>
                                      }
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      </CustomScrollbars>
                    }
                  </Form>
                )}
              </Formik>
            </CardContent>
          </>
        </Card>
      </Grid>
    </Grid >
  );
}

export default EditFamilyMember;
