import React, { useMemo, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Box,
  TextField,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import * as Yup from "yup";
import avatar from "../../../../../assets/adminAvatar.jpg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useLocation, useNavigate } from "react-router";
import cardStyle from "../../../../../theme/card-layout";
import { SettingsApi } from "../../../../../service/Settings/Index";
import { useNotification } from "../../../../../hooks/use-notification";
import { CustomScrollbars } from "../../../../../ui-component/ScrollBar";
import Breadcrumb from "../../../../../ui-component/Breadcrumb";
import CustomPhoneInput from "../../../../../ui-component/CustomPhoneInput";

const containerStyle = {
  position: "relative",
};

const avatarStyle = {
  position: "relative",
  height: 215,
  // background:
  //   'url("https://img.freepik.com/premium-vector/light-blue-vector-background-with-bubbles_6869-1244.jpg?size=626&ext=jpg")',
  backgroundSize: "cover",
  // backgroundColor: "rgba(211, 200, 200, 0.5)",
};

const userProfileStyle = {
  position: "absolute",
  bottom: 0,
  left: 5,
  // width: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "0px 16px",
  color: "#fff",
};

const imageContainerStyle = {
  position: "relative",
};

const cameraIconStyle = {
  position: "absolute",
  bottom: "-25px",
  left: "6.5rem",
  transform: "translateX(-50%)",
  backgroundColor: "white",
  borderRadius: "50%",
};

const fields = [
  { label: "First Name", key: "first_name" },
  { label: "Last Name", key: "last_name" },
  { label: "Email", key: "email", disabled: true },
  { label: "Phone Number", key: "phone_number" },
  { label: "Address Line 1", key: "address_line_1" },
  { label: "Address Line 2", key: "address_line_2" },
  { label: "City", key: "city" },
  { label: "State", key: "state" },
  { label: "Country", key: "country" },
  { label: "Zip Code", key: "zip_code" },
];

function UserProfileDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(location.state?.edit);
  const ProfileDetails = JSON.parse(location.state?.data);
  const [imageLoading, setImageLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(
    ProfileDetails?.profile_pic
  );
  console.log("location.state", selectedImage);
  const [updateImage, setUpdateImage] = useState(null);

  const initialValues = {
    email: ProfileDetails?.email,
    first_name: ProfileDetails?.first_name,
    last_name: ProfileDetails?.last_name,
    phone_number: ProfileDetails?.phone_number,
    address_line_1: ProfileDetails?.address?.address_line_1,
    address_line_2: ProfileDetails?.address?.address_line_2,
    country: ProfileDetails?.address?.country,
    city: ProfileDetails?.address?.city,
    state: ProfileDetails?.address?.state,
    zip_code: ProfileDetails?.address?.zip_code,
    profile_pic: ProfileDetails?.profile_pic,
  };
  const { notifySuccess, notifyError } = useNotification();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email ID address")
      // .max(50, "Email ID must not exceed 50 characters")
      .required("Email ID is required"),
    first_name: Yup.string()
      // .max(50, "First Name must not exceed 50 characters")
      .required("First Name is required"),
    last_name: Yup.string()
      // .max(50, "Last Name must not exceed 50 characters")
      .required("Last Name is required"),
    phone_number: Yup.string()
      .matches(/^[\d\s()+-]+$/, "Invalid Phone number")
      .min(10, "Phone number is too short")
      .max(20, "Phone number is too long")
      .required("Phone number is required"),
    address_line_1: Yup.string()
      // .max(50, "Address Line 1 must not exceed 50 characters")
      .required("Address Line 1 is required"),
    // address_line_2: Yup.string().max(
    //   50,
    //   "Address Line 2 must not exceed 50 characters"
    // ),
    city: Yup.string()
    // .max(50, "State must not exceed 50 characters")
    .required("City is required"),
    state: Yup.string()
      // .max(50, "State must not exceed 50 characters")
      .required("State is required"),
    // city: Yup.string().max(50, "City must not exceed 50 characters"),
    country: Yup.string()
      // .max(50, "Country must not exceed 50 characters")
      .required("Country is required"),
    zip_code: Yup.string()
      .matches(
        /^[0-9]{5,6}$/,
        "Zip code must be 5 to 6 digits and contain only numbers"
      )
      // .max(50, "Zip code must not exceed 50 characters")
      .required("Zip code Address is required"),
  });

  const handleSaveClick = (values) => {
    let payload = {
      first_name: values?.first_name,
      last_name: values?.last_name,
      phone_number: values?.phone_number,
      address: {
        address_line_1: values?.address_line_1,
        address_line_2: values?.address_line_2,
        country: values?.country,
        city:values?.city,
        state: values?.state,
        zip_code: values?.zip_code,
      },
    };
    setLoading(true);
    SettingsApi.updateAdminUser(ProfileDetails?.uuid, payload)
      .then((response) => {
        if (updateImage) {
          SettingsApi.AddProfileImage(ProfileDetails?.uuid, {
            profile_pic: updateImage,
          })
            .then((response) => {
              notifySuccess("Admin Updated Successfully!");
              setLoading(false);
              navigate("/settings", {
                state: { index: 2 },
              });
              // notifySuccess(response.message);
            })
            .catch((error) => {
              notifyError(error?.response?.data?.error);
            });
        } else {
          console.log("updateAdminUser", response);
          notifySuccess("Admin Updated Successfully!");
          setLoading(false);
          navigate("/settings", {
            state: { index: 2 },
          });
        }
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error);
        setLoading(false);
      });
  };

  const handleImageChange = (e) => {
    const file = e?.target?.files[0];
    const maxSize = 5 * 1024 * 1024; // 2 MB in bytes
    if (file?.size > maxSize) {
      notifyError("Image size should not exceed 5 MB.");
    } else {
      setUpdateImage(file);
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setSelectedImage(e.target.result);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // const leftFields = fields.slice(0, 5);
  // const rightFields = fields.slice(5, 10);
  const leftFields = useMemo(
    () => (editMode ? fields.slice(0, 4) : fields.slice(2, 4)),
    []
  );
  const rightFields = useMemo(() => fields.slice(4, 9), []);

  const isSupportedFileType = (fileType) => {
    const supportedFormats = ["jpeg", "jpg", "png", "gif", "bmp"];
    return supportedFormats.some((format) => fileType.endsWith(format));
  };

  const isURL = (url) => {
    try {
      if (url.includes("https://")) {
        // new URL(url);
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  };

  return (
    <Grid container spacing={3} padding={3}>
      <Grid item xs={12}>
        <Card sx={{ boxShadow: 3, height: "88vh" }}>
          <CardContent sx={{ alignItems: "center" }}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSaveClick}
              enableReinitialize
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue, handleSubmit }) => (
                <Form>
                  <Grid
                    container
                    spacing={2}
                    padding={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between", // Add this line
                    }}
                  >
                    <>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ marginBottom: "0px" }}
                      >
                        <Breadcrumb
                          tabLabels={""}
                          value={""}
                          title="Admin Profile"
                        />
                      </Typography>

                      <span>
                        {!editMode ? (
                          <>
                            <Button
                              variant="outlined"
                              onClick={() =>
                                navigate("/settings", {
                                  state: { index: 2 },
                                })
                              }
                              style={{
                                marginRight: "15px",
                                textTransform: "none",
                                width: "163px",
                              }}
                              sx={{ marginRight: "10px" }}
                            >
                              Cancel
                            </Button>

                            <Button
                              type="submit"
                              variant="contained"
                              style={{
                                marginRight: "15px",
                                textTransform: "none",
                                width: "163px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setEditMode(true);
                              }}
                            >
                              Edit Admin Profile
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setEditMode(false);
                                // navigate("/  settings", {
                                //   state: { index: 2 },
                                // })
                              }}
                              style={{
                                marginRight: "15px",
                                textTransform: "none",
                                width: "163px",
                              }}
                              sx={{ marginRight: "10px" }}
                            >
                              Cancel
                            </Button>

                            <Button
                              type="submit"
                              variant="contained"
                              style={{
                                marginRight: "15px",
                                textTransform: "none",
                                width: "163px",
                              }}
                              onClick={() => handleSubmit}
                            >
                              Save
                              {loading && (
                                <CircularProgress
                                  size={18}
                                  style={{ color: "white" }}
                                />
                              )}
                            </Button>
                          </>
                        )}
                      </span>
                    </>
                  </Grid>
                  <CustomScrollbars height={"74vh"} marginBottom={1}>
                  <Grid item xs={12} padding={1}>
                    <Box style={avatarStyle} sx={{ marginBottom: "30px" }}>
                      <Box style={userProfileStyle}>
                        <div style={imageContainerStyle}>
                          <div style={{ position: "relative" }}>
                            {/* Backdrop overlay */}
                            {imageLoading && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  width: "100%",
                                  height: "100%",
                                  backgroundColor: "rgba(119, 118, 118, 0.7)", // Adjust the color and opacity as needed
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  opacity: 0.3,
                                  zIndex: 2,
                                }}
                              >
                                <CircularProgress
                                  size={50}
                                  sx={{
                                    color: "#ffffff",
                                    position: "absolute",
                                  }}
                                />
                              </div>
                            )}

                            {/* User profile photo */}
                            <Avatar
                              alt="User Profile Photo"
                              src={
                                (isURL(selectedImage)
                                  ? `${selectedImage}?${new Date().getTime()}`
                                  : selectedImage) || avatar
                              }
                              sx={{
                                width: 200,
                                height: 200,
                                borderRadius: "0%",
                                marginBottom: "0px",
                                objectFit: "contain",
                                padding: 1,
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 8px #00000029",
                                visibility: imageLoading ? "hidden" : "visible",
                              }}
                              onError={(e) => {
                                e.target.src = avatar;
                                e.target.onerror = null;
                                setImageLoading(false);
                              }}
                              onLoad={() => setImageLoading(false)}
                            />
                          </div>

                          {editMode && (
                            <label
                              htmlFor="image-upload"
                              style={cameraIconStyle}
                            >
                              <input
                                id="image-upload"
                                type="file"
                                onChange={(e) => {
                                  let file = null;
                                  file = e.target?.files[0];
                                  const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
                                  if (file) {
                                    if (!isSupportedFileType(file.type)) {
                                      notifyError(
                                        "Please select a valid image format, such as .jpeg, .jpg, .png, .gif or .bmp."
                                      );
                                    } else if (file.size > maxSize) {
                                      notifyError(
                                        "Image size should not exceed 5 MB."
                                      );
                                    } else {
                                      setUpdateImage(file);
                                      setFieldValue("profile_pic", file);
                                      const reader = new FileReader();
                                      reader.onload = (e) => {
                                        setSelectedImage(e.target.result);
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }
                                }}
                                style={{ display: "none" }}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <PhotoCameraIcon />
                              </IconButton>
                            </label>
                          )}
                        </div>
                      </Box>
                    </Box>
                      {editMode ? (
                        <>
                          <Grid container xs={12} spacing={2}>
                            {fields.map((field) => (
                              <Grid item xs={12} md={12} lg={6} sm={12}>
                                <React.Fragment key={field.key}>
                                  <Grid container xs={12} spacing={2}>
                                    <Grid item xs={editMode ? 12 : 4}>
                                      <Typography
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                          marginBottom: "5px",
                                          display: "flex",
                                        }}
                                      >
                                        {field.label}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={editMode ? 12 : 8}>
                                      {!editMode && (
                                        <>
                                          <Typography
                                            variant="body1"
                                            component="div"
                                            sx={{
                                              marginBottom: "10px",
                                              display: "flex",
                                            }}
                                          >
                                            : &nbsp;&nbsp;&nbsp;
                                            {values[field.key]}
                                          </Typography>
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                  {editMode && (
                                    <>
                                      {field.key === "phone_number" ? (
                                        <>
                                          <CustomPhoneInput
                                            name="phone_number"
                                            as={TextField}
                                            variant="outlined"
                                            country={"us"}
                                            enableSearch={true}
                                            value={values.phone_number}
                                            placeholder="Enter Phone Number"
                                            onChange={(phone) =>
                                              setFieldValue(
                                                "phone_number",
                                                phone
                                              )
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <Field
                                            as={TextField}
                                            fullWidth
                                            multiline={field.multiline || false}
                                            name={field.key}
                                            style={cardStyle}
                                            disabled={field.disabled}
                                            sx={{
                                              marginBottom: "10px",
                                              width: "100%",
                                            }}
                                          />
                                        </>
                                      )}
                                      <ErrorMessage
                                        name={field.key}
                                        component="div"
                                        style={{
                                          color: "red",
                                          paddingTop: "5px",
                                        }}
                                      />
                                    </>
                                  )}
                                </React.Fragment>
                              </Grid>
                            ))}
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid container xs={12} spacing={2}>
                            <Grid item xs={12} md={12} lg={6} sm={12}>
                              <Grid container xs={12} spacing={2}>
                                <Grid item xs={editMode ? 12 : 4}>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    sx={{
                                      marginBottom: "5px",
                                      display: "flex",
                                    }}
                                  >
                                    Full Name
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <>
                                    <Typography
                                      variant="body1"
                                      component="div"
                                      sx={{
                                        marginBottom: "10px",
                                        display: "flex",
                                      }}
                                    >
                                      : &nbsp;&nbsp;&nbsp;
                                      {`${values.first_name} ${values.last_name}`}
                                    </Typography>
                                  </>
                                </Grid>
                              </Grid>
                              {leftFields.map((field) => (
                                <React.Fragment key={field.key}>
                                  <Grid container xs={12} spacing={2}>
                                    <Grid item xs={editMode ? 12 : 4}>
                                      <Typography
                                        variant="subtitle1"
                                        component="div"
                                        sx={{
                                          marginBottom: "5px",
                                          display: "flex",
                                        }}
                                      >
                                        {field.label}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={editMode ? 12 : 8}>
                                      {!editMode && (
                                        <>
                                          <Typography
                                            variant="body1"
                                            component="div"
                                            sx={{
                                              marginBottom: "10px",
                                              display: "flex",
                                              wordBreak:
                                                field.label === "Email"
                                                  ? "break-word"
                                                  : "normal",
                                            }}
                                          >
                                            : &nbsp;&nbsp;&nbsp;
                                            {values[field.key]}
                                          </Typography>
                                        </>
                                      )}
                                    </Grid>
                                  </Grid>
                                  {editMode && (
                                    <>
                                      {field.key === "phone_number" ? (
                                        <>
                                          <CustomPhoneInput
                                            name="phone_number"
                                            as={TextField}
                                            variant="outlined"
                                            country={"us"}
                                            enableSearch={true}
                                            value={values.phone_number}
                                            placeholder="Enter Phone Number"
                                            onChange={(phone) =>
                                              setFieldValue(
                                                "phone_number",
                                                phone
                                              )
                                            }
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <Field
                                            as={TextField}
                                            fullWidth
                                            multiline={field.multiline || false}
                                            name={field.key}
                                            style={cardStyle}
                                            disabled={field.disabled}
                                            sx={{
                                              marginBottom: "10px",
                                              width: "90%",
                                            }}
                                          />
                                          <ErrorMessage
                                            name={field.key}
                                            component="div"
                                            style={{
                                              color: "red",
                                              paddingTop: "5px",
                                            }}
                                          />
                                        </>
                                      )}
                                    </>
                                  )}
                                </React.Fragment>
                              ))}
                            </Grid>
                            <Grid item xs={12} md={12} lg={6} sm={12}>
                              <Grid container xs={12} spacing={2}>
                                <Grid item xs={editMode ? 12 : 4}>
                                  <Typography
                                    variant="subtitle1"
                                    component="div"
                                    sx={{
                                      marginBottom: "5px",
                                      display: "flex",
                                    }}
                                  >
                                    Address
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <>
                                    <Typography
                                      variant="body1"
                                      component="div"
                                      sx={{
                                        marginBottom: "10px",
                                        display: "flex",
                                      }}
                                    >
                                      : &nbsp;&nbsp;&nbsp;
                                      {`${values.address_line_1},${values.address_line_2
                                        }${values.address_line_2 ? "," : ""} ${values.city
                                        }${values.city ? "," : ""} ${values.state
                                        }${values.state ? "," : ""}${values.country
                                        }${values.country ? "," : ""} ${values.zip_code
                                        }`}
                                    </Typography>
                                  </>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </>
                      )}
                  </Grid>
                  </CustomScrollbars>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default UserProfileDetails;
