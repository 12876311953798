import React, { useEffect } from "react";
import { Grid, TextField, Radio } from "@mui/material";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import Input from "../../../Patients/components/Patient/Input";

function BillingAddress({ handleClose }) {
  const { values, setFieldValue } = useFormikContext();


  useEffect(() => {
    if(values.billing_is_same === "No"){
      setFieldValue("bill_address_line_1", "");
      setFieldValue("bill_zip", "");
      setFieldValue("bill_address_line_2", "");
      setFieldValue("bill_country", "");
      setFieldValue("bill_state", "");
      setFieldValue("bill_city", "");
    }
  }, [values.billing_is_same])
  

  const billingInputs = [
    {
      label: "Address Line 1",
      fieldName: "bill_address_line_1",
      fieldType: TextField,
      placeholder: "Enter Address Line 1",
    },
    {
      label: "Address Line 2",
      fieldName: "bill_address_line_2",
      fieldType: TextField,
      placeholder: "Enter Address Line 2",
    },
    {
      label: "City",
      fieldName: "bill_city",
      fieldType: TextField,
      placeholder: "Enter City",
    },
    {
      label: "Country",
      fieldName: "bill_country",
      fieldType: TextField,
      placeholder: "Enter Country",
    },
    {
      label: "State",
      fieldName: "bill_state",
      fieldType: TextField,
      placeholder: "Enter State",
    },
    {
      label: "Zip Code",
      fieldName: "bill_zip",
      fieldType: TextField,
      placeholder: "Enter Zip Code",
    },
  ];

  return (
    <Grid
      container

    >
      {billingInputs?.slice(0, 15).map((inputData, index) => (
        <Grid item xs={12} lg={12} key={index} mt={1.1}>
          <Input data={inputData} />
          <ErrorMessage
            name={inputData.fieldName}
            component="div"
            style={{ color: "red", marginTop: "10px" }}
          />
        </Grid>
      ))}
    </Grid>
  );
}
export default BillingAddress;
