import { Card, Grid } from '@mui/material'
import React, { useState } from 'react'
import cardStyle from '../../../../../../../../theme/card-layout'
import VitalsDetails from './Components/VitalsDetails'
import DrawerComponent from '../../../../../../../../ui-component/Drawer'
import VitalsForm from './Components/VitalsForm'
function Vitals({openDrawer, setOpenDrawer, searchText }) {
  const [activeIndex, setActiveIndex] = useState(0) 
  const [refresh, setRefresh] = useState(false)
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
      <VitalsDetails activeIndex={activeIndex} setActiveIndex={setActiveIndex} refresh={refresh} setRefresh={setRefresh} searchText={searchText} />
      </Grid>

      <DrawerComponent
        open={openDrawer}
        handleOpen={()=>{setOpenDrawer(true)}}
        handleClose={()=>{setOpenDrawer(false)}}
        data={
          <VitalsForm handleClose={setOpenDrawer}
          setRefresh={setRefresh}
          />
        }
      />


    </Grid>
  )
}

export default Vitals