import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Grid,
  TextField,
  Radio,
  Autocomplete,
  Typography,
} from "@mui/material";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import { CarePlanApi } from "../../../../../../../../../service/CarePlan/careAPI";
import { PatientApi } from "../../../../../../../../../service/ProviderGroup/Patient";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { addCode, addCondition } from "../../../../../../../../../store/slices/queAns";

function CareCondition({ handleClose, handleSubmit, select }) {
  const { values, setFieldValue } = useFormikContext();
  console.log("options??", select);

  const data = [];
  console.log("options??", select);
  const [conditionName, setConditionName] = useState("");
  const [icdCodeOptions, setIcdCodeOptions] = useState("");
  const [options, setOptions] = useState([]);
  const [icdCode, setIcdCode] = useState();
  const [condName, setCondName] = useState("");
  const [conditionArr, setConditionArr] = useState([]);
  const dispatch = useDispatch();

  const { id } = useParams();


  // const option=[
  //   {
  //     label: "Diabetes",
  //     name: "Diabetes"
  //   },
  //   {
  //     label: "demo",
  //     name: "demo"
  //   }
  // ]

  useEffect(() => {
    getAllConditionsAndICDCodes();
  }, [conditionName]);

  // useEffect(() => {
  //   if (conditionName) {
  //     const selectedCondition = data?.find(
  //       (item) => item?.conditionName?.name === conditionName?.name
  //     );
  //     const filteredOptions =
  //       selectedCondition?.icd_code !== undefined
  //         ? conditionName?.options?.filter(
  //             (option) => option?.name !== selectedCondition?.icd_code
  //           )
  //         : conditionName?.options;
  //     setIcdCodeOptions(filteredOptions || [{}]);
  //   }
  // }, [conditionName, data]);

  const getAllConditionsAndICDCodes = () => {
    const query = {
      all: true,
      order_by: "name"  
    }
    let ConditionsData = [];
    PatientApi.GetConditions(id, query)
      .then((response) => {
        setConditionArr(response.data);
        console.log("response", response)
        response?.data?.forEach((element) => {
          ConditionsData.push({
            name: element.name,
            label: `${element?.name}`,
            // options: element?.icd_codes?.map((ele) => {
            //   return { name: ele.code, label: ele?.code, title: ele?.title };
            // }),
          });
        });
        setOptions(ConditionsData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  return (
    <Grid container spacing={2} padding={3}>
<Grid item xs={6}>
  <Typography variant="subtitle1" marginBottom={1}>
    Condition Name
  </Typography>
  <Field variant="outlined" fullWidth name={"conditionName"}>
    {({ field }) => (
      <Autocomplete
        {...field}
        disablePortal
        id="combo-box-demo"
        options={options || []}
        sx={cardStyle}
        renderInput={(params) => (
          <TextField {...params} {...field} placeholder="Select" />
        )}
        onChange={(e, newValue) => {
          setConditionName(newValue);
          setFieldValue("icd_code", ""); // Reset ICD code when condition changes
          setFieldValue("conditionName", newValue);
          dispatch(addCondition(newValue));
          setFieldValue("condition", "");
        }}
      />
    )}
  </Field>
  {!conditionName && <ErrorMessage
    name={"conditionName"}
    component="div"
    style={{
      color: "red",
      marginTop: "10px",
      marginBottom: "10px",
    }}
  />}
</Grid>
<Grid item xs={6}>
  <Typography variant="subtitle1" marginBottom={1}>
    ICD Code
  </Typography>
  <Field variant="outlined" fullWidth name="icd_code">
    {({ field }) => (
      <Autocomplete
        {...field}
        disablePortal
        disabled
        id="combo-box-demo"
        options={icdCodeOptions || []}
        value={conditionArr?.find(item => item.name === conditionName?.name)?.icd_code || ""}
        sx={cardStyle}
        renderInput={(params) => (
          <TextField {...params} placeholder="ICD Code" />
        )}
        onChange={(e, newValue) => {
          setFieldValue("icd_code", newValue);
          dispatch(addCode(newValue));
          setFieldValue("condition", "");
        }}
      />
    )}
  </Field>
  <ErrorMessage
    name={"icd_code"}
    component="div"
    style={{
      color: "red",
      marginTop: "10px",
      marginBottom: "10px",
    }}
  />
</Grid>
      <Grid item xs={12}>
      <Field variant="outlined" fullWidth name={"condition"}>
          {({ field }) => (
        <Autocomplete
          // disablePortal
          {...field}
          id="combo-box-demo"
          options={select || []}
          sx={{ width: 300 }}
          disabled ={conditionName ? false : true}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Care Plan" />
          )}
          onChange={(e, newValue) => {
            setFieldValue("condition", newValue ? newValue?.label : "");
          }}
        />
        )}
        </Field>

        {conditionName ? <ErrorMessage
          name="condition"
          component="div"
          style={{
            color: "red",
            marginTop: "10px",
            // marginBottom: "10px",
          }}
        /> : null }
      </Grid>
    </Grid>
  );
}
export default CareCondition;
