import React, { useState, useContext, createContext, useEffect } from 'react';
import { Button, Grid, Select, TextField, CircularProgress, CardActions } from '@mui/material';
import DataTable from 'react-data-table-component';
import Input from '../../Patients/components/Patient/Input';
import * as Yup from "yup";
import { useParams } from 'react-router-dom';
import { useNotification } from '../../../../hooks/use-notification';
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import { CustomScrollbars } from '../../../../ui-component/ScrollBar';
import { checkTabPermission, customDataTableStyles } from '../../../../ccm-constant';
import { IconArrowsSort } from "@tabler/icons-react";
import CustomPagination from '../../../../ui-component/Pagination';
import { SettingsApi } from '../../../../service/Settings/Index';
import DrawerComponent from "../../../../ui-component/Drawer";
import AddProvider from '../../ProviderGroup/feature/AddProvider/addProviders';

const EhrSettingsDataContext = createContext();

export const EhrSettings = () => {
  const [departmentData, setDepartmentData] = useState([]);
  const [fetchProviderData, setFetchProviderData] = useState(false);
  const [loading, setLoading] = useState(false);
  console.log("fetchProviderData", fetchProviderData);
  const { notifySuccess, notifyError } = useNotification();
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId = userType === "provider_group" ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid :
    JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
      ?.uuid;
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;

  const [fields, setFields] = useState([
    {
      label: "EHR Provider",
      fieldName: "ehr_provider",
      fieldType: Select,
      placeholder: "Select EHR",
      options: []
    },
    // {
    //   label: "Practice ID",
    //   fieldName: "practice_id",
    //   fieldType: TextField,
    //   placeholder: "Enter Practice ID",
    // },
    {
      label: "Provider",
      fieldName: "provider_id",
      fieldType: TextField,
      placeholder: "Enter Provider ID",
    },
  ]);

  useEffect(() => {
    getEHRList();
    console.log("fetch ehr")
  }, [])

  const getEHRList = () => {
    let EHRData = [];
    let mapData = [];
    SettingsApi.getEHRList(ProviderId)
      .then((response) => {
        console.log("EHRList", response);
        response?.data?.map((ehr) => {
          EHRData.push({
            name: ehr?.name,
            label: ehr?.name,
          });
        })
        if (EHRData?.length)
          fields?.map((ele) => {
            if (ele?.fieldName === "ehr_provider") {
              mapData?.push({
                label: "EHR Provider",
                fieldName: "ehr_provider",
                fieldType: Select,
                placeholder: "Select EHR",
                options: EHRData
              });
            } else {
              mapData?.push(ele);
            }
          });
        if (mapData?.length >= 1) setFields(mapData);

      })
      .catch((error) => {
        console.error("Error fetching EHR list data:", error);
        setLoading(false);
      });
  };

  const validationSchema = Yup.object().shape({
    ehr_provider: Yup.string().required("EHR Provider is required"),
    // practice_id: Yup.string().required("Practice ID is required"),
    provider_id: Yup.string().required("Provider ID is required"),
  });

  return (
    <>
      <EhrSettingsDataContext.Provider value={{ fetchProviderData, setFetchProviderData }}>
        <Formik
          initialValues={{
            ehr_provider: "",
            practice_id: "",
            provider_id: ""
          }}
          onSubmit={(data, { resetForm }) => {
            console.log("Department Data", data);
            const query = {
              limit: 10,
              // offset:
              //   currentPage === 0
              //     ? currentPage
              //     : searchText
              //       ? 0
              //       : (currentPage - 1) * 10,
              // order_by: sortDirection,
              // alert_severity: buttonValue,
              // sort_by: sortedColumn,
            };
            setLoading(true);
            SettingsApi.fetchProviderData(ProviderId, data?.provider_id)
              .then((response) => {
                console.log("response department", response)
                setDepartmentData(response?.data);
                setFetchProviderData(true);
                resetForm();
                notifySuccess(response?.message);
                setLoading(false);
              })
              .catch((error) => {
                console.error(
                  "Error fetching department data",
                  error
                );
                notifyError(error?.response?.data?.error);
                setLoading(false);
              });
          }
          }
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit, resetForm }) => (
            <Form>
              <Grid container padding={3} paddingBottom={2}>
                <Grid container spacing={4}>
                  {fields?.map((field) => (
                    <Grid item xs={6} sm={6} lg={4} xl={4} md={6}>
                      <Input data={field} />
                      <ErrorMessage
                        name={field.fieldName}
                        component="div"
                        style={{
                          color: "red",
                          fontSize: "15px",
                          marginBottom: 1,
                          marginTop: "5px",
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid container display="flex" alignItems="center" justifyContent="center" mt={4}>
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      width: 160,
                    }}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Fetch Provider &nbsp;{loading && (
                      <CircularProgress size={18} style={{ color: "white" }} />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <EhrSettingsDataTable></EhrSettingsDataTable>
      </EhrSettingsDataContext.Provider>
    </>
  )
}

export const EhrSettingsDataTable = () => {
  const { fetchProviderData, setFetchProviderData } = useContext(EhrSettingsDataContext);
  console.log("fetchProviderData", fetchProviderData);
  const { id } = useParams();
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId = userType === "provider_group" ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid :
    JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group?.uuid;
  const [openForm, setOpenForm] = useState(false);
  const [providerList, setProviderList] = useState();
  const [addProviderData, setAddProviderData] = useState(null);
  console.log("ProviderDatata", addProviderData)
  const [addProviderFromEhrSetting, setAddProviderFromEhrSetting] = useState(false);
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("-created_at");
  const [openCnf, setOpenCnf] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  // const [refreshList, setRefreshList] = useState(false);
  console.log("hgyhuj", addProviderFromEhrSetting);

  const DepartMentColoumns = [
    // {
    //   name: "No",
    //   selector: (row, id) => id + 1 + (currentPage ? (currentPage - 1) * 10 : ""),
    //   width: "7%",
    // },
    {
      name: "Provider Type",
      selector: (row) => row?.provider_type,
      width: "15%",
      sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => row?.first_name,
      sortable: true,
      width: "15%",
    },
    {
      name: "Last Name",
      selector: (row) => row?.last_name,
      sortable: true,
      width: "15%",
    },
    {
      name: "Scheduling Name",
      selector: (row) => row?.created_by,
      sortabwle: true,
      width: "18%",
    },
    {
      name: "Speciality",
      selector: (row) => row?.speciality,
      // sortable: true,
      width: "20%",
    },
    // {
    //   name: "Department",
    //   selector: (row) => row?.speciality,
    //   // sortable: true,
    //   // width: "12%",
    // },
    {
      name: "",
      // width: "14%",
      sortable: true,
      cell: (row) => (
        <Button
          variant="outlined"
          style={{
            textTransform: "none",
            // marginLeft: 15,
            width: 120
          }}
          onClick={() => {
            setAddProviderData(row);
            setOpenForm(true);
            setAddProviderFromEhrSetting(true);
          }}
          disabled={!checkTabPermission("add_provider")}
        >
          {`${row?.status ? "Created" : "Create"}`}
        </Button>
      ),
    }
  ];

  const getProviderList = () => {
    const query = {
      limit: 10,
      offset:
        currentPage === 0
          ? currentPage
          : (currentPage - 1) * 10,
      order_by: sortDirection,
      // alert_severity: buttonValue,
      // sort_by: sortedColumn,
    };
    setLoading(true);
    SettingsApi.getProviderData(ProviderId, query)
      .then((response) => {
        console.log("ProviderList", response)
        setProviderList(response.data);
        setPageCount(response.count);
        setFetchProviderData(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching Provider data:", error);
        setLoading(false);
      });
  };

  // useEffect(() => {
  //   getProviderList();
  // }, [])

  // useEffect(() => {
  //   getProviderList();
  // }, [currentPage, sortDirection])

  useEffect(() => {
    getProviderList();
  }, [currentPage, sortDirection])

  useEffect(() => {
    if (fetchProviderData) {
      getProviderList();
    console.log("fetch providersssss3")

    }
  },[fetchProviderData])
  // useEffect(() => {
  //   if (addProviderFromEhrSetting)
  //     getProviderList();
  // }, [addProviderFromEhrSetting])

  const handleCloseForm = () => {
    setOpenForm(false);
    setAddProviderData(null);
    getProviderList();
    console.log("fetch providersssss")
    setAddProviderFromEhrSetting(false);
  };

  const handleSort = (column, sortDirection) => {
    switch (column.name) {
      case "Provider Type":
        setSortDirection(sortDirection == "desc" ? '-provider_type' : "provider_type");
        break;
      case "First Name":
        setSortDirection(sortDirection == "desc" ? '-first_name' : "first_name");
        break;
      case "Last Name":
        setSortDirection(sortDirection == "desc" ? '-last_name' : "last_name");
        break;
      case "Scheduling Name":
        setSortDirection(sortDirection == "desc" ? '-created_by' : "created_by");
        break;
      case "Log Time In Min":
        setSortDirection(sortDirection == "desc" ? '-speciality' : "speciality");
        break;
      default:
        setSortDirection("-id");
    }
  };

  return (<>
    <CustomScrollbars height="47vh">
      <Grid container padding={2} paddingTop={0}>
        <Grid item xs={12} sx={{ height: "42vh", position: "relative" }}>
          <Grid
            container
            xs={12}
            padding={1}
            sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
          >
            {/* <CustomScrollbars height="25vh"> */}
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!loading ? (
                <DataTable
                  columns={DepartMentColoumns}
                  data={providerList}
                  sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                  onSort={handleSort}
                  // onRowClicked={handleRowClick}
                  highlightOnHover
                  pointerOnHover
                  customStyles={customDataTableStyles}
                />
              )
                : (
                  <CircularProgress
                    color="primary"
                    sx={{ marginTop: "15%" }}
                  />
                )}
            </Grid>
            {/* </CustomScrollbars> */}
            <DrawerComponent
              open={openForm}
              handleClose={handleCloseForm}
              data={<AddProvider handleClose={handleCloseForm} addProviderData={addProviderData} addProviderFromEhrSetting={addProviderFromEhrSetting}
                setFetchProviderData={setFetchProviderData}
              />}
            />
            {/* <ConfirmBox
            handleClose={() => setOpenCnf(false)}
            open={openCnf}
            handleSubmit={() => handleDeleteSubmit()}
          /> */}
          </Grid>
        </Grid>
      </Grid>
    </CustomScrollbars>
    {/* <CardActions sx={{ position: "relative", bottom: 0, width: "100%" }}> */}
    {pageCount !== 0 && !loading && (
      <Grid item xs={12} marginTop={3} marginLeft={2}>
        <CustomPagination
          count={pageCount}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          data={providerList}
        />
      </Grid>
    )}
    {/* </CardActions> */}
  </>)
}