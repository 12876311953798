import React, { useEffect } from "react";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { Stack, Typography, Grid } from "@mui/material";

export default function CustomPagination({
  currentPage,
  setCurrentPage,
  count,
  data,
}) {
  console.log("data page", data);
  useEffect(() => {
    if (data?.message === "No data available." && data?.previous) {
      console.log("-1");
      setCurrentPage(currentPage - 1);
    }
  }, [data]);
  const itemsPerPage = 10;
  const total = Math.ceil(count / itemsPerPage);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const Next = () => {
    return <Typography>Next</Typography>;
  };

  const Previous = () => {
    return <Typography>Previous</Typography>;
  };

  const startRecordIndex =
    (currentPage === 0 ? 0 : currentPage - 1) * itemsPerPage + 1;
  const endRecordIndex = Math.min(
    currentPage === 0 ? 1 * itemsPerPage : currentPage * itemsPerPage,
    count
  );
  const totalRecords = count;
  return (
    <Grid
      mb={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      // padding={1.25}
      mr={4}
      ml={2}
    >
      <Typography variant="subtitle2" textAlign={"start"}>
        {`Showing ${startRecordIndex} to ${endRecordIndex} of ${totalRecords} entries`}
      </Typography>
      <Stack spacing={2}>
        <Pagination
          count={total} 
          page={currentPage <= 1 ? 1 : currentPage}
          variant="outlined"
          color="primary"
          shape="rounded"
          renderItem={(item) => (
            <PaginationItem
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#022C6B !important",
                  color: "#FFFF",
                },
              }}
              {...item}
              slots={{ previous: Previous, next: Next }}
              onClick={(e) => handlePageChange(e, item.page)}
            >
              {item.page === currentPage ? (
                <Typography style={{ fontWeight: "bold" }}>
                  {item.page}
                </Typography>
              ) : (
                item.page
              )}
            </PaginationItem>
          )}
          siblingCount={1}
          boundaryCount={1}
        />
      </Stack>
    </Grid>
  );
}
