import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CustomScrollbars } from "../../../../ui-component/ScrollBar";
import { formatDate } from "../../../../ccm-constant";

const BillingReport = ({ handleClose, selectedRows }) => {
  console.log("first selectedRows", selectedRows);
  const [data, setData] = useState([
    {
      id: 1,
      col1: {
        label: "Demograpics",
        name: "Qwerty Lions 1",
        birth: "Feb 1, 1991",
        pos: "none",
      },
      col2: {
        label: "Condition",
        datas: [
          {
            label: "Asthma qwasx q wdsax wqd asx wqasd wqsaxwq dq",
            value: "j45.30.33",
          },
          {
            label: "Diabetes",
            value: "j95.30.33",
          },
        ],
      },
      col3: {
        label: "Providers",
        datas: [
          {
            label: "CM",
            value: "qwertyu saxz",
          },
          {
            label: "PHY",
            value: "uygfc wsdc",
          },
        ],
      },
      col4: {
        label: "Service Details",
        datas: [
          {
            label: "Service Date",
            value: "Sep 23, 2023",
          },
          {
            // label: "Diabetes",
            value: "99490- asdf e3wsxczw dw",
          },
        ],
      },
    },
  ]);
  const [providerGroup, setProviderGroup] = useState(selectedRows[0]?.patient?.provider_details?.provider_group)

  useEffect(() => {

  }, [selectedRows]);

  return (
    <Grid container width={"70vw"} padding={1}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{
            textTransform: "none",
            width: "auto",
          }}
        >
          Close
        </Button>
      </Grid>
      <Grid container margin={3} marginLeft={5} sx={{ padding: 0, margin: 0 }}>
        <Grid item sx={{ padding: 3, margin: 0 }}>
          <Stack direction="column" spacing={1}>
          <Typography variant="subtitle1">Provider Group Name : </Typography>
        
            <Typography variant="body1">{providerGroup?.display_name}</Typography>
            <Typography variant="subtitle1">Provider Group Email : </Typography>
            <Typography variant="body1">{providerGroup?.email}</Typography>
            <Typography variant="subtitle1">Provider Group Phone Number : </Typography>
            <Typography variant="body1">{providerGroup?.phone_number}</Typography>
          </Stack>
        </Grid>
        <Grid item marginLeft={1} sx={{ padding: 3, margin: 0 }}>
          {/* <Stack direction="column" spacing={1}> */}
            {/* <Typography variant="subtitle1">{providerGroup?.address?.address_line_1}, {providerGroup?.address?.address_line_2}, </Typography>
            <Typography variant="subtitle1">{providerGroup?.address?.country},</Typography>
            <Typography variant="subtitle1">{providerGroup?.address?.state }, {providerGroup?.address?.zip_code }</Typography> */}

            <Typography variant="subtitle1">{providerGroup?.address?.billing_is_same ? "Address : " : "Primary Address : "}</Typography>
          <Typography variant="body1" paddingLeft={2}>{providerGroup?.address?.address_line_1 } , {providerGroup?.address?.address_line_2 } {providerGroup?.address?.address_line_2 ? " , " : " " } {providerGroup?.address?.city }{providerGroup?.address?.city ? " , ": " "}{providerGroup?.address?.country } , {providerGroup?.address?.state } , {providerGroup?.address?.zip_code }</Typography> 
          
          { !providerGroup?.address?.billing_is_same ? <> <Typography variant="subtitle1">{"Billing Address :"}</Typography>
          <Typography variant="body1" paddingLeft={2}>{providerGroup?.address?.billing_address?.address_line_1 } , {providerGroup?.address?.billing_address?.address_line_2 } {providerGroup?.address?.billing_address?.address_line_2 ? " ," : "" } {providerGroup?.address?.billing_address?.city } {providerGroup?.address?.billing_address?.city ? " ,": ""}{providerGroup?.address?.billing_address?.country } , {providerGroup?.address?.billing_address?.state } , {providerGroup?.address?.billing_address?.zip_code }</Typography> 
          </>: ""}
          {/* </Stack> */}
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{ whiteSpace: "nowrap" }}
        marginTop={2}
        marginBottom={1}
      >
        <CustomScrollbars height="72vh">
          <Grid item xs={12} paddingRight={1}>
            <Grid container paddingRight={2}>
              {/* <CustomScrollbars height="75vh"> */}
              <Grid item xs={12} padding={0}>
                {[
                  "Demographics",
                  "Conditions",
                  "Providers",
                  "Service Details",
                ].map((element, id) => (
                  <Grid
                    item
                    minWidth={"330px"}
                    maxWidth={"330px"}
                    key={`header${id}`}
                    padding={1}
                    sx={{
                      border: "1px solid #cbc6c6",
                      height: "20px",
                      display: "table-cell", // Display the items in a line
                      whiteSpace: "normal", // Reset white space to normal
                      margin: 0,
                      borderRadius:
                        id === 0
                          ? "10px 0 0 0"
                          : id === 3
                          ? "0 10px 0 0"
                          : null,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      key={id}
                      textAlign={"center"}
                    >
                      {element}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
              {/* </CustomScrollbars> */}
            </Grid>

            <Grid container paddingRight={2} paddingBottom={2}>
              {selectedRows?.map((row, id) => (
                <Box key={id}>
                  {[1, 2, 3, 4].map((element, colId) => (
                    <Grid
                      minWidth={"330px"}
                      maxWidth={"330px"}
                      key={element}
                      sx={
                        selectedRows?.length === 1
                          ? {
                              border: "1px solid #cbc6c6",
                              height: "150px",
                              display: "table-cell", // Display the items in a line
                              whiteSpace: "normal", // Reset white space to normal
                              padding: 0,
                              margin: 0,
                              borderRadius:
                                id === 0 && colId === 0
                                  ? "0px 0 0 0px"
                                  : id === 0 && colId === 3
                                  ? "0 0px 0px 0"
                                  : "",
                            }
                          : {
                              border: "1px solid #cbc6c6",
                              height: "150px",
                              display: "table-cell", // Display the items in a line
                              whiteSpace: "normal", // Reset white space to normal
                              padding: 0,
                              margin: 0,
                              borderRadius:
                                id === 0 && colId === 0
                                  ? "0 0 0 0"
                                  : id === 0 && colId === 3
                                  ? "0 0px 0 0"
                                  : id === selectedRows?.length - 1 &&
                                    colId === 0
                                  ? "0 0 0 10px"
                                  : id === selectedRows?.length - 1 &&
                                    colId === 3
                                  ? "0 0 10px 0"
                                  : "",
                            }
                      }
                    >
                      <Grid item xs={12} padding={2}  paddingY={0}>
                        {element === 1 ? (
                          <Grid item paddingTop={0}>
                            <Typography
                              variant="body1"
                              color={"#002c6b"}
                              fontWeight={500}
                              marginBottom={1}
                              fontSize={18}
                            >
                              {row?.patient?.first_name}{" "}
                              {row?.patient?.last_name}
                            </Typography>
                            <Stack direction={"row"}>
                              <Typography
                                variant="body1"
                                color={"#002c6b"}
                                paddingRight={2}
                              >
                                Birth &nbsp;:{" "}
                              </Typography>
                              <Typography variant="body1">
                                {row?.patient?.date_of_birth ? formatDate(row?.patient?.date_of_birth) : ""}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"}>
                              <Typography
                                variant="body1"
                                color={"#002c6b"}
                                paddingRight={2}
                                minWidth={"50px"}
                              >
                                MRN &nbsp;:
                              </Typography>
                              <Typography variant="body1">{row?.patient?.mrn ? row?.patient?.mrn : ""}</Typography>
                            </Stack>
                          </Grid>
                        ) : element === 2 ? (
                          <Grid item paddingTop={5}>
                            {row?.patient?.condition?.map((element, id) => (
                              <Stack
                                direction={"row"}
                                key={id}
                                // marginBottom={1}
                              >
                                {element?.name ? (
                                  <>
                                    <Typography
                                      variant="body1"
                                      color={"#002c6b"}
                                      paddingRight={2}
                                      fontWeight={500}
                                      minWidth={"auto"}
                                    >
                                      {element?.name} &nbsp;&nbsp;:&nbsp;&nbsp;
                                    </Typography>
                                    <Typography variant="body1">
                                      {element?.icd_code
                                        ? element?.icd_code
                                        : null}
                                    </Typography>
                                  </>
                                ) : null}
                              </Stack>
                            ))}
                          </Grid>
                        ) : element === 3 ? (
                          <Grid item paddingTop={5}>
                            {/* {row[`col${element}`]?.datas?.map((element, id) => ( */}
                            {/* {row?.patient?.provider_details
                                    ?.role === "Physician"  ?
                                    <Stack direction={"row"} key={id}>
                              <Typography variant="body1" color={"#002c6b"}>
                                PHY
                                &nbsp;&nbsp;:&nbsp;&nbsp;
                              </Typography>
                              <Typography variant="body1">
                                {row?.patient?.provider_details?.first_name}{" "}
                                {row?.patient?.provider_details?.last_name}
                              </Typography>
                            </Stack>:null} */}
                            {
                              row?.careteam?.map(element => {
                                return(
                                <Stack direction={"row"} key={id}>
                                <Typography variant="body1" color={"#002c6b"}>
                                  {element?.role === "Care Team" ? "CM" :element?.role === "Physician" ? "PHY": "NC"}
                                  &nbsp;&nbsp;:&nbsp;&nbsp;
                                </Typography>
                                <Typography variant="body1">
                                  {element?.first_name}{" "}
                                  {element?.last_name}
                                </Typography>
                              </Stack>)
                              })
                            }
                            {/* ))} */}
                          </Grid>
                        ) : (
                          <Grid item paddingTop={5} >
                            {row?.billable_object?.map((element) => {
                              return (
                                <>{element?.value.length ? <Grid item marginBottom={2}>
                                  <Stack direction={"row"} key={"bill_obbj"}  >
                                    <Typography
                                      variant="body1"
                                      color={"#002c6b"}
                                    >
                                      Service Date :
                                    </Typography>

                                    <Typography
                                      variant="body1"
                                      paddingLeft={element?.label ? 1 : 0}
                                    >
                                      {formatDate(
                                         element?.value[
                                          element?.value?.length - 1
                                        ]?.created_at
                                      )}
                                    </Typography>
                                  </Stack> 
                                  <Typography
                                    variant="body1"
                                    paddingLeft={element?.label ? 1 : 0}
                                  >
                                    {element?.description || element?.label} 
                                  {element?.value?.length >=2 ? <span style={{color:"#002c6b", fontWeight:600, fontSize:"12px", paddingLeft:2}} >  X {element?.value?.length}</span>: "" }

                                  </Typography>
                                  
                                  </Grid>
                                  : null}
                                </>
                              );
                            })}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              ))}
            </Grid>
          </Grid>
        </CustomScrollbars>
      </Grid>
    </Grid>
  );
};

export default BillingReport;
