import {
  Card,
  Grid,
  Typography,
  CardContent,
  Divider,
  CircularProgress,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BillingCount from "../../../../ui-component/BillingCount";
import cardStyle from "../../../../theme/card-layout";
import { Billing } from "../../../../service/Billing";
import { None } from "amazon-chime-sdk-js";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { deepOrange } from "@mui/material/colors";
import Revenue from "./minimumExpectedRevenue";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

const DollarIcon = styled(AttachMoneyIcon)({
  position: "absolute",
  top: "40%",
  left: 40,
  fontSize: "1200%",
  opacity: 0.2,
  transform: "translate(-50%, -50%)", // Center the icon within its container
});

const Adherence = ({ AdherenceCount, selectedMonth, selectedYear }) => {
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderGroupId =
    userType === "provider_group"
      ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
      : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
          ?.uuid;
  const [adherenceCount, setAdherenceCount] = useState();
  const [revenue, setRevenue] = useState();
  const [loading, setLoading] = useState();

  console.log("clgggg", adherenceCount?.count["99437"]);

  useEffect(() => {
    getAdherenceCount();
  }, [selectedMonth, selectedYear]);

  const getAdherenceCount = () => {
    setLoading(true);
    Billing.getBillingData(
      ProviderGroupId,
      new Date(selectedMonth).getMonth() + 1,
      new Date(selectedYear).getFullYear()
    )
      .then((response) => {
        console.log("response", response);
        setAdherenceCount(response);
        setRevenue(response?.revenue);
        // setPageCount(response.count);
        // setAllData(response);
        setLoading(false);
        // setRefreshList(false);
      })
      .catch((error) => {
        console.error("Error fetching Time Log data:", error);
        setLoading(true);
      });
  };

  const nonComplex = [
    {
      id: 1,
      name: "99490",
      imageSrc: "/alice.jpg",
      value: adherenceCount?.count["99490"] || 0,
    },
    {
      id: 2,
      name: "99439",
      imageSrc: "/bob.jpg",
      value: adherenceCount?.count["99439"] || 0,
    },
    {
      id: 3,
      name: "99491",
      imageSrc: "/charlie.jpg",
      value: adherenceCount?.count["99491"] || 0,
    },
    {
      id: 3,
      name: "99437",
      imageSrc: "/charlie.jpg",
      value: adherenceCount?.count["99437"] || 0,
    },
  ];

  const complex = [
    {
      id: 1,
      name: "99487",
      imageSrc: "/alice.jpg",
      value: adherenceCount?.count["99487"] || 0,
    },
    {
      id: 2,
      name: "99489",
      imageSrc: "/bob.jpg",
      value: adherenceCount?.count["99489"] || 0,
    },
  ];

  return (
    // <Card
    //   sx={{
    //     ...cardStyle,
    //     minHeight: "13vh",
    //   }}
    //   padding={2}
    // >
    //   <Grid item xs={12} display={"flex"} justifyContent={"center"}>
    //     <Card sx={{marginTop:2, ...cardStyle}}>
    //       <CardContent>
    //         <Grid item xs={12}>
    //         <Typography variant="h6" >
    //           Minimum Expected Revenue
    //         </Typography>
    //         </Grid>
    //         <Grid item xs={12} sx={{display:"flex", alignItems:"center", justifyContent:"center"}}>
    //         <Typography variant="h6" >
    //           265184
    //         </Typography>
    //         </Grid>
    //       </CardContent>
    //     </Card>
    //   </Grid>
    //   <Grid item xs={12} display={"flex"} justifyContent={"center"}>
    //     <Typography variant="h6" paddingTop={1}>
    //       Adherence
    //     </Typography>
    //   </Grid>
    //   <Grid container paddingBottom={2} paddingTop={{xl:2, xs:0}}>
    //     <Grid
    //       container
    //       xs={12}
    //       lg={7}
    //       xl={7}
    //       sx={{
    //         display: "flex",
    //         justifyContent: {xl:"flex-end", lg:"center", xs:"center"},
    //         borderRight: {
    //           lg: "3px dashed #c4bebe", // Add right border on md screens
    //         },
    //         borderBottom:{
    //           xs:"3px dashed #c4bebe",

    //           lg:"0px"
    //         },
    //         paddingBottom:{
    //           xs:2,
    //         },
    //         paddingRight: {
    //           lg: 2,
    //         },
    //       }}
    //     >
    //       <Grid item key={"Non Complex"}  lg={12} xs={12} xl={3} sx={{display:"flex",justifyContent: {xl:"flex-start", lg:"center", xs:"center"}}}>
    //         <Typography variant="body1" paddingTop={3} margin={2}  alignItems={"center"}>
    //           Non Complex
    //         </Typography>
    //       </Grid>
    //       {["99490", "99439","99491", "99437"].map((element, index) => (
    //         <Grid
    //           item
    //           key={index}
    //           paddingX={2}
    //           display={"flex"}
    //           justifyContent={"center"}
    //         >
    //           <BillingCount element={element} count ={AdherenceCount ? AdherenceCount[element] : 0}/>
    //         </Grid>
    //       ))}
    //     </Grid>
    //     <Grid container xs={12} lg={5} xl={5} sx={{
    //         display: "flex",
    //         justifyContent: {lg: "center",xs:"center", xl:"flex-start"},
    //         // paddingRight: {
    //         //   lg: 2,
    //         // },
    //         marginTop:0,
    //         paddingRight: {
    //           lg: 2,
    //         }
    //       }}>
    //       <Grid item sx={{display:"flex",justifyContent: {xl:"flex-start", lg:"center", xs:"center"}}} md={12} lg={12} xl={3} xs={12} marginLeft={{xl:3}}>
    //         <Typography
    //           variant="body1"
    //           paddingTop={3}
    //           margin={2}
    //           // sx={{
    //           //   paddingLeft: {
    //           //     xl: 2,
    //           //   },
    //             // width:{sm:"130px",  lg:"auto"}
    //           // }}
    //           // minWidth="130px"
    //           alignItems={"center"}
    //         >
    //           Complex
    //         </Typography>
    //       </Grid>
    //       {[ "99487", "99489"]?.map((element, index) => (
    //         <Grid
    //           item
    //           paddingX={2}
    //           key={index}
    //           display={"flex"}
    //           justifyContent={"center"}
    //         >
    //           <BillingCount element={element} count ={AdherenceCount ? AdherenceCount[element] : 0}/>
    //         </Grid>
    //       ))}
    //     </Grid>
    //   </Grid>
    // </Card>
    <Grid container spacing={2}>
      {loading ? (
        <CircularProgress
          color="primary"
          sx={{ marginTop: "5%", marginLeft: "49%" }}
        />
      ) : (
        <>
          <Grid item lg={4} md={12}>
            {/* <Card sx={{ ...cardStyle, background: "#022C6B", height:"20vh" }}>
        {loading ? <CircularProgress color="secondary" sx={{ marginTop: "17%", marginLeft: "43%"}} />:
          <CardContent>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "5vh"
              }}
            >
              <Typography variant="h6" color="secondary">Minimum Expected Revenue</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" color="secondary">$ 
              {revenue?.toFixed(2)}</Typography>
            </Grid>
          </CardContent>}
        </Card> */}
            <Revenue revenue={revenue} loading={loading} />
          </Grid>
          <Grid item lg={8} md={12}>
            <Card sx={{ ...cardStyle, height: "20vh" }}>
              <CardContent>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      Adherence
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: 1,
                    }}
                  >
                    <Stack direction="row" spacing={2}>
                      <Stack alignItems="center">
                        <Typography
                          variant="caption"
                          sx={{ fontSize: 16, marginTop: "3vh" }}
                        >
                          Non Complex
                        </Typography>
                      </Stack>
                      {nonComplex.map((user) => (
                        <Stack
                          key={user.id}
                          alignItems="center"
                          style={{ marginRight: "10px" }}
                        >
                          <Avatar
                            sx={{ bgcolor: "#022C6B", width: 60, height: 60 }}
                          >
                            {user.value}
                          </Avatar>
                          <Typography variant="h6">{user.name}</Typography>
                        </Stack>
                      ))}
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ marginY: 0.5, width: "1px", color: "#8F8F8F" }}
                      />
                      <Stack alignItems="center">
                        <Typography
                          variant="caption"
                          sx={{ fontSize: 16, marginTop: "3vh" }}
                        >
                          Complex
                        </Typography>
                      </Stack>
                      {complex.map((user) => (
                        <Stack
                          key={user.id}
                          alignItems="center"
                          style={{ marginRight: "10px" }}
                        >
                          <Avatar
                            sx={{ bgcolor: "#022C6B", width: 60, height: 60 }}
                          >
                            {user.value}
                          </Avatar>
                          <Typography variant="h6">{user.name}</Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default Adherence;
