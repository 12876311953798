import React, { useEffect, useMemo, useState } from "react";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { CircularProgress, Grid, Typography } from "@mui/material";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export function LineChart({
  vitalsReading,
  color,
  unit,
  type,
  vitalsType,
  loading,
}) {
  const [values, setValues] = useState([]);
  const [disValues, setDisValues] = useState([]);
  const [labels, setLabels] = useState([]);
  const lowValue =
    vitalsReading?.alert_range?.normal?.low || vitalsReading?.min_range;
  const highValue =
    vitalsReading?.alert_range?.normal?.high || vitalsReading?.max_range;
  const diastolicLow =
    vitalsReading?.diastolic_alert_range?.normal?.low ||
    vitalsReading?.diastolic_min_range;
  const diastolicHigh =
    vitalsReading?.diastolic_alert_range?.normal?.high ||
    vitalsReading?.diastolic_max_range;

  useEffect(() => {
    if (vitalsReading?.labels) {
      setValues(vitalsReading?.values?.length ? vitalsReading?.values : []);
      setLabels(vitalsReading?.labels?.length ? vitalsReading?.labels : []);
      setDisValues(
        vitalsReading?.diastolic_values?.length
          ? vitalsReading?.diastolic_values
          : []
      );
    }
  }, [vitalsReading]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "",
      },
      backgroundFill: {
        lowValue: lowValue,
        highValue: highValue,
        color: color,
      },
    },
    scales: {
      x: {
        title: {
          display: false,
        },
        grid: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: unit,
        },
        grid: {
          display: true,
        },
        min: 0,
        max:
          vitalsReading?.graph_max_range + vitalsReading?.graph_max_range / 10,
        //  === 200 ? vitalsReading?.graph_max_range +30 :  vitalsReading?.graph_max_range +10,
        ticks: {
          stepSize:
            vitalsReading?.graph_max_range <= 10
              ? Math.abs(vitalsReading?.graph_max_range / 10).toFixed(1)
              :  Math.ceil(vitalsReading?.graph_max_range / 20),
          max:
            vitalsReading?.graph_max_range +
            vitalsReading?.graph_max_range / 10,
        },
      },
    },
    animation: {
      onComplete: function (context) {
        const chart = context.chart;
        const xOffset = chart.scales.x.left;
        const yOffset = chart.scales.y.getPixelForValue(highValue);
        const width = chart.scales.x.width;
        const height = chart.scales.y.getPixelForValue(lowValue) - yOffset;
        chart.ctx.fillStyle = color + "2A";
        chart.ctx.fillRect(xOffset, yOffset, width, height);

        if (type === "Blood Pressure") {
          const xOffset = chart.scales.x.left;
          const yOffset = chart.scales.y.getPixelForValue(diastolicHigh);
          const width = chart.scales.x.width;
          const height =
            chart.scales.y.getPixelForValue(diastolicLow) - yOffset;
          chart.ctx.fillStyle = "#d070162A";
          chart.ctx.fillRect(xOffset, yOffset, width, height);
        }
      },
    },
  };

  const data = useMemo(() => {
    return {
      labels: [...labels],
      datasets: [
        {
          fill: false,
          label: type === "Blood Pressure" ? "Systolic Value" : "Value",
          data: values?.length ? [...values] : [],
          borderColor: (context) => {
            const value = context.dataset?.data[context?.dataIndex];
            return vitalsType !== "avg_spo2"
              ?
              (
                //  vitalsReading?.alert_range?.min_critical?.high ||
                value >= lowValue && value <= highValue
                ? "#02B966":
                (
                  vitalsReading?.alert_range?.normal?.low > value) ||
                (vitalsReading?.alert_range?.max_critical?.low >= value &&
                  vitalsReading?.alert_range?.normal?.high < value)
                ? "#FFBE3D"
                : 
                // vitalsReading?.alert_range?.max_critical?.high >= value ||
                  vitalsReading?.alert_range?.max_critical?.low <= value
                ? "rgb(255, 0, 0)"
                
                // : !vitalsReading?.alert_range?.min_critical
                // ? "#FFBE3D"
                : "#878484"
              )
              : (vitalsReading?.alert_range?.min_critical?.high <= value &&
                vitalsReading?.alert_range?.normal?.low > value
              ? "#FFBE3D"
              : value > lowValue && value <= highValue + 1
              ? "#02B966"
              : vitalsReading?.alert_range?.min_critical?.high > value
              ? "rgb(255, 0, 0)"
              :  "#878484");
          },
          backgroundColor: (context) => {
            const value = context.dataset?.data[context?.dataIndex];
            console.log("value lowValue", value, lowValue, highValue);
            return value >= lowValue && value <= highValue
              ? "#02B966"
              : "#ffffff";
          },
          cubicInterpolationMode: "monotone",
          borderWidth: 2,
          pointRadius: 4,
        },
        {
          fill: false,
          label: type === "Blood Pressure" ? "Diastolic Value" : "Value",
          data: disValues?.length ? disValues : [],
          borderColor: (context) => {
            const value = context.dataset?.data[context?.dataIndex];
            // Adjust conditions for diastolic values
            return (
              // vitalsReading?.diastolic_alert_range?.min_critical?.high <=
              0 <= value &&
              vitalsReading?.diastolic_alert_range?.normal?.low > value) ||
              (vitalsReading?.diastolic_alert_range?.max_critical?.low >
                value &&
                vitalsReading?.diastolic_alert_range?.normal?.high < value)
              ? "#FFBE3D"
              : value >= diastolicLow && value <= diastolicHigh
              ? "#02B966"
              : vitalsReading?.diastolic_alert_range?.max_critical?.low <= value
              ? "rgb(255, 0, 0)"
              : "#878484";
          },
          backgroundColor: (context) => {
            const value = context.dataset?.data[context?.dataIndex];
            // Adjust conditions for diastolic values
            return value >= diastolicLow && value <= diastolicHigh
              ? "#02B966"
              : "#ffffff";
          },
          cubicInterpolationMode: "monotone",
          borderWidth: 2,
          pointRadius: 4,
        },
      ],
    };
  });

  return (
    <>
      {loading ? (
        <Grid display={"flex"} justifyContent={"center"} height={"350px"}>
          <CircularProgress color="primary" sx={{ marginTop: "15%" }} />
        </Grid>
      ) : (
        <>
          {values?.length !== 0 ? (
            <Line
              style={{ marginTop: "10px", maxHeight: "350px" }}
              options={options}
              data={data}
            />
          ) : (
            <Grid item style={{ marginTop: "10px", height: "350px" }}>
              <Typography
                variant="body1"
                fontSize={"16px"}
                margin={3}
                padding={3}
                textAlign={"center"}
              >
                There are no records to display
              </Typography>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
