import React, { useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
// import { Link } from "react-router-dom";
import {  NavLink, useLocation } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// import {
//     Grid
//   } from "@mui/material";
  import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";

const Breadcrumb = ({  value, title, tabLabels }) => {
  const { breadcrumb } = useSelector((state) => state);
  console.log("bread crum list",breadcrumb)
console.log("values in bread 1", tabLabels, value)
// console.log("values in bread 2", tabLabels)
// console.log("values in bread 3", value)
    const location = useLocation();
    // const [labels,setLabels] = useState(tabLabels);
    // const [index,setIndex] = useState(value);
  const [breadcrumbList, setBreadcrumb] = useState([]);

  useEffect(()=> {
    const path = location?.pathname.split("/").filter(Boolean);
    let breadcrum =[];
    breadcrum.push({label: "Analytics", url: "/analytics"})
    // path.forEach(element => {
    //     const matchingRoute = breadcrumb.routes.find((route) => route?.url === "/"+element);
    //     console.log("matching route", path,element)
    //     // breadcrum.push({ label: title, url: "/" + path[0]});
    //     if (matchingRoute && matchingRoute.url !== "/") {
    //       breadcrum.push({ label: matchingRoute?.title, url: matchingRoute?.url });
    //       console.log("breadcrum",breadcrum)
    //     }
    //     setBreadcrumb(breadcrum)
    // });
    console.log("sdhdsbdviksbd 1", tabLabels)
    breadcrum.push({ label: title, url: "/" + path[0]});
    setBreadcrumb(breadcrum)
    if(tabLabels !== undefined && value !== undefined && typeof(tabLabels) === "object") {
      breadcrum.push({label: tabLabels?.find((tab) => tab.label === value)?.label, url: ""})
    }
    setBreadcrumb(breadcrum);
  },[location?.pathname,breadcrumb.routes])
  // useEffect(()=> {
  //   setLabels(tabLabels);
  //   setIndex(value);
  // },[])
  useEffect(()=> {
    console.log("sdhdsbdviksbd 1", tabLabels)
    const updatedItems = [...breadcrumbList];
    console.log("sdhdsbdviksbd 2",updatedItems[updatedItems.length - 1])
if(!updatedItems || !updatedItems[0]?.label){return}
    if(tabLabels !== undefined && value !== undefined && breadcrumbList?.length > 1) {
      const newItem = {label: tabLabels?.find((tab) => tab?.label === value)?.label, url: ""};
      updatedItems[updatedItems?.length - 1] = newItem;
      console.log("sdhdsbdviksbd 4",updatedItems)
      setBreadcrumb(updatedItems);
      
    }
  }, [tabLabels,value])
 
 
  return (
<Grid container >
            <Typography variant="h6">
               {title}   
              </Typography>
              
              <Typography style={{ margin: '0 10px' }} fontSize={"20px"}>
                {"|"}  
              </Typography>
              
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} style={{ margin: '5px 0px' }} aria-label="breadcrumbList">
           {breadcrumbList?.length && breadcrumbList.map((item, index) => (
        <NavLink key={index} color={index === breadcrumb.routes?.length - 1 ? 'textPrimary' : 'inherit'} style={{ textDecoration: "none" }} to={item?.url || ''}>
          {item?.label}
        </NavLink>
      ))}
    </Breadcrumbs>
   
          {/* {tabLabels !== undefined && value !== undefined && (
             <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumbList">
            
           </Breadcrumbs>
          )
          }   */}
      </Grid>
    
  );
};

export default Breadcrumb;
