import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useRef, useEffect } from "react";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { CustomScrollbars } from "../../../../../../../../../ui-component/ScrollBar";
import { singleDateConverter } from "../../../../../../../../../helpers/regularDateConvert";
import CircularLoader from "../../../../../../../../../ui-component/CircularLoader";
import VerifiedIcon from "@mui/icons-material/Verified";
import DrawerComponent from "../../../../../../../../../ui-component/Drawer";
import AddNotes from "./AddNotes";
import { formatDateAndTime } from "../../../../../../../../../ccm-constant";
import ViewNotes from "./ViewNotes";

function NoteHistory({
  onClose,
  history,
  loading,
  setRefreshHistory,
  vitalsType,
  vitalsReading
}) {
  console.log("history", vitalsReading?.history);
  const [viewNote, setViewNote] = useState(false);
  const [showNote, setShowNote] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");
  const cardRef = useRef();

  useEffect(() => {
    if (!showNote) {
      const handleOutsideClick = (event) => {
        if (cardRef.current && !cardRef.current.contains(event.target)) {
          onClose();
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }
  }, [onClose, showNote]);

  return (
    <>
      <Card
        ref={cardRef}
        sx={{
          ...cardStyle,
          position: "fixed",
          bottom: "2.6vh",
          right: "2.2vw",
          width: "35%",
          height: "51.3vh",
        }}
      >
        <Grid container padding={1} paddingRight={3} paddingLeft={3} height={"40px"}>
          <Grid item xs={6}>
            <Typography variant="h6">History</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "end" }}>


            {/* <Button
              variant="contained"
              style={{ textTransform: "none", marginRight: 5 }}
              onClick={() => {
                setViewNote(true);
              }}
            >
              Add Note
            </Button> */}
            <Button
              variant="outlined"
              style={{ textTransform: "none", marginRight: 5 }}
              onClick={onClose}
            >
              Close
            </Button>
            {/* <IconButton size="large" aria-label="close" color="error">
            <CloseIcon fontSize="small"/>
          </IconButton> */}
          </Grid>
        </Grid>
        {loading ? (
          <Grid items xs={12}>
            <CircularLoader />
          </Grid>
        ) : (
          <Grid container spacing={1} padding={3}>
            <CustomScrollbars height={"43vh"}>
              {vitalsReading?.history?.map((item, index) => (
                <>
                  {item !== null &&
                    <Card style={{ ...cardStyle, margin: 5, padding: 2 }}>
                      <Grid container xs={12} padding={1}>
                        <Grid item display={"flex"} xs={12}>
                          <Grid item>
                            <VerifiedIcon
                            // style={
                            //   item?.status === "Progress"
                            //     ? { color: "#00FF0099" }
                            //     : item?.status === "Regress"
                            //       ? { color: "#FF0000CC" }
                            //       : { color: "#4C4C4CB2" }
                            // }
                            color={item.deflection >=0 ? "success" : "error"}

                          />
                          </Grid>
                          <Grid item>
                            <Typography variant="body1" marginLeft={2}>
                              On{" "}
                              {formatDateAndTime(item?.created_at).split(" ")[0]}{" "}
                              at{" "}
                              {formatDateAndTime(item?.created_at).split(" ")[1]} {" "}
                              {formatDateAndTime(item?.created_at).split(" ")[2]}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          display={"flex"}
                          justifyContent={"space-between"}
                          xs={12}
                        >
                          <Grid item marginLeft={4}>
                            <Typography
                              variant="cardTitle"
                              color="primary"
                              // color={
                              //   item?.status === "Progress"
                              //     ? { color: "#42427C" }
                              //     : item?.status === "Regress"
                              //       ? { color: "#FF0000CC" }
                              //       : { color: "#4C4C4CB2" }
                              // }
                              paddingTop={1}
                            >
                              {item?.diastolic_value ? `${item?.value}/${item?.diastolic_value} ${item?.unit}` : `${item?.value} ${item?.unit}`}
                            </Typography>
                          </Grid>
                          <Button
                            variant="text"
                            style={{ textTransform: "none", fontSize: "14px" }}
                            onClick={() => { setShowNote(true); setSelectedNote(item) }}
                          >
                            View Note
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  }
                </>
              ))}
            </CustomScrollbars>

            {!vitalsReading?.history?.length && (
              <Grid xs={12}>

                <Typography
                  variant="body2"
                  fontSize={"16px"}
                  margin={3}
                  padding={3}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  There are no records to display
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
      </Card>

      {/* <DrawerComponent
        open={viewNote}
        handleOpen={() => {
          setViewNote(true);
        }}
        handleClose={() => {
          setViewNote(false);
        }}
        data={
          <AddNotes
            handleClose={setViewNote}
            vitalsType={vitalsType}
            setRefresh={setRefreshHistory}
          />
        }
      /> */}

      <DrawerComponent
        open={showNote}
        handleOpen={() => {
          setShowNote(true);
        }}
        handleClose={() => {
          setShowNote(false);
        }}
        data={
          <ViewNotes
            handleClose={setShowNote}
            data={selectedNote}
          />
        }
      />
    </>
  );
}

export default NoteHistory;
