import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { PatientApi } from "../../../../../../../../../../../service/ProviderGroup/Patient";
import cardStyle from "../../../../../../../../../../../theme/card-layout";
import { DatePicker } from "../../../../../../../../../../../ui-component/DatePicker/DatePicker";
import FormDataTable from "../../../../../../features/FormDataTable";
import { useNotification } from "../../../../../../../../../../../hooks/use-notification";
import { useParams } from "react-router";
import { formatDate } from "../../../../../../../../../../../ccm-constant";

function ConditionsForm({ setOpenDrawer, setRefreshList, selectedData, setSelectedRow, setRefreshPatientData }) {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [conditionName, setConditionName] = useState("");
  const [selectedICD, setSelectedICD] = useState("");
  const [icdCodeOptions, setIcdCodeOptions] = useState("");
  const [noteData, setNoteData] = useState("");
  const [onSetDate, setOnSetDate] = useState(selectedData?.onset_date || null);
  const [lastOccurance, setLastOccurance] = useState(selectedData?.last_occurance || null);
  const [options, setOptions] = useState([]);
  const { notifySuccess, notifyError } = useNotification();
  const [loading, setLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false);


  useEffect(() => {
    if (isSaving) {
      setTimeout(() => {
        setIsSaving(false);
      }, 2000);
    }
  }, [isSaving])

  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.srNo,
      width: "80px",
    },
    {
      name: "Condition Name",
      selector: (row) => <span>{row?.conditionName?.name}</span>,
      // width: "250px",
    },
    {
      name: "Status",
      selector: (row) =>
        row?.status?.charAt(0)?.toUpperCase() + row?.status?.slice(1),
      width: "105px",
    },
    {
      name: "Type",
      selector: (row) =>
        row?.type_condition?.charAt(0)?.toUpperCase() +
        row?.type_condition?.slice(1),
      width: "90px",
    },
    {
      name: "Note",
      selector: (row) => row.note,
      minWidth: "140px",
    },
    {
      name: "Onset Date",
      selector:(row) => formatDate(row?.onset_date),
      width: "130px",
    },
    {
      name: "Last Occurence",
      selector: (row) => formatDate(row?.last_occurance) || "NA",
      width: "170px",
    },
  ];

  useEffect(() => {
    getAllConditionsAndICDCodes();
  }, []);

  useEffect(() => {
    if (conditionName) {
      const selectedCondition = data?.find(
        (item) => item?.conditionName?.name === conditionName?.name
      );
      const filteredOptions =
        selectedCondition?.icd_code !== undefined
          ? conditionName?.options?.filter(
            (option) => option?.name !== selectedCondition?.icd_code
          )
          : conditionName?.options;
      setIcdCodeOptions(filteredOptions || [{}]);
    }
  }, [conditionName, data]);


  useEffect(() => {
    if (selectedData?.name) {
      const selectedCondition = options?.find(
        (item) => item?.name === selectedData?.name
      );
      if (selectedCondition) {
        setConditionName(selectedCondition)
        const icd = selectedCondition?.options?.find(
          (item) => (item?.name === selectedData?.icd_code) || (item?.label === selectedData?.icd_code)
        );
        setSelectedICD(icd)
      }

      console.log("&&&&&&&&&&&", selectedCondition, selectedICD)

    }
  }, [selectedData, options, icdCodeOptions, selectedICD])


  const getAllConditionsAndICDCodes = () => {
    let ConditionsData = [];
    PatientApi.GetConditionsAndICDCodes()
      .then((response) => {
        response?.data?.forEach((element) => {
          ConditionsData.push({
            name: element.title,
            label: `${element?.title}`,
            options: element?.icd_codes?.map((ele) => {
              return { name: ele.code, label: ele?.lable, title: ele?.title };
            }),
          });
        });
        setOptions(ConditionsData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const validationSchema = Yup.object().shape({
    conditionName: Yup.mixed().required("Condition name is required"),
    icd_code: Yup.mixed().required("ICD code is required"),
    onSetDate: Yup.date().required("Onset date is required"),
  });

  const validateField = (values) => {
    const errors = {};
    if (values?.selectedStatus === "historical") {
      if (!values?.lastOccurance) {
        errors.lastOccurance = "Last occurance date is required";
      }
    }
    return errors;
  };

  const SubmitConditionsData = () => {
    const payload = [];
    if (isSaving) {
      // If already saving, return to prevent multiple calls
      return;
    }
    setIsSaving(true);
    if (data?.length === 0) {
      notifyError("Please add at least one condition.")
    }
    else {
      data.forEach((element) => {
        payload.push({
          name: element?.conditionName?.name,
          icd_code: element?.icd_code,
          status: element?.status,
          last_occurance: element?.last_occurance || null,
          type_condition: element?.type_condition,
          onset_date: element?.onset_date,
          note: element?.note,
        });
      });
      if (payload?.length) {
        setLoading(true);
        PatientApi.addConditions(id, payload)
          .then((response) => {
            setOpenDrawer(false);
            setRefreshList(true);
            setLoading(false)
            notifySuccess("Conditions added successfully");
            setRefreshPatientData(true);
            console.log("response.data", response.data);
          })
          .catch((error) => {
            setLoading(false)
            console.error("Error addAllergies data:", error);
            notifyError(error?.response?.data?.error);
          })
          .finally(() => {
            setIsSaving(false); // Reset saving status after API call is completed
          });
      }
    }
  };

  const UpdateConditionsData = (values) => {
    console.log("first values", values)
    setLoading(true)
    const payload = {
      name: values?.conditionName?.name,
        icd_code:values?.icd_code?.name || values?.icd_code,
        status: values?.selectedStatus,
        last_occurance:   values?.selectedStatus === "active" ? null : lastOccurance !== "" ? lastOccurance : null ,
        type_condition: values?.selectedType,
        onset_date: onSetDate !== "" ? onSetDate : null,
        note: values?.note === "" ? null : values?.note,
    };
    PatientApi.updateConditions(selectedData?.uuid, payload)
      .then((response) => {
        setOpenDrawer(false);
        setLoading(false)
        setRefreshList(true);
        setRefreshPatientData(true);
        notifySuccess("Conditions updated successfully");
        console.log("response.data", response.data);
      })
      .catch((error) => {
        setLoading(false)
        console.error("Error addAllergies data:", error);
        notifyError(error?.response?.data?.error);
      });
  };

  return (
    <>
      <Grid container marginTop={2}>
        <Formik
          initialValues={{
            conditionName: selectedData?.name || "",
            icd_code: selectedData?.icd_code || "",
            selectedStatus: selectedData?.status || "active",
            selectedType: selectedData?.type_condition || "chronic",
            note: selectedData?.note || "",
            onSetDate: selectedData?.onset_date || null,
            lastOccurance: selectedData?.last_occurance || null,
          }}
          validationSchema={validationSchema}
          validate={(values) => validateField(values)}
          onSubmit={(values, { resetForm }) => {
            const newCondition = {
              srNo: data?.length + 1 || 1,
              conditionName: values?.conditionName,
              status: values?.selectedStatus,
              type_condition: values?.selectedType,
              onset_date: values?.onSetDate ? values.onSetDate : null,
              note: values?.note,
              icd_code: values?.icd_code?.name,
              last_occurance:
                values?.lastOccurance && values?.selectedStatus === "historical"
                  ? values?.lastOccurance
                  : null,
            };
            if (!selectedData){
              setData([...(data || []), newCondition]);
              resetForm({
                conditionName: "",
                icd_code: "",
                selectedStatus: "active",
                selectedType: "chronic",
                note: "",
                onSetDate: "",
                lastOccurance: null,
              });
              setOnSetDate(null);
              setLastOccurance(null);
              setNoteData("");
            
            }
            else{
              UpdateConditionsData(values)
            }

            }}
          enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit, errors }) => (
            <Form>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: 2,
                  }}
                  spacing={2}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      height: "40px",
                      fontWeight: 700,
                      width: "100px",
                      textTransform: "none",
                      marginRight: 15,
                    }}
                    onClick={() => { setOpenDrawer(false); setSelectedRow(null) }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      height: "40px",
                      fontWeight: 700,
                      width: "100px",
                      textTransform: "none",
                    }}
                    onClick={() => {
                      console.log("first errors?.length", errors)
                      // if(Object.keys(errors)?.length && selectedData){
                      //   console.log("EEEEEEEEEEEEE",errors,  Object.keys(errors)[0], errors[Object.keys(errors)[0]] )
                      // notifyError(Object.values(errors)[0])
                      //   console.log("medication errors", errors, values)
                      // }
                      // else{
                      !selectedData ? SubmitConditionsData() : handleSubmit()}
                        // }
                  }
                  >
                    Save &nbsp;
                    {loading && (
                      <CircularProgress size={18} style={{ color: "white" }} />
                    )}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" marginBottom={2}>
                    {selectedData ? "Edit" : "Add"} Condition
                  </Typography>
                  {/* <Typography variant="body1" marginBottom={2}>
                    Chronic conditions to be managed under CCM Programs
                  </Typography> */}

                  <Grid container spacing={3} marginBottom={2}>
                    <Grid item xs={12} md={conditionName ? 6 : 12}>
                      <Typography variant="subtitle1" marginBottom={1}>
                        Condition Name
                      </Typography>

                      <Field
                        variant="outlined"
                        fullWidth
                        name={"conditionName"}
                      >
                        {({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            id="combo-box-demo"
                            options={options}
                            sx={cardStyle}
                            renderInput={(params) => (
                              <TextField {...params} {...field} placeholder="Select" />
                            )}
                            onChange={(e, newValue) => {
                              setConditionName(newValue);
                              setFieldValue("icd_code", "");
                              setFieldValue("conditionName", newValue);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name={"conditionName"}
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>

                    {conditionName ? (
                      <Grid item xs={12} md={6}>
                        <Typography variant="subtitle1" marginBottom={1}>
                          ICD Code
                        </Typography>
                        <Field variant="outlined" fullWidth name="icd_code">
                          {({ field }) => (
                            <Autocomplete
                              {...field}
                              disablePortal
                              id="combo-box-demo"
                              options={icdCodeOptions}
                              sx={cardStyle}
                              renderInput={(params) => (
                                <TextField {...params} {...field} placeholder="Select" />
                              )}
                              onChange={(e, newValue) => {
                                setFieldValue("icd_code", newValue);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name={"icd_code"}
                          component="div"
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        />
                      </Grid>
                    ) : null}
                  </Grid>

                  <Grid
                    container
                    marginBottom={2}
                    spacing={3}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Grid item sm={12} lg={4} md={6}>
                      <Typography variant="subtitle1" marginBottom={1}>
                        Status
                      </Typography>
                      <Grid sx={{ display: "flex", gap: "10px" }}>
                        <Button
                          variant={
                            values?.selectedStatus === "active"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          onClick={() =>
                            setFieldValue("selectedStatus", "active")
                          }
                          style={{ textTransform: "none", height: "30px" }}
                        >
                          Active
                        </Button>
                        <Button
                          variant={
                            values?.selectedStatus === "historical"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          style={{ textTransform: "none", height: "30px" }}
                          onClick={() =>
                            setFieldValue("selectedStatus", "historical")
                          }
                        >
                          Historical
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item sm={12} lg={4} md={6}>
                      <Typography variant="subtitle1" marginBottom={1}>
                        Type{" "}
                      </Typography>
                      <Grid sx={{ display: "flex", gap: "10px" }}>
                        <Button
                          variant={
                            values?.selectedType === "chronic"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          style={{ textTransform: "none", height: "30px" }}
                          onClick={() =>
                            setFieldValue("selectedType", "chronic")
                          }
                        >
                          Chronic
                        </Button>
                        <Button
                          variant={
                            values?.selectedType === "acute"
                              ? "contained"
                              : "outlined"
                          }
                          color="primary"
                          style={{ textTransform: "none", height: "30px" }}
                          onClick={() => setFieldValue("selectedType", "acute")}
                        >
                          Acute
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item sm={12} lg={4} md={6}>
                      <Typography variant="subtitle1" marginBottom={1}>
                        Onset Date
                      </Typography>
                      <DatePicker
                        setOnSetDate={setOnSetDate}
                        onSetDate={onSetDate}
                        fieldName="onSetDate"
                      />
                      <ErrorMessage
                        name={"onSetDate"}
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>

                    {values?.selectedStatus === "historical" && (
                      <Grid item sm={12} lg={4} md={6}>
                        <Typography variant="subtitle1" marginBottom={1}>
                          Last Occurance
                        </Typography>
                        <DatePicker
                          setOnSetDate={setLastOccurance}
                          onSetDate={lastOccurance}
                          fieldName="lastOccurance"
                          dateFrom={onSetDate}
                          disable={!onSetDate ? true : false}
                        />
                        <ErrorMessage
                          name={"lastOccurance"}
                          component="div"
                          style={{
                            color: "red",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {/* <Grid container xs={12} marginBottom={0} lg={12} spacing={2}> */}
                </Grid>
                {/* </Grid> */}

                <Grid item xs={12}>
                  <Typography variant="subtitle1" marginBottom={1}>
                    Note
                  </Typography>
                  <Field
                    as={TextField}
                    variant="outlined"
                    style={cardStyle}
                    minRows={3}
                    maxRows={3}
                    multiline={true}
                    name="note"
                    placeholder="Note"
                    sx={{ width: "100%" }}
                  />
                </Grid>

                {!selectedData && <Grid container justifyContent="flex-end" marginTop={3}>
                  <Grid item>
                    <Button
                      type="submit"
                      variant="outlined"
                      color="primary"
                      style={{
                        alignItems: "center",
                        height: "40px",
                        fontWeight: 700,
                        width: "100px",
                        textTransform: "none",
                      }}
                      onClick={handleSubmit}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>}
              </Grid>
            </Form>
          )}
        </Formik>

        {!selectedData && <Grid item xs={12} lg={12} marginTop={2}>
          {data?.length ? (
            <FormDataTable data={data} columns={columns} />
          ) : null}
        </Grid>}
      </Grid>
    </>
  );
}

export default ConditionsForm;
