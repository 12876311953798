import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import cardStyle from "../../../../../../../../../theme/card-layout";
import LinearProgress from "@mui/material/LinearProgress";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { PatientApi } from "../../../../../../../../../service/ProviderGroup/Patient";

const ColoredSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-rail": {
    height: "8px",
    borderRadius: 0,
    background: `linear-gradient(90deg, #FF0000CC 0%, #FF0000CC 33.33%, #FF9925 33.34%, #FF9925 66.66%, #5FD557 66.67%, #5FD557 100%)`,
    opacity: 1,
  },
  "& .MuiSlider-track": {
    backgroundColor: "transparent",
    border: 0,
    borderRadius: 0,
  },
  "& .MuiSlider-thumb": {
    borderRadius: "25%",
    width: "30px",
    border: "1px solid #f9f9f9",
    backgroundColor: "#e2e2e4",
  },
  "& .MuiSlider-valueLabel": {
    top: -10, // Adjust the position of the value label
    "& span": {
      color: "#4C4C4C", // Set the color of the value label
      fontSize: "16px",
      padding: 0,
    },
    "&::after": {
      content: '"%"', // Add percentage sign
      position: "absolute",
      top: 1,
      color: "#4C4C4C", // Set the color of the value label
      fontSize: "16px",
      right: 5, // Adjust the position of the percentage sign
    },
    padding: 1,
    paddingRight: 5,
    width: "70px",
    backgroundColor: "#ebeaea",
    border: "1px solid #979696",
  },
}));

const ProgressBarWithSlider = () => {
  // const [value, setValue] = useState(value);
  const { setFieldValue, values } = useFormikContext();

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", m: 1 }}>
      <ColoredSlider
        value={values?.progress}
        onChange={(event, newValue) => {
          // setValue(newValue);
          setFieldValue("progress", newValue);
        }}
        valueLabelDisplay="on"
        sx={{ mt: 2.3 }}
        step={1}
      />
    </Box>
  );
};

function UpdateCarePlanProgress({
  handleClose,
  loading,
  handleSubmit,
  SelectedRow,
  setRefreshList,
}) {
  const statusOptions = [
    { label: "Regress", name: "regress" },
    { label: "Progress", name: "progress" },
    { label: "Not Progress", name: "not_progress" },
  ];

  console.log("first SelectedRow", SelectedRow);


  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Status is required"),
    progress: Yup.number().required("Progress is required"),
    note: Yup.string().required("Note is required"),
  });

  return (
    <Grid item xs={12} margin={2}>
      <Formik
        initialValues={{
          status: "",
          // SelectedRow?.status === "progress"
          //   ? "Progress"
          //   : SelectedRow?.status === "regress"
          //   ? "Regress"
          //   : "Not Progress",
          // note: SelectedRow?.note[0]?.note || "",
          note: "",
          progress: SelectedRow?.progress || 0,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          PatientApi.updtaeCarePlansProgress(
            SelectedRow?.uuid,
            SelectedRow?.patient,
            {
              progress: values?.progress,
              note: values?.note,
              status: values?.status === "Progress"
                ? "progress"
                : values?.status === "Regress"
                  ? "regress"
                  : "not_progress",
            }
          )
            .then((response) => {
              console.log("updtaeCarePlansProgress", response);
              handleClose();
              setRefreshList(true);
            })
            .catch((error) => {
              console.error("Error fetching user data:", error);
            });
        }}
        enableReinitialize
      >
        {({ setFieldValue, values, handleSubmit }) => (
          <Form>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: 1,
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    width: "150px",
                    textTransform: "none",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    marginLeft: "20px",
                    width: "150px",
                    color: "#ffffff",
                    textTransform: "none",
                  }}
                  onClick={handleSubmit}
                >
                  Save
                  {loading && (
                    <CircularProgress size={18} style={{ color: "white" }} />
                  )}
                </Button>
              </Grid>
              <Grid container>
                <Typography variant="h6" marginBottom={2}>
                  {SelectedRow?.name}
                </Typography>
                <Grid item xs={12} marginBottom={2}>
                  <Typography variant="subtitle1" marginBottom={1}>
                    Change Status
                  </Typography>
                  <Field
                    name="status"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disablePortal
                        id="combo-box-demo"
                        options={statusOptions}
                        sx={cardStyle}
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Select" />
                        )}
                        onChange={(e, newValue) => {
                          setFieldValue("status", newValue?.label);
                        }}
                      />
                    )}
                  />
                  <ErrorMessage
                    name="status"
                    component="div"
                    style={{
                      color: "red",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />
                </Grid>

                <Typography variant="subtitle1" marginBottom={1}>
                  Goals Progress
                </Typography>
                <Grid item xs={12} name={"progress"}>
                  <ProgressBarWithSlider values={values?.progress} />
                  <ErrorMessage
                    name="progress"
                    component="div"
                    style={{
                      color: "red",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />
                </Grid>

                <Grid item xs={12} marginTop={3}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    style={cardStyle}
                    minRows={3}
                    maxRows={3}
                    multiline={true}
                    name="note"
                    placeholder="Add Note"
                    sx={{ width: "100%" }}
                  />
                  <ErrorMessage
                    name="note"
                    component="div"
                    style={{
                      color: "red",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

export default UpdateCarePlanProgress;
