import { useLayoutEffect, useState } from "react";

function CheckPermission({ children, codeName, ButtonType }) {
  const [isPermitted, setIsPermitted] = useState(null);

  useLayoutEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user"))?.tokens?.permissions;
    let hasPermission = false;

    if (ButtonType === "group") {
      // Check if every codename in the array exists in the permissions
      hasPermission = codeName.every(code => permissions.some(permission => permission.codename === code));
    } else {
      // Check if a single codename exists in the permissions
      hasPermission = permissions.some(permission => permission.codename === codeName);
    }

    setIsPermitted(hasPermission);
  }, [codeName, ButtonType]);

  return (
    <>
      {isPermitted !== null && isPermitted && children}
    </>
  );
}

export default CheckPermission;
