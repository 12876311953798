import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'

const CircularProgressBarCell = ({ value }) => {
  return (
    <div style={{ width: "55px", margin: "auto", padding: "7px" }}>
    <CircularProgressbar
      value={value}
      text={`${value}%`}
      styles={{
        root: {},
        path: {
          stroke:
            value < 33.33
              ? "#FF0000CC"
              : value >= 33.34 && value <= 66.66
                ? "#FF9925"
                : "#5FD557",
        },
        text: {
          fill:
            value < 33.33
              ? "#FF0000CC"
              : value >= 33.34 && value <= 66.66
                ? "#FF9925"
                : "#5FD557",
          fontWeight: 500,
          fontSize: "18px !important",
        },
      }}
    />
  </div>
  )
}

export default CircularProgressBarCell