import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNotification } from "../hooks/use-notification";
import { useFormikContext } from "formik";
import Link from '@mui/material/Link';
import { Card, Grid, Typography } from "@mui/material";
import cardStyle from "../theme/card-layout";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import config from "../config";

function DragAndDropCSV({selectedFiles, setSelectedFile, setSelectedFiles, multiSelect }) {
  const { notifyError } = useNotification();
  const [selectedImage, setSelectedImage] = useState(null);
  const { setFieldValue, values } = useFormikContext();

  const isSupportedFileType = (fileType) => {
    console.log("fileType", fileType)
    const supportedFormats = multiSelect ? ["jpeg", "jpg", "docx", "pdf", "sheet", "document", "png", "msword"] : ["xlsx", "sheet"];
    return supportedFormats.some((format) => fileType.endsWith(format));
  };

  return (
    <>
      <Grid container justifyContent={!multiSelect ? "center" : ""} alignItems={!multiSelect ? "center" : ""} height="86vh">
        <Grid
          container
          xs={12}
          sx={{
            height: 300,
            position: "relative",
            border: "1px solid #000",
            marginBottom: "20px",
            border: "1px solid #707070",
            borderRadius: "20px",
            opacity: 1,
          }}
        >
          <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }} margin={2}>
            {!multiSelect &&
              <Typography
                variant="body2"
                style={{
                  height: "40px",
                  fontWeight: 700,
                  marginLeft: "20px",
                  cursor: 'pointer',
                }}
                onClick={() => window.open(config?.TEMPLATE_URL)}
              >
                Download Template
              </Typography>
            }
          </Grid>
          <Grid container xs={12} justifyContent="center">
            <Grid item xs={10}>
              <Typography
                variant="h5"
                sx={{
                  textAlign: 'left',
                  font: 'normal normal medium 20px/28px Gilroy ☞',
                  letterSpacing: '0.13px',
                  color: '#000000BC',
                  opacity: 1,
                  marginBottom: "10px",
                  fontWeight: 500
                }}
              >
                Upload {`${multiSelect ? "Documents" : "File"}`}
              </Typography>
              {multiSelect &&
                <Typography
                  variant="body2"
                  sx={{
                    marginBottom: "8px",
                  }}
                >
                  Accepted file - DOC, DOCX, PDF, JPG, JPEG, PNG, XLSX
                </Typography>
              }
              <Card
                sx={{
                  ...cardStyle,
                  height: "126px",
                  display: "1",
                  alignItems: "center",
                  flexDirection: "column",
                  cursor: "arrow",
                  border: "1px dashed #4C4C4C66",
                  borderRadius: "10px",
                  opacity: 1,
                }}
              >
                <FileUploader
                  multiple={true}
                  handleChange={(e) => {
                    let file = null;
                    setFieldValue("file", "");
                    setSelectedImage(null);
                    file = e[0];
                    const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
                    if (file) {
                      if (!isSupportedFileType(file.type)) {

                        notifyError(
                          multiSelect ? "Please select a valid file format, such as .doc, .docx, .pdf, .jpg, .jpeg, .png" : "Invalid file format, Please download template"
                        );
                      } else if (file.size > maxSize) {
                        notifyError("File size should not exceed 5 MB.");
                      } else {
                        setFieldValue("file", e);
                        if (multiSelect) {
                          const fileExists = selectedFiles.some(existingFile => existingFile.name === file.name);
                        console.log("first fileExists", fileExists)
                          if (!fileExists) {
                            const uniqueFiles = [...new Map(selectedFiles.map(file => [file.name, file])).values()];
                            setSelectedFiles([...uniqueFiles, file]);
                            setFieldValue("file", "");
                          } else {
                            // Show error message or take appropriate action
                            notifyError("File already exists. Please choose a unique file.");
                          }
                          setFieldValue("file", "");
                        }
                        else {
                          setSelectedFile(e[0]);
                        }
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          setSelectedImage(e.target.result);
                        };
                        reader.readAsDataURL(file);
                      }
                    }
                  }}
                  name="file"
                  // types={fileTypes}
                  label="Drop File"
                  children={
                    <Grid
                      container
                      xs={12}
                      height={"100%"}
                      style={{
                        position: "relative",
                      }}
                    >
                      {/* <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          background: `url(${selectedImage}) center/cover no-repeat`,
                          opacity: 0.3,
                          zIndex: 0, // Ensure it's below other elements
                        }}
                      ></div> */}
                      <Grid
                        // item
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        zIndex={1}
                        style={{ opacity: 1 }}
                      >
                        <Typography>
                          <CloudUploadIcon style={{ color: "#002c6b", width: 46, height: 32 }} />
                        </Typography>
                      </Grid>
                      <Grid
                        // container
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        zIndex={1}
                        style={{ opacity: 1 }}
                      >
                        <Typography
                          sx={{
                            color: "#3A3A3A",
                            font: "normal normal medium 20px/24px Gilroy ☞",
                          }}
                        >
                          Drag & drop your {`${multiSelect ? "documents" : "file"}`} or <Link href="#" underline="always"
                            style={{
                              color: "#002C6B",
                              font: "normal normal medium 20px/24px Gilroy ☞",
                            }}
                          >Browse</Link>
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </Card>
              <Typography
                padding={1}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                {(values?.file && !multiSelect)
                  ? `File name: ${values?.file[0]?.name}`
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    </>
  );
}

export default DragAndDropCSV;
