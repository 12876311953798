import React, { useEffect, useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import { Button, Grid, Typography, List, ListItem, ListItemText, ListItemIcon, Card, Radio, CircularProgress, FormControlLabel } from "@mui/material";
import { useNotification } from '../../../../../../../hooks/use-notification';
import Link from '@mui/material/Link';
import cardStyle from '../../../../../../../theme/card-layout';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircleIcon from '@mui/icons-material/Circle';
import { PatientApi } from '../../../../../../../service/ProviderGroup/Patient';
import config from '../../../../../../../config';
import ConsentForm from '../ConsentForm/Index';
import { CustomScrollbars } from '../../../../../../../ui-component/ScrollBar';
import { useNavigate } from 'react-router-dom';

const EnrollForm = ({ handleClose, setRefreshList, patientData, setEnrollFromProfile, enrollFromProfile, setRefreshPatientData }) => {
  const navigate = useNavigate();
  const [showverbalConsentForm, setShowVerbalConsentForm] = useState(false);
  const [writtenConsentForm, setWrittenConsentForm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  console.log("FILE NAME", selectedFile)
  const [consentType, setConsentType] = useState(patientData?.consent_medium === "" ? "email" : patientData?.consent_medium);
  const [loading, setLoading] = useState(false);
  const { notifyError, notifySuccess } = useNotification();

  const handleConsentTypeChange = (event) => {
    setConsentType(event.target.value);
  };

  const listPoints = [
    "Only one provider can furnish CCM Services to you during a calendar month.",
    "Your medical information will be shared electronically with your active providers as part of coordinating your care.",
    "You may be billed a standard depending on supplemental coverage and your primary insurance.",
    "You may stop CCM services at any time, effective at the end of the calendar month, by notifying the CCM staff directly."
  ]

  const isSupportedFileType = (fileType) => {
    console.log("fileType", fileType)
    const supportedFormats = ["doc", "docs", "pdf", "docx", "document", "msword"];
    return supportedFormats.some((format) => fileType.endsWith(format));
  };

  const handleConsentForm = async () => {
    const payload = consentType == "written" ? {
      consent_medium: consentType,
      consent_form: selectedFile
    } : {
      consent_medium: consentType,
    }

    if (consentType == "written") {
      setLoading(true);
      if (selectedFile) {
        PatientApi.saveConsentForm(patientData?.uuid, payload)
          .then((response) => {
            console.log("PatientApi written response", response);
            notifySuccess(response?.message);
            setLoading(false);
            handleClose();
            navigate("/patients");
            enrollFromProfile ? setRefreshPatientData(true) : setRefreshList(true);
          })
          .catch((error) => {
            console.error("Error fetching written data:", error);
            notifyError(error?.response?.data?.error);
            setLoading(false);
          });
      }
      else {
        notifyError('Please Select File');
      }
    }
    else if (consentType == "verbal") {
      setLoading(true);
      PatientApi.saveConsentForm(patientData?.uuid, payload)
        .then((response) => {
          console.log("PatientApi verbal response", response);
          notifySuccess(response?.message);
          setLoading(false);
          enrollFromProfile ? setRefreshPatientData(true) : setRefreshList(true);
          setShowVerbalConsentForm(true);
        })
        .catch((error) => {
          console.error("Error fetching verbal data:", error);
          notifyError(error?.response?.data?.error);
          setLoading(false);
        });
    }
    else {
      setLoading(true);
      PatientApi.saveConsentForm(patientData?.uuid, payload)
        .then((response) => {
          console.log("PatientApi email form response", response?.message);
          notifySuccess(response?.message)
          setLoading(false);
          enrollFromProfile ? setRefreshPatientData(true) : setRefreshList(true);
          handleClose();
        })
        .catch((error) => {
          console.error("Error fetching email data:", error);
          notifyError(error?.response?.data?.error);
          setLoading(false);
        });
    }
  };

  const handleBack = () => {
    if (showverbalConsentForm) {
      setShowVerbalConsentForm(false);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (patientData?.consent_medium === "written") {
      setWrittenConsentForm(true);
    }
  }, [])


  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{ marginBottom: 1 }}
          >
            {/* {showverbalConsentForm ?
              <Typography>
                <img
                  width="182"
                  height="26"
                  src="https://dev.bewellacyhealth.com/static/media/logoLight1.e45a15c04f9e7052c094f57afcd62c70.svg"
                  alt="Logo"
                />
              </Typography> : <></>
            } */}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}
          >
            <Button
              variant="outlined"
              color="primary"
              style={{
                height: "40px",
                fontWeight: 700,
                width: "100px",
                textTransform: "none",
              }}
              onClick={() => { handleBack() }}
            >
              {!showverbalConsentForm ? "Cancel" : "Back"}
            </Button>
            {!showverbalConsentForm ?
              <Button
                variant="contained"
                style={{
                  height: "40px",
                  fontWeight: 700,
                  marginLeft: "20px",
                  width: "120px",
                  color: "#ffffff",
                  textTransform: "none",
                }}
                onClick={() => { handleConsentForm() }}
              >
                {consentType === 'verbal' ? "Next" : "Save"} &nbsp;
                {loading && (
                  <CircularProgress
                    size={18}
                    style={{ color: "white" }}
                  />
                )}
              </Button> : <></>
            }
          </Grid>
        </Grid>
        {!showverbalConsentForm ?
          <Grid container justifyContent="flex-center" sx={{ display: "flex", alignItems: "center" }} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h6">
                Patient Consent
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant='subtitle1'>
                By signing below, you are enrolling in CCM and agreeing to the following:
              </Typography>
            </Grid>

            <Grid item xs={12} margin={1}>
              <List>
                {listPoints.map((point => (
                  <ListItem style={{ padding: '3px' }}>
                    <ListItemIcon>
                      <CircleIcon style={{ fontSize: 'small', color: '#4C4C4C' }} />
                    </ListItemIcon>
                    <ListItemText primary={point} />
                  </ListItem>
                )))}
              </List>
            </Grid>

            <Grid container spacing={2} marginTop={2}>
              <Grid item xs={12} >
                <FormControlLabel
                  value="email"
                  control={<Radio />}
                  label="Send the Consent form to patient"
                  checked={(consentType === "" || consentType === 'email')}
                  onChange={handleConsentTypeChange}
                  style={{ height: '25px' }}
                  onClick={() => { setWrittenConsentForm(false) }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  value="verbal"
                  control={<Radio />}
                  label="Verbal consent by the patient"
                  checked={consentType === 'verbal'}
                  onChange={handleConsentTypeChange}
                  style={{ height: '25px' }}
                  onClick={() => { setWrittenConsentForm(false) }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  value="written"
                  control={<Radio />}
                  label="Written consent by the patient"
                  checked={consentType === 'written'}
                  onChange={handleConsentTypeChange}
                  style={{ height: '25px' }}
                  onClick={() => { setWrittenConsentForm(true) }}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  value="ehr"
                  control={<Radio />}
                  label="Enroll patient from EHR"
                  checked={consentType === 'ehr'}
                  onChange={handleConsentTypeChange}
                  style={{ height: '25px' }}
                  onClick={() => { setWrittenConsentForm(false) }}
                />
              </Grid>
            </Grid>

            <Grid container marginTop={2}>
              {writtenConsentForm &&
                <>
                  <Grid xs={12} sx={{ display: "flex", justifyContent: "end" }}>
                    <Button
                      variant="contained"
                      style={{
                        height: "40px",
                        fontWeight: 700,
                        width: "230px",
                        color: "#ffffff",
                        textTransform: "none",
                      }}
                      onClick={() => window.open(config?.CONSENT_FORM_TEMPLATE)}
                    >
                      Download consent form
                    </Button>
                  </Grid>
                  <Grid container xs={12} justifyContent="center" marginTop={2}>
                    <Grid item xs={10}>
                      <Typography
                        variant="h5"
                        sx={{
                          textAlign: 'left',
                          font: 'normal normal medium 20px/28px Gilroy ☞',
                          letterSpacing: '0.13px',
                          color: '#000000BC',
                          opacity: 1,
                          marginBottom: "10px",
                          fontWeight: 500
                        }}
                      >
                        Upload File
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          marginBottom: "8px",
                        }}
                      >
                        Accepted file - DOC, DOCX, PDF,
                      </Typography>
                      <Card
                        sx={{
                          ...cardStyle,
                          height: "80px",
                          display: "1",
                          alignItems: "center",
                          flexDirection: "column",
                          cursor: "arrow",
                          border: "1px dashed #4C4C4C66",
                          borderRadius: "10px",
                          opacity: 1,
                        }}
                      >
                        <FileUploader
                          multiple={true}
                          handleChange={(e) => {
                            let file = null;
                            console.log("file123", e[0]?.type)
                            file = e[0];
                            const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
                            if (file) {
                              if (!isSupportedFileType(file.type)) {
                                notifyError(
                                  "Please select a valid file format, such as .docs, .docx, .pdf"
                                );
                              } else if (file.size > maxSize) {
                                notifyError("File size should not exceed 5 MB.");
                              } else {
                                setSelectedFile(e[0]);
                              }
                            }
                          }}
                          name="file"
                          // types={fileTypes}
                          label="Drop File"
                          children={
                            <Grid
                              container
                              xs={12}
                              height={"100%"}
                              style={{
                                position: "relative",
                              }}
                            >
                              <Grid
                                // item
                                xs={12}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                zIndex={1}
                                style={{ opacity: 1 }}
                              >
                                <Typography>
                                  <CloudUploadIcon style={{ color: "#002c6b", width: 46, height: 32 }} />
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "#3A3A3A",
                                    font: "normal normal medium 20px/24px Gilroy ☞",
                                  }}
                                >
                                  Drag & drop your file or <Link href="#" underline="always"
                                    style={{
                                      color: "#002C6B",
                                      font: "normal normal medium 20px/24px Gilroy ☞",
                                    }}
                                  >Browse</Link>
                                </Typography>
                              </Grid>
                            </Grid>
                          }
                        />
                      </Card>
                      {selectedFile &&
                        <Typography
                          padding={1}
                          sx={{ display: "flex", justifyContent: "center" }}
                        >
                          {`File name: ${selectedFile?.name}`}
                        </Typography>
                      }
                    </Grid>
                  </Grid>
                </>
              }
            </Grid>

          </Grid>
          :
          <Grid container justifyContent="flex-center" sx={{ display: "flex", alignItems: "center" }} xs={12}>
            <CustomScrollbars height="90vh">
              <ConsentForm patientData={patientData}
                handleClose={handleClose}
                setRefreshList={setRefreshList}
                setShowVerbalConsentForm={setShowVerbalConsentForm}
                enrollFromProfile={enrollFromProfile}
                setRefreshPatientData={setRefreshPatientData}
              ></ConsentForm>
            </CustomScrollbars>
          </Grid>
        }
      </Grid>
    </>
  )
}

export default EnrollForm