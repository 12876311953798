import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Button,
  CircularProgress
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import cardStyle from "../../../../../../theme/card-layout";
import SearchIcon from "@mui/icons-material/Search";
import ProgressBars from "../../../../../../ui-component/ProgressBar";
import LanguageIcon from "@mui/icons-material/Language";
import { useTheme } from "@mui/material/styles";
import PlaceIcon from "@mui/icons-material/Place";
import BusinessIcon from "@mui/icons-material/Business";
import NavTabs from "../../../../Settings/Components/NavTabs";
import { useSelector } from "react-redux";
import CareManagement from "./Components/CareManagement/Index";
import TimeLogData from "./Components/TimeLog/Index";
import CareTeamData from "./Components/CareTeam/Index";
import FamilyData from "./Components/Family/Index";
import DocumentData from "./Components/PatientDocument/Index";
import Breadcrumb from "../../../../../../ui-component/Breadcrumb";
import DrawerComponent from "../../../../../../ui-component/Drawer";
import CarePlanStepper from "./Components/CareManagement/Features/CarePlanQuestionMain";
import Clinical from "./Components/Clinical/Index";
import { PatientApi } from "../../../../../../service/ProviderGroup/Patient";
import { CustomScrollbars } from "../../../../../../ui-component/ScrollBar";
import { useNotification } from "../../../../../../hooks/use-notification";
import EnrollForm from "../features/Enroll";
import { clearState } from "../../../../../../store/slices/questionsSlice";
import PatientsProfile from "./Components/PatientProfile/Index";
import Assesment from "./Components/Assesment";
import Vitals from "./Components/Vitals";
import avatar from "../../../../../../assets/adminAvatar.jpg";
import SetDefaultBox from "../../../../../../ui-component/SetDefaultBox";
import { saveTimer } from "../../../../../../store/slices/TImeLogSlice";
import CheckPermission from "../../../../../../ui-component/ButtonWrapper";


const ProviderContext = createContext();
export const useProviderContext = () => {
  return useContext(ProviderContext);
};

function PatientProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const { id } = useParams();
  const { notifySuccess, notifyError } = useNotification();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const theme = useTheme();
  const store = useSelector((state) => state);
  const [value, setValue] = useState(location?.state?.tabIndex || "Care Management");
  console.log("value", value);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [patientData, setPatientdata] = useState();
  console.log("STATUS", patientData)
  const [openEnrollForm, setOpenEnrollForm] = useState(false);
  const userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;
  const [open, setOpen] = useState(state?.addCarePlan || false);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(20);
  const [selectedClinical, setSelectedClinical] = useState("Medication");
  console.log("selectedClinical", selectedClinical);
  const [viewPatientsProfile, setViewPatientsProfile] = useState(false);
  console.log("viewPatientsProfile", viewPatientsProfile)
  const [enrolledDays, setEnrolledDays] = useState();
  const [refreshPatientData, setRefreshPatientData] = useState(false);
  const [dataRefresh, setDataRefresh] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [imageLoading, setImageLoading] = useState(true);
  const [timerLog, setTimerLog] = useState(false);
  const [enrollFromProfile, setEnrollFromProfile] = useState(false);
  console.log("6767", enrollFromProfile)

  const progressValue = convertToMinutes(extractMonthlyMinsValue(patientData?.monthly_mins));

  function convertToMinutes(timeString) {
    const [minutes, second] = timeString?.split(':');
    const totalMinutes = parseInt(minutes, 10) + parseInt(second, 10) / 60;
    return totalMinutes;
  }

  useEffect(() => {
    setPatientdata(patientData);
  }, [patientData])


  const getPatientData = () => {
    setFetchingData(true);
    PatientApi.getPatientDataById(id)
      .then((response) => {
        console.log("PatientApi response", response);
        setPatientdata(response);
        if (response?.status === "Accepted") {
          setEnrollFromProfile(true);
        }
        calculateDaysFromToday(response?.enrolled_date)
        setFetchingData(false);
        setSelectedImage(response?.profile_pic)
        setRefreshPatientData(false);
      })
      .catch((error) => {
        console.error("Error fetching patients data:", error);
      });
  };


  useEffect(() => {
    navigate(`/patients/profile/${id}`, {
      state: { enrolled_status: location?.state?.enrolled_status, patientId: id, care_team_member: location?.state?.care_team_member },
    });
  }, [state?.addCarePlan])

  useEffect(() => {
    getPatientData();
    // return () => {
    // console.log("patientData", location.state, )
    // if(location?.state?.enrolled_status)
    // window.onbeforeunload = ()=>{return <SetDefaultBox
    //   open={timerLog}
    //   handleSubmit={()=> dispatch(saveTimer(true))}
    //   handleClose={() => {
    //     setTimerLog(false)
    //   }}
    //   timeLog = {true}
    // />}
    // if (location?.state?.enrolled_status) {
    //   window.addEventListener('beforeunload', handleBeforeUnload);
    //   window.addEventListener('popstate', handleOnPopState);
    // window.preventDefault()
    // setTimerLog(true)
    // var result = window.confirm("Do you want to log the time ? ")
    // if (result) {
    //   dispatch(saveTimer(true))
    // } else {
    //   setTimerLog(false)
    // }
    // }
    //     return false
    //   }
  }, [])

  // useEffect(() => {

  //   return (e) => {
  //     if(location?.pathname?.startsWith("/patients/profile/")){
  //       setTimerLog(true)
  //       return false
  //     }
  //   }
  // }, [location?.pathname])

  useEffect(() => {
    if (refreshPatientData) {
      getPatientData();
      setEnrollFromProfile(false);
    }
  }, [refreshPatientData])

  const handleOpenDrawer = () => {
    dispatch(clearState());
    console.log("in open")
    if (value === "Care Management") {
      setOpen(true);
    }
    else {
      setOpenDrawer(true);
    }
  }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (openEnrollForm) {
      setOpenEnrollForm(false);
    }
  }

  const handleSearch = (e) => {
    const inputText = e.target.value;
    setSearchText(inputText);
  };

  const tabComponents = {
    "Care Management": <CareManagement dataRefresh={dataRefresh} setDataRefresh={setDataRefresh} />,
    "Vitals": <Vitals openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} searchText={searchText} />,
    "Care Team": <CareTeamData openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />,
    "Clinical": <Clinical openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} setSelectedClinical={setSelectedClinical} setRefreshPatientData={setRefreshPatientData} />,
    "Assessment": <Assesment openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} searchText={searchText} />,
    "Documents": <DocumentData openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />,
    "Time Log": <TimeLogData openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} setRefreshPatientData={setRefreshPatientData}/>,
    "Family": <FamilyData openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}></FamilyData>,
    // 8: <p className="text-center m-5 "></p>,
  };

  const a11TabsProps = [
    {
      label: "Care Management",
    },
    {
      label: "Vitals",
    },
    {
      label: "Care Team",
    },
    {
      label: "Clinical",
    },
    {
      label: "Assessment",
    },
    {
      label: "Documents",
    },
    {
      label: "Time Log",
    },
    {
      label: "Family",
    },
  ];

  useEffect(() => {
    if (value !== "profile") {
      setViewPatientsProfile(false);
    }
    else {
      setValue(value)
    }
  }, [value])

  const handleInvite = (uuid) => {
    setLoading(true);
    const payload = {
      uuid: uuid
    }
    PatientApi.invitePatient(payload)
      .then((response) => {
        console.log("Invite Patient response", response.data);
        setLoading(false);
        notifySuccess('Invitation sent successfully');
        setRefreshPatientData(true);
      })
      .catch((error) => {
        console.error("Error fetching inviting patient:", error.response?.data?.error);
        notifyError(error.response?.data?.error);
        setLoading(false);
      });
  };

  const handleEnroll = () => {
    setOpenEnrollForm(true);
  };

  const handleCloseEnrollForm = () => {
    setOpenEnrollForm(false);
  }


  function calculateDaysFromToday(enrolledDate) {
    if (enrolledDate != null) {
      const EnrolledDate = new Date(enrolledDate);
      const timeDifference = new Date() - EnrolledDate;
      const totalDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      setEnrolledDays(totalDays);
    }
    else {
      setEnrolledDays("0");
    }
  }

  const isURL = (url) => {
    try {
      if (url.includes("https://")) {
        // new URL(url);
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  };

  function extractMonthlyMinsValue(monthlyMins) {
    const matches = monthlyMins?.match(/\d+:\d+/);
    const extractedValue = matches ? matches[0] : "00:00";
    if (convertToMinutes(extractedValue) > max) {
      let totalMinutes = Math.ceil((convertToMinutes(extractedValue)) / 20) * 20;
      setMax(totalMinutes);
    }
    return extractedValue;
  }


  function getCurrentMonth() {
    const currentDate = new Date();
    const monthFormatter = new Intl.DateTimeFormat('en', { month: 'long' });
    return monthFormatter.format(currentDate);
  }

  const buttonConfig = {
    "Care Team": {
      codeName: "add_careteam",
      buttonText: "Assign Care Team"
    },
    "Time Log": {
      codeName: "add_timelog",
      buttonText: "Add Time Log"
    },
    "Clinical": {
      codeName: selectedClinical === "Medication" ? "add_medication" : selectedClinical === "Condition" ? "add_condition" : "add_allergies" ,
      buttonText: `Add ${selectedClinical}`
    },
    "Care Management": {
      codeName: "add_careplan",
      buttonText: "Add Care Plan"
    },
    "Family": {
      codeName: "add_familymembers",
      buttonText: "Add Family Member"
    },
    "Documents": {
      codeName: "add_documents",
      buttonText: "Add Document"
    },
    "Assessment": {
      codeName: "add_assessmentresponse",
      buttonText: "Assign Assessment"
    },
    "Vitals": {
      codeName: "add_vitalalerts",
      buttonText: "Add Vitals"
    }
  };

  const buttonConfig2 = {
    "Clinical": {
      codeName: selectedClinical === "Medication" ? "add_medication" : selectedClinical === "Condition" ? "add_condition" : "add_allergies" ,
      buttonText: `Add ${selectedClinical}`
    },
    "Family": {
      codeName: "add_familymembers",
      buttonText: "Add Family Member"
    },
    "Documents": {
      codeName: "add_documents",
      buttonText: "Add Document"
    },
    "Vitals": {
      codeName: "add_vitalalerts",
      buttonText: "Add Vitals"
    }
  };

  return (
    <>
      {/* {timerLog && <SetDefaultBox
        open={timerLog}
        handleSubmit={() => dispatch(saveTimer(true))}
        handleClose={() => {
          setTimerLog(false);
        }}
        timeLog={true}
      />} */}

      <ProviderContext.Provider
        value={{
          searchText,
        }}
      >
        <Grid container xs={12} padding={3}>
          <Card
            sx={{
              width: "100%",
              boxShadow: "5",
              minHeight: "84.6vh",
              padding: 2,
            }}
          >
            <Grid item xs={12} marginBottom={3}>
              <Grid container xs={12}>
                <Grid item xs={6}>
                  <Breadcrumb
                    value={value}
                    tabLabels={a11TabsProps}
                    title="Patients"
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {!viewPatientsProfile &&
                    <TextField
                      variant="outlined"
                      placeholder="Type here to search"
                      size="small"
                      sx={{ ...cardStyle, width: "400px" }}
                      value={searchText}
                      onChange={handleSearch}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  }
                  {!viewPatientsProfile &&
                    <>
                      {
                        patientData?.enrolled_status == true
                        ? (
                          <>
                            {[
                              "Care Team",
                              "Time Log",
                              "Clinical",
                              "Care Management",
                              "Family",
                              "Documents",
                              "Assessment",
                              "Vitals",
                            ].includes(value) &&
                              (value === "Time Log" && (!patientData?.care_team_member && ProviderRole !== "Physician")) ? <></> : (
                                Object.keys(buttonConfig).map((key) => (
                                  value === key && (
                                    <CheckPermission key={key} codeName={buttonConfig[key].codeName}>
                                      <Button
                                        variant="contained"
                                        style={{
                                          height: "40px",
                                          fontWeight: 700,
                                          marginLeft: "20px",
                                          width: "180px",
                                          color: "#ffffff",
                                          textTransform: "none"
                                        }}
                                        onClick={handleOpenDrawer}
                                      >
                                        {buttonConfig[key].buttonText}
                                      </Button>
                                    </CheckPermission>
                                  )
                                ))
                            )}
                          </>
                        ) : 
                          patientData?.enrolled_status == false ? (
                          <>
                            {["Clinical", "Family", "Documents", "Vitals"].includes(
                              value
                            ) && (
                                Object.keys(buttonConfig2).map((key) => (
                                value === key && (
                                  <CheckPermission key={key} codeName={buttonConfig2[key].codeName}>
                                    <Button
                                      variant="contained"
                                      style={{
                                        height: "40px",
                                        fontWeight: 700,
                                        marginLeft: "20px",
                                        width: "180px",
                                        color: "#ffffff",
                                        textTransform: "none"
                                      }}
                                      onClick={handleOpenDrawer}
                                    >
                                      {buttonConfig2[key].buttonText}
                                    </Button>
                                  </CheckPermission>
                                )
                              ))
                              )}
                          </>
                        ) : 
                          patientData?.enrolled_status == true && value !== "Care Team" ? (
                          <>
                            {["Assessment"].includes(value) && (
                              <CheckPermission codeName={"add_assessmentresponse"}>
                                <Button
                                  variant="contained"
                                  style={{
                                    height: "40px",
                                    fontWeight: 700,
                                    marginLeft: "20px",
                                    width: "180px",
                                    color: "#ffffff",
                                    textTransform: "none",
                                  }}
                                  onClick={() => {
                                    handleOpenDrawer();
                                  }}
                                >
                                  Assign Assessment
                                </Button>
                              </CheckPermission>
                            )}
                          </>
                        ) :
                          patientData?.enrolled_status == true ? (
                          <>
                            {["Care Team"].includes(value) && (
                              <CheckPermission codeName={"add_careplan"}>
                                <Button
                                  variant="contained"
                                  style={{
                                    height: "40px",
                                    fontWeight: 700,
                                    marginLeft: "20px",
                                    width: "180px",
                                    color: "#ffffff",
                                    textTransform: "none",
                                  }}
                                  onClick={() => {
                                    handleOpenDrawer();
                                  }}
                                >
                                  Assign Care Team
                                </Button>
                              </CheckPermission>
                            )}
                          </>
                        )
                          : (
                            <></>
                          )}
                    </>
                  }
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} marginBottom={1}>
              <Grid container xs={12} spacing={3}>
                <Grid item xs={12} md={12} lg={12} xl={8} spacing={3}>
                  {!fetchingData ? (
                    <Card
                      sx={{ ...cardStyle, height: "auto", cursor: "pointer" }}
                      onClick={() => {
                        setViewPatientsProfile(true);
                        setValue("profile");
                      }}
                    >
                      <Grid container>
                        <Grid item xs={12} md={4} sx={{ display: { xs: "flex" }, justifyContent: { xs: "center" } }}>
                          <Grid
                            container
                            xs={12}
                            paddingLeft={1}
                            height={"16vh"}
                            sx={{
                              borderRight: "2px dotted #ccc",
                              // display: "flex",
                              // alignItems: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={4}
                              sx={{ flex: 1, alignItems: "center", padding: 1, alignSelf: "center" }}
                            >
                              <Box
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                {imageLoading && (
                                  <Box
                                    sx={{
                                      width: 80,
                                      height: 80,
                                      ...cardStyle,
                                      borderRadius: "50%",
                                      bottom: 0,
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      margin: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor:
                                        "rgba(255, 255, 255, 0.8)",
                                    }}
                                    zIndex={2}
                                  >
                                    <CircularProgress
                                      size={30}
                                      sx={{
                                        color: "black",
                                        position: "absolute",
                                        marginLeft: "7px",
                                        marginTop: "7px",
                                      }}
                                    />
                                  </Box>
                                )}
                                <Avatar
                                  alt="User Profile Photo"
                                  src={
                                    (isURL(selectedImage)
                                      ? `${selectedImage}?${new Date().getTime()}`
                                      : selectedImage) || avatar
                                  }
                                  sx={{
                                    ...cardStyle,
                                    width: 80,
                                    height: 80,
                                    ...cardStyle,
                                    borderRadius: "50%",
                                    objectFit: "contain",
                                    visibility: imageLoading
                                      ? "hidden"
                                      : "visible",
                                  }}
                                  onError={(e) => {
                                    e.target.src = avatar;
                                    e.target.onerror = null;
                                    setImageLoading(false);
                                  }}
                                  onLoad={() => setImageLoading(false)}
                                />
                              </Box>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              lg={8}
                              sx={{ flex: 1, alignItems: "center", padding: 1 }}
                            >
                              <CustomScrollbars height={"15vh"}>
                                <Typography
                                  variant="h6"
                                  sx={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >{`${patientData?.first_name} ${patientData?.last_name}`}</Typography>
                                <Typography
                                  variant="body1"
                                  fontSize={14}
                                  margin={0}
                                >
                                  {`${patientData?.patient_id ? patientData?.patient_id : ""}`}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  fontSize={14}
                                  margin={0}
                                >
                                  {`${patientData?.gender_at_birth} | ${patientData?.age} Years`}
                                </Typography>
                                {patientData?.enrolled_status === true && (
                                  <Typography
                                    variant="body1"
                                    fontSize={12}
                                    style={{ color: "#002c6b" }}
                                  >
                                    {`CCM- Enrolled ${enrolledDays} Days`}
                                  </Typography>
                                )}
                                {/* {(ProviderRole === "Physician" || userType === "provider_group") &&
                                  patientData?.enrolled_status != true && (
                                    <Typography variant="body1" fontSize={14}>
                                      {patientData?.status}
                                    </Typography>
                                  )} */}
                                    <>
                                    <CheckPermission codeName={"enroll_patient"}>
                                    {(patientData?.status === "Rejected" ||
                                    patientData?.status === "Invited" ||
                                    patientData?.status === "Eligible" ||
                                    patientData?.status === "Accepted") &&
                                    (patientData?.enrolled_status !== true) &&
                                    <Button
                                      variant="contained"
                                      style={{
                                        textTransform: "none",
                                        // marginLeft: 15,
                                        width: 130,
                                        height: 26,
                                        marginTop: "2px",
                                      }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        if (
                                          patientData?.status ===
                                          "Rejected" ||
                                          patientData?.status === "Invited" ||
                                          patientData?.status === "Eligible"
                                        ) {
                                          handleInvite(patientData?.uuid);
                                        } else {
                                          handleEnroll();
                                        }
                                      }}
                                      >
                                        {`${patientData?.status === "Rejected" ||
                                          patientData?.status === "Invited"
                                          ? "Re-Invite"
                                          : patientData?.status === "Eligible"
                                            ? "Invite"
                                            : patientData?.status === "Accepted" && patientData?.enrolled_status !== true
                                              ? "Enroll"
                                              : ""
                                          }`}{" "}
                                        &nbsp;
                                        {loading && (
                                          <CircularProgress
                                            size={18}
                                            style={{ color: "white" }}
                                          />
                                        )}
                                    </Button>
                                    }
                                    </CheckPermission>
                                    </>
                              </CustomScrollbars>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Grid
                            container
                            xs={12}
                            padding={1}
                            marginTop={"7px"}
                            height={"16vh"}
                            sx={{
                              borderRight: "2px dotted #ccc",
                              display: "flex",
                              alignItems: "start",
                            }}
                          >
                            <CustomScrollbars height={"13vh"}>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start", // Align content to the left
                                  paddingLeft: 1, // Add left padding
                                  paddingRight: 2, // Add right padding
                                  // paddingTop: 2,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px"
                                  }}
                                >
                                  <LanguageIcon
                                    style={{
                                      marginRight: "5px",
                                      color: theme.palette.primary.main,
                                    }}
                                  />
                                  <Typography variant="body1">
                                    {patientData?.language}
                                  </Typography>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px"
                                  }}
                                >
                                  <PlaceIcon
                                    style={{
                                      marginRight: "5px",
                                      color: theme.palette.primary.main,
                                    }}
                                  />
                                  <Typography variant="body1">{`${patientData?.address?.city}, ${patientData?.address?.state}, ${patientData?.address?.country}`}</Typography>
                                </Box>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <BusinessIcon
                                    style={{
                                      marginRight: "5px",
                                      color: theme.palette.primary.main,
                                    }}
                                  />
                                  <Typography variant="body1">
                                    {`${patientData?.provider_group_name != null
                                      ? patientData?.provider_group_name
                                      : ""
                                      }`}
                                  </Typography>
                                </Box>
                              </Grid>
                            </CustomScrollbars>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Grid
                            container
                            xs={12}
                            height={"16vh"}
                            padding={1}
                            marginTop={"5px"}
                            sx={{
                              display: "flex",
                              alignItems: "start",
                            }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start", // Align content to the left
                                paddingLeft: 1, // Add left padding
                                paddingRight: 2, // Add right padding
                                // paddingTop: 2,
                              }}
                            >
                              <Typography variant="subtitle1">
                                Conditions
                              </Typography>
                              <CustomScrollbars height={"10vh"}>
                                {patientData?.condition?.length > 0 ? (
                                  patientData?.condition?.map(
                                    (condition, index) => (
                                      <Typography variant="body1">
                                        {`${index + 1}. ${condition?.name} (${condition?.icd_code == null
                                          ? "NA"
                                          : `Code - ${condition?.icd_code}`
                                          })`}
                                      </Typography>
                                    )
                                  )
                                ) : (
                                  <Typography variant="body1">
                                    No conditions.
                                  </Typography>
                                )}
                              </CustomScrollbars>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  ) : (
                    <Card
                      sx={{
                        ...cardStyle,
                        height: "14vh",
                        padding: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress color="primary" />
                    </Card>
                  )}
                </Grid>
                <Grid item xs={12} md={12} lg={12} xl={4}>
                  <Card
                    sx={{
                      ...cardStyle,
                      height: "12.5vh",
                      padding: 2,
                    }}
                  >
                    <Typography variant="subtitle1">
                      Billing Thresholds
                    </Typography>

                    <Grid
                      item
                      xs={12}
                      marginTop={1}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography
                        variant="body1"
                        style={{ marginRight: "10px" }}
                      >
                        {min}
                      </Typography>
                      <ProgressBars
                        value={(progressValue / max) * 100}
                        style={{ marginRight: "30px" }}
                      />
                      <Typography
                        variant="body1"
                        style={{ marginLeft: "10px" }}
                      >
                        {max}
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ marginLeft: "10px" }}
                      >
                        MIN
                      </Typography>
                    </Grid>
                    <Grid item xs={12} marginTop={1}>
                      <Typography variant="body1">
                        {`${extractMonthlyMinsValue(patientData?.monthly_mins)} Minutes Reviewed In ${getCurrentMonth()}.`}
                      </Typography>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            {/* // <Button onClick={handleOpen}>+ Care Plan</Button> */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 1,
                  justifyContent: "space-between",
                }}
              >
                <NavTabs
                  a11TabsProps={a11TabsProps}
                  value={value}
                  setValue={setValue}
                />
              </Box>
              {!viewPatientsProfile ? (
                <Box sx={{ marginBottom: 0 }}>{tabComponents[value]}</Box>
              ) : (
                <PatientsProfile
                  patientDatas={patientData}
                  setRefreshPatientData={setRefreshPatientData}
                  refreshPatientData={refreshPatientData}
                />
              )}
            </Grid>
          </Card>
        </Grid>
        <DrawerComponent
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          data={
            <CarePlanStepper
              handleClose={handleClose}
              setDataRefresh={setDataRefresh}
              setRefreshPatientData={setRefreshPatientData}
            />
          }
        />
        <DrawerComponent
          open={openEnrollForm}
          handleOpen={handleEnroll}
          handleClose={handleCloseEnrollForm}
          data={
            <EnrollForm
              handleClose={handleCloseEnrollForm}
              patientData={patientData}
              setEnrollFromProfile={setEnrollFromProfile}
              enrollFromProfile={enrollFromProfile}
              setRefreshPatientData={setRefreshPatientData}
            />
          }
        />
        {/* </Grid > */}
      </ProviderContext.Provider>
    </>
  );
}

export default PatientProfile;
