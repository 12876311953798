// App.js

import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Box } from "@mui/material";
import Snackbar from "./ui-component/snackbar";
import Loadable from "./ui-component/Loadable";
import useBoxStyles from "./theme/main-layout";
import "./index.css";
import { AuthRoutes } from "./routes/AuthRoutes";
import { Provider } from "./routes/ProviderRoutes";
import { Admin } from "./routes/AdminRoutes";
import { User } from "./routes/UserRoutes";
import { useDispatch } from "react-redux";
import { closeDrawer, openDrawer } from "./store/slices/drawerSlices";
import UserProfileDetails from "./views/workspaces/Settings/Components/AdminUser/UserProfileDetails";
import "./assets/css/style.css";
import httpClient from "./service/base";
// import { setAuthRoutes } from "./store/slices/BreadcrumbSlices";
// import Header from "./views/Header";
// import SideNav from "./views/Side-Nav";
// import NotFound from "./views/errors";


// header and sidenav
const Header = lazy(() => import("./views/Header"));
const SideNav = lazy(() => import("./views/Side-Nav"));
const NotFound = lazy(() => import("./views/errors"));

function App() {
  const [loggedInUserType, setloggedInUserType] = useState("");
  // console.log("httpClient 2",httpClient, loggedInUserType);
  const [alertCount, setAlertCount] = useState(0)

  const dispatch = useDispatch();
  const styles = useBoxStyles();
  const [loggedIn, setloggedIn] = useState(false);
  // const loggedIn = true;
  const [routes, setRoutes] = useState([]);
  const [childRoute, setChildRoute] = useState([]);
  const ExtraRoutes = [];

  useEffect(() => {
    if (httpClient.userRole !== undefined && httpClient.userRole !== null) {
      setloggedInUserType(httpClient.userRole);
      setloggedIn(true);
    }
  }, [httpClient.userRole])

  useEffect(() => {
    let breadcrumbRoutes = [];
    if (loggedIn && loggedInUserType !== undefined && loggedInUserType !== null) {
      dispatch(openDrawer());
      if (loggedInUserType === "super_admin") {
        let childRoutes = [];
        Admin.forEach((route) => {
          if (route?.children) {
            childRoutes.push(...route?.children);
            ExtraRoutes.push(...route?.children);
          }
        });
        setChildRoute(childRoutes);
        setRoutes([...Admin, ...AuthRoutes, ...childRoutes]);
        breadcrumbRoutes = [...Admin, ...childRoutes];
      } else if (loggedInUserType === "admin") {
        let childRoutes = [];
        Admin.forEach((route) => {
          if (route?.children) {
            childRoutes.push(...route?.children);
            ExtraRoutes.push(...route?.children);
          }
        });
        setChildRoute(childRoutes);
        setRoutes([...Admin, ...AuthRoutes, ...childRoutes]);
        breadcrumbRoutes = [...Admin, ...childRoutes];
      }
      else if (loggedInUserType === "provider") {
        let childRoutes = [];
        Provider.forEach((route) => {
          if (route?.children) {
            childRoutes.push(...route?.children);
            ExtraRoutes.push(...route?.children);
          }
        });
        setChildRoute(childRoutes);
        setRoutes([...Provider, ...AuthRoutes, ...childRoutes]);
        breadcrumbRoutes = [...Provider, ...childRoutes];
      } else if (loggedInUserType === "provider_group") {
        let childRoutes = [];
        Provider.forEach((route) => {
          if (route?.children) {
            childRoutes.push(...route?.children);
            ExtraRoutes.push(...route?.children);
          }
        });
        setChildRoute(childRoutes);
        setRoutes([...Provider, ...AuthRoutes, ...childRoutes]);
        breadcrumbRoutes = [...Provider, ...childRoutes];
      } else if (loggedInUserType === "User") {
        setRoutes([...User, ...AuthRoutes]);
        breadcrumbRoutes = [...User];
      }
      // console.log("breadcrumb-----",breadcrumbRoutes)
      // dispatch(setAuthRoutes(breadcrumbRoutes));
    } else {
      setRoutes(AuthRoutes);
    }
    return () => {
      setRoutes(AuthRoutes);
    };
  }, [loggedIn, loggedInUserType]);

  useEffect(() => {
    // console.log("inside dispatch useEffect", routes)
    if (routes !== undefined && routes !== null && routes !== []) {
      // console.log("inside dispatch", routes)
      setTimeout(() => {
        // dispatch(setAuthRoutes(routes));

      }, 5000);

    }
  }, [routes])

  const allAuthRouteUrls = [...AuthRoutes].map((route) => route?.url);
  const allProtectedRouteUrls = [
    ...Admin,
    ...Provider,
    ...User,
    ...childRoute,
    ...ExtraRoutes,
  ].map((route) => route?.url);

  useEffect(() => {
    if (window?.location?.pathname === "/logout") {
      dispatch(closeDrawer());
    }
    else if (allAuthRouteUrls.includes(window?.location?.pathname))
      dispatch(closeDrawer());
    return () => {
      dispatch(openDrawer());
    };
  }, [loggedIn, window?.location?.pathname]);
  console.log("routesroutesroutes", routes)
  return (
    <Router>
      <Suspense >
        {loggedIn && !allAuthRouteUrls.includes(window?.location?.pathname) ? (
          <div className="content">
            {!(window.location.pathname.includes("/meeting")) && <Header setAlertCount={setAlertCount} />}
            {window?.location?.pathname !== "/logout" && !(window.location.pathname.includes("/meeting")) && <SideNav alertCount={alertCount} />}
            <Box sx={styles.box}>
              <Routes>
                {routes?.map((route) => (
                  <Route
                    key={route?.id}
                    path={route?.url}
                    element={route?.element}
                  />
                ))}
                {/* show not found page */}
                <Route
                  path="*"
                  key={"Notfound-" + Date.now()}
                  element={<NotFound loggedIn={!loggedIn ? true : false} />}
                />
              </Routes>
            </Box>
          </div>
        ) : (
          <Routes>
            {routes?.map((route) => (
              <Route
                key={route?.id}
                path={route?.url}
                element={route?.element}
              />
            ))}
            <Route
              path="*"
              element={
                // Check if the requested route exists in your application's routes
                !loggedIn && allProtectedRouteUrls?.includes(window?.location?.pathname) ? (
                  // show access denied page
                  <NotFound loggedIn={false} />
                ) : ![...allProtectedRouteUrls, ...allAuthRouteUrls].includes(
                  window?.location?.pathname
                ) ? (
                  <NotFound loggedIn={true} />
                ) : null
              }
              key={"Notfound-" + Date.now()}
            />
          </Routes>
        )}
      </Suspense>
      <Snackbar />
    </Router>
  );
}

export default App;