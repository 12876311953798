import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Select,
  TextField,
  MenuItem,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { PatientApi } from "../../../../../../../../../../service/ProviderGroup/Patient";
import cardStyle from "../../../../../../../../../../theme/card-layout";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../../../../../../../hooks/use-notification";
import SelectDropdown from "../../../../../../../../../../ui-component/SelectDropdown";
import DragAndDrop from "../../../../../../../../../../ui-component/DragAndDrop";
import Input from "../../../../../Input";

const AddFamilyMember = ({
  setOpenDrawer,
  setRefreshList,
  // memberId,
  // editingMember,
  // setEditingMember,
}) => {
  const { id } = useParams();
  const { notifySuccess, notifyError } = useNotification();
  // const [memberData, setMemberData] = useState(null);
  // console.log("DATA34", memberData);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (editingMember) {
  //     fetchMemberData();
  //   }
  // }, []);

  // const fetchMemberData = () => {
  //   PatientApi.getFamilyMemberDataById(id, memberId)
  //     .then((response) => {
  //       setMemberData(response);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching Family Member Data:", error);
  //     });
  // };

  // useEffect(() => {
  //   setMemberData(memberData);
  // }, [memberData]);

  const FamilyFields = [
    {
      label: "Full Name",
      fieldName: "name",
      fieldType: TextField,
      placeholder: "Full Name",
    },
    {
      label: "Relationship",
      fieldName: "relation",
      fieldType: Select,
      placeholder: "Select Relationship",
      options: [
        {
          label: "Child",
          name: "Child",
        },
        {
          label: "Employer",
          name: "Employer",
        },
        {
          label: "Father",
          name: "Father",
        },
        {
          label: "Grandparent",
          name: "Grandparent",
        },
        {
          label: "Grandchild",
          name: "Grandchild",
        },
        {
          label: "Guardian",
          name: "Guardian",
        },
        {
          label: "Life Partner",
          name: "Life Partner",
        },
        {
          label: "Mother",
          name: "Mother",
        },
        {
          label: "Other",
          name: "Other",
        },
        {
          label: "Parent",
          name: "Parent",
        },
        {
          label: "Spouse",
          name: "Spouse",
        },
        {
          label: "Unknown",
          name: "Unknown",
        },
        {
          label: "Parent",
          name: "Parent",
        },
      ],
    },
    {
      label: "Email ID",
      fieldName: "email",
      fieldType: TextField,
      placeholder: "Email ID",
    },
    {
      label: "Phone Number",
      fieldName: "phone_number",
      // fieldType: TextField,
      placeholder: "Phone Number",
    },
    {
      label: "Address Line 1",
      fieldName: "address_line_1",
      fieldType: TextField,
      placeholder: "Address Line 1",
    },
    {
      label: "Address Line 2",
      fieldName: "address_line_2",
      fieldType: TextField,
      placeholder: "Address Line 2",
    },
    {
      label: "City",
      fieldName: "city",
      fieldType: TextField,
      placeholder: "City",
    },
    {
      label: "Country",
      fieldName: "country",
      fieldType: TextField,
      placeholder: "Country",
    },
    {
      label: "State",
      fieldName: "state",
      fieldType: TextField,
      placeholder: "State",
    },
    {
      label: "Zip Code",
      fieldName: "zip_code",
      fieldType: TextField,
      placeholder: "Zip Code",
    },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Full Name is required"),
    phone_number: Yup.string()
      .matches(/^[\d\s()+-]+$/, "Invalid Phone number")
      .min(10, "Phone number is too short")
      .max(20, "Phone number is too long")
      .required("Phone number is required"),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    // address_line_2: Yup.string().required("Address Line 2 is required"),
    zip_code: Yup.string()
      .matches(
        /^[0-9]{5,6}$/,
        "Zip code must be 5 to 6 digits and contain only numbers"
      )
      .required("Zip code is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
    relation: Yup.string().required("Relationship is required"),
    email: Yup.string().required("Email ID is required"),
  });

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          phone_number: "",
          address_line_1: "",
          address_line_2: "",
          zip_code: "",
          city: "",
          state: "",
          country: "",
          relation: "",
          email: "",
          profile_pic: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data) => {
          setLoading(true);
          console.log("Family Member Data", data);
          const formData = new FormData();
          let address = {
            address_line_1: data?.address_line_1,
            address_line_2: data?.address_line_2,
            zip_code: data?.zip_code,
            city: data?.city,
            state: data?.state,
            country: data?.country,
          };
          formData.append("address", JSON.stringify(address));
          formData.append("name", data?.name);
          formData.append("phone_number", data?.phone_number);
          formData.append("relation", data?.relation);
          formData.append("email", data?.email);
          // if(data?.profile_pic[0]!== memberData?.profile_pic)
          formData.append("profile_pic", data?.profile_pic[0]);

          // if (editingMember) {
          //   PatientApi.updateFamilyMember(id, memberId, formData)
          //     .then((response) => {
          //       notifySuccess(response?.message);
          //       setOpenDrawer(false);
          //       setRefreshList(true);
          //       setLoading(false);
          //       setEditingMember(false);
          //     })
          //     .catch((error) => {
          //       console.error(
          //         "Error fetching Update Family Member Data:",
          //         error
          //       );
          //     });
          // } else {
          PatientApi.AddFamilyMember(id, formData)
            .then((response) => {
              notifySuccess(response?.message);
              setOpenDrawer(false);
              setRefreshList(true);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error fetching Add Family Data:", error);
              notifyError(error?.response?.data?.error)
            });
        }
        }
      // }
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form>
            <Grid
              container
              justifyContent="flex-end"
              sx={{ display: "flex", alignItems: "end" }}
            >
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    width: "100px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setOpenDrawer(false);
                    // setEditingMember(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    marginLeft: "20px",
                    width: "120px",
                    color: "#ffffff",
                    textTransform: "none",
                  }}
                  onClick={!loading ?  handleSubmit : null}
                >
                  Save&nbsp;
                  {loading && (
                    <CircularProgress size={18} style={{ color: "white" }} />
                  )}
                </Button>
              </Grid>
              <Grid
                container
                justifyContent="flex-center"
                sx={{ display: "flex", alignItems: "center" }}
                xs={12}
                marginTop={4}
              >
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  Add a Family Member
                </Typography>
                <Grid container spacing={2}>
                  {FamilyFields?.slice(0, 10).map((inputData, index) => (
                    <Grid
                      item
                      xs={6}
                      lg={6}
                      sm={12}
                      key={index}
                    >
                      {/* {inputData.fieldName !== "relation" ? ( */}
                      <Input data={inputData} />
                      {/* )
                          :(  <SelectDropdown data={inputData}></SelectDropdown>)} */}
                      <ErrorMessage
                        name={inputData.fieldName}
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                  ))}

                  <Grid item xs={6} sm={12} lg={6} paddingBottom={1}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      Profile Picture
                    </Typography>
                    <DragAndDrop isProvider={false} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddFamilyMember;
