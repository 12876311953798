import { Box, Button, Grid, Typography, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { CustomScrollbars } from "../../../../../../../../../ui-component/ScrollBar";
import { Card } from "@mui/material";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { Textarea } from "../../../../../../../../../ccm-constant";
import { CarePlanApi } from "../../../../../../../../../service/CarePlan/careAPI";
import { useNotification } from "../../../../../../../../../hooks/use-notification";

const DynamicCard = ({
  title,
  name,
  isOpen,
  toggleCard,
  values,
  handleChange,
}) => {
  return (
    <Card sx={{ ...cardStyle }}>
      <Grid container xs={12} padding={2}>
        <Grid item xs={6}>
          <Typography variant="subtitle1" alignContent={"center"}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign={"end"}>
          {!isOpen ? (
            <Button
              variant="text"
              style={{
                textAlign: "end",
                textTransform: "none",
                width: "30px",
                height: "30px",
              }}
              onClick={toggleCard}
            >
              <Typography variant="h6">+</Typography>
            </Button>
          ) : null}
        </Grid>
        {isOpen && (
          <Grid item xs={12}>
            <Box width={"100%"}>
              <Textarea
                minRows={5}
                style={{
                  resize: 'unset',
                  width: "96.5%"
                }}
                width={"100%"}
                // style={{ width: "96.5%" }}
                aria-label={title}
                placeholder="Enter Note"
                name={name}
                value={values[name]} // Bind the value to the corresponding field
                onChange={handleChange} // Bind the onChange handler
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

function CarePlanReviewForm({ handleClose, carePlanId, getAllData}) {
  console.log("carePlanId", carePlanId);
  const [isTabOpen, setIsTabOpen] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [loading, setLoading] = useState(false); 
  const { notifySuccess, notifyError } = useNotification();

  const toggleCard = (index) => {
    const newIsTabOpen = isTabOpen.map((_, i) =>
      i === index ? !isTabOpen[i] : false
    );
    setIsTabOpen(newIsTabOpen);
  };

  const dynamicCards = [
    { title: "Goals", name: "goals" },
    { title: "Problems/ Symptoms", name: "problemsOrSymptoms" },
    { title: "Barriers", name: "barriers" },
    { title: "Numbers To Track", name: "numberOfTrack" },
    { title: "Expected Outcomes", name: "expectedOutcomes" },
    { title: "Resources/ Support", name: "resourcesOrSupport" },
    { title: "Interventions", name: "interventions" },
    { title: "Allergies", name: "allergies" },
    { title: "Medications", name: "medications" },
  ];

  return (
    <CustomScrollbars height={"98vh"}>
      <Box paddingLeft={1} paddingRight={1}>
        <Formik
          initialValues={{
            goals: "",
            problemsOrSymptoms: "",
            barriers: "",
            numberOfTrack: "",
            expectedOutcomes: "",
            resourcesOrSupport: "",
            interventions: "",
            allergies: "",
            medications: "",
          }}
          onSubmit={(values) => {
            console.log("values!!", values);
            const careNotes = [
              {
                category: "goals",
                note: values.goals,
              },
              {
                category: "barrier",
                note: values.barriers,
              },
              {
                category: "symptom",
                note: values.problemsOrSymptoms,
              },
              {
                category: "interventions",
                note: values.interventions,
              },
              {
                category: "support",
                note: values.resourcesOrSupport,
              },
              {
                category: "allergies",
                note: values.allergies,
              },
              {
                category: "medications",
                note: values.medications,
              },
              {
                category: "numbers_to_track",
                note: values.numberOfTrack,
              },
              {
                category: "expected_outcomes",
                note: values.expectedOutcomes,
              },

            ];

            const body = [];

            for (const note of careNotes) {
              if (note.note !== null && note.note !== "") {
                body.push({
                  category: note.category,
                  note: note.note,
                });
              }
            }


            CarePlanApi.updateCarePlanNotes(carePlanId, body)
              .then((response) => {
                handleClose();
                // setData(response.data);
                setLoading(true);
                notifySuccess("Notes Added Successfully")

              })
              .catch((error) => {
                console.error("Error fetching user data:", error);
                notifyError(error?.response?.data?.error);
                setLoading(false);
              });
          }}
        >
          {(
            { handleSubmit, values, handleChange } // Pass values and handleChange
          ) => (
            <Form>
              <Grid container xs={12} marginBottom={3} marginTop={3}>
                <Grid item xs={6}>
                  <Typography variant="h6">Add Note</Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ textTransform: "none", width: "130px" }}
                    onClick={handleClose}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      width: "130px",
                      marginLeft: "10px",
                    }}
                    onClick={handleSubmit}
                  >
                    Save
                    {loading && (
                    <CircularProgress size={18} style={{ color: "white" }} />
                  )}
                  </Button>
                </Grid>
              </Grid>

              {dynamicCards.map((card, index) => (
                <Grid container xs={12} marginBottom={3} key={index}>
                  <Grid item xs={12}>
                    <DynamicCard
                      title={card.title}
                      name={card.name}
                      isOpen={isTabOpen[index]}
                      toggleCard={() => toggleCard(index)}
                      values={values} // Pass values and handleChange
                      handleChange={handleChange}
                    />
                  </Grid>
                </Grid>
              ))}
            </Form>
          )}
        </Formik>
      </Box>
    </CustomScrollbars>
  );
}

export default CarePlanReviewForm;
