import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  CardActions,
  Card,
  CardContent,
} from "@mui/material";
import { customDataTableStyles } from "../../../../ccm-constant";
import { IconArrowsSort } from "@tabler/icons-react";
import CustomPagination from "../../../../ui-component/Pagination";
import { SettingsApi } from "../../../../service/Settings/Index";
import CircularLoader from "../../../../ui-component/CircularLoader";
import { CustomScrollbars } from "../../../../ui-component/ScrollBar";
import { formatDate } from "../../../../ccm-constant";

const MyDataTable = ({ onStartDate, onEndDate, auditEvent, dataFor }) => {
  console.log("auditEvent", auditEvent);
  console.log("onStartDate, onEndDate", onStartDate, onStartDate);
  const columns = [
    // {
    //   name: "Date & Time",
    //   selector: (row) => `${formatDate(row?.timestamp, true)}`,
    //   sortable: true,
    //   width: "25%",
      // cell: (row) => (<>
      //     {formatDate(row.timestamp)}
      //     </>
      // ),
    // },
    // {
    //   name: "Event",
    //   selector: (row)=> row?.action,
    //   // sortable: true,
    //   width: "15%",
    //   cell: (row) => (
    //     <div style={{ display: "flex", alignItems: "center" }}>
    //       {toTitleCase(row.action)}
    //     </div>
    //   ),
    // },
    {
      name: "Log In",
      selector: (row)=> row?.action,
      sortable: true,
      width: "20%",
      cell: (row) => (<>
        {formatDate(row.log_in, true)}
        </>
      ),
    },
    {
      name: "Log Out",
      selector: (row)=> row?.action,
      sortable: true,
      width: "20%",
      cell: (row) => (<>
        {formatDate(row.log_out, true)}
        </>
      ),
    },
    {
      name: "Description",
      selector: (row) => `${row?.description}`,
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.description !== undefined
            ? row.description
            : "-"}
        </div>
      ),
      sortable: true,
      width: "20%",
    },
    {
      name: "User IP Address",
      selector: (row)=> row?.ip_address,
      // sortable: true,
      width: "15%",
    },
    {
      name: "User",
      selector: (row) => row?.user_name,
      sortable: true,
      width: "25%",
      cell: (row) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {row.user_name !== undefined
            ? row?.user_name
            : "-"}
        </div>
      ),
    },
  ];
  const [auditLog, setAuditLog] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  console.log("currentPage", setCurrentPage)

  // function formatDate(inputDate) {
  //   if (inputDate) {
  //     const date = new Date(inputDate);

  //     const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  //     const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  //     const day = date.getDate().toString().padStart(2, '0');
  //     const month = months[date.getMonth()];
  //     const year = date.getFullYear();
  //     const hours = date.getUTCHours().toString().padStart(2, '0');
  //     const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  //     const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  //     // Calculate the time zone offset in hours and minutes
  //     const timeZoneOffset = -date.getTimezoneOffset();
  //     const timeZoneHours = Math.floor(timeZoneOffset / 60);
  //     const timeZoneMinutes = timeZoneOffset % 60;

  //     // Construct the time zone string
  //     const timeZone = (timeZoneHours >= 0 ? '+' : '-') +
  //       timeZoneHours.toString().padStart(2, '0') +
  //       timeZoneMinutes.toString().padStart(2, '0');

  //     return `${weekdays[date.getUTCDay()]} ${month} ${day} ${year} ${hours}:${minutes}:${seconds} GMT${timeZone}`;
  //   } else {
  //     return "-";
  //   }
  // }

  function toTitleCase(str) {
    return str
      ?.toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }


  const handleSort = (column, sortDirection) => {
    console.log("column", column);
    setSortDirection(sortDirection);
    switch (column.name) {
      case "Log In":
        setSortDirection(sortDirection == "desc" ? "-log_in" : "log_in");
        break;
      case "Log Out":
        setSortDirection(sortDirection == "desc" ? "-log_out" : "log_out");
        break;
      case "Description":
        setSortDirection(sortDirection == "desc" ? "-description" : "description");
        break;
      case "User":
        setSortDirection(sortDirection == "desc" ? "-user_name" : "user_name");
        break;
      default:
        setSortDirection("id");
    }
  };
  const type = "audit_event"
  
  useEffect(() => {
    console.log(
      currentPage,
      sortDirection,
      sortedColumn,
      onEndDate,
      onStartDate,
      type
    );
    setLoading(true);
    SettingsApi.auditLog(
      currentPage === 0 ? currentPage : (currentPage - 1) * 10,
      sortDirection,
      sortedColumn,
      onEndDate,
      onStartDate,
      // auditEvent ? type : null,
      dataFor
    )
      .then((response) => {
        console.log("auditLog", response);
        setAuditLog(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    // if (currentPage || sortDirection || sortedColumn || auditEvent || onStartDate && onEndDate) {
    //   if (onStartDate !== null && onEndDate !== null && onStartDate !=="NaN-NaN-NaN" && onEndDate !== "NaN-NaN-NaN") {
    //     setCurrentPage(0);
    //     fetchAuditLog();
    //   } else {
    //     fetchAuditLog();
    //   }
    // }
    return () => {
      // setAuditLog([]);
    };
  }, [currentPage, sortDirection, sortedColumn, auditEvent]);

  useEffect(() => {
    console.log("onStartDate", onStartDate, onEndDate);
    if (onStartDate !== null && onEndDate !== null)
    {
      if (
      (onStartDate !== "NaN-NaN-NaN" && onEndDate !== "NaN-NaN-NaN") ||
      (onStartDate === "null" && onEndDate === "null")
      ) {
      setCurrentPage(0)
      fetchAuditLog();
      console.log("fetch4")
    }
    else if (onStartDate === "NaN-NaN-NaN" && onEndDate === "NaN-NaN-NaN") {
        setCurrentPage(0)
      fetchAuditLog();
      console.log("fetch5")
    }}
  }, [onStartDate, onEndDate]);

  const fetchAuditLog = () => {
    setLoading(true);
    SettingsApi.auditLog(
      currentPage === 0 ? currentPage : (currentPage - 1) * 10,
      sortDirection,
      sortedColumn,
      onEndDate,
      onStartDate,
      auditEvent ? type : null,
      dataFor
    )
      .then((response) => {
        console.log("auditLog", response);
        setAuditLog(response);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }

  return (
    <>
      <Grid item xs={12} sx={{ height: "75vh", position: "relative" }}>
        <Card sx={{ height: "75vh", position: "relative" }}>
          <CardContent>
            <Grid
              container
              xs={12}
              padding={1}
              sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
            >
              <CustomScrollbars height="58vh">
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!loading ? (
                    <DataTable
                      columns={columns}
                      data={auditLog?.data}
                      sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                      sortServer
                      onSort={handleSort}
                      sortColumn={sortedColumn}
                      sortDirection={sortDirection}
                      highlightOnHover
                      pointerOnHover
                      customStyles={customDataTableStyles}
                    />
                  ) : (
                    <CircularProgress
                      color="primary"
                      sx={{ marginTop: "15%" }}
                    />
                  )}
                </Grid>
              </CustomScrollbars>
            </Grid>
          </CardContent>
          <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}>
            {auditLog?.count >= 1 && (
              <Grid
                item
                xs={12}
                marginTop={2}
              // display="flex"
              // alignItems="center"
              // justifyContent="space-between"
              >
                <CustomPagination
                  count={auditLog?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </Grid>
            )}
          </CardActions>
        </Card>
      </Grid>
    </>
  );
};

function AuditLog({ onStartDate, onEndDate, auditEvent }) {
  return <MyDataTable onStartDate={onStartDate} onEndDate={onEndDate} auditEvent={auditEvent} />;
}

export default AuditLog;
