import config from "../../../config";
import httpClient from "../../base"

const BACKEND_BASE_URL = config.BACKEND_BASE_URL;

export const ProvidersApi = {

 addUser: (payload) =>
 httpClient.post(`${BACKEND_BASE_URL}users/api/v1/provider`, payload),
 
 getUser: (id, query) =>
 httpClient.get(`${BACKEND_BASE_URL}users/api/v1/provider_group/${id}/provider`,{...query}),

 updateUser: (id, payload) =>
 httpClient.put(`${BACKEND_BASE_URL}users/api/v1/provider/${id}`, payload),
 
 deleteUser: (id) =>
 httpClient.delete(`${BACKEND_BASE_URL}users/api/v1/provider/${id}`),


 AddProviderImage: (uuid, payload) =>
 httpClient.put(`${BACKEND_BASE_URL}users/api/v1/provider/pic/${uuid}`, payload,{}, Headers={
   "Content-Type": "multipart/form-data",
 }),
};