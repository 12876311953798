import React, { useEffect, useState } from "react";
import cardStyle from "../theme/card-layout";
import { Field, useFormikContext } from "formik";
import { Autocomplete, MenuItem, TextField, Typography } from "@mui/material";

function SelectDropdown({ data, disabled }) {
  const { values, setFieldValue } = useFormikContext();
  const {
    heading,
    label,
    fieldName,
    fieldType,
    options,
    placeholder,
    fieldValue,
    disable,
    country,
    phoneValue,
    defaultData,
  } = data;

  // Define your dropdown options
  const dropdownOptions = options || [];

  useEffect(() => {
    if (!values[fieldName]) {
      setFieldValue(fieldName, "")
    }
  }, [values])


  return (
    <>
      {heading && (
        <Typography
          variant="h6"
          sx={{
            marginBottom: "20px",
          }}
        >
          {heading}
        </Typography>
      )}
      {label && (
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: "10px",
          }}
        >
          {label}
        </Typography>
      )}
      <Field name={fieldName} variant="outlined" fullWidth>
        {({ field }) => (
          <Autocomplete
            {...field}
            disablePortal
            id="combo-box-demo"
            options={dropdownOptions}
            sx={cardStyle}
            disabled={disabled || disable}
            getOptionLabel={(option) => option?.label || option} // Use the label property for display
            renderInput={(params) => (
              <TextField
                {...params}
                {...field} // Pass the field props to the input
                placeholder={`${placeholder ? placeholder : label ? label : fieldName
                  }`}
                onChange={(e, newValue) => { }}
              />
            )}
            onChange={(e, newValue) => {
              // Set the value to the name property
              setFieldValue(fieldName, newValue);
            }}
          />
        )}
      </Field>
    </>
  );
}

export default SelectDropdown;
