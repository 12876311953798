// BreadcrumbSlices.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    routes: [],
};
// Create an async thunk for setAuthRoutes
export const setAuthRoutesAsync = createAsyncThunk(
  'breadcrumb/setAuthRoutes',
  async (payload, thunkAPI) => {
    console.log("routrrrrrrrrrrrrrrrrrrr", payload)
    // You can perform asynchronous operations here if needed
    return payload;
  }
);
const BreadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState: {
    routes: [],
  },
  reducers: {
    setAuthRoutes: (state, action) => {
      console.log("setAuthRoutes createSlice",state, state.routes , action)
      // state.routes = action.payload;
      // return { ...state, routes: action.payload };
      // return {
      //   ...state,
      //   routes: action.payload,
      // };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setAuthRoutesAsync.fulfilled, (state, action) => {
      console.log("routrrrrrrrrrrrrrrrrrrr", state, action)
      state.routes = action.payload;
    });
  },
});


// export const { setAuthRoutes, closeDrawer, toggleDrawer } = BreadcrumbSlices.actions;
export const { setAuthRoutes } = BreadcrumbSlice.actions;
console.log("BreadcrumbSlices.actions",BreadcrumbSlice.actions)

export const selectBreadcrumb = (state) => state.breadcrumb.routes;
// export const breadCrumbChange = (state) => state.breadcrumb.routes;
export default BreadcrumbSlice.reducer;

