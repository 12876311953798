import config from "../../config";
import httpClient from "../base";

const BACKEND_BASE_URL = config.BACKEND_BASE_URL;


export const AssesmentApi = {

  createAssesment: (payload) =>
  httpClient.post(`${BACKEND_BASE_URL}assessments/api/v1/assessment`, payload),
  
  getList: (id, payload) =>
  httpClient.get(`${BACKEND_BASE_URL}assessments/api/v1/assessment/provider_group/${id}`, payload),

  getConditions: () =>
    httpClient.get(`${BACKEND_BASE_URL}patients/api/v1/icd_code`),
  
  getQuestions: (id) =>
    httpClient.get(`${BACKEND_BASE_URL}assessments/api/v1/assessment/${id}`),
  
  updateAssesment: (id, payload) =>
    httpClient.put(`${BACKEND_BASE_URL}assessments/api/v1/assessment/${id}`, payload),
  
  getAssessment: (id) =>
  httpClient.get(`${BACKEND_BASE_URL}assessments/api/v1/assessment/${id}`),
  // assessments/api/v1/assessment/a86a72e2-9bdc-486a-9336-7baedb1d9562


  VerifyAssesmentTitle: (title) =>
    httpClient.get(`${BACKEND_BASE_URL}assessments/api/v1/assessment/title`, { title: title }),
  
  getAssessmentById: (id,payload) =>
    httpClient.get(`${BACKEND_BASE_URL}assessments/api/v1/assessment/patients/${id}`,payload),
  
  assignAssessment: (ass_id, patient_id) =>
    httpClient.post(`${BACKEND_BASE_URL}assessments/api/v1/assessment/${ass_id}/assign/patient/${patient_id}`),
  
  unassignAssessment: (ass_id, patient_id) =>
    httpClient.post(`${BACKEND_BASE_URL}assessments/api/v1/assessment/${ass_id}/unassign/patient/${patient_id}`),
  
  getAssessmentNotReviewd: (id) =>
    httpClient.get(`${BACKEND_BASE_URL}assessments/api/v1/assessment/${id}`),
  
  getAssessmentReviewed: (ass_id, patient_id) =>
    httpClient.get(`${BACKEND_BASE_URL}assessments/api/v1/assessment/${ass_id}/patient/${patient_id}/get_answers`),
  
  reviewAssessment: (ass_id, patient_id, payload) =>
    httpClient.post(`${BACKEND_BASE_URL}assessments/api/v1/assessment/${ass_id}/patient/${patient_id}/submit_answers`, payload, {}, Headers = {
      "Content-Type": "multipart/form-data",
    }),
}
