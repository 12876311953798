export function singleDateConverter(utcDateString) {
  const utcDate = new Date(utcDateString);
  const localYear = utcDate.getFullYear();
  const localMonth = utcDate.getMonth() + 1; // Months are zero-based
  const localDay = utcDate.getDate();
  const localDateString = `${localDay}/${localMonth}/${localYear}`;
  return localDateString;
}

export function convertToBrowserLocalTime(timestamp) {
  const dateObject = new Date(timestamp);
  // Ensure that hour12 is set to true to display AM/PM
  const localTime = dateObject.toLocaleTimeString(undefined, { hour12: true });
  console.log("localTime", localTime);
  return localTime;
}

