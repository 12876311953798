import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  IconButton,
  InputAdornment,
  List,
  Paper,
  TextField,
} from "@mui/material";
import FieldWithVisibilityToggle from "./FieldWithVisibilityToggle";
import cardStyle from "../../../../theme/card-layout";
import { AuthApis } from "../../../../service/auth";
import { useNotification } from "../../../../hooks/use-notification";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { AuthActions } from "../../../../contexts/auth";
import { Navigate, useNavigate } from "react-router";

const validRequirementStyle = {
  color: "green", // Change to your desired color for valid requirements
};

const isRequirementMet = (requirementName, yourPassword) => {
  // Create a regular expression for each condition
  const regexPatterns = {
    minLength: /^.{8,}$/, // Minimum 8 characters long
    hasLowerCase: /[a-z]/, // At least one lowercase character
    hasUpperCase: /[A-Z]/, // At least one uppercase character
    hasNumber: /[0-9]/, // At least one number
    hasSymbol: /[@#$%^&+=]/, // At least one symbol
  };

  // Check the specified requirement
  switch (requirementName) {
    case "Minimum 8 characters long - the more, the better":
      return regexPatterns.minLength.test(yourPassword); // Replace yourPassword with the actual password to test
    case "At least one lowercase character":
      return regexPatterns.hasLowerCase.test(yourPassword); // Replace yourPassword with the actual password to test
    case "At least one uppercase character":
      return regexPatterns.hasUpperCase.test(yourPassword); // Replace yourPassword with the actual password to test
    case "At least one number":
      return regexPatterns.hasNumber.test(yourPassword); // Replace yourPassword with the actual password to test
    case "At least one symbol":
      return regexPatterns.hasSymbol.test(yourPassword); // Replace "yourPassword" with the actual password to test
    default:
      // Handle other requirements or throw an error for unknown requirements
      // throw new Error(`Unknown requirement: ${requirementName}`);
      return null;
  }
};

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required("Current Password is required"),
  password: Yup.string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /^(?=.*[a-z])/,
      "Password must contain at least one lowercase letter"
    )
    .matches(
      /^(?=.*[A-Z])/,
      "Password must contain at least one uppercase letter"
    )
    .matches(/^(?=.*\d)/, "Password must contain at least one number")
    .matches(/^(?=.*[@#$%^&+=])/, "Password must contain at least one symbol"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

function ChangeMyPassword(props) {
  const [showPasswordFields, setShowPasswordFields] = useState({
    old_password: false,
    password: false,
    confirm_password: false,
  });
  const { notifySuccess, notifyError } = useNotification();
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const PasswordData = [
    {
      name: "old_password",
      lable: "Current Password",
    },
    {
      name: "password",
      lable: "New Password",
    },
    {
      name: "confirm_password",
      lable: "Confirm Password",
    },
  ];

  const PasswordRequirements = [
    { lable: "Minimum 8 characters long - the more, the better" },
    { lable: "At least one lowercase character" },
    { lable: "At least one uppercase character" },
    { lable: "At least one number" },
    { lable: "At least one symbol" },
  ];

  const handleToggleVisibility = (field) => {
    setShowPasswordFields((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleSaveClick = (values) => {
    const formData = {
      old_password: values.old_password,
      password: values.password,
      confirm_password: values.confirm_password,
    };

    const areRequirementsFulfilled = PasswordRequirements.every((requirement) =>
      isRequirementMet(requirement?.lable, values?.password)
    );
    if (areRequirementsFulfilled) {
      console.log("formData", formData);
      AuthApis.changePassword(
        JSON.parse(localStorage.getItem("user")).tokens,
        formData
      )
        .then((res) => {
          props.handleClose();
          notifySuccess(res?.data?.message);
          dispatch({ type: AuthActions.clearUser });
          navigate("/signin");
        })
        .catch((err) => {
          notifyError(err?.response?.data?.error);
        });
    }
  };

  return (
    <Grid container spacing={1} padding={2} justifyContent="center">
      <Formik
        initialValues={{
          old_password: "",
          password: "",
          confirm_password: "",
        }}
        onSubmit={(e) => handleSaveClick(e)}
        // innerRef={formRef}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, handleSubmit, isValid }) => (
          <>
            <Grid
              container
              xs={12}
              sx={{ flex: 1, alignItems: "center", marginBottom: "30px" }}
            >
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="outlined"
                  style={{
                    marginRight: "8px",
                    textTransform: "none",
                    width: "163px",
                  }}
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginRight: "8px",
                    textTransform: "none",
                    width: "163px",
                  }}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Grid>
            </Grid>

            <Grid container xs={12} sx={{ flex: 1, alignItems: "top" }}>
              <Grid item xs={12} overflow="hidden">
                <Typography
                  variant="h6"
                  component="div"
                  xs={12}
                  sx={{ marginBottom: "5px" }}
                >
                  Change Password
                </Typography>
              </Grid>

              <Grid container spacing={2} xs={12}>
                <Grid item xs={12} md={10} lg={8} sm={12}>
                  <Form>
                    <Grid item xs={12} md={10} lg={8} sm={12} marginBottom={1}>
                      {PasswordData?.map((field) => (
                        <>
                          <Grid key={field.name} item>
                            <Typography
                              variant="subtitle1"
                              htmlFor={field.name}
                              sx={{
                                fontSize: "18px",
                                marginBottom: "3px",
                                fontWeight: "500",
                              }}
                            >
                              {field.lable}
                            </Typography>
                            <Paper
                              sx={{
                                marginBottom: 1,
                                boxShadow: "3",
                                border: "0",
                              }}
                            >
                              <Field
                                as={TextField}
                                fullWidth
                                size="small"
                                name={field.name}
                                type={
                                  showPasswordFields[field.name]
                                    ? "text"
                                    : "password"
                                }
                                onChange={handleChange}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() =>
                                          handleToggleVisibility(field.name)
                                        }
                                        onMouseDown={(e) => e.preventDefault()}
                                      >
                                        {showPasswordFields[field.name] ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Paper>
                            <ErrorMessage
                              name={field.name}
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "15px",
                                marginBottom: 1,
                              }}
                            />
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Form>
                </Grid>

                <Grid container xs={12} sx={1} padding={2}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontSize: "18px",
                      marginBottom: "10px",
                      fontWeight: "500",
                    }}
                  >
                    Password Requirements :
                  </Typography>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: "18px",
                        fontWeight: "400",
                      }}
                    >
                      Ensure that this requirement are met:
                    </Typography>
                  </Grid>

                  <List>
                    {PasswordRequirements.map((field) => {
                      const isMet = isRequirementMet(
                        field.lable,
                        values.password
                      );
                      return (
                        <>
                          <Typography
                            key={field.lable}
                            variant="body1"
                            sx={{
                              marginBottom: "5px",
                              fontSize: "16px",
                              ...(isMet ? validRequirementStyle : ""),
                            }}
                          >
                            {field.lable}
                          </Typography>
                        </>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Formik>
    </Grid>
  );
}

export default ChangeMyPassword;
