import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Card,
  Grid,
  Checkbox,
  Typography,
  Avatar,
  TextField,
  Button,
  Select,
  CircularProgress,
  TextareaAutosize,
  Box,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import cardStyle from "../../../../../../../../theme/card-layout";
import Input from "../../../Input";
import { CustomScrollbars } from "../../../../../../../../ui-component/ScrollBar";
import { useNotification } from "../../../../../../../../hooks/use-notification";
import { PatientApi } from "../../../../../../../../service/ProviderGroup/Patient";
import { useTheme } from "@mui/material/styles";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import avatar from "../../../../../../../../assets/adminAvatar.jpg";
import { DatePicker } from "../../../../../../../../ui-component/DatePicker/DatePicker";
import {
  formatDate,
  genderAtBirth,
  genderIdentity,
  sexualOrientation,
  raceOptions,
  ethinicityOptions,
  languageOptions,
  formatDOB,
} from "../../../../../../../../ccm-constant";
import PhoneNumber from "../../../../../../../../ui-component/PhoneNumber";
import { ProvidersApi } from "../../../../../../../../service/ProviderGroup/Providers";
import SelectDropdown from "../../../../../../../../ui-component/SelectDropdown";
import CheckPermission from "../../../../../../../../ui-component/ButtonWrapper";

const consent = [
  { label: "Consent To Message", name: "is_consent_to_message" },
  { label: "Consent To Call", name: "is_consent_to_call" },
  { label: "Consent To Mail", name: "is_consent_to_email" },
];

function PatientsProfile({ patientDatas, setRefreshPatientData, refreshPatientData }) {
  console.log("Patientdatanew2", patientDatas)
  const { id, memberId } = useParams();
  const theme = useTheme();
  const [patientData, setpatientData] = useState(patientDatas)
  console.log("Patientdatanew", patientData)
  // const provider_id = JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group?.uuid || JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
  // const id = JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
  const { notifySuccess, notifyError } = useNotification();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(patientData?.profile_pic);
  const [imageLoading, setImageLoading] = useState(true);
  const userRole = JSON.parse(localStorage.getItem("user") || "{}");
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;
  console.log("ProviderRole", ProviderRole);
  const [onSetDate, setOnSetDate] = useState(patientData?.date_of_birth);
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId =
    userType === "provider_group"
      ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
      : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
        ?.uuid;
  console.log("DATAP", onSetDate);

  const [fields, setFields] = useState([
    {
      label: "First Name",
      fieldName: "first_name",
      fieldType: TextField,
    },
    {
      label: "Last Name",
      fieldName: "last_name",
      fieldType: TextField,
    },
    {
      label: "Middle Name",
      fieldName: "middle_name",
      fieldType: TextField,
    },
    {
      label: "Date of Birth",
      fieldName: "date_of_birth",
      fieldType: TextField,
    },
    {
      label: "SSN",
      fieldName: "ssn",
      fieldType: TextField,
    },
    {
      label: "Gender At Birth",
      fieldName: "gender_at_birth",
      options: genderAtBirth,
      fieldType: Select,
    },
    {
      label: "Gender Identity",
      fieldName: "gender_identity",
      options: genderIdentity,
      fieldType: Select,
    },
    {
      label: "Sexual Orientation",
      fieldName: "sexual_orientation",
      options: sexualOrientation,
      fieldType: Select,
    },
    {
      label: "Email ID",
      fieldName: "email",
      fieldType: TextField,
      // disable: true,
    },
    {
      label: "Medical Record Number (MRN)",
      fieldName: "medical_record_number",
      fieldType: TextField,
    },
    {
      label: "Home Phone Number",
      fieldName: "home_phone_number",
      // fieldType: TextField,
      placeholder: "Enter Phone Number",
    },
    {
      label: "Cell Phone Number",
      fieldName: "cell_phone_number",
      // fieldType: TextField,
      placeholder: "Enter Phone Number",
    },
    {
      label: "Preferred Languages",
      fieldName: "language",
      options: languageOptions,
      fieldType: Select,
    },
    {
      label: "Race",
      fieldName: "race",
      options: raceOptions,
      fieldType: Select,
    },
    {
      label: "Ethnicity",
      fieldName: "ethinicity",
      options: ethinicityOptions,
      fieldType: Select,
    },
    {
      // heading: "First Name",
      label: "Marital Status",
      fieldName: "marital_status",
      fieldType: Select,
      options: [
        {
          label: "Annulled",
          name: "Annulled",
        },
        {
          label: "Common law",
          name: "Common law",
        },
        {
          label: "Divorced",
          name: "Divorced",
        },
        {
          label: "Domestic partner",
          name: "Domestic partner",
        },
        {
          label: "Interlocutory",
          name: "Interlocutory",
        },
        {
          label: "Legally Separated",
          name: "Legally Separated",
        },
        {
          label: "Living together",
          name: "Living together",
        },
        {
          label: "Married",
          name: "Married",
        },
        {
          label: "Other",
          name: "Other",
        },
        {
          label: "Registered domestic partner",
          name: "Registered domestic partner",
        },
        {
          label: "Separated",
          name: "Separated",
        },
        {
          label: "Single",
          name: "Single",
        },
        {
          label: "Unmarried",
          name: "Unmarried",
        },
        {
          label: "Unreported",
          name: "Unreported",
        },
        {
          label: "Unknown",
          name: "Unknown",
        },
        {
          label: "Widowed",
          name: "Widowed",
        },
      ],
    },
    {
      label: "Address Line 1",
      fieldName: "address_line_1",
      fieldType: TextField,
    },
    {
      label: "Address Line 2",
      fieldName: "address_line_2",
      fieldType: TextField,
    },
    {
      label: "City",
      fieldName: "city",
      fieldType: TextField,
    },
    {
      label: "State",
      fieldName: "state",
      fieldType: TextField,
    },
    {
      label: "Country",
      fieldName: "country",
      fieldType: TextField,
    },
    {
      label: "Zip Code",
      fieldName: "zip_code",
      fieldType: TextField,
    },
    {
      label: "Insurance Name",
      fieldName: "insurance_name",
      fieldType: TextField,
    },
    {
      label: "Holder Name",
      fieldName: "holder_name",
      fieldType: TextField,
    },
    {
      label: "Relationship To Insured",
      fieldName: "relation",
      fieldType: Select,
      options: [
        {
          label: "self",
          name: "self",
        },
        {
          label: "spouse",
          name: "spouse",
        },
        {
          label: "other",
          name: "other",
        },
        {
          label: "child",
          name: "child",
        },
        {
          label: "life_partner",
          name: "life_partner",
        },
        {
          label: "employer",
          name: "employer",
        },
        {
          label: "father",
          name: "father",
        },
        {
          label: "grandparent",
          name: "grandparent",
        },
        {
          label: "mother",
          name: "mother",
        },
        {
          label: "unknown",
          name: "unknown",
        },
        {
          label: "sibling",
          name: "sibling",
        },
        {
          label: "parent",
          name: "parent",
        },
        {
          label: "grandchild",
          name: "grandchild",
        },
        {
          label: "guardian",
          name: "guardian",
        },
      ],
    },
    {
      label: "Insurance Company/Number",
      fieldName: "insurance_number",
      fieldType: TextField,
    },
    {
      label: "Insurance ID Number",
      fieldName: "insurance_id",
      fieldType: TextField,
    },
    {
      label: "Insurance Group Name",
      fieldName: "insured_group_name",
      fieldType: TextField,
    },
    {
      label: "Employer/School Name",
      fieldName: "employer",
      fieldType: TextField,
    },
    {
      label: "Insured Plan",
      fieldName: "insured_plan",
      fieldType: TextField,
    },
    {
      heading: "Provider",
      label: "Usual Provider",
      fieldName: "provider",
      fieldType: Select,
      options: [],
      disable: (userRole === "provider_group" || ProviderRole === "Physician") ? false : true,
    },
    {
      label: "Notes",
      fieldName: "notes",
      fieldType: TextField,
    },
    {
      label: "Consent Medium",
      fieldName: "consent_medium",
      fieldType: TextField,
    },
    {
      label: "Pulled From EHR",
      fieldName: "is_pulled_from_ehr",
      fieldType: TextField,
    },
  ])

  useEffect(() => {
    setpatientData(patientDatas)
  }, [patientDatas])

  useEffect(() => {
    if (editMode)
      getAllProvidersData();
    else {
      setpatientData(patientData)
    }
  }, [editMode, patientData]);

  useEffect(() => {
    // setpatientData(patientData)
    if (patientData?.date_of_birth) {
      const date = new Date(patientData?.date_of_birth);
      if (date) {
        setOnSetDate(
          `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
        );
      }
    }
  }, [patientDatas, patientData]);

  const getAllProvidersData = () => {
    let MapProviderData = [];
    let mapData = [];
    ProvidersApi.getUser(ProviderId, { all: true, order_by: "asc", sort_by: "first_name", search_by: "Physician", search_on: "role" })
      .then((response) => {
        response?.data?.forEach((element) => {
          console.log("first1", element);
          MapProviderData.push({
            name: element.uuid,
            label: `${element?.first_name} ${element?.last_name} (${element?.provider_type_initials})`,
          });
        });
        if (MapProviderData?.length)
          fields?.map((ele) => {
            if (ele?.fieldName === "provider") {
              mapData?.push({
                heading: "Provider",
                label: "Usual Provider",
                fieldName: "provider",
                fieldType: Select,
                options: MapProviderData,
              });
            } else {
              mapData?.push(ele);
            }
          });
        if (mapData?.length >= 1) setFields(mapData);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    // middle_name: Yup.string().required("Middle Name is required"),
    date_of_birth: Yup.string().required("Date of Birth is required"),
    gender_at_birth: Yup.string().required("Gender At Birth is required"),
    // phone_number: Yup.string()
    //   .matches(/^[\d\s()+-]+$/, "Invalid Phone number")
    //   .min(10, "Phone number is too short")
    //   .max(20, "Phone number is too long")
    //   .required("Phone number is required"),
    email: Yup.string()
      .email("Invalid Email ID address"),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    // address_line_2: Yup.string(),
    // country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    zip_code: Yup.string().required("Zip Code is required"),
    // insurance_name: Yup.string().required("Insurance Name is required"),
    // holder_name: Yup.string().required("Holder Name is required"),
    // relation: Yup.string().required("Relationship To Insured is required"),
    // insurance_number: Yup.string().required(
    //   "Insurance Company/Number is required"
    // ),
    // insurance_id: Yup.string().required("Insurance Id Number is required"),
    // insured_group_name: Yup.string().required(
    //   "Insurance Group Name is required"
    // ),
    // employer: Yup.string().required("Employer/School Name is required"),
    // insured_plan: Yup.string().required("Insured Plan is required"),
    // bio: Yup.string().required("Bio is required"),
  });

  // const validateField = (values) => {
  //   const errors = {};
  //   if (values?.home_phone_number === "" && values?.cell_phone_number === "") {
  //       const phoneSchema = Yup.string()
  //         .matches(/^[\d\s()+-]+$/, "Invalid Phone number")
  //         .min(10, "Phone Number is too short")
  //         .max(20, "Phone Number is too long")
  //         .required("Phone Number is required");
  //         if(values.home_phone_number){
  //       try {
  //         phoneSchema.validateSync(values.home_phone_number);
  //       } catch (error) {
  //         errors.home_phone_number = error.message;
  //       }}
  //       else {
  //       try {
  //         phoneSchema.validateSync(values.cell_phone_number);
  //       } catch (error) {
  //         errors.cell_phone_number = error.message;
  //       }
  //     }
  //     // notifyError("Please enter the one of any phone number")
  // }

  //   return errors;
  // };

  const isSupportedFileType = (fileType) => {
    const supportedFormats = ["jpeg", "jpg", "png", "gif", "bmp"];
    return supportedFormats.some((format) => fileType.endsWith(format));
  };

  const isURL = (url) => {
    try {
      if (url.includes("https://")) {
        // new URL(url);
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  };

  const handleSSNChange = (event, setFieldValue) => {
    const inputValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters

    // Add hyphen after the third character
    let formattedSSN = inputValue.slice(0, 3);
    if (inputValue.length > 3) {
      formattedSSN += `-${inputValue.slice(3, 5)}`;
    }
    if (inputValue.length > 5) {
      formattedSSN += `-${inputValue.slice(5, 9)}`;
    }

    setFieldValue('ssn', formattedSSN);
  };

  return (
    <Formik
      // validate={(values) => validateField(values)}
      initialValues={{
        gender_identity: patientData?.gender_identity || "",
        sexual_orientation: patientData?.sexual_orientation || "",
        gender_at_birth: patientData?.gender_at_birth || "",
        ssn: patientData?.ssn || "",
        language: patientData?.language || "",
        race: patientData?.race || "",
        ethinicity: patientData?.ethnicity || "",
        marital_status: patientData?.marital_status || "",
        provider: `${patientData?.provider_details ? patientData?.provider_details?.first_name : ""} ${patientData?.provider_details ? patientData?.provider_details?.last_name : ""}` || "",
        notes: patientData?.notes || "",
        consent_medium: patientData?.consent_medium || "",
        first_name: patientData?.first_name || "",
        last_name: patientData?.last_name || "",
        middle_name: patientData?.middle_name || "",
        date_of_birth: formatDOB(patientData?.date_of_birth) || "",
        phone_number: patientData?.phone_number || "",
        home_phone_number: patientData?.home_phone || "",
        cell_phone_number: patientData?.cell_phone || "",
        email: patientData?.email || "",
        address_line_1: patientData?.address?.address_line_1 || "",
        address_line_2: patientData?.address?.address_line_2 || "",
        country: patientData?.address?.country || "",
        city: patientData?.address?.city || "",
        state: patientData?.address?.state || "",
        zip_code: patientData?.address?.zip_code || "",
        insurance_name: patientData?.insurance?.insurance_name || "",
        holder_name: patientData?.insurance?.holder_name || "",
        relation: patientData?.insurance?.relation || "",
        insurance_number: patientData?.insurance?.insurance_number || "",
        insurance_id: patientData?.insurance?.insurance_id || "",
        insured_group_name: patientData?.insurance?.insured_group_name || "",
        employer: patientData?.insurance?.employer || "",
        insured_plan: patientData?.insurance?.insured_plan || "",
        profile_pic: "",
        medical_record_number: patientData?.mrn || "",
        bio: patientData?.bio || "",
        is_consent_to_call: patientData?.is_consent_to_call || false,
        is_consent_to_email: patientData?.is_consent_to_email || false,
        is_consent_to_message: patientData?.is_consent_to_message || false,
        is_pulled_from_ehr: patientData?.is_pulled_from_ehr === true ? "Yes" : "No"
      }}
      onSubmit={(data, { errors, resetForm }) => {
        setLoading(true);
        console.log("Patient Data", errors);
        const formData = new FormData();
        let address = {
          address_line_1: data?.address_line_1,
          address_line_2: data?.address_line_2,
          zip_code: data?.zip_code,
          city: data?.city,
          state: data?.state,
          country: data?.country,
        };

        let insurance = {
          insurance_name: data?.insurance_name,
          holder_name: data?.holder_name,
          relation: data?.relation,
          insurance_number: data?.insurance_number,
          insurance_id: data?.insurance_id,
          insured_group_name: data?.insured_group_name,
          employer: data?.employer,
          insured_plan: data?.insured_plan,
        };
        formData.append("address", JSON.stringify(address));
        formData.append("insurance", JSON.stringify(insurance));
        formData.append("first_name", data?.first_name);
        formData.append("mrn", data?.medical_record_number);
        formData.append("last_name", data?.last_name);
        formData.append("ssn", data?.ssn);
        formData.append("language", data?.language);
        formData.append("ethnicity", data?.ethinicity);
        formData.append("race", data?.race);
        formData.append("marital_status", data?.marital_status);
        formData.append("notes", data?.notes);
        formData.append("middle_name", data?.middle_name);
        formData.append("gender_identity", data?.gender_identity);
        formData.append("gender_at_birth", data?.gender_at_birth);
        formData.append("sexual_orientation", data?.sexual_orientation);
        formData.append("cell_phone", data?.cell_phone_number?.length >= 10 ? data?.cell_phone_number : "");
        formData.append("home_phone", data?.home_phone_number?.length >= 10 ? data?.home_phone_number : "");
        formData.append("relation", data?.relation);
        formData.append("profile_pic", data?.profile_pic);
        formData.append("is_consent_to_call", data?.is_consent_to_call);
        formData.append("is_consent_to_email", data?.is_consent_to_email);
        formData.append("email", data?.email);
        formData.append("is_consent_to_message", data?.is_consent_to_message);
        formData.append("provider_id", data?.provider?.name || patientData?.provider_details?.uuid);

        formData.append("date_of_birth", onSetDate);

        PatientApi.updatePatientProfile(id, formData)
          .then((response) => {
            console.log("updetails", response);
            setEditMode(false);
            setRefreshPatientData(true);
            console.log("234", response);
            notifySuccess(response?.message);
            setLoading(false);
            resetForm({})
          })
          .catch((error) => {
            console.error("Error fetching Update Patient Profile Data:", error);
            setLoading(false);
            notifyError(error?.response?.data?.error);
          });
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, setFieldValue, handleSubmit, resetForm }) => (
        <Form>
          <Card
            sx={{ ...cardStyle, minHeight: "52vh", padding: 2, cursor: "pointer" }}
          >
            {!refreshPatientData ?
              <Grid container spacing={2}>
                <Grid item xs={3} sm={6} lg={3}>
                  <Card
                    sx={{
                      ...cardStyle,
                      minHeight: "50.5vh",
                      padding: 1,
                      cursor: "pointer",
                      minWidth: "100%",
                    }}
                  >
                    <Grid
                      container
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                      padding={1}
                      marginTop={ProviderRole === "Physician" ? 3 : 15}
                      sx={{
                        display: "flex",
                        // alignItems: "center",
                      }}
                    >
                      <Grid item>
                        <Box
                          style={{
                            position: "relative",
                            display: "inline-block",
                          }}
                        >
                          {imageLoading && (
                            <Box
                              sx={{
                                width: 200,
                                height: 200,
                                ...cardStyle,
                                borderRadius: "50%",
                                bottom: 0,
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                margin: "auto",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "rgba(255, 255, 255, 0.8)",
                              }}
                              zIndex={2}
                            >
                              <CircularProgress
                                size={35}
                                sx={{ color: "black" }}
                              />
                            </Box>
                          )}
                          <Avatar
                            alt="User Profile Photo"
                            src={
                              (isURL(selectedImage)
                                ? `${selectedImage}?${new Date().getTime()}`
                                : selectedImage) || avatar
                            }
                            sx={{
                              width: 200,
                              height: 200,
                              ...cardStyle,
                              borderRadius: "50%",
                              objectFit: "contain",
                              visibility: imageLoading ? "hidden" : "visible",
                            }}
                            onError={(e) => {
                              e.target.src = avatar;
                              e.target.onerror = null;
                              setImageLoading(false);
                            }}
                            onLoad={() => setImageLoading(false)}
                          />
                          {editMode && (
                            <label
                              htmlFor="image-upload"
                              style={{
                                marginLeft: "83px",
                                bottom: "10px",
                                transform: "translateX(-50%)",
                              }}
                            >
                              <input
                                id="image-upload"
                                type="file"
                                onChange={(e) => {
                                  let file = null;
                                  file = e.target?.files[0];
                                  const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
                                  if (file) {
                                    if (!isSupportedFileType(file.type)) {
                                      notifyError(
                                        "Please select a valid image format, such as .jpeg, .jpg, .png, .gif or .bmp."
                                      );
                                    } else if (file.size > maxSize) {
                                      notifyError(
                                        "Image size should not exceed 5 MB."
                                      );
                                    } else {
                                      setFieldValue("profile_pic", file);
                                      const reader = new FileReader();
                                      reader.onload = (e) => {
                                        setSelectedImage(e.target.result);
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }
                                }}
                                style={{ display: "none" }}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                style={{
                                  position: "relative",
                                  bottom: "15px",
                                  // border: '1px solid #ffffff'
                                }}
                              >
                                <PhotoCameraIcon />
                              </IconButton>
                            </label>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      padding={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* <Grid
                      container
                      xs={12}
                      padding={1}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    > */}
                      {(!["Non Clinical"].includes(ProviderRole)) &&
                        <Grid
                          item
                          sx={{
                            flex: 1,
                            alignItems: "center",
                            padding: 1,
                            textAlign: "center",
                          }}
                        >
                          {!editMode && (
                            <CheckPermission codeName={"change_patient"}>
                            <Button
                              variant="contained"
                              style={{
                                textTransform: "none",
                                width: 160,
                                marginTop: "2vh",
                                marginBottom: "0vh",
                              }}
                              // marginBottom={0}
                              onClick={() => {
                                setEditMode(true);
                              }}
                            >
                              Update Profile
                            </Button>
                            </CheckPermission>
                          )}
                        </Grid>
                      }
                      {/* </Grid> */}
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={9} sm={6} lg={9}>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      spacing={2}
                      sx={{
                        display: "flex",
                        alignItems: "end",
                        justifyContent: "end",
                      }}
                      paddingBottom={2}
                    >
                      <>
                        {editMode && (
                          <span>
                            <Button
                              variant="outlined"
                              color="primary"
                              style={{
                                textTransform: "none",
                                width: "100px",
                              }}
                              sx={{ marginRight: "10px" }}
                              onClick={() => {
                                setRefreshPatientData(true);
                                setEditMode(false);
                                setSelectedImage(patientData?.profile_pic);
                                setOnSetDate(patientData?.date_of_birth);
                                resetForm();
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                textTransform: "none",
                                width: "100px",
                              }}
                              onClick={(e) => {
                                handleSubmit();
                                e.preventDefault();
                              }}
                            >
                              Save &nbsp;
                              {loading && (
                                <CircularProgress
                                  size={18}
                                  style={{ color: "white" }}
                                />
                              )}
                            </Button>
                          </span>
                        )}
                      </>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                      <CustomScrollbars height={editMode ? "46vh" : "51vh"}>
                        <Grid container paddingLeft={2}>
                          <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Grid
                              container
                              xs={12}
                              columnSpacing={5}
                              rowSpacing={2}
                              paddingLeft={1}
                            >
                              <Grid item xs={12} md={12}>
                                <Typography variant="h6">Demographics</Typography>
                              </Grid>
                              {fields?.slice(0, 16)?.map((field) => (
                                <Grid item xs={12} md={12}>
                                  <React.Fragment key={field.key}>
                                    {!editMode && (
                                      <Grid container xs={12} spacing={2}>
                                        <Grid item xs={6}>
                                          <Typography variant="subtitle1">
                                            {field.label}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          {
                                            <>
                                              <Typography
                                                variant="body1"
                                                component="div"
                                                sx={{ marginBottom: "5px" }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  :&nbsp;&nbsp;&nbsp;
                                                </span>
                                                {values[field?.fieldName]}
                                              </Typography>
                                            </>
                                          }
                                        </Grid>
                                      </Grid>
                                    )}
                                    {/* {editMode &&
                                    < Input data={field} />
                                  } */}
                                    {editMode && (
                                      <>
                                        {field?.fieldName === "date_of_birth" ? (
                                          <>
                                            <Typography
                                              variant="subtitle1"
                                              marginBottom={3}
                                            >
                                              {field.label}
                                            </Typography>
                                            <DatePicker
                                              onSetDate={onSetDate}
                                              setOnSetDate={setOnSetDate}
                                              // onChange={(e) => setOnSetDate(e.target.value)}
                                              fieldName="date_of_birth"
                                              sx={{ marginLeft: "20px" }}
                                            />
                                          </>
                                        ) : field?.fieldName === "ssn" ?
                                          (<>
                                            <Typography
                                              variant="subtitle1"
                                              sx={{
                                                marginBottom: "10px",
                                              }}
                                            >
                                              SSN
                                            </Typography>
                                            <Field
                                              as={TextField}
                                              variant="outlined"
                                              fullWidth
                                              key={"SSN"}
                                              style={cardStyle}
                                              placeholder={`${field?.fieldName}`}
                                              name={field?.fieldName}
                                              // disabled={field?.disable}
                                              onChange={(e) => handleSSNChange(e, setFieldValue)}
                                            />
                                          </>) :
                                          (field?.fieldName === "home_phone_number" || field?.fieldName === "cell_phone_number")
                                            ?
                                            (<PhoneNumber data={field} />) :
                                            (
                                              <Input data={field} />
                                            )}
                                      </>
                                    )}
                                    <ErrorMessage
                                      name={field.fieldName}
                                      component="div"
                                      style={{
                                        color: "red",
                                        fontSize: "15px",
                                        marginBottom: 1,
                                        marginTop: "10px",
                                      }}
                                    />
                                  </React.Fragment>
                                </Grid>
                              ))}
                              {/* {fields?.slice(10, 12).map((field, index) => (
                              <>
                                {!editMode ? (
                                  <Grid item xs={12} md={12}>
                                    <Grid container xs={12} spacing={2}>
                                      <Grid item xs={6}>
                                        <Typography variant="subtitle1">
                                          {field.label}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        {
                                          <>
                                            <Typography
                                              variant="body1"
                                              component="div"
                                              sx={{ marginBottom: "5px" }}
                                            >
                                              <span
                                                style={{
                                                  fontWeight: 700,
                                                }}
                                              >
                                                :&nbsp;&nbsp;&nbsp;
                                              </span>
                                              {values[field?.fieldName]}
                                            </Typography>
                                          </>
                                        }
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                )
                                  :
                                  <Grid
                                    item
                                    xs={12}
                                    lg={12}
                                    key={field?.label || index}
                                  >
                                    <PhoneNumber data={field} />
                                    <ErrorMessage
                                      name={field.fieldName}
                                      component="div"
                                      style={{
                                        color: "red",
                                        marginTop: "10px",
                                        marginBottom: "10px",
                                      }}
                                    />
                                  </Grid>
                                }
                              </>
                            ))} */}
                            </Grid>
                            <Grid
                              container
                              xs={12}
                              columnSpacing={5}
                              rowSpacing={2}
                              marginTop={1}
                              paddingLeft={1}
                              marginBottom={1}
                            >
                              <Grid item xs={12} md={12}>
                                <Typography variant="h6">
                                  Primary Address
                                </Typography>
                              </Grid>
                              {fields?.slice(16, 22)?.map((field) => (
                                <Grid item xs={12} md={12}>
                                  <React.Fragment key={field.key}>
                                    {!editMode && (
                                      <Grid container xs={12} spacing={2}>
                                        <Grid item xs={6}>
                                          <Typography variant="subtitle1">
                                            {field.label}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          {
                                            <>
                                              <Typography
                                                variant="body1"
                                                component="div"
                                                sx={{ marginBottom: "5px" }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  :&nbsp;&nbsp;&nbsp;
                                                </span>
                                                {values[field?.fieldName]}
                                              </Typography>
                                            </>
                                          }
                                        </Grid>
                                      </Grid>
                                    )}
                                    {editMode && <Input data={field} />}
                                    <ErrorMessage
                                      name={field.fieldName}
                                      component="div"
                                      style={{
                                        color: "red",
                                        fontSize: "15px",
                                        marginBottom: 1,
                                        marginTop: "10px",
                                      }}
                                    />
                                  </React.Fragment>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={6}>
                            <Grid
                              container
                              xs={12}
                              columnSpacing={5}
                              rowSpacing={2}
                              paddingLeft={1}
                            >
                              <Grid item xs={12} md={12}>
                                <Typography variant="h6">Insurance</Typography>
                              </Grid>
                              {fields?.slice(22, 30)?.map((field) => (
                                <Grid item xs={12} md={12}>
                                  <React.Fragment key={field.key}>
                                    {!editMode && (
                                      <Grid container xs={12} spacing={2}>
                                        <Grid item xs={6}>
                                          <Typography variant="subtitle1">
                                            {field.label}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          {
                                            <>
                                              <Typography
                                                variant="body1"
                                                component="div"
                                                sx={{ marginBottom: "5px" }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  :&nbsp;&nbsp;&nbsp;
                                                </span>
                                                {values[field?.fieldName]}
                                              </Typography>
                                            </>
                                          }
                                        </Grid>
                                      </Grid>
                                    )}
                                    {editMode && <Input data={field} />}
                                    <ErrorMessage
                                      name={field.fieldName}
                                      component="div"
                                      style={{
                                        color: "red",
                                        fontSize: "15px",
                                        marginBottom: 1,
                                        marginTop: "10px",
                                      }}
                                    />
                                  </React.Fragment>
                                </Grid>
                              ))}
                            </Grid>
                            <Grid
                              container
                              xs={12}
                              columnSpacing={5}
                              rowSpacing={2}
                              paddingLeft={1}
                              paddingTop={2}
                            >
                              {fields?.slice(30, 31)?.map((field) => (
                                <>
                                  {!editMode &&
                                    <Grid item xs={12} md={12}>
                                      <Typography variant="h6">Provider</Typography>
                                    </Grid>
                                  }
                                  <Grid item xs={12} md={12}>
                                    <React.Fragment key={field.key}>
                                      {!editMode && (
                                        <Grid container xs={12} spacing={2}>
                                          <Grid item xs={6}>
                                            <Typography variant="subtitle1">
                                              {field.label}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                            {
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  component="div"
                                                  sx={{ marginBottom: "5px" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: 700,
                                                    }}
                                                  >
                                                    :&nbsp;&nbsp;&nbsp;
                                                  </span>
                                                  {values[field?.fieldName]}
                                                </Typography>
                                              </>
                                            }
                                          </Grid>
                                        </Grid>
                                      )}
                                      {editMode &&
                                        <>
                                          <SelectDropdown data={field} disabled={field?.disable} />
                                          <ErrorMessage
                                            name={field.fieldName}
                                            component="div"
                                            style={{
                                              color: "red",
                                              marginTop: "10px",
                                              marginBottom: "10px",
                                            }}
                                          />
                                        </>
                                      }
                                      <ErrorMessage
                                        name={field.fieldName}
                                        component="div"
                                        style={{
                                          color: "red",
                                          fontSize: "15px",
                                          marginBottom: 1,
                                          marginTop: "10px",
                                        }}
                                      />
                                    </React.Fragment>
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                            <Grid
                              container
                              xs={12}
                              columnSpacing={5}
                              rowSpacing={2}
                              paddingLeft={1}
                              paddingTop={2}
                            >
                              <Grid item xs={12}>
                                <Typography variant="subtitle1">Privacy</Typography>
                              </Grid>
                              {consent.map((item, index) => (
                                <Grid item xs={12} lg={12} xl={6} md={12} key={item?.label || index} display={"flex"}>
                                  <FormControlLabel
                                    control={
                                      <Field
                                        type="checkbox"
                                        as={Checkbox}
                                        name={item.name}
                                        // checked={item.name}
                                        disabled={editMode ? false : true}
                                        onChange={(e) => {
                                          setFieldValue(item.name, e.target.checked);
                                        }}
                                      />
                                    }
                                  />
                                  <Typography sx={{ marginTop: '10px' }}>{item.label}</Typography>
                                </Grid>
                              ))}
                            </Grid>
                            {/* </Grid> */}
                            <Grid container
                              xs={12}
                              columnSpacing={5}
                              rowSpacing={2}
                              paddingLeft={1}
                              paddingTop={1}
                            >
                              {fields?.slice(31, 32)?.map((field) => (
                                <>
                                  {!editMode &&
                                    <Grid item xs={12} md={12} paddingTop={2} sx={{
                                      marginTop: "10px"
                                    }} >
                                      <Typography variant="h6">Patient Notes</Typography>
                                    </Grid>
                                  }
                                  <Grid item xs={12} md={12}>
                                    <React.Fragment key={field.key}>
                                      {!editMode && (
                                        <Grid container xs={12} spacing={2}>
                                          <Grid item xs={6}>
                                            <Typography variant="subtitle1">
                                              {field.label}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                            {
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  component="div"
                                                  sx={{ marginBottom: "5px" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: 700,
                                                    }}
                                                  >
                                                    :&nbsp;&nbsp;&nbsp;
                                                  </span>
                                                  {values[field?.fieldName]}
                                                </Typography>
                                              </>
                                            }
                                          </Grid>
                                        </Grid>
                                      )}
                                      {editMode &&
                                        <Grid item xs={12}>
                                          <Typography
                                            variant="subtitle1"
                                            sx={{
                                              marginBottom: "10px",
                                              marginTop: "10px"
                                            }}
                                          >
                                            Patient Notes
                                          </Typography>
                                          <Field
                                            as={TextField}
                                            fullWidth
                                            minRows={4}
                                            maxRows={4}
                                            multiline={true}
                                            name="notes"
                                            placeholder="Enter Notes"
                                            style={{ ...cardStyle }}
                                            sx={{
                                              width: "100%",
                                            }}
                                          />
                                        </Grid>
                                      }
                                    </React.Fragment>
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                            <Grid container
                              xs={12}
                              columnSpacing={5}
                              rowSpacing={2}
                              paddingLeft={1}
                              paddingTop={1}
                            >
                              {!editMode &&
                                <Grid item xs={12} md={12} paddingTop={2} sx={{
                                  marginTop: "10px"
                                }} >
                                  <Typography variant="h6">Others</Typography>
                                </Grid>
                              }
                              {fields?.slice(32, 34)?.map((field) => (
                                <>
                                  <Grid item xs={12} md={12}>
                                    <React.Fragment key={field.key}>
                                      {!editMode && (
                                        <Grid container xs={12} spacing={2}>
                                          <Grid item xs={6}>
                                            <Typography variant="subtitle1">
                                              {field.label}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={6}>
                                            {
                                              <>
                                                <Typography
                                                  variant="body1"
                                                  component="div"
                                                  sx={{ marginBottom: "5px" }}
                                                >
                                                  <span
                                                    style={{
                                                      fontWeight: 700,
                                                    }}
                                                  >
                                                    :&nbsp;&nbsp;&nbsp;
                                                  </span>
                                                  {values[field?.fieldName]}
                                                </Typography>
                                              </>
                                            }
                                          </Grid>
                                        </Grid>
                                      )}
                                    </React.Fragment>
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CustomScrollbars>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              :
              <Grid container sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20vh"
              }}>
                <CircularProgress />
              </Grid>
            }
          </Card>
        </Form>
      )}
    </Formik>
  );
}

export default PatientsProfile;
