import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  Typography,
  Select,
  TextField,
  MenuItem,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { PatientApi } from "../../../../../../../../../service/ProviderGroup/Patient";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { useParams } from "react-router-dom";
import { useNotification } from "../../../../../../../../../hooks/use-notification";
import { AssesmentApi } from "../../../../../../../../../service/Assesment/assesmentAPI";

const AssignAssessment = ({ setOpenDrawer, setRefreshList }) => {
  const { id } = useParams();
  const { notifySuccess, notifyError } = useNotification();
  const [onSetDate, setOnSetDate] = useState(new Date());
  const [list, setList] = useState([]);
  const [options, setOptions] = useState([]);
  console.log("options", options);
  const [loading, setLoading] = useState(false);
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  const [providerGroupId, setProviderGroupId] = useState(
    userType === "provider_group"
      ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
      : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
        ?.uuid
  );

  useEffect(() => {
    fetchAllAssessment();
  }, []);

  const fetchAllAssessment = async () => {
    console.log("I am calling");
    // const query = {
    //   role: "Care Team",
    //   patient: id,
    // };
    const query = {
      all: true,
      order_by: "title",
      status: true
    }
    const order_by = "title"
    AssesmentApi.getList(providerGroupId,query)
      .then((response) => {
        const outputArray = response?.data?.map((item) => ({
          label: item.title,
          value: item.uuid,
        }));
        setList(response?.data);
        setOptions(outputArray);
        console.log("response", response);
      })
      .catch((error) => {
        console.error("Error fetching Care Team Providers data:", error);
      });
  };

  const validationSchema = Yup.object().shape({
    assessment: Yup.string().required("Assessment is required"),
  });

  return (
    <>
      <Formik
        initialValues={{
          assessment: "",
          uuid: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(data) => {
          setLoading(true);
          console.log("Care Data", data);
          AssesmentApi.assignAssessment(data?.uuid, id)
            .then((response) => {
              notifySuccess("Assessment Assigned Successfully!");
              setOpenDrawer(false);
              setRefreshList(true);
              setLoading(false);
            })
            .catch((error) => {
              notifyError(error?.response?.data?.error);
              setLoading(false);
            });
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form>
            <Grid
              container
              justifyContent="flex-end"
              sx={{ display: "flex", alignItems: "end" }}
            >
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "end", marginBottom: 2 }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    width: "100px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setOpenDrawer(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{
                    height: "40px",
                    fontWeight: 700,
                    marginLeft: "20px",
                    width: "120px",
                    color: "#ffffff",
                    textTransform: "none",
                  }}
                  onClick={handleSubmit}
                >
                  Save &nbsp;
                  {loading && (
                    <CircularProgress size={18} style={{ color: "white" }} />
                  )}
                </Button>
              </Grid>
              <Grid
                container
                justifyContent="flex-center"
                sx={{ display: "flex", alignItems: "center" }}
                xs={12}
                marginTop={4}
              >
                <Typography
                  variant="h6"
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  Assign Assessment
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" marginBottom={1}>
                      Select Assessment
                    </Typography>
                    <Field variant="outlined" fullWidth name={"assessment"}>
                      {({ field }) => (
                        <Autocomplete
                          disablePortal
                          {...field}
                          id="combo-box-demo"
                          options={options || []}
                          sx={{ width: 500, ...cardStyle }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Assessment"
                            />
                          )}
                          onChange={(e, newValue) => {
                            setFieldValue(
                              "assessment",
                              newValue ? newValue?.label : ""
                            );
                            setFieldValue(
                              "uuid",
                              newValue ? newValue?.value : ""
                            );
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="assessment"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        // marginBottom: "10px",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AssignAssessment;
