import {
  Autocomplete,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { Button } from "@mui/material";
import { PatientApi } from "../../../../../../../../../service/ProviderGroup/Patient";
import { useParams } from "react-router";
import { useNotification } from "../../../../../../../../../hooks/use-notification";
import { capitalizeFirstLetter } from "../../../../../../../../../helpers/capitalizeFirstLetter";
// import { Field, Form, Formik, ErrorMessage } from "formik";
import * as Yup from "yup";


function VitalsForm({ handleClose, setRefresh }) {
  const { id } = useParams();
  const [data, setVitalsData] = useState([]);
  const [SelectedVitals, setSelectedVitals] = useState(data || []);
  const [vitalTypes, setVitalsTypes] = useState([]);
  const { notifyError, notifySuccess } = useNotification();
  const [isSaving, setIsSaving] = useState(false);
  const [bloodGlucoseStatus, setBloodGlucoseStatus] = useState("")
  const [erorr, setError] = useState("")
  const [saveDisabled, setSaveDisabled] = useState(false)
  const bloodGlucoseOptions = [
    {name:"After Meal", label:"After Meal"},
    {name:"During Meal", label:"During Meal"},
    {name:"Before Meal", label:"Before Meal"}
  ]

  useEffect(() => {
    getAllVitalsType();
  }, []);

  const getAllVitalsType = () => {
    let VitalsData = [];
    PatientApi.getVitalsType()
      .then((response) => {
        response?.results?.forEach((element) => {
          VitalsData.push({
            name: element?.type,
            label: element?.label,
            unit: element?.unit,
            graph_max_range: element?.graph_max_range,
            graph_min_range: element?.graph_min_range,
            max_range: element?.max_range,
            min_range: element?.min_range,
            values: null,
            data_type: element?.data_type,
          });
        });
        if (VitalsData) setVitalsTypes(VitalsData);
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error)
        console.error("Error fetching user data:", error);
      });
  };

  const checkType = (value, dtype, max, min) => {
    if (dtype === "float") {
      const floatValue = parseFloat(value);
      return /^[0-9]*(\.[0-9]*)?$/.test(value)
        && floatValue >= min && floatValue <= max;
    }

    if (dtype === "integer") {
      const intValue = parseInt(value, 10);
      return /^[0-9]*$/.test(value)
        && intValue >= min && intValue <= max;
    }

    // Add additional types or validation logic as needed
    return true;
  };

  const validationSchema = Yup.object().shape({
    note: Yup.string().required("Note is required")
  })


  return (
    <>
      <Grid item xs={12}>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            note: "",
            blood_glucose_status : ""
          }}
          onSubmit={(values) => {
            setSaveDisabled(true);
            let IsValid = false;
            console.log("bloodGlucoseStatus", bloodGlucoseStatus, values?.blood_glucose_status)
            if (isSaving) {
              setIsSaving(false)
              return;
            }
            else{
            setIsSaving(true);
            const payload = [];
            SelectedVitals?.forEach((element, index) => {
              console.log("SelectedVitals values", element, data, index === SelectedVitals?.length - 1);
              if (/^-?\d*\.?\d*$/.test(element?.values)
                && element?.values <= element?.graph_max_range && element?.values >= element?.graph_min_range
              ) {
            
                if(element?.name === 'blood_glucose'){
                  if(!values?.blood_glucose_status && erorr && !bloodGlucoseStatus?.name){
                    IsValid = false
                  setError("Please Select Status")
                  }
                  else{
                    IsValid = true
                    setError(null)
                  }
                }
                else{
                  IsValid = true
                }
                payload?.push({
                  type: element?.name,
                  value: element?.values,
                  note: values?.note,
                  status: element?.name === 'blood_glucose' ? values?.blood_glucose_status : ""
                });
              } else {
                IsValid = false
              }
              // if (index === SelectedVitals?.length - 1 && IsValid) {
                //   const data = PatientApi.addVitals(id, payload);
                // data?.then((res) => {
                //   console.log(("addVitals", res));
                //   handleClose(false);
                //   setRefresh(true)
                //   notifySuccess("Vitals added successfully")
                // });
                // data?.catch((err) => {
                //   notifyError(err?.response?.data?.error)
                //   console.log("err", err);
                // });
              // }
            });
            setTimeout(() => {
                if (payload?.length >= 1 && IsValid) {
              PatientApi.addVitals(id, payload)
              .then((res) => {
                console.log(("addVitals", res));
                handleClose(false);
                setRefresh(true);
                setIsSaving(false);
                notifySuccess("Vitals added successfully")
                setSaveDisabled(false);
              })
              .catch((err) => {
                notifyError(err?.response?.data?.error)
                setIsSaving(false);
                console.log("err", err);
                setSaveDisabled(false);
              });
                }
              setRefresh(false);
            }, 100);          
          }
          }}
          enableReinitialize
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: 2,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      height: "40px",
                      fontWeight: 700,
                      width: "100px",
                      textTransform: "none",
                      marginRight: 15,
                    }}
                    onClick={() => handleClose(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      height: "40px",
                      fontWeight: 700,
                      width: "100px",
                      textTransform: "none",
                    }}
                    onClick={handleSubmit}
                    disabled={saveDisabled}
                  >
                    Save
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sx={{
                    marginBottom: "20px",
                    minHeight: "60vh",
                  }}
                >
                  <Grid
                    container
                    sx={{
                      marginBottom: "20px",
                    }}
                  >
                    <Grid item xs={12} marginBottom={2}>
                      <Typography variant="subtitle1" marginBottom={1}>
                        Select Vitals
                      </Typography>
                      <Field variant="outlined" fullWidth name="vitalsType">
                        {({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            id="combo-box-demo"
                            options={vitalTypes}
                            sx={cardStyle}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Select Vitals"
                              />
                            )}
                            onChange={(e, newValue) => {
                              if (
                                !SelectedVitals?.some((vital) => vital?.name === newValue?.name)
                              ) {
                                if (newValue?.name) {
                                  // Add the selected vital to the list
                                  setSelectedVitals([...SelectedVitals, newValue]);
                                  if(newValue?.name === 'blood_glucose' && !bloodGlucoseStatus?.name){
                                    setError("Please Select Status")
                                  }
                                  // Check if the selected vital is "Systolic"
                                  if (newValue?.name === "systolic" || newValue?.name === "diastolic") {
                                    // Add the diastolic vital to the list
                                    const diastolicVital = vitalTypes.find(
                                      (vital) => vital.name === (newValue?.name === "systolic" ? "diastolic" : "systolic")
                                    );
                                    if (diastolicVital) {
                                      if(diastolicVital.name === "diastolic")
                                      setSelectedVitals([...SelectedVitals, newValue, diastolicVital]);
                                    else{
                                      setSelectedVitals([...SelectedVitals, diastolicVital, newValue]);
                                    }
                                    }
                                    else {
                                      setSelectedVitals([
                                        ...SelectedVitals,
                                        newValue,
                                      ]);
                                    }
                                  }
                                }
                              }
                            }}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        {SelectedVitals?.map((element) => (
                          <>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            // lg={4}
                            marginBottom={3}
                            key={element?.name}
                          >
                            <Typography variant="subtitle1" marginBottom={1}>
                              {element?.label === "Blood Pressure" ? capitalizeFirstLetter(element?.name) : element?.label}
                            </Typography>

                            <Grid item xs={12}>
                              <FormControl
                                variant="outlined"
                                name={"vitals"}
                                error={
                                  !checkType(element?.values, element?.data_type, element?.graph_max_range, element?.graph_min_range)
                                }
                              >
                                <OutlinedInput
                                  id="filled-adornment-weight"
                                  name={element?.name}
                                  placeholder={element?.label === "Blood Pressure" ? capitalizeFirstLetter(element?.name) : element?.label}
                                  value={element?.values}
                                  style={{ ...cardStyle}}
                                  endAdornment={
                                    <InputAdornment
                                      position="end"
                                      sx={{ color: "#e1e0e0", width:"100px" }}
                                    >
                                      {element?.unit}
                                    </InputAdornment>
                                  }
                                  aria-describedby="filled-weight-helper-text"
                                  inputProps={{
                                    "aria-label": "weight",
                                    type: "number",  // Set the input type to "number"
                                    inputMode: "numeric",
                                  }}
                                  onChange={(e) => {
                                    const inputValue = e.target?.value;
                                    if (inputValue) {
                                      const newElement = {
                                        ...element,
                                        values: inputValue,
                                      };
                                      const existingIndex =
                                        SelectedVitals.findIndex(
                                          (item) => item.name === element?.name
                                        );

                                      if (existingIndex !== -1) {
                                        const updatedData = [...SelectedVitals];
                                        updatedData[existingIndex] = newElement;
                                        setSelectedVitals(updatedData);
                                        setVitalsData(updatedData);
                                      } else {
                                        setSelectedVitals([
                                          ...SelectedVitals,
                                          newElement,
                                        ]);
                                        setVitalsData(SelectedVitals);
                                      }
                                    } else {
                                      const newElement = {
                                        ...element,
                                        values: inputValue,
                                      };
                                      const existingIndex =
                                        SelectedVitals.findIndex(
                                          (item) => item.name === element?.name
                                        );

                                      if (existingIndex !== -1) {
                                        const updatedData = [...SelectedVitals];
                                        updatedData[existingIndex] = newElement;
                                        setSelectedVitals(updatedData);
                                        setVitalsData(updatedData);
                                      }
                                    }
                                  }}
                                />
                                {!checkType(
                                  element?.values,
                                  element?.data_type,
                                  element?.graph_max_range,
                                  element?.graph_min_range
                                ) && (
                                    <FormHelperText
                                      sx={{
                                        // fontVariant: "bold",
                                        fontSize: "16px",
                                        // fontWeight: 500,
                                        color: "red !important",
                                      }}
                                    >
                                      {element?.values
                                        ? `Please enter a valid ${element?.data_type} data between ${element?.graph_min_range} and ${element?.graph_max_range}.`
                                        : `${element?.label === "Blood Pressure" ? capitalizeFirstLetter(element?.name) : element?.label} is required.`}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                          </Grid>
                          {
                              element?.name === 'blood_glucose' ? <>
                          <Grid xs={12} md={6} paddingX={2}>
                          
                                <Typography variant="subtitle1" marginBottom={"3%"} minHeight={{md:"40px"}}>
                            </Typography>

                                <Field variant="outlined" fullWidth name="blood_glucose_status">
                    {({ field }) => (
                      <Autocomplete
                        {...field}
                        disablePortal
                        id="combo-box-demo"
                        options={bloodGlucoseOptions}
                        sx={{...cardStyle, marginBottom:"13px", maxWidth:"360px"}}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            {...field}
                            placeholder="Select Vitals Status"
                            onChange={(e, newValue)=>{
                            }}
                          />
                        )}
                        onChange={(e, newValue) => {
                          if(newValue){
                            setFieldValue("blood_glucose_status", newValue?.name)
                          setBloodGlucoseStatus(newValue)
                          setError("")
                          }
                          else{
                            setFieldValue("blood_glucose_status", "")
                            setBloodGlucoseStatus("")
                            setError("Please Select Status")
                          }
                        }}
                      />
                    )}
                  </Field>
                  {erorr ? <FormHelperText
                                      sx={{
                                        fontSize: "16px",
                                        color: "red !important",
                                      }}
                                    >
                                      {erorr}
                                          </FormHelperText> : null }
                        </Grid>
                              </>:null
                            }
                        </>))}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                      <Typography variant="subtitle1" marginBottom={1}>
                        Note
                      </Typography>
                      <Field
                        as={TextField}
                        variant="outlined"
                        style={cardStyle}
                        minRows={3}
                        maxRows={3}
                        multiline={true}
                        name="note"
                        placeholder="Note"
                        sx={{ width: "100%" }}
                      />
                      <ErrorMessage
                        name={"note"}
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
}

export default VitalsForm;
