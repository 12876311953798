import React, { useState } from 'react';
import { Typography, Button, Grid, CircularProgress } from '@mui/material';
import { PatientApi } from '../../../../../../../service/ProviderGroup/Patient';
import { useNotification } from '../../../../../../../hooks/use-notification';
import { formatDate } from '../../../../../../../ccm-constant';
import { useNavigate } from 'react-router-dom';
import Logo from "../../../../../../../assets/consentLogo.png";

const ConsentForm = ({ patientData, handleClose, setRefreshList, setShowVerbalConsentForm, enrollFromProfile, setRefreshPatientData }) => {
  const navigate = useNavigate();
  const [isAgreed, setIsAgreed] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const { notifySuccess, notifyError } = useNotification();
  const [sign, setsign] = useState("");
  const [loading, setLoading] = useState(false);
  const [dontAgree, setDontAgree] = useState(false);

  const signPage = () => {
    setsign(`${patientData?.first_name} ${patientData?.last_name}`)
    setIsAgreed(true);
    setDontAgree(false);
    setShowSubmit(true);
  };

  const dontSignPage = () => {
    setDontAgree(true);
    setsign('');
    setShowSubmit(true)
    setIsAgreed(false);
  };

  const listPoints = [
    "You consent to the Provider providing CCM Services to you. You authorize electronic communication of your medical information with other treating providers as part of coordination of your care.",
    "You acknowledge that only one practitioner can furnish CCM Services to you during a calendar month.",
    "You understand that cost-sharing will apply to CCM Services, so you may be billed for a portion of CCM Services even though CCM Services will not involve a face-to-face meeting with the Provider.",
  ]

  const benefitsListPoints = [
    "Coordinate visits with your doctors, facilities, labs, radiology, or others. ",
    "Provide access to around-the-clock (24/7) services from your care team. ",
    "Assist with management of medications. ",
    "Provide a personalized and comprehensive care plan management. ",
    "Assist with scheduling preventive care services, many of which are covered by Medicare. "
  ]

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const response = await PatientApi.submitConsentForm(patientData?.uuid);
      console.log("PatientApi verbal consent form response", response);
      notifySuccess('Patient Enrolled Successfully');
      setLoading(false);
      handleClose();
      navigate("/patients");
      enrollFromProfile ? setRefreshPatientData(true) : setRefreshList(true);
    } catch (error) {
      console.error("Error fetching verbal consent form data:", error);
      notifyError(error);
    }
    finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    setShowVerbalConsentForm(false);
  };

  const formattedDate = () => {
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
  };

  return (
    <>
      <Grid container>
        <Grid item sx={{ display: "flex", justifyContent: "end", alignItems: "end" }} marginTop={2} xs={12}>
          <Typography>
            <img
              width="100"
              height="100"
              src={Logo}
              // src="https://dev.bewellacyhealth.com/static/media/logoLight1.e45a15c04f9e7052c094f57afcd62c70.svg"
              alt="Logo"
            />
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} xs={12}>
          <Typography variant="h5" align="center" fontWeight="bold">
            Chronic Care Management Consent Form
          </Typography>
        </Grid>
        <Grid container>
          <Grid item xs={12} lg={6} md={12}>
            <Typography variant='body1' marginTop={2}>
              <b>Patient Name: </b> {`${patientData?.first_name} ${patientData?.last_name}`}<br />
              <b>Primary Insurance: </b>{`${patientData?.insurance?.insurance_name ? patientData?.insurance?.insurance_name : ""}`} <br />
              <b>Phone Number: </b>{`${patientData?.cell_phone ? patientData?.cell_phone : patientData?.home_phone ? patientData?.home_phone : "-"}`} <br />
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} md={12}>
            <Typography variant='body1' marginTop={2}>
              <b>DOB: </b>{`${formatDate(patientData?.date_of_birth)}`} <br />
              <b>Member ID: </b>{`${patientData?.insurance?.insurance_number ? patientData?.insurance?.insurance_number : ""}`}<br />
              <b>Preferred Email: </b>{`${patientData?.email}`}<br />
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Typography variant="body1" marginTop={2} style={{ textDecoration: 'underline' }}><b>Informed Consent</b></Typography>
          <Typography variant='body1' marginTop={1}>
            You are eligible for a new Medicare program that enables us to provide you with around-the clock service to oversee your chronic conditions and improve your overall wellness. Chronic conditions are ongoing medical problems like diabetes, high blood pressure, dementia, heart disease, depression, osteoporosis, and many others. These conditions must be managed effectively in partnership between the healthcare team and patient to maintain the best possible overall health and wellness. CCM Services are available to you because you have been diagnosed with two (2) or more chronic conditions which are expected to last at least twelve (12) months and which place you at significant risk of further decline.
          </Typography>

          <Typography variant="body1" marginTop={2} style={{ textDecoration: 'underline' }}><b>What are the benefits of signing up for Chronic Care Management Services?</b></Typography>

          <ul>
            {benefitsListPoints.map((point => (
              <li><Typography variant='body1'>{point}</Typography></li>
            )))}
          </ul>

          <Typography variant='body1'>
            NOTE: You must sign an agreement or provide verbal consent to receive this type of chronic care management services.
          </Typography>

          <Typography variant="body1" marginTop={2} style={{ textDecoration: 'underline' }}><b>What do you need to know before signing up?</b></Typography>
          <Typography variant='body1' marginTop={1}>
            We are allowed to bill Medicare for these services during any month that we have provided at least 20 minutes of non-face-to-face chronic care management services to you.          </Typography>
          <Typography variant='body1' marginTop={1}>
            Medicare will reimburse us and it requires you to pay your Medicare coinsurance amount, which may be covered by your secondary insurance) each
            month that you receive at least 20 minutes of chronic care management. Our oﬃce will have the record of when and how the 20 minutes were spent and
            you will have 24/7 access to your electronic medical record if you ever have questions. Our practice is compliant with HIPAA and all laws related to
            the privacy and security of Protected Health Information (PHI). As a part of this program, your PHI may be shared between caregivers directly
            involved with your health.
          </Typography>

          <Typography variant="body1" marginTop={2} style={{ textDecoration: 'underline' }}><b>You have a right to:</b></Typography>
          <Typography variant='body1' marginTop={1}>
            Discontinue this service at any time for any reason. Because your signature is required to end your chronic care management services, please ask any of our staff members for the CCM termination form. The provider will continue providing CCM services until the end of the month and may bill Medicare for those services. After the end of the month, the provider will discontinue CCM services and no longer bill for those services to Medicare.
          </Typography>
          <Typography variant='body1' marginTop={1}>NOTE: Only one physician can bill for this service for you. Please let your physician or our staff know if you have entered into a similar agreement with another physician/ practice.</Typography>

          <Typography variant="body1" marginTop={2} style={{ textDecoration: 'underline' }}><b>Beneficiary Acknowledgment and Authorization.</b></Typography>
          <Typography variant="body1" marginTop={1}>By signing this Agreement, you agree to the following:</Typography>
          <ul>
            {listPoints.map((point => (
              <li><Typography variant='body1'>{point}</Typography></li>
            )))}
          </ul>
        </Grid>
        <Typography variant="body1"><b>I agree to participate in the Chronic Care Management program.</b></Typography>
        <Grid container marginTop={2}>
          <Grid item xs={6}>
            <Typography variant='body1' marginTop={1}>
              <b>Patient/Client/Representative: </b> {`${patientData?.first_name} ${patientData?.last_name}`}<br />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='body1' marginTop={1}>
              <b>Signature: </b><span style={{ textDecoration: 'underline' }}>{sign}</span><br />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='body1' marginTop={1}>
              <b>Date: </b>{formatDate(formattedDate())}
            </Typography>
          </Grid>
        </Grid>
        <Grid container marginTop={2}>
          <Grid item xs={6}>
            <Typography variant='body1' marginTop={1}>
              <b>Authorized Representative/ Reason for Rep Signature: </b><br />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='body1' marginTop={1}>
              <b>Reason: </b><span style={{ textDecoration: 'underline' }}>-</span><br />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='body1' marginTop={1}>
              <b>Date: </b>{formatDate(formattedDate())}
            </Typography>
          </Grid>
        </Grid>
        <Grid container marginTop={2}>
          <Grid item xs={6}>
            <Typography variant='body1' marginTop={1}>
              <b>Agency Representative:</b> <br />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='body1' marginTop={1}>
              <b>Signature: </b><span style={{ textDecoration: 'underline' }}>-</span><br />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='body1' marginTop={1}>
              <b>Date: </b>{formatDate(formattedDate())}
            </Typography>
          </Grid>
        </Grid>
        <Grid container marginTop={3}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <Typography variant="body1"><b>345 North York Road Hatboro, PA, 19040</b></Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <Typography variant="body1"><b>Phone Number: (215) 675-1516 Fax: (215) 675-9176</b></Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <Typography variant="body1" style={{ textDecoration: 'underline', color: '#0077C5' }}><b>www.hatboromedical.com, &nbsp; info@hatboromedical.com</b></Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <Typography variant="body1"><b>Monday - Thursday 7:30 A.M. - 9:00 P.M.</b></Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <Typography variant="body1"><b>Friday 7:30 A.M. - 4:00 P.M.</b></Typography>
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
            <Typography variant="body1"><b>Saturday 8:00 A.M. - 12:00 P.M.</b></Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justify="center" marginTop={1}>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}
          >
            <Button
              variant={isAgreed ? "contained" : "outlined"}
              onClick={() => signPage(this)}
              style={{
                height: "40px",
                fontWeight: 700,
                width: "100px",
                // color: "#ffffff",
                textTransform: "none",
              }}
            >
              I Agree
            </Button>
            <Button
              variant={dontAgree ? "contained" : "outlined"}
              style={{
                height: "40px",
                fontWeight: 700,
                width: "120px",
                marginLeft: "20px",
                textTransform: "none",
              }}
              onClick={() => dontSignPage(this)}
            >
              Don't Agree
            </Button>
            <Button
              type="submit"
              variant="contained"
              disabled={!showSubmit}
              style={{
                height: "40px",
                fontWeight: 700,
                marginLeft: "20px",
                width: "120px",
                textTransform: "none",
              }}
              onClick={(event) => isAgreed ? handleSubmit(event) : handleBack()}
            >
              Submit &nbsp;
              {loading && (
                <CircularProgress
                  size={18}
                  style={{ color: "white" }}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default ConsentForm