import React from "react";
import {
  Grid,
  TextField,
  Autocomplete,
  Typography,
  Button,
} from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { PatientApi } from "../../../../../../../../../service/ProviderGroup/Patient";
import { useParams } from "react-router";
import { useNotification } from "../../../../../../../../../hooks/use-notification";

const validationSchema = Yup.object().shape({
  goalProgress: Yup.string().required("Goal Progress is required"),
  note: Yup.string().required("Note is required"),
});

function AddNotes({ handleClose, setRefresh, vitalsType }) {
    const { id } = useParams();
    const {notifySuccess, notifyError} = useNotification()
  const statusOptions = [
    { label: "Regress", name: "regress" },
    { label: "Progress", name: "progress" },
    { label: "Not Progress", name: "not_progress" },
  ];

  const handleSubmit = (values) => {
    // Implement your form submission logic here
    console.log("values", values, vitalsType);
    const payload = {
        vital_type: vitalsType,
        status: values?.goalProgress,
        patient: id,
        note: values?.note
    }
 
    PatientApi.addVitalsNote(payload)
    .then((res)=>{
        handleClose(false)
        setRefresh(true)
        notifySuccess("Vitals note added successfully")
        console.log("addVitalsNote res", res)
    })
    .catch((err)=>{
      notifyError(err?.response?.data?.error);
        console.log("addVitalsNote err", err)
    })
  };

  return (
    <Formik
      initialValues={{
        goalProgress:"",
        note: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Form>
          <Grid container spacing={2} padding={1}>
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "end",
                marginBottom: 2,
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                style={{
                  height: "40px",
                  fontWeight: 700,
                  width: "100px",
                  textTransform: "none",
                  marginRight: 15,
                }}
                onClick={() => handleClose(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  height: "40px",
                  fontWeight: 700,
                  width: "100px",
                  textTransform: "none",
                }}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Grid>
            <Typography variant="h6" marginBottom={1} marginLeft={2}>
              Add Note
            </Typography>

            <Grid item xs={12}>
              <Typography variant="subtitle1" marginBottom={1}>
                Goal Progress
              </Typography>

              <Field name="goalProgress">
                {({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    id="combo-box-demo"
                    options={statusOptions || []}
                    sx={cardStyle}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select" />
                    )}
                    onChange={(e, newValue) => {
                      setFieldValue("goalProgress",newValue?.label);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="goalProgress"
                component="div"
                style={{
                  color: "red",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
            </Grid>

            <Grid item xs={12} marginBottom={3}>
              <Typography variant="subtitle1" marginBottom={1}>
                Note
              </Typography>
              <Field
                as={TextField}
                variant="outlined"
                style={cardStyle}
                minRows={3}
                maxRows={3}
                multiline={true}
                name="note"
                placeholder="Type Your Review..."
                sx={{ width: "100%" }}
              />
              <ErrorMessage
                name="note"
                component="div"
                style={{
                  color: "red",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default AddNotes;
