import React, { useState, forwardRef, useEffect  } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Grid, InputAdornment, TextField } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import cardStyle from "../../theme/card-layout";
import "./react-date-picker.css";


export function ReactDatePicker({
  selectedButton,
  selectedDateRange,
  selectedMonth,
  onYearHandler,
  onChangeHandler,
  onMonthHandler,
  selectedYear
}) {

  const [weekInitialDate, setWeekInitialDate] = useState();


  function getCurrentSunday() {
    const currentDate = new Date();
    const currentDay = currentDate.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
  
    // Calculate the difference between the current day and Sunday
    const difference = currentDay - 0; // 0 is Sunday
  
    // Clone the current date and subtract the difference
    const currentSunday = new Date(currentDate);
    currentSunday.setDate(currentDate.getDate() - difference);
  
    return currentSunday;
  }

  useEffect(() => {
    const sundayDate = getCurrentSunday();
    console.log(sundayDate.toISOString().slice(0, 10)); 
    setWeekInitialDate(sundayDate.toISOString().slice(0, 10));
  },[])

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    
    <TextField
      id="outlined-start-adornment"
      sx={{ width: "180px", }}
      style={cardStyle}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalendarMonthIcon color="primary" />
          </InputAdornment>
        ),
      }}
      onClick={onClick}
      ref={ref}
      value={value || weekInitialDate} 
    />
  ));
  

  return (
    <>
      {selectedButton === "Week" && (
        <DatePicker
        selected={selectedDateRange}
        onChange={onChangeHandler}
        // dateFormat="I/R"
        // dateFormat=""
        locale="en-GB"
        showWeekNumbers
        showWeekPicker
        placeholderText="Select Week"
        maxDate={new Date()}
        customInput={<ExampleCustomInput />}
      />
      )}

      {(selectedButton === "Month") && (
        <DatePicker
        selected={selectedMonth}
        onChange={onMonthHandler}
        dateFormat="MMMM yyyy"
        showMonthYearPicker
        placeholderText="Select Month"
        maxDate={new Date()}
        customInput={<ExampleCustomInput />}
      />
      )}

      {selectedButton === "Custom" && (
        <DatePicker
        selected={selectedYear}
        onChange={onYearHandler}
        showYearPicker
        dateFormat="yyyy"
        placeholderText="Select Year"
        maxDate={new Date()}
        customInput={<ExampleCustomInput />}
      />
      )}
    </>
  );
}
