import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Grid,
  TextField,
  Radio,
  Autocomplete,
  Box,
  CardContent,
  Typography,
  Card,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  RadioGroup,
  Rating,
  Button,
  CircularProgress,
  IconButton,
  Avatar
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { IconUpload } from "@tabler/icons-react";
import {
  addQuesAns,
  removeQuesAns,
  addGoals,
} from "../../../../../../../../../store/slices/queAns";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from '@mui/icons-material/Download';
import { Formik, Form, Field } from "formik";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { CustomScrollbars } from "../../../../../../../../../ui-component/ScrollBar";
import { AssesmentApi } from "../../../../../../../../../service/Assesment/assesmentAPI";
import { useNotification } from "../../../../../../../../../hooks/use-notification";
import avatar from "../../../../../../../../../assets/adminAvatar.jpg";

function AssessmentQueAnsCard({
  data,
  handleClose,
  rowData,
  patientId,
  setRefreshList,
  setDrawerSize
}) {
  const [assQue, setAssQue] = useState();
  const [sortDirection, setSortDirection] = useState("-id");
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "DOC", "DOCX", "XLSX"];
  const dispatch = useDispatch();
  const { notifySuccess, notifyError } = useNotification();

  useEffect(() => {
    if (rowData.is_reviewed === false) {
      setLoading(true);
      AssesmentApi.getAssessmentNotReviewd(rowData?.assessment?.uuid)
        .then((response) => {
          setAssQue(response?.questions);
          setLoading(false);
          console.log("response", response);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setLoading(false);
        });
    } else {
      AssesmentApi.getAssessmentReviewed(rowData?.assessment?.uuid, patientId)
        .then((response) => {
          // setDrawerSize(true);
          setAssQue(response?.questions);
          setLoading(false);
          console.log("ans_response", response);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          setLoading(false);
        });
    }
  }, []);

  function DragDrop({ setFieldValue, item }) {
    const [file, setFile] = useState(null);

    const handleChange = (file) => {
      console.log("file", file);
      // Assuming the file object has a uuid property, adjust this according to your file object structure
      const { uuid } = item;
      setFile(file);

      // Set the file in the form field with its corresponding question UUID
      setFieldValue(uuid, file);
      const reader = new FileReader();
      reader.onload = (file) => {
        setSelectedImage(file.target.result);
      };
    };

    return (
      <>
        <Typography
          variant="body2"
          sx={{
            marginBottom: "8px",
            marginTop: "8px"
          }}
        >
          Accepted file - DOC, DOCX, PDF, JPG, JPEG, PNG
        </Typography>
        <Card
          sx={{
            ...cardStyle,
            marginLeft: 0.5,
            height: "126px",
            display: "1",
            alignItems: "center",
            flexDirection: "column",
            cursor: "arrow",
            marginTop: 2,
          }}
        >
          <FileUploader
            handleChange={handleChange}
            name="file"
            types={fileTypes}
            children={
              <Grid
                container
                xs={12}
                height={"100%"}
                style={{
                  position: "relative", // Add this style to create a stacking context
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: `url(${selectedImage}) center/cover no-repeat`,
                    opacity: 0.3,
                    zIndex: 0, // Ensure it's below other elements
                  }}
                ></div>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  zIndex={1}
                  style={{ opacity: 1 }}
                >
                  <Typography>
                    <IconButton>
                      <IconUpload style={{ color: "#002c6b" }} />
                    </IconButton>
                  </Typography>
                </Grid>
                <Grid
                  container
                  xs={12}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  zIndex={1}
                  style={{ opacity: 1 }}
                >
                  <Typography
                    sx={{
                      color: "#002c6b",
                      font: "normal normal medium 18px/25px Gilroy",
                    }}
                  >
                    Drop File
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </Card>
        <Typography
          padding={1}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          {file ?
            `File name: ${file?.name}` : ""}
        </Typography>
      </>
    );
  }

  function getFileType(url) {
    const match = url.match(/\.([a-zA-Z0-9]+)(\?.*)?$/);
    return match ? match[1].toLowerCase() : null;
  }

  return (
    <Grid item xs={12}>
      {loading ?
        <CircularProgress color="primary" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
        :
        <Formik
          initialValues={{
            // ... (your existing initialValues)
            profile_pic: null,
          }}
          onSubmit={(values) => {
            console.log("values???", values);

            // Handle file upload separately
            const file = values.profile_pic;
            if (file) {
              const formData = new FormData();
              formData.append("profile_pic", file);
            }

            // Process other form data
            const transformedData = Object.keys(values)
              .filter((questionId) => questionId !== "profile_pic")
              .map((questionId) => ({
                question: questionId,
                response:
                  typeof values[questionId] === "string"
                    ? [values[questionId]]
                    : values[questionId],
              }));
            console.log("transformedData", transformedData);

            // Create an array of file response keys only for non-array and non-number responses
            const fileResponseData = transformedData
              .filter(
                (item) =>
                  !Array.isArray(item.response) &&
                  item.response !== null &&
                  typeof item.response !== "number"
              )
              .map((item) => {
                const key = `file_response_${item.question}`;
                const response = item.response;
                return { key, response };
              });
            console.log("fileResponseData", fileResponseData);

            // Create an object with responses for file upload questions
            const fileResponses = fileResponseData.reduce(
              (acc, { key, response }) => {
                acc[key] = response;
                return acc;
              },
              {}
            );

            const body = {
              question_responses: transformedData,
              ...fileResponses,
            };
            const formData = new FormData();
            Object.entries(body).forEach(([key, value]) => {
              if (value instanceof File) {
                // Append the file to FormData
                formData.append(key, value, value.name);
              } else {
                // Append non-file fields as usual
                formData.append(key, JSON.stringify(value));
              }
            });

            console.log("transformedData", transformedData);
            console.log("body", body);
            if (body.question_responses.length) {
              setLoading(true);
              AssesmentApi.reviewAssessment(
                rowData?.assessment?.uuid,
                patientId,
                formData
              )
                .then((response) => {
                  setAssQue(response?.questions);
                  setLoading(false);
                  console.log("response", response);
                  notifySuccess("Assessment Reviewed Successfully!");
                  setRefreshList(true);
                  handleClose();
                })
                .catch((error) => {
                  notifyError(error?.response?.data?.error);
                  console.error("Error fetching user data:", error);
                  setLoading(false);
                });
            } else {
              notifyError("Please review at least single question");
            }
            dispatch(addQuesAns(transformedData));
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Form>
              {/* {loading ? (
            <CircularProgress color="primary" sx={{ marginTop: "40vh" }} />
          ) : (
          )} */}
              <Grid container>
                <Grid container xs={12} padding={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginBottom: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{
                        textTransform: "none",
                        width: "163px",
                        marginRight: "15px",
                      }}
                      onClick={() => {
                        handleClose()
                        // setDrawerSize(false);
                      }}
                    >
                      Back
                    </Button>
                    {rowData.is_reviewed === false && (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ textTransform: "none", width: "163px" }}
                        onClick={handleSubmit}
                      >
                        Submit Review
                      </Button>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "start",
                      marginBottom: 0,
                    }}
                  >
                    <Typography variant="cardTitle">Review Assessment</Typography>
                  </Grid>
                  <CustomScrollbars height="82vh">
                    <Grid>
                      {assQue?.map((item, index) => (
                        <Grid item xs={12} padding={1.5} spacing={2} key={index}>
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              {item.statement}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {item.type === "MCMA" && (
                              <FormControl component="fieldset">
                                <Field name={item.uuid}>
                                  {({ field }) => (
                                    <FormGroup
                                      {...field}
                                      aria-label="checkbox-group"
                                      name="checkbox-group"
                                    >
                                      {item?.options?.map((choice, index) => (
                                        <FormControlLabel
                                          key={index}
                                          control={
                                            <Field
                                              name={item.uuid}
                                              type="checkbox"
                                              value={choice}
                                              as={Checkbox}
                                              onClick={(e, newValue) =>
                                                setFieldValue(item.uuid, newValue)
                                              }
                                              checked={item.response?.includes(
                                                choice
                                              )}
                                            />
                                          }
                                          label={choice}
                                        />
                                      ))}
                                    </FormGroup>
                                  )}
                                </Field>
                              </FormControl>
                            )}
                            {item.type === "MCSA" && (
                              <FormControl component="fieldset">
                                <Field name={item.uuid}>
                                  {({ field }) => (
                                    <RadioGroup
                                      {...field}
                                      aria-labelledby="demo-radio-buttons-group-label"
                                    >
                                      {item?.options?.map((choice, index) => (
                                        <FormControlLabel
                                          key={index}
                                          value={choice}
                                          control={<Radio />}
                                          label={choice}
                                          onClick={(e, newValue) =>
                                            setFieldValue(item.uuid, newValue)
                                          }
                                          checked={item.response?.includes(
                                            choice
                                          )}
                                        />
                                      ))}
                                    </RadioGroup>
                                  )}
                                </Field>
                              </FormControl>
                            )}
                            {item.type === "Free_Text" && (
                              <Field
                                as={TextField}
                                id={item.uuid}
                                name={item.uuid}
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                sx={cardStyle}
                                value={item.response}
                                disabled={rowData.is_reviewed === true}
                              />
                            )}
                            {item.type === "Rating" && (
                              <Field name={item.uuid} type="number">
                                {({ field }) => (
                                  <Rating
                                    name={field.name}
                                    value={item.response}
                                    onChange={(event, newValue) => {
                                      setFieldValue(field.name, newValue);
                                    }}
                                  />
                                )}
                              </Field>
                            )}
                            {item.type === "File_Upload" && (
                              <>
                                {rowData.is_reviewed === false && (
                                  <DragDrop
                                    setFieldValue={setFieldValue}
                                    item={item}
                                  />
                                )}
                                {rowData.is_reviewed === true &&
                                  <>
                                    <Grid container marginTop={1}>
                                      {
                                        ["jpg", "jpeg", "png"].includes(getFileType(item?.response)) ?
                                          <Grid item xs={12} paddingRight={1}>
                                            <Avatar
                                              alt="User Profile Photo"
                                              src={
                                                item?.response ? item?.response : avatar
                                              }
                                              sx={{
                                                width: 600,
                                                height: 500,
                                                borderRadius: "0%",
                                                marginBottom: "0px",
                                                objectFit: "contain",
                                                padding: 1,
                                                backgroundColor: "#ffffff",
                                                boxShadow: "0px 0px 8px #00000029",
                                                // visibility: imageLoading
                                                //   ? "hidden"
                                                //   : "visible",
                                              }}
                                              onError={(e) => {
                                                e.target.src = avatar;
                                                e.target.onerror = null;
                                              }}
                                            />
                                          </Grid> :
                                          ["pdf"].includes(getFileType(item?.response)) ?
                                            <Grid item xs={12}>
                                              <embed src={`${item?.response}?${Date.now()}#toolbar=0&navpanes=0`} type="application/pdf" width="100%" height="600px" >
                                              </embed>
                                            </Grid>
                                            :
                                            <Grid item xs={12}>
                                              <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${item?.response}&embedded=true?${Date.now()}`}
                                                width="100%"
                                                height="600px"
                                              ></iframe>
                                            </Grid>
                                      }
                                    </Grid>
                                  </>
                                  //   <IconButton size="large" sx={{ marginLeft: 5 }}
                                  //   onClick={() => window.open(item.response)}
                                  // >
                                  //   <DownloadIcon color="primary" fontSize="large" />
                                  // </IconButton>
                                }
                              </>
                            )}
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </CustomScrollbars>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      }
    </Grid>
  );
}
export default AssessmentQueAnsCard;
