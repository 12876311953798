import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Medication from "./Features/Medication/Index";
import Allergies from "./Features/Allergies/Index";
import Conditon from "./Features/Condition/Index";

function Clinical({ openDrawer, setOpenDrawer, setSelectedClinical, setRefreshPatientData }) {
  const [value, setValue] = useState("Medication");

  useEffect(() => {
    setSelectedClinical(value)
  }, [value])
  

  const a11TabsProps = [
    {
      label: "Medication",
    },
    {
      label: "Condition",
    },
    {
      label: "Allergies",
    },
  ];

  const tabComponents = {
    Medication: <Medication openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />,
    Condition: <Conditon openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} setRefreshPatientData={setRefreshPatientData} />,
    Allergies: <Allergies openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />,
  };

  return (
    <Grid container spacing={2}>
      {a11TabsProps?.map((tab, id) => (
        <Grid item>
          <Typography
            variant="body1"
            fontWeight={500}
            underline="none"
            onClick={(e) => {
              console.log(e.target.value, e);
              setValue(tab?.label);
              setSelectedClinical(tab?.label)
            }}
            style={{
              padding: "0px 10px",
              borderRight:
                id < a11TabsProps.length - 1 ? "2px solid #949191" : "",
              color: tab.label === value ? "#002c6b" : null,
              cursor: "pointer",
            }}
          >
            {tab.label}
          </Typography>
        </Grid>
      ))}
      <Grid item xs={12} paddingTop={1}>
        {tabComponents[value]}
      </Grid>
    </Grid>
  );
}

export default Clinical;
