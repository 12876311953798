// src/store/store.js

import { configureStore } from '@reduxjs/toolkit';
import snackbarSlice from './slices/snackbarSlice';
import drawerSlices from './slices/drawerSlices';
import navTabSlices from './slices/NavTabsSlices';
import BreadcrumbSlices from './slices/BreadcrumbSlices';

import patientFormSlices from './slices/patientFormSlices';
import questionsSlice from './slices/questionsSlice';
import reducer from '../contexts/auth/auth-reducer';
import queAns from './slices/queAns';
import TImeLogSlice from './slices/TImeLogSlice';
import CallWindowSlice from './slices/CallWindow';


export const store = configureStore({
  reducer: {
    auth: reducer,
    snackbar: snackbarSlice,
    drawer: drawerSlices,
    navTabIndex: navTabSlices,
    breadcrumb: BreadcrumbSlices,
    PatientForm: patientFormSlices,
    questions: questionsSlice,
    answer: queAns,
    timeLog: TImeLogSlice,
    callWindow: CallWindowSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disable serializable check
    }),
});

