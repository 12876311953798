import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import MoreIcon from "@mui/icons-material/MoreVert";
import { IconArrowsSort } from "@tabler/icons-react";
import {
  Grid,
  IconButton,
  Popover,
  Switch,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import DrawerComponent from "../../../../../ui-component/Drawer";
import { checkTabPermission, customDataTableStyles } from "../../../../../ccm-constant";
import { ProvidersApi } from "../../../../../service/ProviderGroup/Providers";
import { useNotification } from "../../../../../hooks/use-notification";
import ConfirmBox from "../../../../../ui-component/ConfirmBox";
import CustomPagination from "../../../../../ui-component/Pagination";
import avatar from "../../../../../assets/adminAvatar.jpg";
import { CustomScrollbars } from "../../../../../ui-component/ScrollBar";
import { useSettings } from "../..";
import AddProvider from "../../../ProviderGroup/feature/AddProvider/addProviders";
import { formatDate } from "../../../../../ccm-constant";
import CheckPermission from "../../../../../ui-component/ButtonWrapper";

const ProvidersTable = () => {
  const navigate = useNavigate();
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId = userType === "provider_group" ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid :
    JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
      ?.uuid;
  const { searchText, setOpenDrawer, openDrawer } = useSettings();
  const { notifySuccess, notifyError } = useNotification();
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("desc");
  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  const [loading, setLoading] = useState(false);
  const [openCnf, setOpenCnf] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [allData, setAllData] = useState();

  const GroupColumns = [
    {
      name: "Provider Name",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "3px",
            cursor: "pointer",
          }}
          onClick={() => handleRowClick(row)}
        >
          <img
            src={row?.profile_pic ? `${row?.profile_pic}?${new Date().getTime()}` : avatar}
            alt="Image"
            width={40}
            height={40}
            style={{
              marginRight: 10,
              borderRadius: "50%",
              padding: "1px",
              border: "1px solid #dfdfdf",
            }}
            onError={(e) => {
              e.target.src = avatar;
              e.target.onerror = null; // Prevent an infinite loop in case the error image also fails to load
            }}
          />
          {`${row.first_name} ${row.last_name}`}
        </div>
      ),
      width: "14%",
      sortable: true,
    },
    {
      name: "Role",
      selector: (row)=> row.role,
      sortable: true,
      width: "9%",
      wrap:true
    },
    {
      name: "Title",
      selector: (row)=> row.provider_type_initials,
      sortable: true,
      width: "11%",
      wrap:true
    },
    {
      name: "Speciality",
      selector:(row)=>row?.speciality,
      cell: (row) => (
        <span onClick={() => handleRowClick(row)}
          style={{
            whiteSpace: 'pre-line',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            // WebkitLineClamp: 3,
          }}
        >
          {row?.speciality?.join(", ")}
        </span>
      ),
      width: "20%",
    },
    {
      name: "Email ID",
      selector: (row)=> row?.email,
      cell: (row) => (
          <div
            style={{
              color: "#002c6b",
            }}
            onClick={() => handleRowClick(row)}
          >
            {row.email}
          </div>
      ),
      sortable: true,
      width: "16%",
    },
    {
      name: "Last Login ",
      selector: (row) => row?.last_login,
      // sortable: true,
      // width: "15%",
      cell: (row) => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => handleRowClick(row)}
        >
          {formatDate(row.last_login, true)}
        </div>
      ),
    },
    {
      name: "Status",
      cell: (row) => <StatusSwitch status={row.status} row={row} />,
      width: "120px",
    },
    {
      name: "Action",
      cell: (row) => <MoreButton row={row} />,
      width: "10px",
    },
  ];

  const ProviderColumns = [
    {
      name: "Provider Name",
      selector: (row) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "3px",
            cursor: "pointer",
          }}
          onClick={() => handleRowClick(row)}
        >
          <img
            src={row?.profile_pic ? `${row?.profile_pic}?${new Date().getTime()}` : avatar}
            alt="Image"
            width={40}
            height={40}
            style={{
              marginRight: 10,
              borderRadius: "50%",
              padding: "1px",
              border: "1px solid #dfdfdf",
            }}
            onError={(e) => {
              e.target.src = avatar;
              e.target.onerror = null; // Prevent an infinite loop in case the error image also fails to load
            }}
          />
          {`${row.first_name} ${row.last_name}`}
        </div>
      ),
      width: "20%",
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row?.provider_type_initials,
      sortable: true,
      width: "9%",
    },
    {
      name: "Speciality",
      selector: (row) => row?.speciality,
      cell: (row) => (
        <span onClick={() => handleRowClick(row)}
          style={{
            whiteSpace: 'pre-line',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            // WebkitLineClamp: 3,
          }}
        >
          {row?.speciality?.join(", ")}
        </span>
      ),
      width: "20%",
    },
    {
      name: "Email ID",
      selector: (row) => row?.email,
      cell: (row) => (
        <>
          <span
            style={{
              color: "#002c6b",
            }}
            onClick={() => handleRowClick(row)}
          >
            {row.email}
          </span>
        </>
      ),
      sortable: true,
      width: "20%",
    },
    {
      name: "Last Login ",
      selector: (row) => row?.last_login,
      // sortable: true,
      // width: "25%",
      cell: (row) => (
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => handleRowClick(row)}
        >
          {formatDate(row.last_login, true)}
        </div>
      ),
    },
  ];

  let columns = userType === "provider" ? ProviderColumns : GroupColumns;

  const getAllData = () => {
    setLoading(false);
    console.log("I am calling");
    const query = {
      search_by: searchText,
      limit: 10,
      offset:
        currentPage === 0
          ? currentPage
          : searchText
            ? 0
            : (currentPage - 1) * 10,
      order_by: sortDirection,
      sort_by: sortedColumn,
      search_on: "first_name,last_name,email,phone_number, provider_type,speciality,role",
    };
    ProvidersApi.getUser(ProviderId, query)
      .then((response) => {
        setUserData(response.data);
        setPageCount(response.count);
        setAllData(response);
        setLoading(true);
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
        setLoading(true);
      });
  };

  const handleSort = (column, sortDirection) => {
    setSortDirection(sortDirection);
    switch (column.name) {
      case "Provider Name":
        setSortedColumn("first_name");
        break;
      case "Specialty":
        setSortedColumn("speciality");
        break;
      case "Title":
        setSortedColumn("provider_type");
        break;
        case "Role":
        setSortedColumn("role");
        break;
      case "Email ID":
        setSortedColumn("email");
        break;
      case "Address":
        setSortedColumn("address_line_1");
        break;
      default:
        setSortedColumn("phone_number");
    }
  };

  const handleRowClick = (row) => {
    navigate(`/settings/Provider/profile/${row.uuid}`, {
      state: {
        edit: false,
        pn: currentPage,
      },
    });
  };

  const MoreButton = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const handleEdit = () => {
      handleClose();
      console.log(`Editing row with ID ${row.uuid}`);
      navigate(`/settings/Provider/profile/${row.uuid}`, {
        state: {
          edit: true,
          pn: currentPage,
        },
      });
    };

    return (
      <div>
        <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <CheckPermission codeName={"change_provider"}><MenuItem onClick={handleEdit}>Edit</MenuItem></CheckPermission>
            <CheckPermission codeName={"delete_provider"}>
            <MenuItem
              onClick={() => {
                setOpenCnf(true);
                setDeleteId(row.uuid);
              }}
            >
              Delete
            </MenuItem>
            </CheckPermission>
          </Menu>
        </Popover>
      </div>
    );
  };

  const StatusSwitch = ({ status, row }) => {
    console.log("row", row);
    const [checked, setChecked] = useState(status === true);

    const handleChange = async () => {
      setChecked(!checked);
      const newStatus = checked ? false : true;
      console.log(`Status changed to ${newStatus}${row.uuid}`);
      const body = {
        status: newStatus,
      };
      ProvidersApi.updateUser(row.uuid, body)
        .then((response) => {
          notifySuccess("Status updated successfully")
          getAllData();
        })
        .catch((error) => {
          notifyError("Something went wrong.");
          setLoading(true);
        });
    };

    return (
      <div>
        <Switch
          checked={checked}
          onChange={handleChange}
          color="primary"
          inputProps={{ "aria-label": "status switch" }}
          disabled={!checkTabPermission("change_provider")}
        />
      </div>
    );
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      getAllData();
    }, 1000); // Adjust the delay as needed (3 seconds in this case)

    return () => {
      clearTimeout(delay);
    };
  }, [searchText, sortDirection, sortedColumn, currentPage]);

  const handleDeleteSubmit = async () => {
    console.log(`Deleting row with ID ${deleteId}`);
    ProvidersApi.deleteUser(deleteId)
      .then((response) => {
        console.log("response", response);
        notifySuccess("Provider deleted successfully.");
        getAllData();
        setOpenCnf(false);
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error);
        setOpenCnf(false);
      });
  };

  return (
    <Grid>
      <Grid item xs={12}>
        <Card sx={{ height: "75vh", position: "relative" }}>
          <CardContent>
            <Grid
              container
              xs={12}
              padding={1}
              sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
            >
              <CustomScrollbars height="58vh">
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {loading ? (
                    <DataTable
                      columns={columns}
                      data={userData}
                      sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                      onSort={handleSort}
                      onRowClicked={handleRowClick}
                      highlightOnHover
                      pointerOnHover
                      customStyles={customDataTableStyles}
                    />
                  ) : (
                    <CircularProgress
                      color="primary"
                      sx={{ marginTop: "15%" }}
                    />
                  )}
                </Grid>
              </CustomScrollbars>
              <DrawerComponent
                open={openDrawer}
                handleOpen={() => setOpenDrawer(true)}
                handleClose={() => setOpenDrawer(false)}
                data={
                  <AddProvider
                    handleClose={() => setOpenDrawer(false)}
                    getAllData={getAllData}
                  />
                }
              />
              <ConfirmBox
                handleClose={() => setOpenCnf(false)}
                open={openCnf}
                handleSubmit={() => handleDeleteSubmit()}
              />
            </Grid>
          </CardContent>
          <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}>
            {pageCount !== 0 && loading && (
              <Grid item xs={12} marginTop={2}>
                <CustomPagination
                  count={pageCount}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  data={allData}
                />
              </Grid>
            )}
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

function ProviderList() {
  return (
    <div className="App">
      <ProvidersTable />
    </div>
  );
}

export default ProviderList;
