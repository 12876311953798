import React, { useState } from "react";
import { useParams } from "react-router";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Select,
  CircularProgress,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage, Field } from "formik";
import { CustomScrollbars } from "../../../../ui-component/ScrollBar";
import { useNotification } from "../../../../hooks/use-notification";
import { PatientApi } from "../../../../service/ProviderGroup/Patient";
import Input from "../../Patients/components/Patient/Input";
import cardStyle from "../../../../theme/card-layout";

const field = [
  {
    label: "Insurance Name",
    fieldName: "insurance_name",
    fieldType: TextField,
  },
  {
    label: "Holder Name",
    fieldName: "holder_name",
    fieldType: TextField,
  },
  {
    label: "Relationship To Insured",
    fieldName: "relation",
    fieldType: Select,
    options: [
      {
        label: "self",
        name: "self",
      },
      {
        label: "spouse",
        name: "spouse",
      },
      {
        label: "other",
        name: "other",
      },
      {
        label: "child",
        name: "child",
      },
      {
        label: "life_partner",
        name: "life_partner",
      },
      {
        label: "employer",
        name: "employer",
      },
      {
        label: "father",
        name: "father",
      },
      {
        label: "grandparent",
        name: "grandparent",
      },
      {
        label: "mother",
        name: "mother",
      },
      {
        label: "unknown",
        name: "unknown",
      },
      {
        label: "sibling",
        name: "sibling",
      },
      {
        label: "parent",
        name: "parent",
      },
      {
        label: "grandchild",
        name: "grandchild",
      },
      {
        label: "guardian",
        name: "guardian",
      },
    ],
  },
  {
    label: "Insurance Company/Number",
    fieldName: "insurance_number",
    fieldType: TextField,
  },
  {
    label: "Insurance Id Number",
    fieldName: "insurance_id",
    fieldType: TextField,
  },
  {
    label: "Insurance Group Name",
    fieldName: "insured_group_name",
    fieldType: TextField,
  },
  {
    label: "Employer/School Name",
    fieldName: "employer",
    fieldType: TextField,
  },
  {
    label: "Insured Plan",
    fieldName: "insured_plan",
    fieldType: TextField,
  },
];

function AddInsurance({patientData, handleClose }) {
  const { PatientId, memberId } = useParams();
  const { notifySuccess, notifyError } = useNotification();
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(field);

  const validationSchema = Yup.object().shape({
    insurance_name: Yup.string().required("Insurance Name is required"),
    holder_name: Yup.string().required("Holder Name is required"),
    relation: Yup.string().required("Relationship To Insured is required"),
    insurance_number: Yup.string().required(
      "Insurance Company/Number is required"
    ),
    insurance_id: Yup.string().required("Insurance Id Number is required"),
    insured_group_name: Yup.string().required(
      "Insurance Group Name is required"
    ),
    employer: Yup.string().required("Employer/School Name is required"),
    insured_plan: Yup.string().required("Insured Plan is required"),
  });

  return (
    <Grid container padding={1}>
    <Formik
      initialValues={{
        insurance_name: patientData?.insurance?.insurance_name,
          holder_name: patientData?.insurance?.holder_name,
          relation: patientData?.insurance?.relation,
          insurance_number: patientData?.insurance?.insurance_number,
          insurance_id: patientData?.insurance?.insurance_id,
          insured_group_name: patientData?.insurance?.insured_group_name,
          employer: patientData?.insurance?.employer,
          insured_plan: patientData?.insurance?.insured_plan,
          is_insurance_verified: patientData?.insurance?.is_insurance_verified,
      }}
      onSubmit={(data, { errors }) => {
        setLoading(true);
        console.log("Patient Data", errors);
        const formData = new FormData();
        let insurance = {
          insurance_name: data?.insurance_name,
          holder_name: data?.holder_name,
          relation: data?.relation,
          insurance_number: data?.insurance_number,
          insurance_id: data?.insurance_id,
          insured_group_name: data?.insured_group_name,
          employer: data?.employer,
          insured_plan: data?.insured_plan,
          is_insurance_verified:data?.is_insurance_verified
        };
        formData.append("insurance", JSON.stringify(insurance));
        formData.append("provider_id", patientData?.provider_details?.uuid);
        PatientApi.updatePatientProfile(PatientId, formData)
          .then((response) => {
            console.log("234", response);
            notifySuccess(response?.message);
            setLoading(false);
            handleClose();
          })
          .catch((error) => {
            console.error("Error fetching Update Patient Profile Data:", error);
            notifyError(error?.response?.data?.error);
          });
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ handleSubmit, resetForm }) => (
        <Form>
            <Grid
              item
              xs={12}
              spacing={2}
              sx={{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
              }}
              paddingBottom={2}
            >
              <>
                <span>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      textTransform: "none",
                      width: "100px",
                    }}
                    sx={{ marginRight: "10px" }}
                    onClick={() => {
                      handleClose();
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      textTransform: "none",
                      width: "100px",
                    }}
                    onClick={(e) => {
                      handleSubmit();
                      e.preventDefault();
                    }}
                  >
                    Save &nbsp;
                    {loading && (
                      <CircularProgress size={18} style={{ color: "white" }} />
                    )}
                  </Button>
                </span>
              </>
            </Grid>
              <CustomScrollbars height={"80vh"}>
            <Grid item xs={12} sx={{height:"70vh"}}>
              <Typography variant="h6" marginBottom={2}>Edit Insurance</Typography>
                <Grid container columnSpacing={3} rowSpacing={2}>
                  {fields?.slice(0, 12)?.map((field, id) => (
                    <Grid item xs={12} md={6} key={id}>
                        <Input data={field} />
                        <ErrorMessage
                          name={field.fieldName}
                          component="div"
                          style={{
                            color: "red",
                            fontSize: "15px",
                            marginBottom: 1,
                            marginTop: "10px",
                          }}
                        />
                    </Grid>
                  ))}

                  <Grid item xs={12} md={6} key={"insurance"}>
                  <Typography
          variant="subtitle1"
          sx={{
            marginBottom: "10px",
          }}
        >
      Is Insured Verified  :  <Field type="checkbox" name="is_insurance_verified"   style={{...cardStyle,  content: "",
  position: "relative",
  left: 0,
  top: 4,
  width: "20px", /* Set the width of the checkbox */
  height: "20px", /* Set the height of the checkbox */
  border: "1px solid #000", /* Set the border color */
  backgroundColor: "#fff", /* Set the background color */ }}/> 
        </Typography>
              
                    </Grid>
                </Grid>
            </Grid>
              </CustomScrollbars>
        </Form>
      )}
    </Formik>
    </Grid>
  );
}

export default AddInsurance;
