import {
  Button,
  Card,
  Dialog,
  DialogContent,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { CustomScrollbars } from "../../../../../../../../../ui-component/ScrollBar";
import { formatDate } from "../../../../../../../../../ccm-constant";
import CircularLoader from "../../../../../../../../../ui-component/CircularLoader";

function CarePlanUpdateHistory({ onClose, history, loading }) {
  console.log("history", history);

  return (
    <Card
      sx={{
        ...cardStyle,
        position: "fixed",
        bottom: "4.5vh",
        right: "2.2vw",
        width: "50%",
        height: "53.3vh",
        // boxShadow:1
      }}
    >
      <Grid container xs={12} padding={2}>
        <Grid item xs={6}>
          <Typography variant="h6">Note History</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "end" }}>
          {/* <Link
            textAlign={"end"}
            href="#"
            underline="none"
            fontSize={20}
            fontWeight={500}
            onClick={onClose}
            style={{
              color: "#4C4C4CCC",
            }}
          >
            X
          </Link> */}
          <IconButton size="large" aria-label="close" color="error">
            <CloseIcon fontSize="small" onClick={onClose} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container xs={12} paddingLeft={2} paddingRight={2}>
      {loading ? (
              <Grid items>
                <CircularLoader />
              </Grid>
            ) :<CustomScrollbars height={"44vh"}>
          {history?.map((item, index) => (
            <>
              <Typography variant="subtitle1">
                {item.category  && item.category !== "symptom" && item.category !== "barrier" ?
                (item.category.charAt(0).toUpperCase() + item.category.slice(1)).split("_").join(" ") 
                : null
                }
                {item.category === "symptom" ? 
                    "Symptoms" 
                    : item.category === "barrier" ? 
                        "Barriers" 
                        : null
                }
                <span
                  style={{
                    marginLeft: "15px",
                    color: "#42427C",
                    background: "#7B7B7B1A 0% 0% no-repeat padding-box",
                    fontWeight: 400,
                    textAlign: "end",
                  }}
                >
                  ( {item.created_by?.name} added note on{" "}
                  {`      ${formatDate(item.created_at)}      `})
                </span>
              </Typography>

              <List>
                <ListItem
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    padding: 0,
                    marginBottom: 1,
                  }}
                >
                  <ListItemIcon sx={{paddingTop:1}}>
                    <CircleIcon
                      style={{ fontSize: "small", color: "#4C4C4C" }}
                    />
                  </ListItemIcon>
                  <Typography variant="body1" component="span">
                    {item.note}
                  </Typography>
                </ListItem>
              </List>
            </>
          ))}

          {!history?.length && (
            <Typography
              variant="body2"
              fontSize={"16px"}
              margin={3}
              padding={3}
              textAlign={"center"}
            >
              There are no records to display
            </Typography>
          )}
        </CustomScrollbars>}
      </Grid>
    </Card>
  );
}

export default CarePlanUpdateHistory;
