import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNotification } from "../hooks/use-notification";
import { useFormikContext } from "formik";
import { Card, Grid, IconButton, Typography } from "@mui/material";
import { IconUpload } from "@tabler/icons-react";
import cardStyle from "../theme/card-layout";

function DragAndDrop({ isProvider, disable }) {
  const { notifyError } = useNotification();
  const [selectedImage, setSelectedImage] = useState(null);
  const { setFieldValue, values } = useFormikContext();

  const isSupportedFileType = (fileType) => {
    console.log("fileType", fileType)
    const supportedFormats = ["jpeg", "jpg", "png", "gif", "bmp"];
    return supportedFormats.some((format) => fileType.endsWith(format));
  };
  return (
    <>
      <Card
        sx={{
          ...cardStyle,
          marginLeft: 0.5,
          height: "126px",
          display: "1",
          alignItems: "center",
          flexDirection: "column",
          cursor: "arrow",
        }}
      >
        <FileUploader
          disabled={disable}
          multiple={true}
          handleChange={(e) => {
            let file = null;
            setFieldValue("profile_pic", "");
            setSelectedImage(null);
            file = e[0];
            const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
            if (file) {
              if (!isSupportedFileType(file.type) && !disable) {
                notifyError(
                  "Please select a valid image format, such as .jpeg, .jpg, .png, .gif or .bmp."
                );
              } else if (file.size > maxSize) {
                notifyError("Image size should not exceed 5 MB.");
              } else if (disable) {
                return null
              }
              else {
                setFieldValue("profile_pic", e);
                const reader = new FileReader();
                reader.onload = (e) => {
                  setSelectedImage(e.target.result);
                };
                reader.readAsDataURL(file);
              }
            }
          }}
          name="file"
          // types={fileTypes}
          label="Drop Image"
          children={
            <Grid
              container
              xs={12}
              height={"100%"}
              style={{
                position: "relative", // Add this style to create a stacking context
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: `url(${selectedImage}) center/cover no-repeat`,
                  opacity: 0.3,
                  zIndex: 0, // Ensure it's below other elements
                }}
              ></div>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
                zIndex={1}
                style={{ opacity: 1 }}
              >
                <Typography>
                  <IconButton>
                    <IconUpload style={{ color: "#002c6b" }} />
                  </IconButton>
                </Typography>
              </Grid>
              <Grid
                container
                xs={12}
                display="flex"
                justifyContent="center"
                alignItems="center"
                zIndex={1}
                style={{ opacity: 1 }}
              >
                <Typography
                  sx={{
                    color: "#002c6b",
                    font: "normal normal medium 18px/25px Gilroy",
                  }}
                >
                  {!isProvider ? (!disable ? "Drop Profile Picture" : "Drop File") : "Drop Here Logo"}
                </Typography>
                {/* <Typography
                  variant="body1"
                  sx={{ textAlign: "center" }}
                  paddingRight={3}
                  paddingLeft={3}
                >
                  Accepted image types: PNG, GIF, JPG, JPEG, SVG, BMP
                </Typography> */}
              </Grid>
            </Grid>
          }
        />
      </Card>
      <Typography
        padding={1}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {values?.profile_pic
          ? `File name: ${values?.profile_pic[0]?.name}`
          : ""}
      </Typography>
    </>
  );
}

export default DragAndDrop;
