import { Grid, Link, Tab, Tabs, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import CarePlanReview from "./CarePlanReview";
import CarePlanProgress from "./CarePlanProgress";
import PhysicianReview from "./PhysicianReview";
import NavTabs from "../../../../../../../Settings/Components/NavTabs";
import { CustomScrollbars } from "../../../../../../../../../ui-component/ScrollBar";

function CareManagementTabs({dataRefresh, setDataRefresh}) {
  const theme = useTheme();
  const [value, setValue] = useState("Care Plan Review");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11TabsProps = [
    {
      label: "Care Plan Review",
    },
    {
      label: "Care Plan Progress",
    },
    {
      label: "Physician Review",
    },
  ];

  const tabComponents = {
    "Care Plan Review": <CarePlanReview />,
    "Care Plan Progress": <CarePlanProgress dataRefresh={dataRefresh} setDataRefresh={setDataRefresh} />,
    "Physician Review": <PhysicianReview />,
  };

  return (
    <Grid container spacing={2}>
      {a11TabsProps?.map((tab, id) => (
        <Grid item >
          <Typography variant="body1" fontWeight={500}
            underline="none"
            onClick={(e) => {
              console.log(e.target.value, e)
              setValue(tab?.label);
            }}
            style={{
              padding: "0px 10px",
              borderRight: id < a11TabsProps.length - 1 ? "2px solid #949191" : "",
              color: tab.label === value ? "#002c6b" : null,
              cursor: "pointer",
            }}
          >
            {tab.label}
          </Typography>
        </Grid>
      ))}
      <Grid item xs={12} paddingTop={1}>
        {tabComponents[value]}
      </Grid>
    </Grid>
  );
}

export default CareManagementTabs;
