import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Card,
  Grid,
  Typography,
  Avatar,
  TextField,
  Button,
  Select,
  CircularProgress,
  Box,
  IconButton,
  Checkbox,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import cardStyle from "../../../../theme/card-layout";
import { CustomScrollbars } from "../../../../ui-component/ScrollBar";
import { useNotification } from "../../../../hooks/use-notification";
import { PatientApi } from "../../../../service/ProviderGroup/Patient";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import avatar from "../../../../assets/adminAvatar.jpg";
import { DatePicker } from "../../../../ui-component/DatePicker/DatePicker";
import { formatDate } from "../../../../ccm-constant";
import { patientContetxt } from "./PatientDetails";

const field = [
  {
    label: "Insurance Name",
    fieldName: "insurance_name",
    fieldType: TextField,
  },
  {
    label: "Holder Name",
    fieldName: "holder_name",
    fieldType: TextField,
  },
  {
    label: "Relationship To Insured",
    fieldName: "relation",
    fieldType: Select,
    options: [
      {
        label: "child",
        name: "child",
      },
      {
        label: "employer",
        name: "employer",
      },
      {
        label: "father",
        name: "father",
      },
      {
        label: "grandchild",
        name: "grandchild",
      },
      {
        label: "grandparent",
        name: "grandparent",
      },
      {
        label: "guardian",
        name: "guardian",
      },
      {
        label: "life_partner",
        name: "life_partner",
      },
      {
        label: "mother",
        name: "mother",
      },
      {
        label: "other",
        name: "other",
      },
      {
        label: "parent",
        name: "parent",
      },
      {
        label: "self",
        name: "self",
      },
      {
        label: "sibling",
        name: "sibling",
      },
      {
        label: "spouse",
        name: "spouse",
      },
      {
        label: "unknown",
        name: "unknown",
      },
    ],
  },
  {
    label: "Insurance Company/Number",
    fieldName: "insurance_number",
    fieldType: TextField,
  },
  {
    label: "Insurance ID Number",
    fieldName: "insurance_id",
    fieldType: TextField,
  },
  {
    label: "Insurance Group Name",
    fieldName: "insured_group_name",
    fieldType: TextField,
  },
  {
    label: "Employer/School Name",
    fieldName: "employer",
    fieldType: TextField,
  },
  {
    label: "Insured Plan",
    fieldName: "insured_plan",
    fieldType: TextField,
  },
  {
    label: "Patient Name",
    fieldName: "patient_name",
    fieldType: TextField,
  },
  {
    label: "Is Insured Verified?",
    fieldName: "is_insurance_verified",
    fieldType: TextField,
  },
];

function Insurance() {
  const { id, memberId } = useParams();
  const { patientData, fetchingData } = useContext(patientContetxt);
  const { notifySuccess, notifyError } = useNotification();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(field);
  const [selectedImage, setSelectedImage] = useState(patientData?.profile_pic);
  const [imageLoading, setImageLoading] = useState(true);
  const [onSetDate, setOnSetDate] = useState(patientData?.date_of_birth);

  return (
    <>
      {fetchingData ? (
        <Grid
          item
          sx={{ height: "80vh", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress color="primary" sx={{ marginTop: "15%" }} />
        </Grid>
      ) : (
        <Formik
          initialValues={{
            insurance_name: patientData?.insurance?.insurance_name,
            holder_name: patientData?.insurance?.holder_name,
            relation: patientData?.insurance?.relation,
            insurance_number: patientData?.insurance?.insurance_number,
            insurance_id: patientData?.insurance?.insurance_id,
            insured_group_name: patientData?.insurance?.insured_group_name,
            employer: patientData?.insurance?.employer,
            insured_plan: patientData?.insurance?.insured_plan,
            patient_name:  patientData?.first_name + " " + patientData?.last_name,
            is_insurance_verified : patientData?.insurance?.is_insurance_verified ? "Yes" : "No"
          }}
          onSubmit={(data, { errors }) => {
            setLoading(true);
            console.log("Patient Data", errors);
            const formData = new FormData();
            let address = {
              address_line_1: data?.address_line_1,
              address_line_2: data?.address_line_2,
              zip_code: data?.zip_code,
              city: data?.city,
              state: data?.state,
              country: data?.country,
            };

            let insurance = {
              insurance_name: data?.insurance_name,
              holder_name: data?.holder_name,
              relation: data?.relation,
              insurance_number: data?.insurance_number,
              insurance_id: data?.insurance_id,
              insured_group_name: data?.insured_group_name,
              employer: data?.employer,
              insured_plan: data?.insured_plan,
            };
            formData.append("address", JSON.stringify(address));
            formData.append("insurance", JSON.stringify(insurance));
            formData.append("first_name", data?.first_name);
            formData.append("last_name", data?.last_name);
            formData.append("middle_name", data?.middle_name);
            formData.append(
              "gender",
              data?.gender === "Male"
                ? "M"
                : data?.gender === "Female"
                ? "F"
                : "O"
            );
            formData.append("phone_number", data?.phone_number);
            formData.append("relation", data?.relation);
            formData.append("profile_pic", data?.profile_pic);
            formData.append("date_of_birth", onSetDate);

            PatientApi.updatePatientProfile(id, formData)
              .then((response) => {
                console.log("234", response);
                notifySuccess(response?.message);
                setLoading(false);
                setEditMode(false);
                // setRefreshPatientData(true);
              })
              .catch((error) => {
                console.error(
                  "Error fetching Update Patient Profile Data:",
                  error
                );
                notifyError(error?.response?.data?.error);
              });
          }}
          enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit, resetForm }) => (
            <Form>
              <Grid container spacing={2} marginTop={3}>
                <Grid item xs={12} md={12} sm={12}>
                  <CustomScrollbars height={"72vh"}>
                    <Grid container paddingLeft={2}>
                      <Grid item xs={12} marginBottom={2}>
                        <Grid
                          container
                          xs={12}
                          columnSpacing={5}
                          rowSpacing={2}
                          paddingLeft={1}
                        >
                          <Grid item xs={12} md={12}>
                            <Typography variant="h6">
                              Insurance Details
                            </Typography>
                          </Grid>
                          {fields?.slice(0, 10)?.map((field) => (
                            <Grid item xs={12} md={6}>
                              <React.Fragment key={field.key}>
                                {!editMode && (
                                  <Grid container xs={12} spacing={2}>
                                    <Grid item xs={6}>
                                      <Typography variant="subtitle1">
                                        {field.label}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      {
                                        <>
                                          <Typography
                                            variant="body1"
                                            component="div"
                                            sx={{ marginBottom: "5px" }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: 700,
                                              }}
                                            >
                                              :&nbsp;&nbsp;&nbsp;
                                            </span>
                                            {values[field?.fieldName]}
                                          </Typography>
                                        </>
                                      }
                                    </Grid>
                                  </Grid>
                                )}
                              </React.Fragment>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </CustomScrollbars>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
export default Insurance;
