import { styled } from "@mui/system";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import BloodPressureIcon from "../src/assets/blood_pressure.svg";
import BMIIcon from "../src/assets/bmi.svg";
import BloodGlucose from "../src/assets/blood_glucose.svg";
import HipCircumstances from "../src/assets/hip_circumstances.svg";
import BodyFatIcon from "../src/assets/body_fat.svg";
import BrainActivityIcon from "../src/assets/brain_activity.svg";
import CholestrolLevelIcon from "../src/assets/cholestrol_level.svg";
import HeartRateIcon from "../src/assets/heart_rate.svg";
import HeightIcon from "../src/assets/height.svg";
import PulseIcon from "../src/assets/pulse.svg";
import RespirationRateIcon from "../src/assets/respiration_rate.svg";
import TemperatureIcon from "../src/assets/temperature.svg";
import WaistCircumstancesIcon from "../src/assets/waist_circumstances.svg";
import WeightIcon from "../src/assets/weight.svg";
import Pill from "../src/assets/pill.svg";
import OxygenSaturation from "../src/assets/oxygen_saturation.svg";
import LocalCafeOutlinedIcon from "@mui/icons-material/LocalCafeOutlined";
export const drawerWidth = "275px";

const grey = {
  50: "#eeeeef",
  100: "#f0f1f1",
  200: "#dedede",
  300: "#bfbfbf",
  400: "#aaaaab",
  500: "#898a8a",
  600: "#777879",
  700: "#656769",
  800: "#5b5b5c",
  900: "#5a5a5a",
};

export const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    // font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 10px 10px 0 10px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 2px ${
      theme.palette.mode === "dark" ? grey[900] : grey[50]
    };

    &:hover {
      border-color: ${grey[100]};
    }

    &:focus {
      border-color: ${grey[100]};
      box-shadow: 0 0 0 3px ${
        theme.palette.mode === "dark" ? grey[100] : grey[100]
      };
    }

    &:focus-visible {
      outline: 0;
    }
  `
);

export const customDataTableStyles = {
  cells: {
    style: {
      textAlign: "left",
      fontStyle: "normal",
      fontVariant: "medium",
      fontSize: "16px",
      lineHeight: "26px",
      color: "#4C4C4CB2",
      marginTop: "1%",
      marginBottom: "1%",
      // font: "normal normal medium 18px/21px Gilroy" ,
      opacity: 1,
    },
  },
  headRow: {
    style: {
      fontSize: "18px",
      backgroundColor: "#F8F8F8", // Change the background color of the header
      color: "#4C4C4C", // Change the text color of the header
      border: "0.8px solid #E9E9E9",
      // font: "normal normal medium 18px/21px Gilroy" ,
      fontWeight: "500",
    },
  },
};

export const customNotificationTableStyle = {
  cells: {
    style: {
      textAlign: "left",
      fontStyle: "normal",
      fontVariant: "medium",
      fontSize: "16px",
      lineHeight: "26px",
      color: "#4C4C4CB2",
      font: "normal normal medium 18px/21px Gilroy",
      opacity: 1,
    },
  },
  headRow: {
    style: {
      fontSize: "18px",
      backgroundColor: "#F8F8F8", // Change the background color of the header
      color: "#4C4C4C", // Change the text color of the header
      border: "0.8px solid #E9E9E9",
      font: "normal normal medium 18px/21px Gilroy",
      fontWeight: "500",
    },
  },
  rows: {
    style: {
      borderBottom: "none",
    },
  },
};

export const TOKEN_EXPIRED_MSG = "Signature has expired";
export const localStorageKeys = {
  LastPagePathName: "LastPagePathName", // when the user tries to log in again, the browser redirects to the page the user was previously on
};
export const sentryEvents = {
  auth: {
    resendEmail: "Resend email",
    resetPassword: "Reset password",
    signin: "User sign-in",
    signup: "User sign-up",
    ssoSignin: "User SSO sign-in",
    changePassword: "Change password",
    getMe: "Get user data",
    updateProfile: "Update user profile",
  },
};

export const dateFromDays = (startDate, day) => {
  console.log("dateFromDays", startDate, day);
  // Convert the start date string to a Date object
  const startDateObject = new Date(startDate);

  // Calculate the end date by adding the specified number of days
  const endDateObject = new Date(
    startDateObject.getTime() + day * 24 * 60 * 60 * 1000
  );

  const options = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  };

  // Format the end date as 'YYYY-MM-DD'
  const endDate = endDateObject
    .toLocaleString("en-US", options)
    .replace(/\//g, "-");
  return endDate;
};

export const DaysFromDate = (start, end) => {
  const startDate = new Date(start);
  const endDate = new Date(end);

  // Check if dates are valid
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return "Invalid date";
  }

  // Calculate days, rounding to the nearest whole day
  const timeDifference = endDate.getTime() - startDate.getTime();
  const daysDifference = Math.round(timeDifference / (1000 * 3600 * 24) + 1);

  return `${daysDifference} days`;
};

export const formatDate = (utcDateString, includeTime = false) => {
  if (utcDateString !== null) {
    const utcDate = new Date(utcDateString);

    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: includeTime ? "2-digit" : undefined,
      minute: includeTime ? "2-digit" : undefined,
      second: includeTime ? "2-digit" : undefined,
    };

    const localTime = utcDate
      .toLocaleString("en-US", options)
      .replace(/\//g, "-");
    return localTime;
  } else {
    return "-";
  }
};

export const formatDOB = (utcDateString, includeTime = false, timeZone = 'UTC') => {
  if (utcDateString !== null) {
    const utcDate = new Date(utcDateString);

    const options = {
      timeZone: timeZone,
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: includeTime ? "2-digit" : undefined,
      minute: includeTime ? "2-digit" : undefined,
      second: includeTime ? "2-digit" : undefined,
    };

    const localTime = new Intl.DateTimeFormat("en-US", options)
      .format(utcDate)
      .replace(/\//g, "-");
    return localTime;
  } else {
    return "-";
  }
};

export const formatDateAndTime = (utcDateString) => {
  if (utcDateString !== null) {
    const utcDate = new Date(utcDateString);

    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true, // Set to true for 12-hour format
    };
    const localTime = utcDate
      .toLocaleString("en-US", options)
      .replace(/\//g, "-");
    const formattedTime = localTime.split(", ")[1];
    return `${localTime.split(", ")[0]} ${formattedTime}`;
  } else {
    return "-";
  }
};

export const formatDateAnalytics = (date) => {
  const year = date?.getFullYear();
  const month = String(date?.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(date?.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const getStartAndEndDateOfWeek = (currentDate) => {
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - currentDate.getDay()); // Set to the first day of the current week (Sunday)

  const endOfWeek = new Date(currentDate);
  endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay())); // Set to the last day of the current week (Saturday)

  return { start_date: startOfWeek, end_date: endOfWeek };
};

export const VitalsCardStyle = (label) => {
  switch (label) {
    case 0:
      return {
        color: "#108608",
        background: "#10860826 0% 0% no-repeat padding-box",
        border: "5px solid #108608",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#DCEDDC",
      };
    case 1:
      return {
        color: "#FFBE3D",
        background: "#FFBE3D1A 0% 0% no-repeat padding-box",
        border: "5px solid #FFBE3D",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#FFF8EC",
      };
    case 2:
      return {
        color: "#cc6699",
        background: "#cc66991A 0% 0% no-repeat padding-box",
        border: "5px solid #cc6699",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#f9ecf2",
      };
    case 3:
      return {
        color: "#2D6FC8",
        background: "#3B8FFF4D 0% 0% no-repeat padding-box",
        border: "5px solid #2D6FC8",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#3B8FFF4D",
      };
    case 4:
      return {
        color: "#27817c",
        background: "#27817c1A 0% 0% no-repeat padding-box",
        border: "5px solid #27817c",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#E9F2F2",
      };
    case 5:
      return {
        color: "#8638FF",
        background: "#8638FF1A 0% 0% no-repeat padding-box",
        border: "5px solid #8638FF",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#F3EAFF",
      };
    case 6:
      return {
        color: "#20ba65",
        background: "#20ba651A 0% 0% no-repeat padding-box",
        border: "5px solid #20ba65",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#E9F8F0",
      };
    case 7:
      return {
        color: "#992fb9",
        background: "#992fb91A 0% 0% no-repeat padding-box",
        border: "5px solid #992fb9",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#F5E9F8",
      };
    case 8:
      return {
        color: "#b8d42a",
        background: "#b8d42a1A 0% 0% no-repeat padding-box",
        border: "5px solid #b8d42a",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#F8FBEB",
      };
    case 9:
      return {
        color: "#0adad7",
        background: "#0adad71A 0% 0% no-repeat padding-box",
        border: "5px solid #0adad7",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#E7FBFB",
      };
      // case "Pulse Rate":
      //   return {
      //     color: "#ff6666",
      //     background: "#ff66661A 0% 0% no-repeat padding-box",
      //     border: "5px solid #ff6666",
      //     borderRadius: "5px",
      //     opacity: 1,
      //     borderColor: "#ffe6e6"
      //   };
      // case "Respiration Rate":
      //   return {
      //     color: "#ff4dff",
      //     background: "#ff4dff1A 0% 0% no-repeat padding-box",
      //     border: "5px solid #ff4dff",
      //     borderRadius: "5px",
      //     opacity: 1,
      //     borderColor: "#ffe6ff"
      //   };
      // case "Waist Circumference":
      //   return {
      //     color: "#7575a3",
      //     background: "#7575a31A 0% 0% no-repeat padding-box",
      //     border: "5px solid #7575a3",
      //     borderRadius: "5px",
      //     opacity: 1,
      //     borderColor: "#f0f0f5"
      //   };
      // case "Weight":
      //   return {
      //     color: "#cc6699",
      //     background: "#cc66991A 0% 0% no-repeat padding-box",
      //     border: "5px solid #cc6699",
      //     borderRadius: "5px",
      //     opacity: 1,
      //     borderColor: "#f9ecf2"
      //   };
      // case "Daily Sleep":
      //   return {
      //     color: "#cc6699",
      //     background: "#cc66991A 0% 0% no-repeat padding-box",
      //     border: "5px solid #cc6699",
      //     borderRadius: "5px",
      //     opacity: 1,
      //     borderColor: "#f9ecf2"
      //   };
      // case "Daily Sleep":
      return {
        color: "#cc6699",
        background: "#cc66991A 0% 0% no-repeat padding-box",
        border: "5px solid #cc6699",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#f9ecf2",
      };
    default:
      return {
        color: "#972677",
        background: "#9726771A 0% 0% no-repeat padding-box",
        border: "5px solid #972677",
        borderRadius: "5px",
        opacity: 1,
        borderColor: "#DCEDDC",
      };
  }
};

export const Icon = (label, color) => {
  const iconStyle = {
    height: "40px",
    width: "auto",
    color: color,
    paddingTop: "7px",
    marginTop: "3px",
    marginLeft: "auto",
  };

  switch (label) {
    case "Fasting Blood Sugar":
      return <img src={BloodGlucose} alt="Logo" style={iconStyle} />;
    case "Blood Pressure":
      return <img src={BloodPressureIcon} alt="Logo" style={iconStyle} />;
    case "Body Fat":
      return <img src={BodyFatIcon} alt="Logo" style={iconStyle} />;
    case "Body Mass Index":
      return <img src={BMIIcon} alt="Logo" style={iconStyle} />;
    case "Body Temperature":
      return <img src={TemperatureIcon} alt="Logo" style={iconStyle} />;
    case "Total Cholesterol":
      return <img src={CholestrolLevelIcon} alt="Logo" style={iconStyle} />;
    case "Heart Rate Variability":
      return <img src={HeartRateIcon} alt="Logo" style={iconStyle} />;
    case "Height":
      return <img src={HeightIcon} alt="Logo" style={iconStyle} />;
    case "Hip Circumference":
      return <img src={HipCircumstances} alt="Logo" style={iconStyle} />;
    case "Oxygen saturation (SpO2)":
      return <img src={OxygenSaturation} alt="Logo" style={iconStyle} />;
    case "Pulse Rate":
      return <img src={PulseIcon} alt="Logo" style={iconStyle} />;
    case "Respiration Rate":
      return <img src={RespirationRateIcon} alt="Logo" style={iconStyle} />;
    case "Waist Circumference":
      return <img src={WaistCircumstancesIcon} alt="Logo" style={iconStyle} />;
    case "Weight":
      return <img src={WeightIcon} alt="Logo" style={iconStyle} />;
    case "HDL Cholesterol":
      return <img src={CholestrolLevelIcon} alt="Logo" style={iconStyle} />;
    case "Daily Sleep":
      return <img src={HeartRateIcon} alt="Logo" style={iconStyle} />;
    case "Hemoglobin A1c %":
      return <img src={BloodGlucose} alt="Logo" style={iconStyle} />;
    case "LDL Cholesterol":
      return <img src={CholestrolLevelIcon} alt="Logo" style={iconStyle} />;
    case "Total Chol/HDL Ratio":
      return <img src={CholestrolLevelIcon} alt="Logo" style={iconStyle} />;
    case "Waist-to-Height Ratio":
      return <img src={BMIIcon} alt="Logo" style={iconStyle} />;
    case "Resting Heart Rate":
      return <img src={HeartRateIcon} alt="Logo" style={iconStyle} />;

    case "Number of Daily Medications Taken":
      return (
        <img src={Pill} alt="Logo" style={{ ...iconStyle }} width={"100px"} />
      );
    case "Ounces Of Coffee":
      return <LocalCafeOutlinedIcon style={iconStyle} />;
    case "Ounces of Water":
      return <LocalCafeOutlinedIcon style={iconStyle} />;
    default:
      return <ThermostatIcon style={iconStyle} />;
  }
};

export const raceOptions = [
  {
    label: "American Indian or Alaska Native",
    name: "American Indian or Alaska Native",
  },
  { label: "Asian Indian", name: "Asian Indian" },
  { label: "Black or African American", name: "Black or African American" },
  { label: "Chinese", name: "Chinese" },
  { label: "Filipino", name: "Filipino" },
  { label: "Guamanian or Chamorro", name: "Guamanian or Chamorro" },
  { label: "Japanese", name: "Japanese" },
  { label: "Korean", name: "Korean" },
  { label: "Native Hawaiian", name: "Native Hawaiian" },
  { label: "Other Asian", name: "Other Asian" },
  { label: "Pacific Islander", name: "Pacific Islander" },
  { label: "Samoan", name: "Samoan" },
  { label: "Vietnamese", name: "Vietnamese" },
  { label: "White", name: "White" },
  {
    label: "Another race not listed above",
    name: "Another race not listed above",
  },
];

export const ethinicityOptions = [
  { label: "Cuban", name: "Cuban" },
  {
    label: "Mexican, Mexican American, Chicano/a",
    name: "Mexican, Mexican American, Chicano/a",
  },
  {
    label: "Other Hispanic, Latino, or Spanish origin",
    name: "Other Hispanic, Latino, or Spanish origin",
  },
  { label: "Puerto Rican", name: "Puerto Rican" },
  {
    label: "An ethnicity not listed above",
    name: "An ethnicity not listed above",
  },
];

export const reactionOptions = [
  { label: "Abdominal Pain", name: "Abdominal Pain" },
  { label: "Anaphylaxis", name: "Anaphylaxis" },
  { label: "Angioedema", name: "Angioedema" },
  { label: "Arthralgia (Joint Pain)", name: "Arthralgia (Joint Pain)" },
  { label: "Bradycardia", name: "Bradycardia" },
  { label: "Blacking Out", name: "Blacking Out" },
  { label: "Bloating", name: "Bloating" },
  { label: "Breathing Trouble", name: "Breathing Trouble" },
  { label: "Cardiac Arrest", name: "Cardiac Arrest" },
  { label: "Chest Pain", name: "Chest Pain" },
  { label: "Chest Tightness", name: "Chest Tightness" },
  { label: "Confusion", name: "Confusion" },
  { label: "Cough", name: "Cough" },
  { label: "Decreased Blood Pressure", name: "Decreased Blood Pressure" },
  { label: "Diarrhea", name: "Diarrhea" },
  { label: "Dizziness", name: "Dizziness" },
  { label: "Dry Mouth", name: "Dry Mouth" },
  {
    label: "Dyspnea (Difficulty Breathing)",
    name: "Dyspnea (Difficulty Breathing)",
  },
  { label: "Edema", name: "Edema" },
  { label: "Encephalitis", name: "Encephalitis" },
  { label: "Eye Redness", name: "Eye Redness" },
  { label: "Eye Swelling", name: "Eye Swelling" },
  { label: "Facial Swelling", name: "Facial Swelling" },
  { label: "Feeling Faint", name: "Feeling Faint" },
  { label: "Headache", name: "Headache" },
  { label: "Hives", name: "Hives" },
  { label: "Insomnia", name: "Insomnia" },
  { label: "Irregular Heart Rate", name: "Irregular Heart Rate" },
  { label: "Itching", name: "Itching" },
  { label: "Light-Headedness", name: "Light-Headedness" },
  { label: "Muscle Cramps", name: "Muscle Cramps" },
  { label: "Myalgias (Muscle Pain)", name: "Myalgias (Muscle Pain)" },
  { label: "Nausea", name: "Nausea" },
  { label: "Pain", name: "Pain" },
  { label: "Palpitations", name: "Palpitations" },
  { label: "Photosensitivity", name: "Photosensitivity" },
  { label: "Rash", name: "Rash" },
  { label: "Redness", name: "Redness" },
  { label: "Respiratory Distress", name: "Respiratory Distress" },
  { label: "Ringing In Ears", name: "Ringing In Ears" },
  { label: "Runny Nose", name: "Runny Nose" },
  { label: "Seizure", name: "Seizure" },
  { label: "Stevens-Johnson Syndrome", name: "Stevens-Johnson Syndrome" },
  { label: "Sneezing", name: "Sneezing" },
  { label: "Stomach Cramps", name: "Stomach Cramps" },
  { label: "Swelling", name: "Swelling" },
  { label: "Tachycardia", name: "Tachycardia" },
  { label: "Throat Closing", name: "Throat Closing" },
  { label: "Tongue Swelling", name: "Tongue Swelling" },
  { label: "Vasculitis", name: "Vasculitis" },
  { label: "Vomiting", name: "Vomiting" },
  { label: "Watering Eyes", name: "Watering Eyes" },
  { label: "Wheezing", name: "Wheezing" },
  { label: "Other", name: "Other" },
];

export const languageOptions = [
  { label: "Afrikaans", name: "Afrikaans" },
  { label: "Albanian", name: "Albanian" },
  { label: "Amharic", name: "Amharic" },
  { label: "Arabic", name: "Arabic" },
  { label: "Armenian", name: "Armenian" },
  { label: "Assamese", name: "Assamese" },
  { label: "Basque", name: "Basque" },
  { label: "Bengali", name: "Bengali" },
  { label: "Bosnian", name: "Bosnian" },
  { label: "Bulgarian", name: "Bulgarian" },
  { label: "Burmese", name: "Burmese" },
  { label: "Cantonese (or Yue)", name: "Cantonese (or Yue)" },
  { label: "Catalan", name: "Catalan" },
  { label: "Chamorro", name: "Chamorro" },
  { label: "Chinese", name: "Chinese" },
  { label: "Chuukese", name: "Chuukese" },
  { label: "Croatian", name: "Croatian" },
  { label: "Czech", name: "Czech" },
  { label: "Danish", name: "Danish" },
  { label: "Dutch", name: "Dutch" },
  { label: "Egyptian Ancient", name: "Egyptian Ancient" },
  { label: "Elamite", name: "Elamite" },
  { label: "English", name: "English" },
  { label: "Estonian", name: "Estonian" },
  { label: "Farsi", name: "Farsi" },
  { label: "Fijian", name: "Fijian" },
  { label: "Filipino", name: "Filipino" },
  { label: "Finnish", name: "Finnish" },
  { label: "French", name: "French" },
  { label: "French Creole", name: "French Creole" },
  { label: "Gaelic", name: "Gaelic" },
  { label: "German", name: "German" },
  { label: "Greek", name: "Greek" },
  { label: "Gujarati", name: "Gujarati" },
  { label: "Hebrew", name: "Hebrew" },
  { label: "Hiligaynon", name: "Hiligaynon" },
  { label: "Hindi", name: "Hindi" },
  { label: "Hmong", name: "Hmong" },
  { label: "Hungarian", name: "Hungarian" },
  { label: "Igbo", name: "Igbo" },
  { label: "Ilocano (or Iloko)", name: "Ilocano (or Iloko)" },
  { label: "Indonesian", name: "Indonesian" },
  { label: "Irish", name: "Irish" },
  { label: "Italian", name: "Italian" },
  { label: "Japanese", name: "Japanese" },
  { label: "Javanese", name: "Javanese" },
  { label: "Korean", name: "Korean" },
  { label: "Lao", name: "Lao" },
  { label: "Latvian", name: "Latvian" },
  { label: "Lithuanian", name: "Lithuanian" },
  { label: "Macedonian", name: "Macedonian" },
  { label: "Malayalam", name: "Malayalam" },
  { label: "Mandarin", name: "Mandarin" },
  { label: "Marathi", name: "Marathi" },
  { label: "Mien (or lu Mien)", name: "Mien (or lu Mien)" },
  { label: "Mixteco", name: "Mixteco" },
  { label: "Mongolian", name: "Mongolian" },
  { label: "Mon-Khmer", name: "Mon-Khmer" },
  { label: "Navajo", name: "Navajo" },
  { label: "Nepali", name: "Nepali" },
  { label: "Norwegian", name: "Norwegian" },
  { label: "Panjabi (or Punjabi)", name: "Panjabi (or Punjabi)" },
  { label: "Pashto", name: "Pashto" },
  { label: "Persian", name: "Persian" },
  { label: "Polish", name: "Polish" },
  { label: "Portuguese", name: "Portuguese" },
  { label: "Romanian", name: "Romanian" },
  { label: "Russian", name: "Russian" },
  { label: "Samoan", name: "Samoan" },
  { label: "Servian", name: "Servian" },
  { label: "Sign Language", name: "Sign Language" },
  { label: "Sinhalese", name: "Sinhalese" },
  { label: "Slovak", name: "Slovak" },
  { label: "Somali", name: "Somali" },
  { label: "Spanish", name: "Spanish" },
  { label: "Swahili", name: "Swahili" },
  { label: "Swedish", name: "Swedish" },
  { label: "Tagalog", name: "Tagalog" },
  { label: "Telugu", name: "Telugu" },
  { label: "Thai", name: "Thai" },
  { label: "Tigrinya", name: "Tigrinya" },
  { label: "Tonga", name: "Tonga" },
  { label: "Turkish", name: "Turkish" },
  { label: "Ukrainian", name: "Ukrainian" },
  { label: "Urdu", name: "Urdu" },
  { label: "Vietnamese", name: "Vietnamese" },
  { label: "Yiddish", name: "Yiddish" },
  { label: "Yoruba", name: "Yoruba" },
  { label: "Zapotec", name: "Zapotec" },
  { label: "Zulu", name: "Zulu" },
];

export const genderAtBirth = [
  { label: "Female", name: "Female" },
  { label: "Male", name: "Male" },
  { label: "Prefer not to answer", name: "Prefer not to answer" },
  { label: "Unknown", name: "Unknown" },
  { label: "Other", name: "Other" },
];

export const genderIdentity = [
  { label: "Female", name: "Female" },
  { label: "Male", name: "Male" },
  { label: "Transgender female (MTF)", name: "Transgender female (MTF)" },
  { label: "Transgender male (FTM)", name: "Transgender male (FTM)" },
  { label: "Gender identity not listed", name: "Gender identity not listed" },
  { label: "Not sure", name: "Not sure" },
  { label: "Prefer not to answer", name: "Prefer not to answer" },
];

export const sexualOrientation = [
  { label: "Lesbian or gay", name: "Lesbian or gay" },
  { label: "Straight", name: "Straight" },
  { label: "Bisexual", name: "Bisexual" },
  {
    label: "A sexual orientation that’s not listed",
    name: "A sexual orientation that’s not listed",
  },
  { label: "Not sure", name: "Not sure" },
  { label: "Prefer not to answer", name: "Prefer not to answer" },
];

export const notificationsTitleStyle = {
  fontSize: "18px",
  color: "#4C4C4C",
  // font: "normal normal medium 18px/21px Gilroy",
  fontWeight: "600",
};

export const notificationsSubtitleStyle = {
  fontStyle: "normal",
  fontVariant: "medium",
  fontSize: "16px",
  lineHeight: "26px",
  color: "#4C4C4CB2",
  font: "normal normal medium 18px/21px Gilroy",
  opacity: 1,
};

export const checkTabPermission = (codeName) => {
  let hasPermission = null;
  const permissions = JSON.parse(localStorage.getItem("user"))?.tokens?.permissions;
  hasPermission = permissions.some(permission => permission.codename === codeName);
  console.log(`permissions ${codeName}`, hasPermission)
  return hasPermission;
};
