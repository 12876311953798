import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import cardStyle from "../../../theme/card-layout";
import Breadcrumb from "../../../ui-component/Breadcrumb";
import SearchIcon from "@mui/icons-material/Search";
import Adherence from "./components/Adherence";
import { Link, useNavigate } from "react-router-dom";
import DrawerComponent from "../../../ui-component/Drawer";
import BillingReport from "./components/BillingReport";
import Configure from "./components/Configure";
import CCMBilling from "./components/CCMBilling";
import { HorizontalRule } from "@mui/icons-material";
import { Divider } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Box } from "@mui/material";
import { Billing } from "../../../service/Billing";
import { ReactDatePicker } from "../../../ui-component/ReactDatePicker/react-date-picker";
import CheckPermission from "../../../ui-component/ButtonWrapper";

const Billings = () => {
  const dateref = useRef(null);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [monthlyBillingReport, setMonthlyBillingReport] = useState(false);
  const [configure, setConfigure] = useState(false);
  const [patientData, setPatientData] = useState();
  const [isViewReport, setIsViewReport] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [ProviderID, setProviderId] = useState(
    JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
  );


  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let providerGroup =
    userType === "provider_group"
      ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
      : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
          ?.uuid;

          const [ConfigData, setConfigData]= useState({date:" - "})

  const [open, setOpen]= useState(false)
  const [type, setType]= useState(false)
  const [refreshed, setRefreshed]= useState(false)
  const [selectedMonth, setSelectedMonth] = React.useState(
    new Date()
  );
  const [selectedYear, setSelectedYear] = React.useState(
    new Date()
  );
  const [isDatePickerActive, setIsDatePickerActive] = useState(false);

  const handleFocus = () => {
    setIsDatePickerActive(true);
  };

  const handleBlur = () => {
    setIsDatePickerActive(false);
  };

  console.log("patientData", selectedMonth, selectedYear);
  const [AdherenceCount, setAdherenceCount] = useState(null);
  const handleSearch = (e) => {
    const inputText = e.target.value;
    setSearchText(inputText);
  };

  const handleDateChange = (date) => {
    setSelectedRows([])
    const dateObject = new Date(date+1);
    setSelectedYear(dateObject);
    setSelectedMonth(dateObject);
  };

  const onMonthHandler = (month) => {
    console.log("selected Date:", month);
    setSelectedMonth(month);
  };

  const onYearHandler = (year) => {
    console.log("selected Date:", year);
    setSelectedYear(year);
  };

  // useEffect(() => {
  //   // dateref.current.clientHeight = 10
  //   const inputElement = dateref.current.querySelector(".MuiInputBase-input");
    
  //   console.log("first dateref", inputElement.value);
  //   // Check if the input element exists before attempting to modify it
  //   if (inputElement) {
  //     // Change the height of the input element
  //     inputElement.style.height = "6px";
  //     inputElement.style.width = "120px"; // Replace '40px' with your desired height
  //     inputElement.placeholder = "MM-YYYY";
  //     inputElement.value = "MM-YYYY";
  //   }
  // }, [dateref]);


  const handleReportError = () =>{
    const patients = []

    selectedRows.forEach(element => {
      patients.push(element?.patient?.uuid)
      console.log("first selectedRows", element?.patient?.uuid, type)
    });
    Billing.reportBillingError(providerGroup, {patients}, type)
      .then((response) => {
        setRefreshed(true)
        setOpen(false);
        setType("")
        console.log("response reportBillingError", response)
      })
      .catch((error) => {
        console.error("Error fetching Time Log data:", error);
      });
  }

  return (
    <Grid container spacing={3} padding={3}>
      <Grid item xs={12}>
        <Card
          sx={{
            ...cardStyle,
            height: "88vh",
          }}
        >
          <CardContent>
            <Grid container display={"flex"} justifyContent={"space-between"}>
              <Grid item>
                <Breadcrumb value={""} tabLabels={""} title="Billing" />
              </Grid>
              <Grid item display={"flex"} justifyContent={"flex-end"}>
                <TextField
                  variant="outlined"
                  placeholder="Type here to search"
                  size="small"
                  sx={{ ...cardStyle, width: "300px" }}
                  value={searchText}
                  onChange={handleSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid sx={{ marginLeft: 4 }}>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}> */}
      {/* <DatePicker
        ref={dateref}
        views={["month", "year"]}
        style={{ width: "150px" }}
        onChange={handleDateChange}
        disableFuture={true}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CalendarMonthIcon color="primary" />
                </InputAdornment>
              ),
              placeholder: isDatePickerActive ? "Select month and year" : "MM-YYYY",
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        )}
      /> */}
    {/* </LocalizationProvider> */}

    <ReactDatePicker
            selectedButton={"Month"}
            // selectedDateRange={selectedDateRange}
            selectedMonth={selectedMonth}
            selectedYear={selectedYear}
            // onChangeHandler={onChangeHandler}
            onMonthHandler={handleDateChange}
            onYearHandler={handleDateChange}
          />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} marginTop={2}>
              <Adherence AdherenceCount={AdherenceCount} selectedMonth={selectedMonth}
                selectedYear={selectedYear}/>
            </Grid>

            <Grid
              item
              xs={12}
              marginTop={2}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography
                variant="body1"
                style={{ color: "#002c6b", padding: 3 }}
              >
                CCM Billing
                <Divider style={{ border: "0.5px solid #002c6b" }} />
              </Typography>

              {selectedRows?.length >= 1 ? (

                <Box>
                  { 
                  (new Date().getFullYear() ===  new Date(selectedMonth).getFullYear()  && new Date().getMonth()+1 ===  new Date(selectedMonth).getMonth()+1  ) && 
                  <CheckPermission codeName={"change_patientbilling"}>
                    <Button
                    variant="outlined"
                    style={{
                      textTransform: "none",
                      width: "auto", 
                      marginRight:5
                    }}
                    onClick={(e)=> {e.preventDefault();setOpen(true); setType("billing")}}
                  >
                  Submit Billing
                  </Button>
                </CheckPermission>
                }
                { 
                (new Date().getFullYear() ===   new Date(selectedYear).getFullYear()  && new Date().getMonth()+1 ===   new Date(selectedMonth).getMonth()+1 ) && 
                <CheckPermission codeName={"change_patientbilling"}>
                  <Button
                    variant="outlined"
                    style={{
                      textTransform: "none",
                      width: "auto", 
                      marginRight:5
                    }}
                    onClick={(e)=> {e.preventDefault();setOpen(true); setType("error")}}
                  >
                    Report Issue And Error
                  </Button>
                </CheckPermission>
                }
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    width: "auto",
                  }}
                  onClick={() => setMonthlyBillingReport(true)}
                >
                  Monthly Billing Report
                </Button>
                </Box>
              ) : (
                ""
              )}
            </Grid>

            <Grid item xs={12} my={2}>
              <CCMBilling
                setConfigure={setConfigure}
                setIsViewReport={setIsViewReport}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setAdherenceCount={setAdherenceCount}
                setPatientData={setPatientData}
                selectedMonth={selectedMonth}
                selectedYear={selectedYear}
                refreshed={refreshed}
                searchText= {searchText}
                setRefreshed={setRefreshed}
                // ConfigData = {ConfigData}
                setConfigData = {setConfigData}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <DrawerComponent
        open={monthlyBillingReport}
        handleClose={() => setMonthlyBillingReport(false)}
        drawerSize={true}
        data={
          <BillingReport
            handleClose={() => setMonthlyBillingReport(false)}
            selectedRows={selectedRows}
            setRefreshed={setRefreshed}
          />
        }
      />

      <DrawerComponent
        open={configure}
        handleClose={() => setConfigure(false)}
        drawerSize={true}
        data={
          <Configure
            handleClose={() => setConfigure(false)}
            isViewReport={isViewReport}
            patientData={patientData}
            ConfigData = {ConfigData}
            setRefreshed={setRefreshed}
          />
        }
      />


<Dialog open={open} sx={{ padding: "5px" }}>
      <DialogTitle>
        <Typography
          variant="h6"
          sx={{ textAlign: "center", paddingBottom: "8px", fontSize: "22px" }}
        >
          Are You Sure ?
          </Typography>
        <Divider orientation="horizontal" sx={{ color: "#00000099" }} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body1" textAlign="center" sx={{ fontSize: "17px" }}>
          {`Are you sure you want to ${type === "error" ? "report billing issue and error" : "submit billing"} `}?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "15px" }}>
        <Button variant="outlined" color="primary" onClick={(e)=> {e.preventDefault();setType(""); setOpen(false)}} sx={{ textTransform: 'none', }}>
          No
        </Button>
        <Button variant="contained" color="primary" onClick={handleReportError} sx={{ textTransform: 'none', }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
    </Grid>
  );
};

export default Billings;
