import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  Paper,
  RadioGroup,
  Radio,
  CardContent,
  Card,
  IconButton,
  Select,
  CircularProgress,
  Autocomplete,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { styled } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import { IconUpload } from "@tabler/icons-react";
import cardStyle from "../../../../../theme/card-layout";
import Input from "../../../Patients/components/Patient/Input";
import { ProviderGroupApi } from "../../../../../service/ProviderGroup";
// import BillingAddress from "./billingForm";
import { useNotification } from "../../../../../hooks/use-notification";
import { CustomScrollbars } from "../../../../../ui-component/ScrollBar";
import { ProvidersApi } from "../../../../../service/ProviderGroup/Providers";
import { useTheme } from "@mui/material/styles";
import DragAndDrop from "../../../../../ui-component/DragAndDrop";
import { languageOptions } from "../../../../../ccm-constant";

const MainContainer = styled(Grid)({
  height: "100vh",
  display: "flex",
  marginTop: "20px",
});

const ProviderForm = [
  // {
  //   label: "Provider Type",
  //   fieldName: "provider_type",
  //   fieldType: Select,
  //   placeholder: "Select Provider Type",
  //   options: [
  //     {
  //       name: "MD",
  //       label: "MD",
  //     },
  //     {
  //       name: "PA",
  //       label: "PA",
  //     },
  //     {
  //       name: "PSYD",
  //       label: "PSYD",
  //     },
  //     {
  //       name: "LCSW",
  //       label: "LCSW",
  //     },
  //     {
  //       name: "NP",
  //       label: "NP",
  //     },
  //     {
  //       name: "RN",
  //       label: "RN",
  //     },
  //     {
  //       name: "BHNP",
  //       label: "BHNP",
  //     },
  //   ],
  // },
  {
    label: "Phone Number",
    fieldName: "phone_number",
    fieldType: "Phone",
    placeholder: "Enter Phone Number",
  },
  // {
  //   // heading: "Demographics",
  //   label: "Display Name",
  //   fieldName: "display_name",
  //   fieldType: TextField,
  // },
  {
    label: "Email ID",
    fieldName: "email",
    fieldType: TextField,
    placeholder: "Enter Email ID",
  },
  {
    label: "First Name",
    fieldName: "first_name",
    fieldType: TextField,
    placeholder: "Enter First Name",
  },
  {
    label: "Last Name",
    fieldName: "last_name",
    fieldType: TextField,
    placeholder: "Enter Last Name",
  },
  {
    label: "NPI Number",
    fieldName: "npi",
    fieldType: TextField,
    placeholder: "Enter NPI Number",
  },
  {
    label: "Gender",
    fieldName: "gender",
    fieldType: Radio,
    fieldValue: ["Male", "Female", "Other"],
  },

  {
    label: "Year Of Experience",
    fieldName: "year_of_experience",
    fieldType: TextField,
    placeholder: "Enter Year Of Experience",
  },
  {
    label: "License Number",
    fieldName: "license_number",
    fieldType: TextField,
    placeholder: "Enter License Number",
  },
  // {
  //   label: "Language",
  //   fieldName: "langauge",
  //   fieldType: Select,
  //   placeholder: "Select Language",
  //   options: [
  //     {
  //       label: "ENGLISH",
  //       name: "ENGLISH",
  //     },
  //     {
  //       label: "MARATHI",
  //       name: "MARATHI",
  //     },
  //     {
  //       label: "HINDI",
  //       name: "HINDI",
  //     },
  //   ],
  // },
  {
    label: "Address Line 1",
    fieldName: "address_line_1",
    fieldType: TextField,
    placeholder: "Enter Address Line 1",
  },
  {
    label: "Address Line 2",
    fieldName: "address_line_2",
    fieldType: TextField,
    placeholder: "Enter Address Line 2",
  },
  {
    label: "City",
    fieldName: "city",
    fieldType: TextField,
    placeholder: "Enter City",
  },
  {
    label: "State",
    fieldName: "state",
    fieldType: TextField,
    placeholder: "Enter State",
  },
  {
    label: "Country",
    fieldName: "country",
    fieldType: TextField,
    placeholder: "Enter Country",
  },
  {
    label: "Zip Code",
    fieldName: "zip_code",
    fieldType: TextField,
    placeholder: "Enter Zip Code",
  },
  {
    label: "Preferred Languages",
    fieldName: "langauge",
    fieldType: Select,
    placeholder: "Select Language",
    options: languageOptions,
  },
];

function AddProvider({ handleClose, getAllData, addProviderData, addProviderFromEhrSetting, setFetchProviderData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("hgyhuj", addProviderFromEhrSetting)
  const { id } = useParams();
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let Providerid = JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid ||
    JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group
      ?.uuid;
  console.log("=====", id);
  const { notifySuccess, notifyError } = useNotification();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const theme = useTheme();
  const fileTypes = ["JPEG", "PNG", "GIF", "JPG", "SVG", "BMP"];
  const primaryColor = theme.palette.primary.main;
  const [selectedImage, setSelectedImage] = useState(null);
  const [providerForm, setproviderForm] = useState(ProviderForm);
  const [providerSpecialityData, setProviderSpecialityData] = useState([]);
  const [selectedType, setSelectedType] = useState()
  const [providerTypeData, setProviderTypeData] = useState([]);
  const scrollableFieldStyle = {
    height: '130px', // Set the desired height for your scrollable field
    overflow: 'auto',
    width: "100%"
  };
  const DropBox = () => {
    return (
      <Grid container>
        <Card
          sx={{
            height: "10vh",
            width: "19vw",
            // marginLeft: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...cardStyle,
            marginTop: 2,
          }}
        >
          <CardContent>
            <IconButton sx={{ marginLeft: "32px" }}>
              <IconUpload color="primary" />
            </IconButton>
            <Typography>Drop Logo Here</Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const content = DropBox();

  const handleChange = (file) => {
    setFile(file);
  };

  useEffect(() => {
    getProviderSpecialityData();
  }, []);

  useEffect(() => {
    setProviderTypeData([])
    if (selectedType)
      getProviderTypesData();
  }, [selectedType])


  const getProviderTypesData = () => {
    ProviderGroupApi.getProviderType(selectedType)
      .then((response) => {
        let MapData = [];
        response?.data?.forEach((element) => {
          MapData.push({
            // label : `${element?.initials} - ${element?.name}` ,
            label: element?.name,
            name: element?.name,
          });
        });
        setProviderTypeData(MapData);
        console.log("ProviderGroupApi response", MapData);
      })
      .catch((error) => {
        setProviderTypeData([])
        // notifyError(error?.response?.data?.error);
      });
  };




  const getProviderSpecialityData = () => {
    ProviderGroupApi.getProviderSpeciality()
      .then((response) => {
        let MapData = [];
        response?.data?.forEach((element) => {
          console.log("element", element);
          MapData.push({
            name: element,
            label: element,
          });
        });
        setProviderSpecialityData(MapData);
        console.log("ProviderGroupApi response", MapData);
      })
      .catch((error) => {
        // notifyError(error?.response?.data?.error);
      });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email ID format")
      .required("Email ID is required"),
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    npi: Yup.string()
      .matches(
        /^[1-5][0-1][0-9]{8}$/,
        "NPI must be a 10-digit number with first number should between 1-5 and second should be 0 or 1"
      )
      .required("NPI is required"),
    // display_name: Yup.string().required("Provider Group Name is required"),
    gender: Yup.string().required("Gender is required"),
    langauge: Yup.string().required("Language is required"),
    phone_number: Yup.string()
      .matches(/^[\d\s()+-]+$/, "Invalid Phone number")
      .min(10, "Phone number is too short")
      .max(20, "Phone number is too long")
      .required("Phone number is required"),
    speciality: Yup.array().min(1, "At least one speciality is required"), // Ensure at least one speciality is selected

    // speciality: Yup.array().required("Speciality is required"),
    role: Yup.string().required("Provider Role is required"),
    license_number: Yup.string().required("License number is required"),
    // year_of_experience: Yup.string().required("Year Of Experience is required"),
    year_of_experience: Yup.number()
      .required("Year Of Experience is required")
      .typeError("Year Of Experience must be a number")
      .positive("Year Of Experience must be a positive number")
      .min(0, "Year Of Experience must valid")
      .max(70, "Year Of Experience must be at most 70"),
    provider_type: Yup.string().required("Provider Type is required"),
    address_line_1: Yup.string().required("Address Line1 is required"),
    country: Yup.string().required("Country is required"),
    zip_code: Yup.string()
      .matches(
        /^[0-9]{5,6}$/,
        "Zip code must be 5 to 6 digits and contain only numbers"
      )
      .required("Zip code Address is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    // bio: Yup.string().max(250, "Bio must not exceed 250 characters"),
  });

  return (
    // <MainContainer>
    <Formik
      initialValues={{
        email: addProviderFromEhrSetting ? addProviderData?.email : "",
        first_name: addProviderFromEhrSetting ? addProviderData?.first_name : "",
        last_name: addProviderFromEhrSetting ? addProviderData?.last_name : "",
        gender: addProviderFromEhrSetting ? addProviderData?.gender : "Male",
        npi: addProviderFromEhrSetting ? addProviderData?.npi : "",
        display_name: "",
        phone_number: addProviderFromEhrSetting ? addProviderData?.phone_number : "",
        speciality: addProviderFromEhrSetting ? addProviderData?.speciality : [],
        role: addProviderFromEhrSetting ? addProviderData?.role : "",
        langauge: addProviderFromEhrSetting ? addProviderData?.langauge : "",
        license_number: addProviderFromEhrSetting ? addProviderData?.license_number : "",
        provider_group: addProviderFromEhrSetting ? addProviderData?.provider_group : userType === "provider_group" ? Providerid : id,
        provider_type: addProviderFromEhrSetting ? addProviderData?.provider_type : "",
        year_of_experience: addProviderFromEhrSetting ? addProviderData?.year_of_experience : "",
        address_line_1: addProviderFromEhrSetting ? addProviderData?.address?.address_line_1 : "",
        address_line_2: addProviderFromEhrSetting ? addProviderData?.address?.address_line_2 : "",
        country: addProviderFromEhrSetting ? addProviderData?.address?.country : "",
        zip_code: addProviderFromEhrSetting ? addProviderData?.address?.zip_code : "",
        state: addProviderFromEhrSetting ? addProviderData?.address?.state : "",
        city: addProviderFromEhrSetting ? addProviderData?.address?.city : "",
        bio: addProviderFromEhrSetting ? addProviderData?.bio : "",
        // billing_is_same: "Yes",

        // bill_address_line_1: "",
        // bill_zip: "",
        // bill_address_line_2: "",
        // bill_country: "",
        // bill_state: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(data) => {
        console.log("data_provider", data);
        const body1 = {
          email: data.email,
          pull: true,
          pulled_id: addProviderData?.uuid,
          details: {
            first_name: data.first_name,
            last_name: data.last_name,
            // gender: data.gender,
            gender:
              data.gender === "Male"
                ? "M"
                : data.gender === "Female"
                  ? "F"
                  : "O",
            npi: data.npi,
            display_name: data.display_name,
            phone_number: data.phone_number,
            speciality: data?.speciality?.map((ele) => {
              return ele?.name
            }),
            provider_group: data.provider_group,
            license_number: data.license_number,
            role: data.role,
            language: data.langauge,
            year_of_experience: data.year_of_experience,
            provider_type: data.provider_type,
            bio: data.bio,
            // new_speciality: ["Obstetrician", "Cardiologist"],
          },
          address: {
            address_line_1: data.address_line_1,
            address_line_2: data.address_line_2,
            country: data.country,
            city: data.city,
            state: data.state,
            zip_code: data.zip_code,
            billing_is_same: false,
            // billing_address: {
            //   address_line_1: data.bill_address_line_1,
            //   address_line_2: data.bill_address_line_2,
            //   country: data.bill_country,
            //   // city: data.bill_city,
            //   state: data.bill_state,
            //   zip_code: data.bill_zip,
            // },
          },
        };
        const body2 = {
          email: data.email,
          details: {
            first_name: data.first_name,
            last_name: data.last_name,
            // gender: data.gender,
            gender:
              data.gender === "Male"
                ? "M"
                : data.gender === "Female"
                  ? "F"
                  : "O",
            npi: data.npi,
            display_name: data.display_name,
            phone_number: data.phone_number,
            speciality: data?.speciality?.map((ele) => {
              return ele?.name
            }),
            provider_group: data.provider_group,
            license_number: data.license_number,
            role: data.role,
            language: data.langauge,
            year_of_experience: data.year_of_experience,
            provider_type: data.provider_type,
            bio: data.bio,
            // new_speciality: ["Obstetrician", "Cardiologist"],
          },
          address: {
            address_line_1: data.address_line_1,
            address_line_2: data.address_line_2,
            country: data.country,
            city: data.city,
            state: data.state,
            zip_code: data.zip_code,
            billing_is_same: false,
            // billing_address: {
            //   address_line_1: data.bill_address_line_1,
            //   address_line_2: data.bill_address_line_2,
            //   country: data.bill_country,
            //   // city: data.bill_city,
            //   state: data.bill_state,
            //   zip_code: data.bill_zip,
            // },
          },
        };
        setLoading(true);
        ProvidersApi.addUser(addProviderFromEhrSetting ? body1 : body2)
          .then((response) => {
            if (data?.profile_pic && response?.data?.uuid) {
              ProvidersApi.AddProviderImage(response?.data?.uuid, {
                profile_pic: data?.profile_pic[0],
              })
                .then((response) => {
                  console.log("in erorr 3")
                  // notifySuccess(response.message);
                  if (!addProviderFromEhrSetting) {
                    getAllData();
                  }
                  if(addProviderFromEhrSetting)
                  setFetchProviderData(true);
                
                  setLoading(false);
                  notifySuccess("Provider Added Successfully!");
                  handleClose();
                })
                .catch((error) => {
                  console.log("in erorr 1")
                  if(error?.response?.data?.error)
                  notifyError(error?.response?.data?.error);
                });
            } else {
              console.log("in erorr 2")
              notifySuccess("Provider Added Successfully!");
              if (!addProviderFromEhrSetting) {
                getAllData();
              }
              setLoading(false);
              handleClose();
            }
            console.log("osjdkjcidck", response)
          })
          .catch((error) => {
            setLoading(false);
            notifyError(error?.response?.data?.error);
          });
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form>
          <Grid container>
            <Grid container xs={12}>
              <Grid item xs={6}>
                <Typography variant="cardTitle">Add Provider</Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: 2,
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ textTransform: "none", width: "163px" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    width: "163px",
                    marginLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                  onClick={handleSubmit}
                >
                  Save
                  {loading && (
                    <CircularProgress size={18} style={{ color: "white" }} />
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid conatiner mb={3}>
              <CustomScrollbars height="89vh">
                <Grid container xs={12} spacing={3} mt={2}>
                  <Grid item xs={12} lg={6} key={"speciality"}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      Speciality
                    </Typography>
                    {/* <MultipleSelect
                        data={{
                          fieldName: "speciality",
                          placeholder: "Select Speciality",
                          options: providerSpecialityData,
                          defaultValue: [],
                        }}
                      /> */}

                    <Autocomplete
                      multiple
                      limitTags={2}
                      name={"speciality"}
                      options={providerSpecialityData}
                      getOptionLabel={(option) => option?.name}
                      // defaultValue={defaultValue}
                      variant="outlined"
                      fullWidth
                      onChange={(event, newValue) => {

                        setFieldValue("speciality", newValue)
                      }}
                      key={"speciality"}
                      style={cardStyle}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={"Please Select Speciality"}
                        />
                      )}
                    />

                    <ErrorMessage
                      name={"speciality"}
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} key={"provider_role"}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      Provider Role
                    </Typography>
                    <Field name={"role"} variant="outlined" fullWidth>
                      {({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          id="combo-box-demo"
                          // getOptionLabel={(option) => option?.name === "CARETEAM" ? option.label : option}
                          // filterSelectedOptions={}
                          options={[
                            {
                              name: "Care Team",
                              label: "Care Team",
                            },
                            {
                              name: "Physician",
                              label: "Physician",
                            },
                            {
                              name: "Non Clinical",
                              label: "Non Clinical",
                            },
                          ]}
                          sx={cardStyle}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={`Please Select Provider Role`}
                            />
                          )}
                          onChange={(e, newValue) => {
                            setProviderTypeData([])
                            setFieldValue("provider_type", '');
                            setSelectedType(newValue?.label)
                            setFieldValue("role", newValue ? newValue.name : '');
                          }}
                        />
                      )}
                    </Field>

                    <ErrorMessage
                      name={"role"}
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6} key={"provider_type"}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      Provider Type
                    </Typography>
                    <Field name={"provider_type"} variant="outlined" fullWidth>
                      {({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          id="combo-box-demo"
                          options={providerTypeData}
                          sx={cardStyle}
                          disabled={selectedType ? false : true}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={`Please Select Provider Type`}
                            />
                          )}
                          onChange={(e, newValue) => {
                            setFieldValue("provider_type", newValue ? newValue.name : '');
                          }}
                        />
                      )}
                    </Field>

                    <ErrorMessage
                      name={"provider_type"}
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>




                  {/* {
    label: "Role",
    fieldName: "",
    fieldType: Select,
    placeholder: "Select Role",
    
  }, */}

                  {providerForm.slice(0, 20).map((inputData, index) => (
                    <Grid item xs={12} lg={6} key={index}>
                      <Input data={inputData} />
                      <ErrorMessage
                        name={inputData.fieldName}
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12} lg={6}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      Bio
                    </Typography>

                    <Field
                      as={TextField}
                      fullWidth
                      minRows={6}
                      maxRows={6}
                      multiline={true}
                      name="bio"
                      placeholder="Enter Bio"
                      style={{ ...cardStyle }}
                      sx={{
                        width: "100%",
                      }}
                    />
                    <ErrorMessage
                      name="bio"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "20px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6} paddingBottom={1}>
                    <DragAndDrop isProvider={false} />
                  </Grid>
                </Grid>
              </CustomScrollbars>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

export default AddProvider;
