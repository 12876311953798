import React from 'react'
import CareManagementTabs from './Features/Index'

function CareManagement({dataRefresh, setDataRefresh}) {
  return (
    <>
    <CareManagementTabs dataRefresh={dataRefresh} setDataRefresh={setDataRefresh}/>
    </>
  )
}

export default CareManagement