import React, { lazy } from "react";
import {
  DashboardOutlinedIcon,
  AccountCircleRoundedIcon,
  SettingsOutlinedIcon,
  LogoutRoundedIcon,
} from "../assets/Icons/SideBarIcons";
import Settings from "../views/workspaces/Settings";
import UserProfileDetails from "../views/workspaces/Settings/Components/AdminUser/UserProfileDetails";
import ProviderProfileDetails from "../views/workspaces/ProviderGroup/components/ProviderProfile/Index";

// Provider component
const Analytics = lazy(() => import("../views/workspaces/Dashboard"));
const Provider = lazy(() => import("../views/workspaces/ProviderGroup"));
const Logout = lazy(() => import("../views/authentication/log-out"));
const ProviderProfile = lazy(() =>
  import("../views/workspaces/ProviderGroup/components/provider-group-profile")
);
const ProviderUpdate = lazy(() =>
  import("../views/workspaces/ProviderGroup/feature/updateGroup")
);
const ProviderGroupTabs = lazy(() =>import("../views/workspaces/ProviderGroup/components/Tabs"));

export const Admin = [
  {
    id: "analytics",
    title: "Analytics",
    type: "item",
    icon: <DashboardOutlinedIcon />,
    url: `/analytics`,
    breadcrumbs: true,
    element: <Analytics />,
  },
  {
    id: "provider-group",
    title: "Provider Group",
    type: "item",
    icon: <AccountCircleRoundedIcon />,
    url: `/provider-group`,
    breadcrumbs: true,
    element: <Provider />,
    children: [
      {
        id: "provider-group-profile",
        title: "Provider Group Profile",
        type: "item",
        icon: <AccountCircleRoundedIcon />,
        url: `/provider-group/profile/:id`,
        breadcrumbs: true,
        element: <ProviderProfile />,
      },
      {
        id: "provider-group-update",
        title: "Provider Group",
        type: "item",
        icon: <AccountCircleRoundedIcon />,
        url: `/provider-group/update/:id`,
        breadcrumbs: true,
        element: <ProviderUpdate />,
      },
      {
        id: "provider-user",
        title: "Provider User",
        type: "item",
        icon: <AccountCircleRoundedIcon />,
        url: `/provider-group/:id/provider-user/:tab/:provider_id`,
        breadcrumbs: true,
        element: <ProviderProfileDetails />,
      },
      {
        id: "provider-group-Tab",
        title: "Provider Group",
        type: "item",
        icon: <AccountCircleRoundedIcon />,
        url: `/provider-group/:id`,
        breadcrumbs: true,
        element: <ProviderGroupTabs />,
      },
    ],
  },
  {
    id: "settings",
    title: "Settings",
    type: "item",
    icon: <SettingsOutlinedIcon />,
    url: "/settings",
    breadcrumbs: true,
    element: <Settings />,
    children: [
      {  
        id: "settings-profile",
        title: "Settings",
        type: "item",
        icon: <AccountCircleRoundedIcon />,
        url: `/settings/Admin/profile/:id`,
        breadcrumbs: true,
        element: <UserProfileDetails />,
      },
    ],
  },
  { id: "logout", 
    url: "/logout", 
    element: <Logout/>, 
    icon:<LogoutRoundedIcon />,  
    title: "Logout",
    type: "item", },
];
