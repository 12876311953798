import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Radio,
  Autocomplete,
  Box,
  CardContent,
  Typography,
  Card,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  RadioGroup,
  Rating,
  Button,
  CircularProgress,
} from "@mui/material";
import {
  addQuesAns,
  removeQuesAns,
  addGoals,
} from "../../../../../../../../../store/slices/queAns";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import cardStyle from "../../../../../../../../../theme/card-layout";
import { CustomScrollbars } from "../../../../../../../../../ui-component/ScrollBar";

function QueAnsCard({ data, onSubmit, goals, loading, handleBack, activeStep }) {
  console.log("dillllllllll", data);
  const { answer } = useSelector((state) => state);
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  const [goalsData, setGoalsData] = useState(answer)
  console.log("activeStep answer", answer)
  useEffect(() => {
    setGoalsData(answer)
  }, [answer])
  
  return (
    <Formik
      initialValues={{
          // checkOption: [],
          // radioOption: "",
        }}
      onSubmit={(values) => {
        console.log("values??? 11", values);
        const transformedData = Object.keys(values).map((questionId) => ({
          question: questionId,
          answer:
            typeof values[questionId] === "string"
              ? [values[questionId]]
              : values[questionId],
        }));
        dispatch(addQuesAns(transformedData));

        if (goals) {
          let allGoal = Object.keys(values)?.map((questionId) => {
            console.log("item", questionId.split("_")[0]);
            if (questionId.split("_")[0] === "1") { // Corrected the condition
              return {
                answer: [values[questionId]],
              };
            } else {
              return null; // Add this to handle non-matching keys
            }
          });
        
          console.log("allGoal", allGoal);
        
          // Filter out null values (non-matching keys)
          allGoal = allGoal.filter((item) => item !== null);
        
          if (allGoal?.length) {
            const outputArray = allGoal.flatMap((item) => item.answer[0]);
            console.log(outputArray);
            console.log("goals???", allGoal, outputArray);
            dispatch(addGoals(outputArray));
          }
        }

        console.log("transformedData", transformedData);
        activeStep === 9 ? setTimeout(() => {
          console.log("answer1111", answer)
          let ans = {...answer}
          ans['quaAns'] = transformedData
          console.log("first ans", ans)
          setGoalsData(ans)
          onSubmit(ans)

        }, 100) :
        onSubmit(transformedData)
      }}
    >
      {({ handleSubmit, values, setFieldValue, isSubmitting }) => (
        <Form>
          <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  marginBottom: 2,
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ textTransform: "none", width: "163px" }}
                  onClick={handleBack}
                  // onClick={activeStep === 0 ? handleClose : handleBack}

                >
                  {activeStep === 1 ? "Cancel" : "Previous Question"}
                </Button>
                <Button
                  variant="contained"
                  style={{
                    textTransform: "none",
                    width: "163px",
                    marginLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                  onClick={handleSubmit}
                  // onClick={handleNext}

                >
                  {activeStep === 9 ? "Finish" : "Next Question"}
                  {/* Save & Next */}
                  {/* {loading && (
                  <CircularProgress size={18} style={{ color: "white" }} />
                )} */}
                </Button>
              </Grid>
          {loading ? (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "40vh",
              }}
            >
              <CircularProgress color="primary" />
            </Grid>
          ) : data.length == 0 ? (
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "30vh",
                width: 480
              }}
            >
              No Questions Available
            </Grid>
          ) : (
            <Grid item xs={12}>
              <CustomScrollbars height="80vh">
                <Grid sx={{width:480}}>
                  {data?.map((item, index) => (
                    <Grid item xs={12} padding={1.5} spacing={3} key={index}>
                      <Card sx={{...cardStyle, width: 500}}>
                        <CardContent>
                          <Grid item xs={12}>
                            <Typography variant="subtitle1">
                              {item.question}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {item.type === "MCMA" && (
                              <FormControl component="fieldset">
                                <Field name={`${activeStep}_${item.uuid}`}>
                                  {({ field }) => (
                                    <FormGroup
                                      {...field}
                                      aria-label="checkbox-group"
                                      name="checkbox-group"
                                    >
                                      {item?.options?.map((choice, index) => (
                                        <FormControlLabel
                                          key={index}
                                          control={
                                            <Field
                                              name={`${activeStep}_${item.uuid}`}
                                              type="checkbox"
                                              value={choice}
                                              as={Checkbox}
                                              onClick={(e, newValue) =>
                                                setFieldValue(
                                                  `${activeStep}_${item.uuid}`,
                                                  newValue
                                                )
                                              }
                                            />
                                          }
                                          label={choice}
                                        />
                                      ))}
                                    </FormGroup>
                                  )}
                                </Field>
                              </FormControl>
                            )}
                            {item.type === "MCSA" && (
                              <FormControl component="fieldset">
                                <Field name={`${activeStep}_${item.uuid}`}>
                                  {({ field }) => (
                                    <RadioGroup
                                      {...field}
                                      aria-labelledby="demo-radio-buttons-group-label"
                                    >
                                      {item?.options?.map((choice, index) => (
                                        <FormControlLabel
                                          key={index}
                                          value={choice}
                                          control={<Radio />}
                                          label={choice}
                                          onClick={(e, newValue) =>
                                            setFieldValue(`${activeStep}_${item.uuid}`, newValue)
                                          }
                                        />
                                      ))}
                                    </RadioGroup>
                                  )}
                                </Field>
                              </FormControl>
                            )}
                            {item.type === "Free_Text" && (
                              <Field
                                as={TextField}
                                id={`${activeStep}_${item.uuid}`}
                                name={`${activeStep}_${item.uuid}`}
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth
                                sx={cardStyle}
                              />
                            )}
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </CustomScrollbars>
            </Grid>
          )}
          {/* {data.length !== 0 && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  width: "163px",
                  marginTop: "25px",
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          )} */}
        </Form>
      )}
    </Formik>
  );
}
export default QueAnsCard;
