import {
  Button,
  Card,
  Grid,
  Typography,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import cardStyle from "../../../../../../../../../theme/card-layout";
import CircularLoader from "../../../../../../../../../ui-component/CircularLoader";
import { useNotification } from "../../../../../../../../../hooks/use-notification";
import SquareIcon from "@mui/icons-material/Square";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/system";
import { PatientApi } from "../../../../../../../../../service/ProviderGroup/Patient";
import { useParams } from "react-router";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { updateNoteHistory } from "../../../../../../../../../store/slices/TImeLogSlice";
import * as Yup from "yup";

const ColoredSlider = styled(Slider)(({ dynamicColorStops }) => {
  const generateGradient = () => {
    let gradient = "linear-gradient(90deg, ";
    dynamicColorStops.forEach((stop, index) => {
      gradient += `${stop.color} ${stop.percent}%`;
      if (index < dynamicColorStops.length - 1) {
        gradient += ", ";
      }
    });
    gradient += ")";
    return gradient;
  };

  return {
    "& .MuiSlider-rail": {
      height: "8px",
      borderRadius: 0,
      background: generateGradient(),
      opacity: 1,
    },
    "& .MuiSlider-track": {
      backgroundColor: "transparent",
      border: 0,
      borderRadius: 0,
    },
    "& .MuiSlider-thumb": {
      borderRadius: "23%",
      width: "20px",
      border: "1px solid #f9f9f9",
      backgroundColor: "#e2e2e4",
      "&:hover, &.Mui-focusVisible": {
        zIndex:1,
        boxShadow: "0 0 0 8px rgba(226, 226, 228, 0.16)",
      },
    },
    "& .MuiSlider-valueLabel": {
      "& span": {
        color: "#4C4C4C",
        fontSize: "16px",
        padding: 0,
      },
      "& .span": {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1,
        color: '#your-active-color',
      },
      "&::after": {
        position: "absolute",
        top: 1,
        color: "#ffffff",
        fontSize: "16px",
        right: 5,
      },
      padding: 1,
      paddingRight: 1,
      width: "40px",
      backgroundColor: "#ebeaea",
      border: "1px solid #979696",
      transition: "background-color 0.3s, top 0.3s", // Adjust transition for top position
      "&:hover, &.Mui-focusVisible": {
        backgroundColor: "#b3b0b0",
      },
    },
  };
});


const ProgressBarWithSlider = ({ values, setValues, type, max_range, graph_max_range }) => {
  const [dynamicColorStops, setDynamicColorStops] = useState([]);

  useEffect(() => {
    console.log("first type00000000000", type)
    // if (["blood_glucose"].includes(type)) {
    //   setDynamicColorStops([
    //     { color: "#FF0000CC", percent: 0 },
    //     { color: "#FF0000CC", percent: (values[0] / (graph_max_range)) * 100 },
    //     {
    //       color: "#FF9925",
    //       percent: ((values[0] + 1) / (graph_max_range)) * 100,
    //     },
    //     { color: "#FF9925", percent: (values[1] / (graph_max_range)) * 100 },
    //     {
    //       color: "#5FD557",
    //       percent: ((values[1] + 1) / (graph_max_range)) * 100,
    //     },
    //     { color: "#5FD557", percent: (values[2] / (graph_max_range)) * 100 },
    //     {
    //       color: "#FF9925",
    //       percent: ((values[2] + 1) / (graph_max_range)) * 100,
    //     },
    //     { color: "#FF9925", percent: (values[3] / (graph_max_range)) * 100 },
    //     {
    //       color: "#FF0000CC",
    //       percent: ((values[3] + 1) / (graph_max_range)) * 100,
    //     },
    //     { color: "#FF0000CC", percent: graph_max_range },
    //   ]);
    // } 
    // else{
      setDynamicColorStops(type === "avg_spo2" ? [
        {
          color: "#FF0000CC",
          percent: 0,
        },
        { color: "#FF0000CC", percent: ((values[0]) / (graph_max_range)) * 100 },
        { color: "#FF9925", percent: ((values[0]) / (graph_max_range)) * 100 },
        { color: "#FF9925", percent: (values[1] / (graph_max_range)) * 100 },
        {
          color: "#5FD557",
          percent: ((values[1]) / (graph_max_range)) * 100,
        },
        { color: "#5FD557", percent: 100 },
        // { color: "#FF9925", percent: ((values[]) / (graph_max_range)) * 100, },
        // { color: "#FF9925", percent: (values[2] / (graph_max_range)) * 100 },
        
      ] : [
        { color: "#FF9925", percent: 0 },
        { color: "#FF9925", percent: (values[0] / (graph_max_range)) * 100 },
        {
          color: "#5FD557",
          percent: ((values[0]) / (graph_max_range)) * 100,
        },
        { color: "#5FD557", percent: (values[1] / (graph_max_range)) * 100 },
        { color: "#FF9925", percent: ((values[1]) / (graph_max_range)) * 100, },
        { color: "#FF9925", percent: (values[2] / (graph_max_range)) * 100 },
        {
          color: "#FF0000CC",
          percent: ((values[2]) / (graph_max_range)) * 100,
        },
        { color: "#FF0000CC", percent: 100 },
      ]);
    // }
  }, [values]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", m: 1 }}>
        <ColoredSlider
          value={values.map((ele) => parseFloat(ele.toFixed(1)))}
          onChange={(event, newValues) => {
            setValues(()=> newValues.map((ele) => parseFloat(ele.toFixed(1))));
          }}
          valueLabelDisplay="on"
          sx={{ mt: 2.3 }}
          step={0.1}
          min={0.0}
          max={graph_max_range}
          dynamicColorStops={dynamicColorStops}
        />
      </Box>
    </>
  );
};

function Range({
  onClose,
  loading,
  setRefreshHistory,
  vitalsType,
  vitalsReading,
  vitalID,
  type,
  setRefresh,
}) {
  const cardRef = useRef();
  const { id } = useParams();
  const [values, setValues] = useState(
  [
      0,
      vitalsReading?.min_range,
      vitalsReading?.max_range,
      vitalsReading?.graph_max_range,
    ]
    );
    const [diastolicvalues, setDiastolicValues] = useState(
    [
        // 0,
        vitalsReading?.diastolic_min_range,
        vitalsReading?.diastolic_max_range,
        vitalsReading?.graph_max_range,
        ]
        );
        console.log("firstvitalsReading", values, diastolicvalues);
        const [allProviders, setAllProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const { notifySuccess, notifyError } = useNotification();
  const dispatch = useDispatch();


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose]);


  useEffect(() => {
  if(vitalsReading?.provider){
    setSelectedProvider({label:`${vitalsReading?.provider?.first_name} ${vitalsReading?.provider?.last_name}`, name:vitalsReading?.provider?.uuid})
  }
  }, [vitalsReading?.provider])
  
  useEffect(() => {
    console.log(
      "diastolic_alert_range",
      vitalsReading?.diastolic_alert_range?.length
    );
    fetchAllProviders();
    // if (
    //   vitalsReading?.alert_range?.min_critical &&
    //   ["blood_glucose"].includes(type)
    // ) {
    //   setValues([
    //     vitalsReading?.alert_range !== undefined
    //     ? vitalsReading?.alert_range?.normal?.low
    //     : vitalsReading?.min_range,
    //   vitalsReading?.alert_range !== undefined
    //     ? vitalsReading?.alert_range?.normal?.high
    //     : vitalsReading?.max_range,
    //   vitalsReading?.alert_range?.max_critical !== undefined 
    //   ? vitalsReading?.alert_range?.max_critical?.low
    //     : vitalsReading?.graph_max_range,
    //   ]);
      
    // } else{
        setValues(vitalsReading?.alert_range?.min_critical && type === "avg_spo2" ? [
          vitalsReading?.alert_range?.min_critical !== undefined 
          ? vitalsReading?.alert_range?.min_critical?.high
            : vitalsReading?.graph_min_range,
          vitalsReading?.alert_range !== undefined
            ? vitalsReading?.alert_range?.normal?.low
            : vitalsReading?.min_range,
          // vitalsReading?.alert_range !== undefined
          //   ? vitalsReading?.alert_range?.normal?.high
          //   : vitalsReading?.max_range,
        ]:[
          vitalsReading?.alert_range !== undefined
            ? vitalsReading?.alert_range?.normal?.low
            : vitalsReading?.min_range,
          vitalsReading?.alert_range !== undefined
            ? vitalsReading?.alert_range?.normal?.high
            : vitalsReading?.max_range,
          vitalsReading?.alert_range?.max_critical !== undefined 
          ? vitalsReading?.alert_range?.max_critical?.low
            : vitalsReading?.graph_max_range,
        ])
      
        if (vitalsReading?.diastolic_alert_range)
        setDiastolicValues([
            vitalsReading?.diastolic_alert_range !== undefined
            ? vitalsReading?.diastolic_alert_range?.normal?.low
            : vitalsReading?.min_range,
          vitalsReading?.diastolic_alert_range !== undefined
            ? vitalsReading?.diastolic_alert_range?.normal?.high
            : vitalsReading?.max_range,
          vitalsReading?.diastolic_alert_range?.max_critical !== undefined 
          ? vitalsReading?.diastolic_alert_range?.max_critical?.low
            : vitalsReading?.graph_max_range,
        ]);
      // }
  }, [vitalsReading?.alert_range]);

  const updateVitalsRange = (value) => {
    console.log("first value", value)
    let payload = [];
    if (type === "blood_pressure") {
      payload.push(
        {
          type: "systolic",
          alert_range: {
            normal: {
              low: values[0],
              high: values[1],
            },
            max_critical: {
              low: values[2],
            },
          },
          provider:  value?.provider?.name,
        },
        {
          type: "diastolic",
          alert_range: {
            normal: {
              low: diastolicvalues[0],
              high: diastolicvalues[1],
            },
            max_critical: {
              low: diastolicvalues[2],
            },
          },
          provider:  value?.provider?.name,
        }
      );
    } else if(type === "avg_spo2") {
        payload.push({
          type: type,
          alert_range: {
            normal: {
              low: values[1],
              high: values[2],
            },
            min_critical: {high:values[0]},
          },
          provider: value?.provider?.name,
        });
    }
    else{
      payload.push({
        type: type,
        alert_range: {
          normal: {
            low: values[0],
            high: values[1],
          },
          max_critical: {low:values[2]},
        },
        provider: value?.provider?.name,
      });
    }
    dispatch(updateNoteHistory(false));
    PatientApi.setVitalsRange(id, payload)
      .then((response) => {
        // setRefreshHistory(true);
        // setRefresh(true);
        dispatch(updateNoteHistory(true));
        notifySuccess(response?.message);
        console.log("firstvitalsReading 1", response);
        onClose();
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error);
        console.log("getAllVitals error", error?.response?.data?.error);
      });
  };

  const fetchAllProviders = async () => {
    console.log("I am calling");
    const query = {
      order_by: "first_name",
      sort_by: "asc",
      all: true
    };
    PatientApi.getLoggedByList(id, query)
      .then((response) => {
        setAllProviders(response?.data);
      })
      .catch((error) => {
        console.error("Error fetching Providers data:", error);
      });
  };

  const validationSchema = Yup.object().shape({
    // Define your validation rules here
    provider: Yup.object().required("Provider is required"),
    // Add other fields if needed
  });

  return (
    <>
      <Card
        ref={cardRef}
        sx={{
          ...cardStyle,
          position: "absolute",
          bottom: "5.6vh",
          right: "3.7vw",
          width: "650px",
          minHeight: "47.5vh",
        }}
      >
        <Formik
          initialValues={{
            provider: selectedProvider, // Initial value for the provider field
            // Add other initial values if needed
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            // Handle form submission here
            console.log("Form values:", values);
            updateVitalsRange(values); // Pass the form values to your existing function
          }}
          enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>
              <Grid
                container
                padding={2}
                paddingRight={3}
                paddingLeft={3}
                height={"40px"}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Grid
                  item
                  display={"flex"}
                  justifyContent={"space-between"}
                  paddingTop={1}
                >
                  <SquareIcon
                    style={{
                      backgroundColor: "#5FD557",
                      color: "#5FD557",
                      height: "15px",
                      marginTop: 3,
                    }}
                  />
                  <Typography variant="body1" marginRight={1} marginLeft={1}>
                    Normal
                  </Typography>
                  <SquareIcon
                    style={{
                      backgroundColor: "#FF9925",
                      color: "#FF9925",
                      height: "15px",
                      marginTop: 3,
                    }}
                  />
                  <Typography variant="body1" marginRight={1} marginLeft={1}>
                    Abnormal
                  </Typography>
                  <SquareIcon
                    style={{
                      backgroundColor: "#FF0000CC",
                      color: "#FF0000CC",
                      marginTop: 3,
                      height: "15px",
                    }}
                  />
                  <Typography variant="body1" marginRight={1} marginLeft={1}>
                    Critical
                  </Typography>
                </Grid>
                <Grid item sx={{ textAlign: "end" }}>
                  <Button
                    variant="contained"
                    style={{ textTransform: "none", marginRight: 5 }}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ textTransform: "none", marginRight: 5 }}
                    onClick={onClose}
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
              {loading ? (
                <Grid items xs={12}>
                  <CircularLoader />
                </Grid>
              ) : (
                <Grid item xs={12} padding={3}>
                  {/* <CustomScrollbars height={"33vh"}> */}
                  <Typography
                    variant="h6"
                    marginBottom={type === "blood_pressure" ? 1 : 3}
                  >
                    {vitalsType}
                  </Typography>
                  <Grid
                    container
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      variant="body1"
                      marginTop={2}
                      marginBottom={type === "blood_pressure" ? 1 : 3}
                    >
                      Interval : Daily
                      <span style={{ color: "#FF0000CC" }}>
                        (Alert if value is)
                      </span>
                    </Typography>
                    {/* <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={[
                ...(allProviders?.map((provider) => ({
                  label: `${provider?.first_name} ${provider?.last_name}`,
                  name: `${provider?.uuid}`,
                })) || []),
              ]}
              sx={{minWidth:"300px", marginBottom:type === "blood_pressure" ? 2 : 4, zIndex: 9999}}
              getOptionLabel={(option) => option?.label || option} // Use the label property for display
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={`Select provider`}
                  onChange={(e, newValue) => {}}
                />
              )}
              onChange={(e, newValue) => {
                setSelectedProvider(newValue);
              }}
            /> */}
              <Grid item xs={6} >
                    <Field name={"provider"} variant="outlined" fullWidth>
                      {({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          id="combo-box-demo"
                          options={[
                            ...(allProviders?.map((provider) => ({
                              label: `${provider?.first_name} ${provider?.last_name}`,
                              name: `${provider?.uuid}`,
                            })) || []),
                          ]}
                          sx={{
                            ...cardStyle,
                            minWidth: "300px",
                            // marginBottom: type === "blood_pressure" ? 2 : 4,
                            zIndex: 9999,
                          }}
                          getOptionLabel={(option) =>
                            option?.label || option?.name || option
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              {...field}
                              placeholder={`Select Provider`}
                              onChange={(e, newValue) => {}}
                            />
                          )}
                          onChange={(e, newValue) => {
                            setFieldValue("provider", newValue);
                            setSelectedProvider(newValue);
                          }}
                        />
                      )}
                    </Field>
                  
                    <ErrorMessage
                      name={"provider"}
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                    </Grid>
                    
                  </Grid>

                  {/* </CustomScrollbars> */}
                </Grid>
              )}
            </Form>
          )}
        </Formik>
        <Grid item xs={12}   padding={2}
                paddingRight={3}
                paddingLeft={3}
                height={"40px"}
              >
        {type === "blood_pressure" && (
          <Typography variant="subtitle1" marginBottom={2}>
            Systolic
          </Typography>
        )}
        {
          <ProgressBarWithSlider
            type={type === "blood_pressure" ? "systolic" : type}
            values={values}
            setValues={setValues}
            max_range={vitalsReading?.max_range}
            graph_max_range={vitalsReading?.graph_max_range}
          />
        }
        {type === "blood_pressure" && (
          <>
            <Typography variant="subtitle1" marginBottom={2}>
              {" "}
              Diastolic{" "}
            </Typography>
            <ProgressBarWithSlider
              type={"diastolic"}
              values={diastolicvalues}
              setValues={setDiastolicValues}
              max_range={vitalsReading?.diastolic_max_range}
              graph_max_range={vitalsReading?.graph_max_range}
            />
          </>
        )}
        </Grid>
      </Card>
    </>
  );
}

export default Range;
