import React from "react";
import DataTable from "react-data-table-component";
import { customDataTableStyles } from "../../../../../../ccm-constant";

function FormDataTable({ data, columns }) {
  return (
    <DataTable
      columns={columns}
      customStyles={customDataTableStyles}
      data={data}
      sortServer
    />
  );
}

export default FormDataTable;
