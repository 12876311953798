import React from "react";
import { Avatar, Card, Grid, Typography } from "@mui/material";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import MonitorHeartOutlinedIcon from "@mui/icons-material/MonitorHeartOutlined";
import cardStyle from "../../../../../../../../../theme/card-layout";
import {
  VitalsCardStyle,
  formatDate,
  formatDateAndTime,
  Icon
} from "../../../../../../../../../ccm-constant";
import TrendingDownOutlinedIcon from '@mui/icons-material/TrendingDownOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';

const iconContainerStyle = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  paddingRight: '3px'
};

const VitalsCard = ({ data, active, ind }) => {
  const { color, background, border, borderRadius, opacity, borderColor } =
    VitalsCardStyle(ind%10) || [];
  console.log("VitalsCard data", data);
  console.log("background", background);

  const cardContainerStyle = {
    height: "150px",
    ...cardStyle,
    background,
    border: active ? `5px solid ${color}` : `5px solid ${borderColor}`,
    borderRadius: borderRadius,
    // boxSizing: active ? "border-box" : "auto",
    opacity: opacity,
  };

  return (
    <Card style={cardContainerStyle}>
      <Grid container spacing={2}>
        <Grid item xs={12} padding={0}>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Grid item sx={iconContainerStyle}>
              <Typography
                variant="h6"
                sx={{
                  color: color,
                  fontSize: "28px",
                  padding: "0px 1px 1px 5px",
                  // padding: 1,
                  margin: 0,
                }}
              >
                {data?.value}
              </Typography>
              <Typography
                variant="h6"
                sx={{ color: color, fontSize: "18px", paddingTop: 1 }}
              >
                {data?.unit}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container style={iconContainerStyle}>
                <Grid>{Icon(data?.label, color)}</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} paddingTop={0} rowSpacing={0}>
          <Grid
            container
            paddingRight={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              padding: 0
            }}
          >
            <Typography
              margin={0}
              variant="h6"
              sx={{ textAlign: "end", fontSize: "20px", fontWeight: 500, paddingRight: 1 }}
            >
              {data?.label}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: 0 }}>
          <Grid
            container
            display={"flex"}
            justifyContent={"space-between"}
            paddingLeft={1}
            paddingRight={1}
          // padding={1}
          // sx={{ paddingTop: data?.label === "Blood Pressure" ? 0 : 2 }}
          >
            {/* {data?.label === "Blood Pressure" ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="body1" display="flex" justifyContent="flex-start">
                    Systolic :&nbsp;
                    {data?.defSystolic >= 0 ? (
                      <TrendingUpOutlinedIcon style={{ color: "#2BBC30", marginRight: 1 }} />
                    ) : (
                      <TrendingDownOutlinedIcon style={{ color: "#FA0801", marginRight: 1 }} />
                    )}
                    {Math.abs(data?.defSystolic?.toFixed(1))}%
                  </Typography>
                </Grid>
              </>
            ) : ( */}
            {data?.label !== "Blood Pressure" &&
              <Typography variant="body1" display="flex" justifyContent="flex-start">
                {data?.deflection >= 0 ? (
                  <TrendingUpOutlinedIcon style={{ color: "#2BBC30", marginRight: 1 }} />
                ) : (
                  <TrendingDownOutlinedIcon style={{ color: "#FA0801", marginRight: 1 }} />
                )}
                {Math.abs(data?.deflection?.toFixed(1))}%
              </Typography>
            }
            {/* )} */}

            {data?.label === "Blood Pressure" ? (
              <>
                <Grid item sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                  <Typography variant="body1" display="flex" justifyContent="flex-start">
                    {/* Diastolic :&nbsp; */}
                    {data?.defSystolic >= 0 ? (
                      <TrendingUpOutlinedIcon style={{ color: "#2BBC30", marginRight: 1 }} />
                    ) : (
                      <TrendingDownOutlinedIcon style={{ color: "#FA0801", marginRight: 1 }} />
                    )}
                    {Math.abs(data?.defSystolic?.toFixed(1))}% /
                    {data?.defDiastolic >= 0 ? (
                      <TrendingUpOutlinedIcon style={{ color: "#2BBC30", marginRight: 1 }} />
                    ) : (
                      <TrendingDownOutlinedIcon style={{ color: "#FA0801", marginRight: 1 }} />
                    )}
                    {Math.abs(data?.defDiastolic?.toFixed(1))}%
                  </Typography>
                </Grid>
                <Grid item sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                  <Typography variant="body1" color={color}>
                    {data?.dateDiastolic ? formatDateAndTime(data?.dateDiastolic) : ""}
                  </Typography>
                </Grid>
              </>
            ) : (
              <Grid item sx={{ justifyContent: "flex-end", alignItems: "center" }}>
                <Typography variant="body1" color={color}>
                  {formatDateAndTime(data?.created_at)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default VitalsCard;
