import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  TextField,
  Button,
  IconButton,
  Radio,
  Select,
  CircularProgress,
  TextareaAutosize,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useLocation, useNavigate } from "react-router";
import { ProviderGroupApi } from "../../../../../service/ProviderGroup/index";
import Input from "../../../Patients/components/Patient/Input";
import { useNotification } from "../../../../../hooks/use-notification";
import cardStyle from "../../../../../theme/card-layout";
import { CustomScrollbars } from "../../../../../ui-component/ScrollBar";
import avatar from "../../../../../assets/adminAvatar.jpg";
import BillingAddress from "../../../ProviderGroup/feature/AddProviderGroup/billingForm";
import { saveState } from "../../../../../helpers/storage";
const containerStyle = {
  position: "relative",
};

const imageContainerStyle = {
  position: "relative",
  // display: "flex !important",
  // alignItems: "center !important",
  // minHeight: "180px",
};

const cameraIconStyle = {
  position: "absolute",
  bottom: -15,
  left: "7rem",
  transform: "translateX(-50%)",
  backgroundColor: "white",
  borderRadius: "50%",
};

const providerGroupsDetails = [
  {
    label: "Email ID",
    fieldName: "email",
    fieldType: TextField,
    placeholder: "Enter Email ID",
    disable: true,
  },
  {
    label: "Provider Group Name",
    fieldName: "display_name",
    fieldType: TextField,
    placeholder: "Enter Provider Group Name",
  },
  {
    label: "Phone Number",
    fieldName: "phone_number",
    fieldType: "",
    placeholder: "Enter Phone Number",
  },
  {
    label: "Speciality",
    fieldName: "speciality",
    fieldType: Select,
    options: [],
    placeholder: "Select Speciality",
  },
  {
    label: "Website",
    fieldName: "website",
    fieldType: TextField,
    placeholder: "Enter Website",
  },
  {
    label: "Practice ID",
    fieldName: "practice_id",
    fieldType: TextField,
    placeholder: "Enter Practice ID",
  },
  {
    label: "Address Line 1",
    fieldName: "address_line_1",
    fieldType: TextField,
    placeholder: "Enter Address Line 1",
  },
  {
    label: "Address Line 2",
    fieldName: "address_line_2",
    fieldType: TextField,
    placeholder: "Enter Address Line 2",
  },
  {
    label: "City",
    fieldName: "city",
    fieldType: TextField,
    placeholder: "Enter City",
  },
  {
    label: "Country",
    fieldName: "country",
    fieldType: TextField,
    placeholder: "Enter Country",
  },
  {
    label: "State",
    fieldName: "state",
    fieldType: TextField,
    placeholder: "Enter State",
  },
  {
    label: "Zip Code",
    fieldName: "zip",
    fieldType: TextField,
    placeholder: "Enter Zip Code",
  },
];

const billingInputs = [
  {
    label: "",
    fieldName: "billing_is_same",
    fieldType: Radio,
    fieldValue: ["Yes", "No"],
  },
  {
    label: "Address Line 1",
    fieldName: "bill_address_line_1",
    fieldType: TextField,
    setValue: "myvalue",
    placeholder: "Enter Address Line 1",
  },
  {
    label: "Address Line 2",
    fieldName: "bill_address_line_2",
    fieldType: TextField,
    placeholder: "Enter Address Line 2",
  },
  {
    label: "City",
    fieldName: "bill_city",
    fieldType: TextField,
    placeholder: "Enter Country",
  },
  {
    label: "Country",
    fieldName: "bill_country",
    fieldType: TextField,
    placeholder: "Enter Country",
  },
  {
    label: "State",
    fieldName: "bill_state",
    fieldType: TextField,
    placeholder: "Enter State",
  },
  {
    label: "Zip Code",
    fieldName: "bill_zip",
    fieldType: TextField,
    placeholder: "Enter Zip Code",
  },
];

function GroupProfileUpdate({ saveClick, setSave, setEdit, setLoading }) {
  const formRef = React.createRef();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  let id = JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid;
  const { notifySuccess, notifyError } = useNotification();
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  //   const [loading, setLoading] = useState(false);
  const [dataLoding, setDataLoading] = useState(false);
  console.log("pn", location?.state?.pn);
  const [imageLoading, setImageLoading] = useState(true);
  const [pageN, setPageN] = useState(location?.state?.pn);
  const [providerGroupDetails, setProviderGroupDetails] = useState(
    providerGroupsDetails
  );

  useEffect(() => {
    console.log("formRef 6666", formRef);
    formRef.current?.validateForm();
    if (saveClick && formRef.current) {
      formRef.current?.submitForm();
      if(!formRef.current?.errors){
        handleSaveClick(formRef.current.values)}
      else{
        setSave(false)
      }
    }
  }, [saveClick]);

  const scrollableFieldStyle = {
    height: "130px", // Set the desired height for your scrollable field
    overflow: "auto",
    width: "100%",
  };

  const handleSaveClick = (values) => {
    console.log("updated_values:", values);
    setLoading(true);
    const body = {
      practice_id: values?.practice_id,
      first_name: values.first_name,
      last_name: values.last_name,
      website: values.website,
      display_name: values.display_name,
      phone_number: values.phone_number,
      speciality: values.speciality,
      address: {
        address_line_1: values.address_line_1,
        address_line_2: values.address_line_2,
        country: values.country,
        city: values.city,
        state: values.state,
        zip_code: values.zip,
        billing_is_same: values.billing_is_same === "Yes" ? true : false,
        billing_address: {
          address_line_1: values.bill_address_line_1,
          address_line_2: values.bill_address_line_2,
          country: values.bill_country,
          city: values.bill_city,
          state: values.bill_state,
          zip_code: values.bill_zip,
        },
      },
      bio: values.bio,
    };

    setLoading(true);
    const response = ProviderGroupApi.updateUser(id, body);
    response.then((res) => {
      if (id && values?.profile_pic !== data?.profile_pic) {
        ProviderGroupApi.AddProviderGroupImage(id, {
          profile_pic: values?.profile_pic,
        })
          .then((response) => {
            notifySuccess("Provider Group Updated Successfully");
            setLoading(false);
            setEdit(false);
            formRef?.current?.resetForm();
            setSave(false);
            navigate(`/settings`, {
              state: {
                pn: pageN,
              },
            });
          })
          .catch((error) => {
            notifyError(error?.response?.data?.error);
            setLoading(false);
          });
      } else {
        notifySuccess("Provider Group Updated Successfully");
        setEdit(false);
        setLoading(false);
        formRef?.current?.resetForm();
        setSave(false);
        navigate(`/settings`, {
          state: {
            pn: pageN,
          },
        });
      }
    });
    response.catch((error) => {
      notifyError("Something went wrong.");
      setLoading(false);
      setSave(false);
      setLoading(false);
    });
  };

  useEffect(() => {
    setDataLoading(true);
    getProviderSpecialityData();
    ProviderGroupApi.getProviderGroup(id)
      .then((response) => {
        setData(response?.data);
        setDataLoading(false);
        setSelectedImage(response?.data?.profile_pic);
      })
      .catch((error) => {
        setDataLoading(false);
        console.error(
          "Error fetching user data:",
          error?.response?.data?.error
        );
      });
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid Email ID format")
      .required("Email ID is required"),
    website: Yup.string().required("Website is required"),
    display_name: Yup.string().required("Provider Group Name is required"),
    phone_number: Yup.string()
      .matches(/^[\d\s()+-]+$/, "Invalid Phone number")
      .min(10, "Phone number is too short")
      .max(20, "Phone number is too long")
      .required("Phone number is required"),
    speciality: Yup.array().min(1, "At least one speciality is required"), // Ensure at least one speciality is selected

    practice_id: Yup.string().required("Practice ID is required"),

    address_line_1: Yup.string().required("Address Line 1 is required"),
    country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),
    zip: Yup.string()
      .matches(
        /^[0-9]{5,6}$/,
        "Zip code must be 5 to 6 digits and contain only numbers"
      )
      .required("Zip code Address is required"),
    state: Yup.string().required("State is required"),
  });

  const getProviderSpecialityData = () => {
    let MapSpecialityData = [];
    let mapData = [];
    ProviderGroupApi.getProviderSpeciality()
      .then((response) => {
        response?.data?.forEach((element) => {
          MapSpecialityData.push({
            name: element,
            label: element,
          });
        });
      })
      .catch((error) => {});

    providerGroupDetails?.map((ele) => {
      if (ele?.fieldName === "speciality") {
        mapData?.push({
          label: "Speciality",
          fieldName: "speciality",
          fieldType: Select,
          placeholder: "Select Speciality",
          options: MapSpecialityData,
          defaultData: data?.speciality,
        });
      } else {
        mapData?.push(ele);
      }
    });

    console.log("mapData", mapData);
    setProviderGroupDetails(mapData);
    console.log("ProviderGroupApi response", mapData);
  };

  const isSupportedFileType = (fileType) => {
    const supportedFormats = ["jpeg", "jpg", "png", "gif", "bmp"];
    return supportedFormats.some((format) => fileType.endsWith(format));
  };

  const validateField = (values) => {
    const errors = {};
    if (values.billing_is_same === "No") {
      if (!values.bill_address_line_1) {
        errors.bill_address_line_1 = "Billing Address Line 1 is required";
      }
      if (!values.bill_country) {
        errors.bill_country = "Billing Country is required";
      }
      if (!values.bill_state) {
        errors.bill_state = "Billing State is required";
      }
      if (!values.bill_city) {
        errors.bill_city = "Billing City is required";
      }
      if (!values.bill_zip) {
        errors.bill_zip = "Billing Zip code is required";
      } else if (!/^[0-9]{5,6}$/.test(values.bill_zip)) {
        errors.bill_zip =
          "Billing Zip code must be 5 to 6 digits and contain only numbers";
      }
    }
    return errors;
  };

  const isURL = (url) => {
    try {
      if (url.includes("https://")) {
        // new URL(url);
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  };

  return (
    <Grid container spacing={2} padding={2} sx={containerStyle}>
      <Grid item xs={12}>
        <Formik
          initialValues={{
            email: data?.email,
            first_name: data?.first_name,
            last_name: data?.last_name,
            npi: data?.npi,
            website: data?.website,
            display_name: data?.display_name,
            phone_number: data?.phone_number,
            speciality: data?.speciality,
            practice_id: data?.practice_id,
            address_line_1: data?.address?.address_line_1,
            address_line_2: data?.address?.address_line_2,
            country: data?.address?.country,
            state: data?.address?.state,
            city: data?.address?.city,
            zip: data?.address?.zip_code,
            billing_is_same: data?.address?.billing_is_same ? "Yes" : "No",
            profile_pic: data?.profile_pic,
            bill_address_line_1: data?.address?.billing_address?.address_line_1,
            bill_address_line_2: data?.address?.billing_address?.address_line_2,
            bill_country: data?.address?.billing_address?.country,
            bill_city: data?.address?.billing_address?.city,
            bill_state: data?.address?.billing_address?.state,
            bill_zip: data?.address?.billing_address?.zip_code,
            profile_pic: data?.profile_pic,
            bio: data?.bio,
          }}
          innerRef={formRef}
          onSubmit={(values) => handleSaveClick(values)}
          validationSchema={validationSchema}
          validate={(values) => validateField(values)}
          enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <Form>
              <Grid item xs={12} padding={1}>
                <CustomScrollbars height={"70vh"}>
                  {dataLoding ? (
                    <CircularProgress
                      color="primary"
                      sx={{ marginTop: "20%", marginLeft: "50%" }}
                    />
                  ) : (
                    <Grid item xs={12} padding={1}>
                      <Grid container xs={12} marginBottom={2}>
                        <Box
                          display="flex"
                          marginBottom={3}
                          width="100%"
                          margin={1}
                        >
                          {/* Image container */}
                          <Box position="relative" marginRight={2}>
                            {/* Backdrop overlay */}
                            {imageLoading && (
                              <Box
                                position="absolute"
                                top={0}
                                left={0}
                                width="100%"
                                height="100%"
                                backgroundColor="rgba(119, 118, 118, 0.7)"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                opacity={0.3}
                                zIndex={2}
                              >
                                <CircularProgress
                                  size={50}
                                  sx={{
                                    color: "#ffffff",
                                    position: "absolute",
                                  }}
                                />
                              </Box>
                            )}

                            {/* User profile photo */}
                            <Avatar
                              alt="User Profile Photo"
                              src={
                                (isURL(selectedImage)
                                  ? `${selectedImage}?${new Date().getTime()}`
                                  : selectedImage) || avatar
                              }
                              sx={{
                                width: 200,
                                height: 180,
                                borderRadius: "0%",
                                marginBottom: "0px",
                                objectFit: "contain",
                                padding: 1,
                                backgroundColor: "#ffffff",
                                boxShadow: "0px 0px 8px #00000029",
                                visibility: imageLoading ? "hidden" : "visible",
                              }}
                              onError={(e) => {
                                e.target.src = avatar;
                                e.target.onerror = null;
                                setImageLoading(false);
                              }}
                              onLoad={() => setImageLoading(false)}
                            />

                            <label
                              htmlFor="image-upload"
                              style={cameraIconStyle}
                            >
                              <input
                                id="image-upload"
                                type="file"
                                onChange={(e) => {
                                  let file = null;
                                  file = e.target?.files[0];
                                  const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
                                  if (file) {
                                    if (!isSupportedFileType(file.type)) {
                                      notifyError(
                                        "Please select a valid image format, such as .jpeg, .jpg, .png, .gif or .bmp."
                                      );
                                    } else if (file.size > maxSize) {
                                      notifyError(
                                        "Image size should not exceed 5 MB."
                                      );
                                    } else {
                                      setFieldValue("profile_pic", file);
                                      const reader = new FileReader();
                                      reader.onload = (e) => {
                                        setSelectedImage(e.target.result);
                                      };
                                      reader.readAsDataURL(file);
                                    }
                                  }
                                }}
                                style={{ display: "none" }}
                              />
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                              >
                                <PhotoCameraIcon />
                              </IconButton>
                            </label>
                          </Box>

                          {/* Bio container */}
                          <Box
                            display="flex"
                            flexDirection="column"
                            flexGrow={1}
                            width="100%"
                            margin={1}
                          >
                            <React.Fragment key={"bio"}>
                              <Typography variant="subtitle1" marginRight={2}>
                                Bio :
                              </Typography>
                              <Box
                                display="flex"
                                flexDirection="column"
                                boxShadow={
                                  values?.bio ? "0px 0px 5px #00000020" : null
                                }
                                flexGrow={1}
                                marginTop={1}
                              >
                                <TextareaAutosize
                                className="css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input"
                                placeholder="Enter bio"
                                  style={{
                                    resize: "unset",
                                    ...cardStyle,
                                    ...scrollableFieldStyle,
                                    textAlign: "left",
                                    fontSize:"16px",
                                  fontFamily:theme?.typography?.fontFamily,

                                    // font: "normal normal bold 18px/21px Gilroy !important",
                                    letterSpacing: "0px",
                                    // color: "#4C4C4CCC",
                                    opacity: 1,
                                    wordBreak: "break-word",
                                    whiteSpace: "pre-line",
                                    textAlign: "justify",
                                    border: "0px",
                                    backgroundColor: "#ffffff",
                                    boxShadow: "0px 0px 8px #00000029",
                                    border: "0px",
                                    padding: "10px",
                                    margin: 1,
                                    "&:hover": {
                                      border: "0px",
                                    },
                                    "&:focus": {
                                      outline: 0,
                                      border: "0px",
                                    },
                                  }}
                                  name="bio"
                                  defaultValue={values?.bio}
                                  onChange={(e) =>
                                    setFieldValue("bio", e.target.value)
                                  }
                                />
                              </Box>
                            </React.Fragment>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid container xs={12} spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <Grid item xs={12} marginBottom={2}>
                            <Typography variant="cardTitle">
                              Demographics
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {providerGroupDetails
                              ?.slice(0, 6)
                              .map((inputData, index) => (
                                <Grid item xs={12} lg={12} key={index}>
                                  <Input data={inputData} />
                                  <ErrorMessage
                                    name={inputData.fieldName}
                                    component="div"
                                    style={{
                                      color: "red",
                                      fontSize: "15px",
                                      marginBottom: 1,
                                      marginTop: "5px",
                                    }}
                                  />
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} spacing={3}>
                          <Grid item xs={12} marginBottom={2}>
                            <Typography variant="cardTitle">
                              Physical Address
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            {providerGroupDetails
                              ?.slice(6, 15)
                              .map((inputData, index) => (
                                <Grid item xs={12} lg={12} key={index}>
                                  <Input data={inputData} />
                                  <ErrorMessage
                                    name={inputData.fieldName}
                                    component="div"
                                    style={{
                                      color: "red",
                                      fontSize: "15px",
                                      marginBottom: 1,
                                      marginTop: "5px",
                                    }}
                                  />
                                </Grid>
                              ))}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} spacing={3}>
                          <Grid item xs={12} marginBottom={2}>
                            <Typography variant="cardTitle">
                              Billing Address
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography variant="subtitle1" marginBottom="8px">
                              Billing same as primary
                            </Typography>
                          </Grid>
                          <Grid item xs={12} lg={12}>
                            <Input data={billingInputs[0]} />
                            <ErrorMessage
                              name={billingInputs[0]?.fieldName}
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "15px",
                                marginBottom: 1,
                                marginTop: "5px",
                              }}
                            />
                          </Grid>

                          {values.billing_is_same === "No" ? (
                            <BillingAddress />
                          ) : null}

                          {/* } */}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </CustomScrollbars>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </Grid>
  );
}

export default GroupProfileUpdate;
