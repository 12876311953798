import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import cardStyle from "../../../../../../../../../../../theme/card-layout";
import { DatePicker } from "../../../../../../../../../../../ui-component/DatePicker/DatePicker";
import { useParams } from "react-router";
import { PatientApi } from "../../../../../../../../../../../service/ProviderGroup/Patient";
import FormDataTable from "../../../../../../features/FormDataTable";
import { useNotification } from "../../../../../../../../../../../hooks/use-notification";
import { formatDate, reactionOptions,  } from "../../../../../../../../../../../ccm-constant";

function AllergiesForm({setOpenDrawer, setRefreshList, selectedData, setSelectedRow}) {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [onsetDate, setOnsetDate] = useState(selectedData?.onset_date || null);
  const { notifySuccess, notifyError } = useNotification();
  const [loading, setLoading]= useState(false)
const [isSaving, setIsSaving] = useState(false);


useEffect(() => {
  if(isSaving){
    setTimeout(() => {
      setIsSaving(false);
    }, 2000);
  }
}, [isSaving])

  const criticalityOptions = [
      { label: "Low", name: "Low" },
      { label: "High", name: "High" },
      { label: "Unable To Assess", name: "Unable To Assess" },
  ];

  // const reactionOptions = [
  //   { label: "Abdominal Pain", name: "Abdominal Pain" },
  //   { label: "Anaphylaxis", name: "Anaphylaxis" },
  //   { label: "Angioedema", name: "Angioedema" },
  //   { label: "Arthralgia (Joint Pain)", name: "Arthralgia (Joint Pain)" },
  //   { label: "Bradycardia", name: "Bradycardia" },
  //   { label: "Cardiac Arrest", name: "Cardiac Arrest" },
  //   { label: "Chest Pain", name: "Chest Pain" },
  //   { label: "Confusion", name: "Confusion" },
  //   { label: "Cough", name: "Cough" },
  //   { label: "Decreased Blood Pressure", name: "Decreased Blood Pressure" },
  //   { label: "Diarrhea", name: "Diarrhea" },
  //   { label: "Dizziness", name: "Dizziness" },
  //   { label: "Dry Mouth", name: "Dry Mouth" },
  //   { label: "Dyspnea", name: "Dyspnea" },
  //   { label: "Edema", name: "Edema" },
  //   { label: "Encephalitis", name: "Encephalitis" },
  //   { label: "Eye Redness", name: "Eye Redness" },
  //   { label: "Eye Swelling", name: "Eye Swelling" },
  //   { label: "Facial Swelling", name: "Facial Swelling" },
  //   { label: "Insomnia", name: "Insomnia" },
  //   { label: "Irregular Heart Rate", name: "Irregular Heart Rate" },
  //   { label: "Itching", name: "Itching" },
  //   { label: "Lightheadedness", name: "Lightheadedness" },
  //   { label: "Muscle Cramps", name: "Muscle Cramps" },
  //   { label: "Myalgias (Muscle Pain)", name: "Myalgias (Muscle Pain)" },
  //   { label: "Nausea", name: "Nausea" },
  //   { label: "Other", name: "Other" },
  //   { label: "Palpitations", name: "Palpitations" },
  //   { label: "Photosensitivity", name: "Photosensitivity" },
  //   { label: "Rash", name: "Rash" },
  //   { label: "Respiratory Distress", name: "Respiratory Distress" },
  //   { label: "Ringing in Ears", name: "Ringing in Ears" },
  //   { label: "Seizure", name: "Seizure" },
  //   { label: "Stevens-Johnson Syndrome", name: "Stevens-Johnson Syndrome" },
  //   { label: "Swelling", name: "Swelling" },
  //   { label: "Tachycardia", name: "Tachycardia" },
  //   { label: "Vasculitis", name: "Vasculitis" },
  //   { label: "Vomiting", name: "Vomiting" },
  //   { label: "Wheezing", name: "Wheezing" },
  //   { label: "Other", name: "Other" },
  // ];

  const severityOptions = [
    { label: "Mild", name: "Mild" },
    { label: "Moderate", name: "Moderate" },
    { label: "Severe", name: "Severe" },
    { label: "Very Mild", name: "Very Mild" },
  ];

  const columns = [
    {
      name: "Sr.No",
      selector: (row) => row.srNo,
      width: "80px",
    },
    {
      name: "Allergies Name",
      selector: (row) => row.allergiesName,
      // width: "250px",
    },
    {
      name: "Criticality",
      selector: (row) => row.criticality,
      width: "120px",
    },
    {
      name: "Reaction",
      selector: (row) => row.reaction,
      width: "120px",
    },
    {
      name: "Severity",
      selector: (row) => row.severity,
      width: "120px",
    },
    {
      name: "Note",
      selector: (row) => row.note,
      minWidth: "140px",
    },
    {
      name: "Onset Date",
      selector: (row) => formatDate(row?.onSetDate),
      width: "140px",
    },
  ];

  const validationSchema = Yup.object().shape({
    allergiesName: Yup.string().required("Allergies Name is required"),
    criticality: Yup.string().required("Criticality is required"),
    reaction: Yup.string().required("Reaction is required"),
    severity: Yup.string().required("Severity is required"),
    onSetDate: Yup.date().required("Onset Date is required"),
  });

  const SubmitAllergiesData = () => {
    if (isSaving) {
      // If already saving, return to prevent multiple calls
      return;
    }
    setIsSaving(true);
    const payload = [];
    if(data?.length === 0){
      notifyError("Please add at least one allergy.")
    }
    else{
    data.forEach((element) => {
      payload.push({
        name: element?.allergiesName,
        criticality: element?.criticality,
        reaction: element?.reaction,
        serverity: element?.severity,
        onset_date: element?.onSetDate,
        note: element?.note,
      });
    });
    if (payload?.length)
    {
      setLoading(true)
    
      PatientApi.addAllergies(id, payload)
        .then((response) => {
          setOpenDrawer(false);
          setLoading(false)
          setRefreshList(true);
          notifySuccess("Allergies added successfully");
          console.log("response.data", response.data);
        })
        .catch((error) => {
          setLoading(false)
          console.error("Error addAllergies data:", error);
          notifyError(error?.response?.data?.error);
        })
        .finally(() => {
          setIsSaving(false); // Reset saving status after API call is completed
        });
      }}
  };


  const UpdateAllergiesData = (values) => {
    setLoading(true)

    console.log("first values", values, onsetDate)
    const payload = {
        name: values?.allergiesName,
        criticality: values?.criticality,
        reaction: values?.reaction,
        serverity: values?.severity,
        onset_date: values?.onSetDate !== "" ? values?.onSetDate : null,
        note: values?.note=== "" ? null : values?.note,
      }
      PatientApi.updateAllergies(selectedData?.uuid, payload)
        .then((response) => {
          setOpenDrawer(false);
          setRefreshList(true);
          setSelectedRow(null)
          setLoading(false)
          notifySuccess("Allergies updated successfully");
          console.log("response.data", response.data);
        })
        .catch((error) => {
          setLoading(false)
          console.error("Error addAllergies data:", error);
          notifyError(error?.response?.data?.error);
        });
  };

  return (
    <>
      <Grid container marginTop={2}>
        <Formik
          initialValues={{
            allergiesName: selectedData?.name || null,
            criticality: selectedData?.criticality || null,
            reaction: selectedData?.reaction || null,
            severity: selectedData?.serverity || null,
            note: selectedData?.note || null,
            onSetDate: selectedData?.onset_date || null,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, {setFieldValue, resetForm }) => {
            const newCondition = {
              srNo: data?.length + 1 || 1,
              allergiesName: values.allergiesName,
              criticality: values.criticality,
              reaction: values.reaction,
              severity: values.severity,
              note: values.note,
              onSetDate: values?.onSetDate ? values.onSetDate : null,
            }
            
            if(!selectedData){
              setData([
                ...(data || []),
                newCondition,
              ]);
            resetForm({
              allergiesName: "",
              criticality: "",
              reaction: "",
              severity: "",
              allergiesNote: "",
              onSetDate: "",
              note:""
            });
            setFieldValue("allergiesName", "")
            setFieldValue("note", "")
            setOnsetDate(null);}
            else{
              UpdateAllergiesData(values)
            }
          }}
          enableReinitialize
        >
          {({ values, handleSubmit, setFieldValue, errors, setErrors }) => (
            <Form>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    marginBottom: 2,
                  }}
                  spacing={2}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      height: "40px",
                      fontWeight: 700,
                      width: "100px",
                      textTransform: "none",
                      marginRight: 15,
                    }}
                    onClick={() => {setOpenDrawer(false);  setSelectedRow(null)}}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      height: "40px",
                      fontWeight: 700,
                      width: "100px",
                      textTransform: "none",
                    }}
                    onClick={()=> {  console.log("first errors?.length", errors)
                    // if(Object.keys(errors)?.length && selectedData){
                    // notifyError(JSON.stringify(errors))
                    // }
                    // else{
                      !selectedData ? SubmitAllergiesData() : handleSubmit()}
                    // }
                  }
                  >
                    Save&nbsp;
                  {loading && (
                    <CircularProgress size={18} style={{ color: "white" }} />
                  )}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" marginBottom={2}>
                    {selectedData ? "Edit" : "Add"} Allergies
                  </Typography>

                  <Typography variant="subtitle1" marginBottom={1}>
                    Allergies Name
                  </Typography>
                  <Grid item xs={12} marginBottom={2}>
                    <Field
                      as={TextField}
                      variant="outlined"
                      style={cardStyle}
                      value={values?.allergiesName}
                      placeholder="Please Add Allergies Name"
                      sx={{ width: "100%" }}
                      name="allergiesName"
                    />
                    <ErrorMessage
                      name="allergiesName"
                      component="div"
                      style={{
                        color: "red",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    />
                  </Grid>

                  <Grid container spacing={3} marginBottom={2}>
                    <Grid item xs={12} lg={6}>
                      <Typography variant="subtitle1" marginBottom={1}>
                        Criticality
                      </Typography>
                      <Field variant="outlined" fullWidth name="criticality">
                        {({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            id="combo-box-demo"
                            options={criticalityOptions}
                            sx={cardStyle}
                            renderInput={(params) => (
                              <TextField {...params} {...field} placeholder="Select" />
                            )}
                            onChange={(e, newValue) => {
                              setFieldValue("criticality", newValue?.name || null);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="criticality"
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Typography variant="subtitle1" marginBottom={1}>
                        Reaction
                      </Typography>

                      <Field variant="outlined" fullWidth name="reaction">
                        {({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            id="combo-box-demo"
                            options={reactionOptions}
                            sx={cardStyle}
                            renderInput={(params) => (
                              <TextField {...params} {...field} placeholder="Select" />
                            )}
                            onChange={(e, newValue) => {
                              setFieldValue("reaction", newValue?.name || null);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="reaction"
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Typography variant="subtitle1" marginBottom={1}>
                        Severity
                      </Typography>
                      <Field variant="outlined" fullWidth name="severity">
                        {({ field }) => (
                          <Autocomplete
                            {...field}
                            disablePortal
                            id="combo-box-demo"
                            options={severityOptions}
                            sx={cardStyle}
                            renderInput={(params) => (
                              <TextField {...params} {...field} placeholder="Select" />
                            )}
                            onChange={(e, newValue) => {
                              setFieldValue("severity", newValue?.name || null);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="severity"
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Typography variant="subtitle1" marginBottom={1}>
                        Onset Date
                      </Typography>
                      <DatePicker
                        setOnSetDate={setOnsetDate}
                        onSetDate={onsetDate}
                        fieldName="onSetDate"
                      />
                      <ErrorMessage
                        name="onSetDate"
                        component="div"
                        style={{
                          color: "red",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} marginBottom={3}>
                    <Typography variant="subtitle1" marginBottom={1}>
                      Note
                    </Typography>
                    <Field
                      as={TextField}
                      variant="outlined"
                      style={cardStyle}
                      minRows={3}
                      maxRows={3}
                      multiline={true}
                      name="note"
                      placeholder="Note"
                      sx={{ width: "100%" }}
                    />
                  </Grid>

                  {!selectedData && <Grid container justifyContent="flex-end">
                    <Grid item>
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        style={{
                          alignItems: "center",
                          height: "40px",
                          fontWeight: 700,
                          width: "100px",
                          textTransform: "none",
                        }}
                        onClick={handleSubmit}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>

        {!selectedData &&<Grid item xs={12} lg={12} marginTop={3}>
          {data?.length ? (
            <FormDataTable data={data} columns={columns} />
          ) : null}
        </Grid>}
      </Grid>
    </>
  );
}

export default AllergiesForm;
