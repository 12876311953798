// main component

import { ImportExportRounded } from "@mui/icons-material";
import { MdOutlinePersonalInjury } from "react-icons/md";
import React, { lazy } from "react";
import {
  DashboardOutlinedIcon,
  AccountCircleRoundedIcon,
  SettingsOutlinedIcon,
  LogoutRoundedIcon,
  CallIcon,
  TaskOutlinedIcon,
  ErrorOutlineIcon,
  MessageOutlinedIcon,
  LocalLibraryOutlinedIcon,
  SchoolOutlinedIcon,
  PaymentsOutlinedIcon,
} from "../assets/Icons/SideBarIcons";
import PatientProfile from "../views/workspaces/Patients/components/Patient/Profile";
import Providerprofile from "../views/workspaces/Settings/Components/ProviderUserProfile/Providerprofile";
import EditFamilyMember from "../views/workspaces/Patients/components/Patient/Profile/Components/Family/Features/EditMember/Index";
import Billing from "../views/workspaces/Billing/Index";
import PatientDetails from "../views/workspaces/Billing/components/PatientDetails";
import Billings from "../views/workspaces/Billing/Index";

// Provider component
const Analytics = lazy(() => import("../views/workspaces/Analytics"));
const Patients = lazy(() => import("../views/workspaces/Patients"));
const CallScheduler = lazy(() => import("../views/workspaces/CallScheduler"));
const Logout = lazy(() => import("../views/authentication/log-out"));
const Task = lazy(() => import("../views/workspaces/Task"));
const AssesmentLibrary = lazy(() => import("../views/workspaces/AssessmetLibrary"));
const AssesmentBuilder = lazy(() => import("../views/workspaces/AssessmetLibrary/components/AssesmentBuilder"));
const Settings = lazy(() => import("../views/workspaces/Settings/index"));
const Alert = lazy(() => import("../views/workspaces/Alert"));
const Meeting = lazy(() => import("../views/workspaces/CallScheduler/components/ChimeMeeting/Meeting"));


export const Provider = [
  {
    id: "analytics-provider",
    title: "Analytics",
    type: "item",
    icon: <DashboardOutlinedIcon />,
    url: `/analytics`,
    breadcrumbs: true,
    element: <Analytics />,
  },
  {
    id: "patients-provider",
    title: "Patients",
    type: "item",
    icon: <MdOutlinePersonalInjury style={{ width: '28px', height: '28px' }} />,
    url: `/patients`,
    breadcrumbs: true,
    element: <Patients />,
    children: [
      {
        id: "patients-profile",
        title: "Patients",
        type: "item",
        icon: <MdOutlinePersonalInjury style={{ width: '28px', height: '28px' }} />,
        url: `/patients/profile/:id`,
        breadcrumbs: true,
        element: <PatientProfile />,
      },
      {
        id: "patients-family-member-profile",
        title: "Family Member",
        type: "item",
        icon: <MdOutlinePersonalInjury style={{ width: '28px', height: '28px' }} />,
        url: `/patients/profile/familyMember/:id/:memberId`,
        breadcrumbs: true,
        element: <EditFamilyMember />,
      },
    ],
  },
  {
    id: "call_scheduler-provider",
    title: "Call Scheduler",
    type: "item",
    icon: <img
      src=""
      alt="Logo"
      style={{
        width: '28px',
        height: '28px',
        display: { xs: "none", sm: "none", md: "block" },
        // background: theme.palette.primary.main,
      }}
    />,
    url: `/call_scheduler`,
    breadcrumbs: true,
    element: <CallScheduler />
  },
  {
    id: "task-component",
    title: "Task",
    type: "item", // Change type to 'group' for nested children
    icon: <TaskOutlinedIcon />,
    url: "/task",
    breadcrumbs: true,
    element: <Task />,
  },
  {
    id: "alerts-provider",
    title: "Alerts",
    type: "item",
    icon: <ErrorOutlineIcon />,
    url: "/alerts",
    breadcrumbs: true,
    element: <Alert/>
  },
  // {
  //   id: "message-provider",
  //   title: "Message",
  //   type: "item",
  //   icon: <MessageOutlinedIcon />,
  //   url: "/message",
  //   breadcrumbs: true,
  // },
  // {
  //   id: "education-provider",
  //   title: "Education",
  //   type: "item",
  //   icon: <SchoolOutlinedIcon />,
  //   url: "/education",
  //   breadcrumbs: true,
  // },
  {
    id: "assesment_library-provider",
    title: "Assessment Library",
    type: "item",
    icon: <LocalLibraryOutlinedIcon />,
    url: "/assesment_library",
    breadcrumbs: true,
    element: <AssesmentLibrary />,
    children: [
      {
        id: "assesment_builder-provider",
        title: "Assesment Builder",
        type: "item",
        icon: <AccountCircleRoundedIcon />,
        url: `/assesment_library/builder`,
        breadcrumbs: true,
        element: <AssesmentBuilder />,
      },
    ],
  },
  {
    id: "billing-provider",
    title: "Billing",
    type: "item",
    icon: <PaymentsOutlinedIcon />,
    url: "/billing",
    element: <Billings />,
    breadcrumbs: true,
    children: [
      {
        id: "billing-patient-details",
        title: "Patient Details",
        type: "item",
        icon: <AccountCircleRoundedIcon />,
        url: `/billing/patient/profile/:PatientId`,
        breadcrumbs: true,
        element: <PatientDetails />,
      },
    ],
  },
  {
    id: "settings-provider",
    title: "Settings",
    type: "item",
    icon: <SettingsOutlinedIcon />,
    url: "/settings",
    breadcrumbs: true,
    element: <Settings />,
    children: [
      {
        id: "settings-profile",
        title: "Settings",
        type: "item",
        icon: <AccountCircleRoundedIcon />,
        url: `/settings/Provider/profile/:provider_id`,
        breadcrumbs: true,
        element: <Providerprofile />,
      },
    ],
  },
  {
    id: "logout",
    url: "/logout",
    element: <Logout />,
    icon: <LogoutRoundedIcon />,
    title: "Logout",
    type: "item",
  },
  // {
  //   id: "meeting",
  //   title: "",
  //   type: "item",
  //   // icon: <LogoutRoundedIcon />,
  //   url: "/meeting/:meeting_id/:attendee_id",
  //   breadcrumbs: true,
  //   element: <Meeting />,
  // },
];
