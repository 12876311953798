import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Card,
  Grid,
  Typography,
  Avatar,
  TextField,
  Button,
  Select,
  CircularProgress,
  Box,
  IconButton,
  Checkbox,
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
import cardStyle from "../../../../theme/card-layout";
import { CustomScrollbars } from "../../../../ui-component/ScrollBar";
import { useNotification } from "../../../../hooks/use-notification";
import { PatientApi } from "../../../../service/ProviderGroup/Patient";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import avatar from "../../../../assets/adminAvatar.jpg";
import { DatePicker } from "../../../../ui-component/DatePicker/DatePicker";
import { formatDate } from "../../../../ccm-constant";
import { patientContetxt } from "./PatientDetails";

const field = [
  {
    label: "Enrollment Date",
    fieldName: "enrolled_date",
  },
  {
    label: "Enrolled for",
    fieldName: "enrolled_for",
  },
  // {
  //   label: "Expected Goal Achievement Date",
  //   fieldName: "expectedDate",
  // },
  {
    label: "Conditions",
    fieldName: "conditions",
  },
  {
    label: "Goals",
    fieldName: "goals",
  },
  {
    label: "Patient Name",
    fieldName: "name",
  },
  {
    label: "Email",
    fieldName: "email",
  },
  {
    label: "Cell Phone Number",
    fieldName: "cell_phone_number",
  },
  {
    label: "Home Phone Number",
    fieldName: "home_phone_number",
  },
  {
    label: "Patient Id",
    fieldName: "patient_id",
  },
  {
    label: "Date Of Birth",
    fieldName: "dob",
  },
  {
    label: "Age",
    fieldName: "age",
  },
  {
    label: "MRN",
    fieldName: "mrn",
  },
];

function Enrollement() {
  const { patientData, fetchingData } = useContext(patientContetxt);
  console.log("patientData", patientData);
  const { id, memberId } = useParams();
  const { notifySuccess, notifyError } = useNotification();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState(field);
  const [selectedImage, setSelectedImage] = useState(patientData?.profile_pic);
  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    setSelectedImage(patientData?.profile_pic);
  }, [patientData]);

  const isSupportedFileType = (fileType) => {
    const supportedFormats = ["jpeg", "jpg", "png", "gif", "bmp"];
    return supportedFormats.some((format) => fileType.endsWith(format));
  };

  const isURL = (url) => {
    try {
      if (url.includes("https://")) {
        // new URL(url);
        return true;
      } else return false;
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      {fetchingData ? (
        <Grid item sx={{ height: "80vh", display:"flex", justifyContent:"center" }} >
          <CircularProgress color="primary" sx={{ marginTop: "15%" }} />
        </Grid>
      ) : (
        <Formik
          initialValues={{
            enrolled_date: patientData?.enrolled_date
              ? formatDate(patientData?.enrolled_date)
              : "",
            enrolled_for: "CCM",
            expectedDate: "",
            conditions: patientData?.condition?.map((patient, id) => {
              return `${patient?.name}${
                id !== patientData?.condition?.length - 1 ? "," : "."
              }  `;
            }),
            goals: patientData?.careplan_goals?.map((goal, id) => {
              return `${goal}${
                id !== patientData?.careplan_goals?.length - 1 ? " , " : " . "
              }  `;
            }),
            patient_id: patientData?.patient_id,
            dob: patientData?.date_of_birth ? formatDate(patientData?.date_of_birth) : "",
            email:patientData?.email,
            age:patientData?.age,
            name: `${patientData?.first_name} ${patientData?.middle_name ? patientData?.middle_name : ""} ${patientData?.last_name}` ,
            cell_phone_number:patientData?.cell_phone,
            home_phone_number:patientData?.home_phone,
            mrn:patientData?.mrn
          }}
          enableReinitialize
        >
          {({ values, setFieldValue, handleSubmit, resetForm }) => (
            <Form>
              <Grid container spacing={2} marginTop={3}>
                <Grid item xs={12} sm={12} md={12} lg={2.5}>
                  <Grid
                    container
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    padding={1}
                    marginTop={3}
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Grid item>
                      <Box
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                      >
                        {imageLoading && (
                          <Box
                            sx={{
                              width: 200,
                              height: 200,
                              ...cardStyle,
                              borderRadius: "50%",
                              bottom: 0,
                              position: "absolute",
                              top: 0,
                              left: 0,
                              right: 0,
                              margin: "auto",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              backgroundColor: "rgba(255, 255, 255, 0.8)",
                            }}
                            zIndex={2}
                          >
                            <CircularProgress
                              size={35}
                              sx={{ color: "black" }}
                            />
                          </Box>
                        )}
                        <Avatar
                          alt="User Profile Photo"
                          src={
                            (isURL(selectedImage)
                              ? `${selectedImage}?${new Date().getTime()}`
                              : selectedImage) || avatar
                          }
                          sx={{
                            width: 200,
                            height: 200,
                            ...cardStyle,
                            borderRadius: "50%",
                            objectFit: "contain",
                            visibility: imageLoading ? "hidden" : "visible",
                          }}
                          onError={(e) => {
                            e.target.src = avatar;
                            e.target.onerror = null;
                            setImageLoading(false);
                          }}
                          onLoad={() => setImageLoading(false)}
                        />
                        {editMode && (
                          <label
                            htmlFor="image-upload"
                            style={{
                              marginLeft: "83px",
                              bottom: "10px",
                              transform: "translateX(-50%)",
                            }}
                          >
                            <input
                              id="image-upload"
                              type="file"
                              onChange={(e) => {
                                let file = null;
                                file = e.target?.files[0];
                                const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
                                if (file) {
                                  if (!isSupportedFileType(file.type)) {
                                    notifyError(
                                      "Please select a valid image format, such as .jpeg, .jpg, .png, .gif or .bmp."
                                    );
                                  } else if (file.size > maxSize) {
                                    notifyError(
                                      "Image size should not exceed 5 MB."
                                    );
                                  } else {
                                    setFieldValue("profile_pic", file);
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                      setSelectedImage(e.target.result);
                                    };
                                    reader.readAsDataURL(file);
                                  }
                                }
                              }}
                              style={{ display: "none" }}
                            />
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              style={{
                                position: "relative",
                                bottom: "15px",
                                // border: '1px solid #ffffff'
                              }}
                            >
                              <PhotoCameraIcon />
                            </IconButton>
                          </label>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={9.5}>
                  <Grid container>
                    <Grid item xs={12} md={12} sm={12}>
                      <CustomScrollbars height={"72vh"}>
                        <Grid container paddingLeft={2}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={7}
                            lg={7}
                            marginBottom={2}
                          >
                            <Grid
                              container
                              xs={12}
                              columnSpacing={5}
                              rowSpacing={2}
                              paddingLeft={1}
                            >
                              <Grid item xs={12} md={12}>
                                <Typography variant="h6">
                                  Enrollment Details
                                </Typography>
                              </Grid>
                              {fields?.slice(0, 4)?.map((field) => (
                                <Grid item xs={12} md={12}>
                                  <React.Fragment key={field.key}>
                                    {!editMode && (
                                      <Grid container xs={12} spacing={2}>
                                        <Grid item xs={6}>
                                          <Typography variant="subtitle1">
                                            {field.label}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          {
                                            <>
                                              <Typography
                                                variant="body1"
                                                component="div"
                                                sx={{ marginBottom: "5px" }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  :&nbsp;&nbsp;&nbsp;
                                                </span>
                                                {values[field?.fieldName]}
                                              </Typography>
                                            </>
                                          }
                                        </Grid>
                                      </Grid>
                                    )}
                                  </React.Fragment>
                                </Grid>
                              ))}

                              <Grid item xs={12} md={12}>
                                <Grid container xs={12} spacing={2}>
                                  <Grid item xs={6}>
                                    <Typography variant="subtitle1">
                                      Consent Signed
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    container
                                    xs={6}
                                    paddingLeft={2}
                                    display={"flex"}
                                    justifyContent={"flex-start"}
                                  >
                                    <Typography variant="body1">
                                    <Checkbox
                                        checked={
                                          patientData?.consent_medium ===
                                          "verbal"
                                            ? true
                                            : false
                                        }
                                        disabled={true}
                                      />
                                      Verbal
                                    </Typography>
                                    <Typography variant="body1">
                                      <Checkbox
                                        checked={
                                          patientData?.consent_medium ===
                                          "written"
                                            ? true
                                            : false
                                        }
                                        disabled={true}
                                      />
                                      Written
                                    </Typography>
                                    <Typography variant="body1">
                                    <Checkbox
                                        checked={
                                          patientData?.consent_medium ===
                                          "email"
                                            ? true
                                            : false
                                        }
                                        disabled={true}
                                      />{" "}
                                      Email
                                    </Typography>

                                    <Typography variant="body1">
                                    <Checkbox
                                        checked={
                                          patientData?.consent_medium ===
                                          "ehr"
                                            ? true
                                            : false
                                        }
                                        disabled={true}
                                      />
                                      EHR
                                    </Typography>

                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <Grid container xs={12} spacing={2} padding={0}>
                                  <Grid item xs={6}>
                                    <Typography variant="subtitle1">
                                      CCM Program Assigned
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    {
                                      <>
                                        <Checkbox checked={true} disabled={true}/>
                                      </>
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <Grid container xs={12} spacing={2}>
                                  <Grid item xs={6}>
                                    <Typography variant="subtitle1">
                                      Device Assigned
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} paddingX={0} marginX={0} paddingLeft={0}>
                                    {
                                      <>
                                        <Checkbox checked={false}  disabled={true} paddingX={0} marginX={0}/>
                                      </>
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>

                              <Grid item xs={12} md={12}>
                                <Grid container xs={12} spacing={2}>
                                  <Grid item xs={6}>
                                    <Typography variant="subtitle1">
                                    Import from EHR
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6} paddingX={0} marginX={0} paddingLeft={0}>
                                    {
                                      <>
                                        <Checkbox checked={patientData?.is_pulled_from_ehr}  
                                            disabled={true} 
                                            paddingX={0} 
                                            marginX={0}/>
                                      </>
                                    }
                                  </Grid>
                                </Grid>
                              </Grid>


                            </Grid>
                          </Grid>
                          <Grid item xs={12} sm={12} md={5} lg={5}>
                            <Grid
                              container
                              xs={12}
                              columnSpacing={5}
                              rowSpacing={2}
                              paddingLeft={1}
                            >
                              <Grid item xs={12} md={12}>
                                <Typography variant="h6">
                                  Patient Details
                                </Typography>
                              </Grid>
                              {fields?.slice(4, 12)?.map((field) => (
                                <Grid item xs={12} md={12}>
                                  <React.Fragment key={field.key}>
                                    {!editMode && (
                                      <Grid container xs={12} spacing={2}>
                                        <Grid item xs={6}>
                                          <Typography variant="subtitle1">
                                            {field.label}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          {
                                            <>
                                              <Typography
                                                variant="body1"
                                                component="div"
                                                sx={{ marginBottom: "5px" }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  :&nbsp;&nbsp;&nbsp;
                                                </span>
                                                {field?.fieldName === "age" ? values[field?.fieldName] + " Years" : values[field?.fieldName]}
                                              </Typography>
                                            </>
                                          }
                                        </Grid>
                                      </Grid>
                                    )}
                                    <ErrorMessage
                                      name={field.fieldName}
                                      component="div"
                                      style={{
                                        color: "red",
                                        fontSize: "15px",
                                        marginBottom: 1,
                                        marginTop: "10px",
                                      }}
                                    />
                                  </React.Fragment>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </CustomScrollbars>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

export default Enrollement;
