import {
  Autocomplete,
  MenuItem,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  FormControl,
  Grid,
} from "@mui/material";
import { Field, useFormikContext } from "formik";
import React from "react";
import cardStyle from "../../../../../theme/card-layout";
import PhoneInput from "react-phone-input-2";
import CustomPhoneInput from "../../../../../ui-component/CustomPhoneInput";
import PhoneNumber from "libphonenumber-js";

const Input = ({ data, setSelectedType }) => {
  const { values, setFieldValue } = useFormikContext();
  console.log("first values", values);
  const {
    heading,
    label,
    fieldName,
    fieldType,
    options,
    placeholder,
    fieldValue,
    disable,
    country,
    phoneValue,
    defaultData,
  } = data;

  console.log("defaultData", defaultData);

  function validatePhoneNumber(value, selectedCountry) {
    try {
      // Assuming 'selectedCountry' is set to 'HK' for Hong Kong
      const phoneNumber = PhoneNumber(value, selectedCountry, "National");
      if (phoneNumber.isValid()) {
        return undefined; // Phone number is valid
      } else {
        return "Invalid phone number";
      }
    } catch (error) {
      return "Invalid phone number";
    }
  }

  return (
    <>
      {heading && (
        <Typography
          variant="h6"
          sx={{
            marginBottom: "20px",
          }}
        >
          {heading}
        </Typography>
      )}
      {label && (
        <Typography
          variant="subtitle1"
          sx={{
            marginBottom: "10px",
          }}
        >
          {label}
        </Typography>
      )}
      {fieldType === TextField ? (
        <Field
          as={fieldType}
          variant="outlined"
          fullWidth
          key={label}
          style={cardStyle}
          placeholder={`${
            placeholder ? placeholder : label ? label : fieldName
          }`}
          name={fieldName}
          disabled={disable}
        >
          <MenuItem value="">
            <>Select</>
          </MenuItem>
          {options?.map((item, index) => {
            return (
              <MenuItem key={index} value={item.name} label={item.label}>
                {item.label}
              </MenuItem>
            );
          })}
        </Field>
      ) : fieldType === Select ? (
        <>
          {fieldName === "speciality" || fieldName === "specialty" || fieldName === "condition" ? (
            <Autocomplete
              multiple
              limitTags={2}
              name={fieldName}
              options={options.filter(option => !values[fieldName]?.includes(option?.name))}
              value={values[fieldName] || []}
              // defaultValues={values?.[fieldName]}
              variant="outlined"
              fullWidth
              onChange={(event, newValue) => {
                const newValueAsStringArray = newValue?.map((item) => {
                  if (typeof item === "string") {
                    return item;
                  } else if (item.name) {
                    return item.name;
                  }
                  return ""; // Handle other cases as needed
                });
                console.log("newValueAsStringArray",newValueAsStringArray)
                setFieldValue(fieldName, newValueAsStringArray);
              }}
              key={fieldName}
              style={cardStyle}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={
                    !values?.[fieldName]?.length || values?.[fieldName]?.length >=1
                      ? placeholder
                      : `Please select ${fieldName === "condition" ? "condition" : "speciality"}`
                  }
                />
              )}
            />
          ) : (
            <Field name={fieldName} variant="outlined" fullWidth>
              {({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  // value={values.fieldName}
                  id="combo-box-demo"
                  options={options}
                  disabled={disable}
                  sx={cardStyle}
                  getOptionLabel={(option) => option?.label || option?.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      {...field}
                      placeholder={`${
                        placeholder ? placeholder : label ? label : fieldName
                      }`}
                      onChange={(e, newValue)=>{}}
                    />
                  )}
                  onChange={(e, newValue) => {
                    if (fieldName === "provider_type") {
                      // setSelectedType(newValue?.name)
                      setFieldValue(fieldName, newValue ? newValue.name : "");
                    } else {
                      console.log("6666666666666666666")
                      if(fieldName === "role"){
                      setSelectedType(newValue?.name);
                      setFieldValue("provider_type", "")
                      }

                      setFieldValue(fieldName, newValue ? newValue.label : "");
                    }
                    if (fieldName === "patient_name") {
                      setFieldValue(fieldName, newValue ? newValue.label : "");
                      setFieldValue("patient_id", newValue ? newValue.name : "");
                    }
                    if (fieldName === "assignee") {
                      setFieldValue(fieldName, newValue ? newValue.label : "");
                      setFieldValue("assignee_id", newValue ? newValue.name : "");
                    }
                  }}
                />
              )}
            </Field>
          )}
        </>
      ) : fieldType === Radio ? (
        <Grid sx={{ marginBottom: "14px" }}>
          <FormControl component="fieldset">
            <Field name={fieldName}>
              {({ field }) => (
                <RadioGroup
                  {...field}
                  aria-labelledby="demo-radio-buttons-group-label"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  {fieldValue.map((choice, index) => (
                    <FormControlLabel
                      value={choice}
                      control={<Radio />}
                      label={choice}
                      style={{ display: "flex" }}
                      disabled={disable}
                    />
                  ))}
                </RadioGroup>
              )}
            </Field>
          </FormControl>
        </Grid>
      ) : fieldType === "description" ? (
        <Field
          as={TextField}
          fullWidth
          minRows={6}
          maxRows={6}
          multiline={true}
          disabled={disable}
          name={fieldName}
          placeholder={`${
            placeholder ? placeholder : label ? label : fieldName
          }`}
          style={{ ...cardStyle }}
          sx={{
            width: "100%",
          }}
        />
      ) : (
        <Field
          name={fieldName}
          variant="outlined"
          fullWidth
          // validate={validatePhoneNumber}
        >
          {({ field }) => (
            <CustomPhoneInput
              {...field}
              name="phone_number"
              as={TextField}
              variant="outlined"
              country={"us"} // Use the selectedCountry from your form values
              enableSearch={true}
              value={values.phone_number}
              color="primary"
              placeholder={placeholder}
              onChange={(phone) => setFieldValue("phone_number", phone)}
            />
          )}
        </Field>
      )}
    </>
  );
};

export default Input;
