import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  OpenWindow: false,
};

const CallWindowSlice = createSlice({
  name: 'OpenWindow',
  initialState,
  reducers: {
    updateWindowState: (state, action) => {
      state.OpenWindow = action.payload;
    },
  },
});

export const { updateWindowState } = CallWindowSlice.actions;
export default CallWindowSlice.reducer;