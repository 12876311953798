import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams, } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Typography,
  Paper,
  Grid,
  Divider
} from "@mui/material";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import CareCondition from "./Condition";
import QueAnsCard from "./QueAnsCard";
import { CustomScrollbars } from "../../../../../../../../../ui-component/ScrollBar";
import { CarePlanApi } from "../../../../../../../../../service/CarePlan/careAPI";
import { useNotification } from "../../../../../../../../../hooks/use-notification";
import { isCarePlanAssign } from "../../../../../../../../../store/slices/queAns";
import SetDefaultBox from "../../../../../../../../../ui-component/SetDefaultBox";
import CircularLoader from "../../../../../../../../../ui-component/CircularLoader";


export default function CarePlanStepper({ handleClose, setRefreshPatientData }) {

  const steps = [
    { label: "Care Plan" },
    { label: "Goals" },
    { label: "Barriers" },
    { label: "Symptoms" },
    { label: "Interventions" },
    { label: "Support" },
    { label: "Allergies" },
    { label: "Medications" },
    { label: "Numbers To Track" },
    { label: "Expected Outcomes" },
  ];

  const { id } = useParams('id');
  console.log("id???", id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // const location = useLocation()
  // const patienId= location.state.patienId
  const { notifySuccess, notifyError } = useNotification();
  const { answer } = useSelector((state) => state);
  const [defaults, setDefault] = useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [prevStep, setPrevStep] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  console.log("data", data)
  const [options, setOptions] = useState();
  const [conditionData, setConditionData] = useState();
  const [condition, setCondition] = useState();
  console.log("conditionId==", condition);

  console.log("activeStep", activeStep);

  const [formData, setFormData] = useState({
    step1Data: {},
    step2Data: {},
    step3Data: {},
    step4Data: {},
  });

  console.log("formDataMain", formData);

  const handleNext = () => {
    setPrevStep(activeStep);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    console.log("first activeStep", activeStep)
    setPrevStep(activeStep);
    if (activeStep === 1) {
      // //  window.alert("hello")
      setDefault(true)
    }
    else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepSubmit = (stepData) => {
    setFormData((prevData) => ({
      ...prevData,
      [`step${activeStep + 1}Data`]: stepData,
    }));
    if (activeStep > 0) {
      handleNext();
    }
  };

  const handleSubmitAll = (answers) => {
    console.log("last step")
    console.log("All form data:", answers);
    const body = {
      careplan: {
        name: condition?.name
      },
      goals: answers?.goals,
      questions: answers?.quaAns?.map((item) => {
        const [step, question] = item.question.split("_");
        return {
          question,
          answer: item.answer
        };
      }),

    };
    setLoading(true);
    dispatch((isCarePlanAssign(false)))
    CarePlanApi.assignCarePlan(id, body)
      .then((response) => {
        notifySuccess("Care Plan Assigned Successfully!");
        dispatch((isCarePlanAssign(true)))
        handleClose();
        setRefreshPatientData(true);
        navigate(`/patients/profile/${id}`, {
          state: { enrolled_status: location?.state?.enrolled_status, patientId: id, care_team_member: location?.state?.care_team_member },
        });
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error);
        setLoading(false);
        setActiveStep(0);
      });

  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const validationSchema = Yup.object().shape({
    condition: Yup.string().required("Condition is required"),
    conditionName: Yup.object().required("Condition Name is required"),
  })

  const renderStepContent = (step, values) => {
    switch (step) {
      case 0:
        return <CareCondition onSubmit={handleStepSubmit} values={values} select={options} />;
      case 1:
        return <QueAnsCard onSubmit={handleStepSubmit} handleBack={handleBack} values={values} data={data.filter(item => item.category === "goals")} goals={true} loading={loading} activeStep={activeStep} />;
      case 2:
        return <QueAnsCard onSubmit={handleStepSubmit} handleBack={handleBack} values={values} data={data.filter(item => item.category === "barrier")} activeStep={activeStep} />;
      case 3:
        return <QueAnsCard onSubmit={handleStepSubmit} handleBack={handleBack} values={values} data={data.filter(item => item.category === "symptom")} activeStep={activeStep} />;
      case 4:
        return <QueAnsCard onSubmit={handleStepSubmit} handleBack={handleBack} values={values} data={data.filter(item => item.category === "interventions")} activeStep={activeStep} />;
      case 5:
        return <QueAnsCard onSubmit={handleStepSubmit} handleBack={handleBack} values={values} data={data.filter(item => item.category === "support")} activeStep={activeStep} />;
      case 6:
        return <QueAnsCard onSubmit={handleStepSubmit} handleBack={handleBack} values={values} data={data.filter(item => item.category === "allergies")} activeStep={activeStep} />;
      case 7:
        return <QueAnsCard onSubmit={handleStepSubmit} handleBack={handleBack} values={values} data={data.filter(item => item.category === "medications")} activeStep={activeStep} />;
      case 8:
        return <QueAnsCard onSubmit={handleStepSubmit} handleBack={handleBack} values={values} data={data.filter(item => item.category === "numbers_to_track")} activeStep={activeStep} />;
      case 9:
        return <QueAnsCard onSubmit={handleSubmitAll} handleBack={handleBack} values={values} data={ data.filter(item => item.category === "expected_outcomes")} activeStep={activeStep}/>;
    }
  };

  const saveAndContinue = () => {
    handleStepSubmit(formData[`step${activeStep + 1}Data`]);
    if (activeStep < steps.length - 1) {
      handleNext();
    } else {
      // Handle submission when all steps are completed
      handleSubmitAll();
    }
  };

  useEffect(() => {
    if (condition) {
      setLoading(true);
      CarePlanApi.getCareQuestionsById(condition.uuid)
        .then((response) => {
          setData(response.data);
          setLoading(false);
        })
        .catch((error) => {
          notifyError(error?.response?.data?.error);
          setLoading(false);
        });
    }
  }, [condition]);


  useEffect(() => {
    CarePlanApi.getCareConditions(answer?.conditonName?.name || "", answer?.icd_code?.code || "")
      .then((response) => {
        const outputArray = response?.data?.map((item) => ({
          label: item.name,
          name: item.name,
        }));
        setOptions(outputArray);
        setConditionData(response?.data);
        // navigate(`/patient/profile/${id}`)
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error);
        handleClose();
        // setLoading(true);
      });
  }, [answer]);


  return (<>
    <SetDefaultBox handleClose={() => { setActiveStep(1); setDefault(false) }}
      open={defaults}
      handleSubmit={() => { setActiveStep(0); setDefault(false) }} FormResetConfirm={true} />
    <Formik
      initialValues={{
        condition: "",
        icd_code: "",
        conditionName: ""
      }}

      validationSchema={validationSchema}
      onSubmit={(data) => {
        console.log("object????", data)
        const matchingObject = conditionData.find(item => item.name === data.condition);

        // const uuid = matchingObject ? matchingObject.uuid : null;
        setCondition(matchingObject)
        console.log("matchingObject", matchingObject);
        saveAndContinue(); // This will trigger the next step
      }}
      enableReinitialize
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form>
          {loading ?
            <Grid items>
              <CircularLoader />
            </Grid> :
            <Grid container padding={2}>
              <Grid container xs={12}>
                <Grid item xs={6}>
                  <Typography variant="cardTitle">Add Care Plan</Typography>
                </Grid>
                {activeStep === 0 && <>

                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginBottom: 2,
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ textTransform: "none", width: "163px" }}
                      // onClick={handleClose}
                      onClick={activeStep === 0 ? handleClose : handleBack}

                    >
                      Back
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        textTransform: "none",
                        width: "163px",
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                      }}
                      onClick={handleSubmit}
                    // onClick={handleNext}

                    >
                      {activeStep === 9 ? "Finish" : "Save & Next"}

                    </Button>
                  </Grid></>}
              </Grid>
              <Grid conatiner xs={12} sx={{ display: "flex", }}>
                <Grid item xs={3}>
                  <Box sx={{ maxWidth: 400 }}>
                    <Stepper activeStep={activeStep} orientation="vertical" sx={{ width: "100%", height: "100%" }}>
                      {steps.map((step, index) => (
                        <Step key={step.label}>
                          <StepLabel
                          // optional={
                          //   index === 8 ? (
                          //     <Typography variant="caption">
                          //       Last step
                          //     </Typography>
                          //   ) : null
                          // }
                          >
                            {step.label}
                          </StepLabel>
                          <StepContent>
                          </StepContent>
                        </Step>
                      ))}
                    </Stepper>
                    {activeStep === steps.length && (
                      <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>
                          All steps completed - you&apos;re finished
                        </Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                          Reset
                        </Button>
                      </Paper>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  {/* <CustomScrollbars height="86vh"> */}
                  {renderStepContent(activeStep, values)}
                  {/* </CustomScrollbars> */}
                </Grid>
              </Grid>
            </Grid>}
        </Form>
      )}
    </Formik>
  </>

  );
}
