import { Grid, Typography, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { customNotificationTableStyle } from "../../../../ccm-constant";
import { useNotification } from "../../../../hooks/use-notification";
import { SettingsApi } from "../../../../service/Settings/Index";
import CircularLoader from "../../../../ui-component/CircularLoader";
import SetDefaultBox from "../../../../ui-component/SetDefaultBox";

function NotificationSettings({
  saveClick,
  setSave,
  setLoading,
  SaveButton,
  setSaveButton,
  confirm, setConfirm,
  defaultClicked,setDefaultClicked
}) {
  const { notifySuccess, notifyError } = useNotification();
  const [loadingData, setLoadingData] = useState(false);
  // const [confirm, setConfirm] = useState(false);
  const initialNotificationSettings = [
    {
      id: 0,
      notification_type: "Alerts",
      settings: [
        {
          type: "Low",
          text: false,
          email: false,
          push_notification: false,
        },
        {
          type: "Medium",
          text: false,
          email: false,
          push_notification: false,
        },
        {
          type: "High",
          text: false,
          email: false,
          push_notification: false,
        },
      ],
      sortable: false,
    },
    {
      id: 1,
      notification_type: "Billing",
      settings: [
        {
          type: "Monthly Bill Generate",
          text: false,
          email: false,
          push_notification: false,
        },
        {
          type: "Payment Reminder",
          text: false,
          email: false,
          push_notification: false,
        },
      ],
      sortable: false,
    },
    {
      id: 2,
      notification_type: "Task",
      settings: [
        {
          type: "Reminder",
          text: false,
          email: false,
          push_notification: false,
        },
      ],
      sortable: false,
    },
    // {
    //   id: 3,
    //   notification_type: "Note",
    //   settings: [
    //     {
    //       type: "Add",
    //       text: false,
    //       email: false,
    //       push_notification: false,
    //     },
    //     {
    //       type: "Delete",
    //       text: false,
    //       email: false,
    //       push_notification: false,
    //     },
    //     {
    //       type: "Update",
    //       text: false,
    //       email: false,
    //       push_notification: false,
    //     },
    //   ],
    //   sortable: false,
    // },
  ];
  const [checkboxValues, setCheckboxValues] = useState();
  // initialNotificationSettings

  const columns = [
    {
      name: "Notification Names",
      selector: (row)=> row.notification_type,
      sortable: false,
      spanAbove: true, // Merge cells in this column
      style: {
        textAlign: "left",
        fontStyle: "normal",
        fontVariant: "bold",
        lineHeight: "26px",
        color: "#4C4C4CB2",
        // fontSize: "1px",
        opacity: 1,
      },
      width:"200px",
    },
    {
      name: "Type",
      selector: (row)=> row.settings,
      cell: (row) =>
        row.settings.map((setting, index) => (
          <div key={index}>{setting.type}</div>
        )),
      sortable: false,
      style: {
        borderBottom: "0px",
      },
      minWidth:"250px",
    },
    {
      name: "Text",
      selector: (row)=> row.settings,
      cell: (row) =>
        row.settings.map((setting, index) => (
          <div key={index}>
            <Checkbox
              checked={setting.text}
              onClick={() => handleCheckboxClick(row.id, "text")}
            />
          </div>
        )),
      sortable: false,
      style: {
        borderRight: "none",
      },
    },
    {
      name: "Email",
      selector: (row)=> row.settings,
      cell: (row) =>
        row.settings.map((setting, index) => (
          <div key={index}>
            <Checkbox
              checked={setting.email}
              onClick={() => handleCheckboxClick(row.id, "email")}
            />
          </div>
        )),
      sortable: false,
      style: {
        borderRight: "none",
      },
    },
    {
      name: "Push Notification",
      selector: (row)=> row.settings,
      cell: (row) =>
        row.settings.map((setting, index) => (
          <div key={index}>
            <Checkbox
              checked={setting.push_notification}
              onClick={() => handleCheckboxClick(row.id, "push_notification")}
            />
          </div>
        )),
        width:"200px",
      sortable: false,
      style: {
        borderRight: "none",
      },
    },
  ];

  useEffect(() => {
    if (!SaveButton) setCheckboxValues(checkboxValues);
  }, [SaveButton]);

  const handleCheckboxClick = (notificationIndex, field) => {
    setSaveButton(true);
    const updatedSettings = [...checkboxValues];
    updatedSettings[notificationIndex.split("_")[0]].settings[
      notificationIndex.split("_")[1]
    ][field] =
      !updatedSettings[notificationIndex.split("_")[0]].settings[
        notificationIndex.split("_")[1]
      ][field];

    setCheckboxValues(updatedSettings);
    console.log("updatedSettings", updatedSettings);
  };

  // useEffect(() => {
  //   // formRef.current?.validateForm();
  //   if (saveClick) {
  //     // formRef.current?.submitForm();
  //     // if(!formRef.current?.errors){
  //     console.log("formRef 6666", checkboxValues);
  //     // handleSaveClick(formRef.current.values)
  //     // }
  //     // else{
  //     setSave(false);
  //     // }
  //   }
  // }, [saveClick]);

  useEffect(() => {
    getChatNotification();
    setSaveButton(false);
  }, []);

  useEffect(() => {
    if (saveClick) {
      if (!confirm && defaultClicked) {
        setConfirm(true);
      }
      else {
        updateNotification(checkboxValues);
      }
    } else if (SaveButton) {
      setCheckboxValues(checkboxValues);
    } 
  }, [saveClick,SaveButton]);

  // Flatten the settings into individual rows
  const notificationSettings = checkboxValues?.flatMap((item) =>
    item.settings.map((setting, index) => ({
      id: `${item.id}_${index}`,
      notification_type: index === 0 ? item.notification_type : "",
      settings: [setting],
      noDivider: true,
    }))
  );

  const updateNotification = (updatedSettings) => {
    console.log(
      "first updatedSettings",
      updatedSettings,
      updatedSettings[3]?.settings[0]?.text
    );
    let payload = {
      user_notification: {
        // note: {
        //   add: {
        //     text: updatedSettings[3]?.settings[0]?.text,
        //     email: updatedSettings[3]?.settings[0]?.email,
        //     push_notification:
        //       updatedSettings[3]?.settings[0]?.push_notification,
        //   },
        //   delete: {
        //     text: updatedSettings[3]?.settings[1]?.text,
        //     email: updatedSettings[3]?.settings[1]?.email,
        //     push_notification:
        //       updatedSettings[3]?.settings[1]?.push_notification,
        //   },
        //   update: {
        //     text: updatedSettings[3]?.settings[2]?.text,
        //     email: updatedSettings[3]?.settings[2]?.email,
        //     push_notification:
        //       updatedSettings[3]?.settings[2]?.push_notification,
        //   },
        // },
        task: {
          reminder: {
            text: updatedSettings[2]?.settings[0]?.text,
            email: updatedSettings[2]?.settings[0]?.email,
            push_notification:
              updatedSettings[2]?.settings[0]?.push_notification,
          },
        },
        alerts: {
          low: {
            text: updatedSettings[0]?.settings[0]?.text,
            email: updatedSettings[0]?.settings[0]?.email,
            push_notification:
              updatedSettings[0]?.settings[0]?.push_notification,
          },
          high: {
            text: updatedSettings[0]?.settings[2]?.text,
            email: updatedSettings[0]?.settings[2]?.email,
            push_notification:
              updatedSettings[0]?.settings[2]?.push_notification,
          },
          medium: {
            text: updatedSettings[0]?.settings[1]?.text,
            email: updatedSettings[0]?.settings[1]?.email,
            push_notification:
              updatedSettings[0]?.settings[1]?.push_notification,
          },
        },
        billing: {
          monthly_billing: {
            text: updatedSettings[1]?.settings[0]?.text,
            email: updatedSettings[1]?.settings[0]?.email,
            push_notification:
              updatedSettings[1]?.settings[0]?.push_notification,
          },
          payment_reminder: {
            text: updatedSettings[1]?.settings[1]?.text,
            email: updatedSettings[1]?.settings[1]?.email,
            push_notification:
              updatedSettings[1]?.settings[1]?.push_notification,
          },
        },
      },
    };
    SettingsApi.updateNotification({
      ...payload,
      default: SaveButton ? false : true,
    })
      .then((response) => {
        getChatNotification();
        notifySuccess(response?.message);
        setSaveButton(false);
        setSave(false);
        setConfirm(false)
        setDefaultClicked(false)
      })
      .catch((error) => {
        notifyError(error?.response?.data?.error);
        console.error("Error updating settings:", error);
        notifyError("Something went wrong!");
      });
  };

  const getChatNotification = () => {
    setLoadingData(true);
    setLoading(true);
    SettingsApi.getNotification()
      .then((response) => {
        console.log("getNotification", response?.data?.user_notification);
        setCheckboxValues([
          {
            id: 0,
            notification_type: "Alerts",
            settings: [
              {
                type: "Low",
                text: response?.data?.user_notification?.alerts?.low?.text,
                email: response?.data?.user_notification?.alerts?.low?.email,
                push_notification:
                  response?.data?.user_notification?.alerts?.low
                    ?.push_notification,
              },
              {
                type: "Medium",
                text: response?.data?.user_notification?.alerts?.medium?.text,
                email: response?.data?.user_notification?.alerts?.medium?.email,
                push_notification:
                  response?.data?.user_notification?.alerts?.medium
                    ?.push_notification,
              },
              {
                type: "High",
                text: response?.data?.user_notification?.alerts?.high?.text,
                email: response?.data?.user_notification?.alerts?.high?.email,
                push_notification:
                  response?.data?.user_notification?.alerts?.high
                    ?.push_notification,
              },
            ],
            sortable: false,
          },
          {
            id: 1,
            notification_type: "Billing",
            settings: [
              {
                type: "Monthly Bill Generate",
                text: response?.data?.user_notification?.billing
                  ?.monthly_billing?.text,
                email:
                  response?.data?.user_notification?.billing?.monthly_billing
                    ?.email,
                push_notification:
                  response?.data?.user_notification?.billing?.monthly_billing
                    ?.push_notification,
              },
              // {
              //   type: "Payment Reminder",
              //   text: response?.data?.user_notification?.billing
              //     ?.payment_reminder?.text,
              //   email:
              //     response?.data?.user_notification?.billing?.payment_reminder
              //       ?.email,
              //   push_notification:
              //     response?.data?.user_notification?.billing?.payment_reminder
              //       ?.push_notification,
              // },
            ],
            sortable: false,
          },
          {
            id: 2,
            notification_type: "Task",
            settings: [
              {
                type: "Reminder",
                text: response?.data?.user_notification?.task?.reminder?.text,
                email: response?.data?.user_notification?.task?.reminder?.email,
                push_notification:
                  response?.data?.user_notification?.task?.reminder
                    ?.push_notification,
              },
            ],
            sortable: false,
          },
          // {
          //   id: 3,
          //   notification_type: "Note",
          //   settings: [
          //     {
          //       type: "Add",
          //       text: response?.data?.user_notification?.note?.add?.text,
          //       email: response?.data?.user_notification?.note?.add?.email,
          //       push_notification:
          //         response?.data?.user_notification?.note?.add
          //           ?.push_notification,
          //     },
          //     {
          //       type: "Delete",
          //       text: response?.data?.user_notification?.note?.delete?.text,
          //       email: response?.data?.user_notification?.note?.delete?.email,
          //       push_notification:
          //         response?.data?.user_notification?.note?.delete
          //           ?.push_notification,
          //     },
          //     {
          //       type: "Update",
          //       text: response?.data?.user_notification?.note?.update?.text,
          //       email: response?.data?.user_notification?.note?.update?.email,
          //       push_notification:
          //         response?.data?.user_notification?.note?.update
          //           ?.push_notification,
          //     },
          //   ],
          //   sortable: false,
          // },
        ]);
        setLoadingData(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setSave(false);
  };

  const hideBordersForRow = (row) => {
    console.log("hideBordersForRow",row)
    return ['row-0_2','row-1_0', 'row-2_0', 'row-3_2'].includes(row.id); // Customize this condition as needed
  }

  const customRowStyles = (row) => ({
    ...(hideBordersForRow(row) ? { borderBottom: "1px solid #4C4C4C33;",paddingBottom: "10px", marginBottom: "10px" } : {}),
  });

  return (
    <>
      {confirm ? (
        <SetDefaultBox
        handleClose={() => {setConfirm(false); setDefaultClicked(false); setSave(false)}}
        open={confirm}
        handleSubmit={() => updateNotification(checkboxValues)}
      />
      ) : null}
      <div style={{ position: "relative" }}>
        <Grid
          container
          spacing={1}
          padding={1}
          style={{
            minHeight: "72.5vh",
          }}
        >
          {loadingData ? (
            <CircularLoader />
          ) : (
            <Grid container spacing={1} paddingLeft={3} paddingRight={3}>
              <Grid item xs={12}>
                <DataTable
                  columns={columns}
                  data={notificationSettings}
                  responsive
                  customStyles={{
                    ...customNotificationTableStyle,
                    rows: {
                      style: customRowStyles,
                    },
                  }}
                  customTableStyles={{
                    style: {
                      borderCollapse: "collapse", // Make sure the border-collapse is set to collapse
                    },
                  }}
                  noDivider={true}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    </>
  );
}

export default NotificationSettings;
