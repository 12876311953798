import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import MoreIcon from "@mui/icons-material/MoreVert";
import { IconArrowsSort } from "@tabler/icons-react";
import {
  Grid,
  IconButton,
  Popover,
  Menu,
  MenuItem,
  CircularProgress,
  CardActions,
} from "@mui/material";
import DrawerComponent from "../../../../../../../../../../ui-component/Drawer";
import { customDataTableStyles, formatDate } from "../../../../../../../../../../ccm-constant";
import { PatientApi } from "../../../../../../../../../../service/ProviderGroup/Patient";
import { useNotification } from "../../../../../../../../../../hooks/use-notification";
import CustomPagination from "../../../../../../../../../../ui-component/Pagination";
import { CustomScrollbars } from "../../../../../../../../../../ui-component/ScrollBar";
import { useProviderContext } from "../../../..";
import ConfirmBox from "../../../../../../../../../../ui-component/ConfirmBox";
import ConditionsForm from "./Components/ConditionsForm";
import cardStyle from "../../../../../../../../../../theme/card-layout";

const Conditon = ({ setOpenDrawer, openDrawer, setRefreshPatientData }) => {
  const { searchText } = useProviderContext();
  const { id } = useParams();
  let userType = JSON.parse(localStorage.getItem("user"))?.tokens?.userRole;
  let ProviderId =
    userType === "provider_group"
      ? JSON.parse(localStorage.getItem("user"))?.user?.data?.uuid
      : JSON.parse(localStorage.getItem("user"))?.user?.data?.provider_group?.uuid;
  const ProviderRole = JSON.parse(localStorage.getItem("user"))?.user?.data?.role;
  const { notifySuccess, notifyError } = useNotification();
  const [sortedColumn, setSortedColumn] = useState("");
  const [sortDirection, setSortDirection] = useState("-id");
  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [allData, setAllData] = useState();
  const [refreshList, setRefreshList] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [IsDelete, setIsDelete] = useState(false);
  const ConditionsColumns = [
    {
      name: "No",
      selector: (row, id) => id + 1 + (currentPage ? (currentPage - 1) * 10 : ""),
      // sortable: true,
      width: "8%",
    },
    {
      name: "Conditions Name",
      selector: (row) => (
          <div
            // style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            style={{
              whiteSpace: 'pre-line',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              // overflow: 'hidden',
              // WebkitLineClamp: 3,
            }}
          >
            {row.name}
          </div>
      ),
      sortable: true,
      minWidth: "200px",
    },
    {
      name: "Status",
      selector: (row) => row?.status?.charAt(0)?.toUpperCase() + row?.status?.slice(1),
      sortable: true,
      minWidth: "15%",
    },
    {
      name: "Type",
      selector: (row) => row?.type_condition?.charAt(0)?.toUpperCase() + row?.type_condition?.slice(1),
      sortable: true,
      minWidth: "15%",
    },
    {
      name: "Onset Date",
      selector: (row) => formatDate(row?.onset_date),
      sortable: true,
      minWidth: "160px",
    },
    {
      name: "Last Occurrence",
      selector: (row) => <span>{row?.last_occurance ? formatDate(row?.last_occurance) : "-"}</span>,
      sortable: true,
      minWidth: "190px",
    },
    {
      name: "",
      selector: "",
      width: "8%",
      cell: (row) => {
        if (
          userType === "provider" &&
          (ProviderRole === "Care Team" || ProviderRole === "Physician")
        ) {
          return <MoreButton row={row} />;
        } else {
          return null;
        }
      },
    },
  ];

  const getAllConditions = () => {
    setLoading(false);
    console.log("I am calling", currentPage);
    const query = {
      search: searchText,
      limit: 10,
      offset:
        currentPage === 0
          ? currentPage
          : searchText
            ? 0
            : (currentPage - 1) * 10,
      order_by: sortDirection,
    };

    PatientApi.getAllConditions(id, query)
      .then((response) => {
        setUserData(response.data);
        setPageCount(response.count);
        setAllData(response);
        setLoading(true);
        setRefreshList(false);

        console.log("response.data", response.data);
      })
      .catch((error) => {
        console.error("Error fetching Care Team data:", error);
        setLoading(true);
      });
  };

  const handleSort = (column, sortDirection) => {
    console.log("sortDirection", sortDirection);
    switch (column.name) {
      case "Conditions Name":
        setSortDirection(sortDirection === "desc" ? "-name" : "name");
        break;
      case "Status":
        setSortDirection(
          sortDirection === "desc" ? "-status" : "status"
        );
        break;
      case "Type":
        setSortDirection(sortDirection === "desc" ? "-type_condition" : "type_condition");
        break;
      case "OnsetDate":
        setSortDirection(sortDirection === "desc" ? "-onset_date" : "onset_date");
        break;
      case "Last Occurrence":
        setSortDirection(
          sortDirection === "desc" ? "-last_occurance" : "last_occurance"
        );
        break;
      default:
        setSortDirection("-id");
    }
  };

  const MoreButton = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
      <div>
        <IconButton onClick={handleClick}>
          <MoreIcon />
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() => {
                setSelectedRow(row);
                setOpenDrawer(true);
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                setSelectedRow(row);
                setIsDelete(true);
              }}
            >
              Delete
            </MenuItem>
          </Menu>
        </Popover>
      </div>
    );
  };

  useEffect(() => {
    if (refreshList) {
      getAllConditions();
      setSelectedRow(null);
    }
  }, [refreshList]);

  useEffect(() => {
    const delay = setTimeout(
      () => {
        getAllConditions();
      },
      searchText ? 1000 : 100
    );

    return () => {
      clearTimeout(delay);
    };
  }, [searchText, sortDirection, sortedColumn, currentPage]);

  const handleDeleteSubmit = () => {
    PatientApi.deleteConditions(selectedRow?.uuid)
      .then((response) => {
        setRefreshList(true);
        setSelectedRow(null);
        setIsDelete(false);
        setRefreshPatientData(true);
        notifySuccess("Conditions deleted successfully");
        console.log("response.data", response.data);
      })
      .catch((error) => {
        console.error("Error addAllergies data:", error);
        notifyError(error?.response?.data?.error);
      });
  };

  return (
    <Grid>
      {IsDelete && (
        <ConfirmBox
          handleClose={() => setIsDelete(false)}
          open={true}
          handleSubmit={() => handleDeleteSubmit()}
        />
      )}
      <Grid item xs={12} sx={{ height: "68vh", position: "relative" }}>
        <Grid
          container
          xs={12}
          padding={1}
          sx={{ flex: 1, alignItems: "center", marginBottom: "10px" }}
        >
          <CustomScrollbars height="58vh">
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {loading ? (
                <DataTable
                  columns={ConditionsColumns}
                  data={userData}
                  sortIcon={<IconArrowsSort size={18} marginLeft={1} />}
                  onSort={handleSort}
                  highlightOnHover
                  pointerOnHover
                  customStyles={customDataTableStyles}
                />
              ) : (
                <CircularProgress color="primary" sx={{ marginTop: "15%" }} />
              )}
            </Grid>
          </CustomScrollbars>
          <DrawerComponent
            open={openDrawer}
            handleClose={() => {
              setOpenDrawer(false);
            }}
            data={
              <ConditionsForm
                setOpenDrawer={setOpenDrawer}
                setRefreshList={setRefreshList}
                selectedData={selectedRow}
                setSelectedRow={setSelectedRow}
                setRefreshPatientData={setRefreshPatientData}
              />
            }
          />
        </Grid>
        <CardActions sx={{ position: "absolute", bottom: 0, width: "100%" }}>
          {pageCount !== 0 && loading && (
            <Grid item xs={12} marginTop={2}>
              <CustomPagination
                count={pageCount}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                data={allData}
              />
            </Grid>
          )}
        </CardActions>
      </Grid>
    </Grid>
  );
};

export default Conditon;
