import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider, Typography } from "@mui/material";

function SetDefaultBox({ open, handleSubmit, handleClose, unassignCof, FormResetConfirm, title, timeLog }) {
  return (
    <Dialog open={open} sx={{ padding: "5px" }}>
      <DialogTitle>
        <Typography
          variant="h6"
          sx={{ textAlign: "center", paddingBottom: "8px", fontSize: "22px" }}
        >
          {`${unassignCof ? 'Unassign Care Team' : FormResetConfirm ? "Are You Sure ?" : title === "Stop Medication" ? "Stop Medication" : title ? 'Unassign Assessment' : timeLog ? "Time Log" :'Set Default'}`}
        </Typography>
        <Divider orientation="horizontal" sx={{ color: "#00000099" }} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText variant="body1" textAlign="center" sx={{ fontSize: "17px" }}>
          Are you sure you want to {`${unassignCof ? 'unassign care team' : FormResetConfirm ? 'discard changes' : title === "Stop Medication" ? 'stop medication' : title ? 'unassign assessment' : timeLog ? "save time log" :'set default settings'}`} ?
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ padding: "15px" }}>
        <Button variant="outlined" color="primary" onClick={(e)=> {e.preventDefault(); handleClose()}} sx={{ textTransform: 'none', }}>
          No
        </Button>
        <Button variant="contained" color="primary" onClick={(e)=> {e.preventDefault();  handleSubmit() }} sx={{ textTransform: 'none', }}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SetDefaultBox;
