import { CircularProgress } from '@mui/material'
import React from 'react'

function CircularLoader() {
  return (
    // <div style={{ position: "relative" }}>
        <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          >
            <CircularProgress size={50} thickness={4} color="primary" />
          </div>
        // </div>
  )
}

export default CircularLoader