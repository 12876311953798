// questionsSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questions: [],
  createAssesment: {},
  previewQuestions: []
};

const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    addQuestion: (state, action) => {
      console.log("object", action.payload.element);

      // Check if the element exists in the questions array
      const existingIndex = state.questions.findIndex((question) => question.element === action.payload.element && question.title === action.payload.title);

      if (existingIndex !== -1) {
        // Update existing question at the specified index
        state.questions[existingIndex] = action.payload;
      } else {
        // Add new question if the element doesn't exist
        state.questions= [action.payload,...state.questions];
      }
    },
    removeQuestion: (state, action) => {
      console.log("action",action)
      // Use the action.payload as the index to remove the question
      const indexToRemove = action.payload;

      // Remove the question with the matching index
      state.questions.splice(indexToRemove, 1);
    },
    createFormData: (state, action) => {
      state.createAssesment = action.payload
    },
    addPreviewQuestions: (state, action) => {
      state.previewQuestions.push(action.payload);
     },
     clearState: (state, action) => {
      state.questions = []
      state.createAssesment = {}
      state.previewQuestions = []
     }
  },
});

export const { addQuestion, removeQuestion, createFormData, addPreviewQuestions, clearState} = questionsSlice.actions;

export default questionsSlice.reducer;
